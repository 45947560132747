import {
  DEFAULT_SELECTED_ENTITY_IDS,
  useSelectedEntityId,
} from "../../../../store/model-entity-selection";
import { useEnsureData } from "../../../../helpers/use-ensure-data";
import _ from "lodash";

export function useLoading(): boolean {
  const s0 = useSelectedEntityId("student");
  const l0 = DEFAULT_SELECTED_ENTITY_IDS.student === s0;
  const l1 = useEnsureData("ecp_student");
  return _.max([l0, l1]);
}
