import { TableModel, tableModelFactory } from "../table-model-factory";

export interface TeacherRetentionKpisModel extends TableModel {}

export function getTeacherRetentionKpisModel(): TeacherRetentionKpisModel {
  return {
    ...tableModelFactory(
      "teacherRetentionKpis",
      "teacher_retention_kpis",
      getTeacherRetentionKpisRowId
    ),
  };
}

function getTeacherRetentionKpisRowId(row) {
  return [row.TeacherIndex.toString(), row.StudentIndex.toString()].join("|");
}
