import { TableModel, tableModelFactory } from "../table-model-factory";
import { computed, Computed } from "easy-peasy";
import { StoreModel } from "../model";
import { LoyaltyYear } from "../../pages/loyalty/types";
import _ from "lodash";

interface StatisticsForSingleLoyaltyEmailTypeCode {
  loyalty_year: LoyaltyYear;
  email_type_code: string; //EmailTypeCodeT
  email_type_name?: string;
  id: string; //EmailTypeCodeT
  campaign_number: number; // int
  campaign_category: string;
  n_emails_sent: number; // int
  nuniq_students: number; // int
  n_opened_by_TO: number; // int
  pct_opened_by_TO: number; // float
  n_re_enrollments: number; // int
  incremental_n_re_enrollments: number; // int
}

type StatisticsForSingleLoyaltyYear = StatisticsForSingleLoyaltyEmailTypeCode[];
type StatisticsForAllLoyaltyYears = StatisticsForSingleLoyaltyEmailTypeCode[];
type ByLoyaltyYear = {
  [loyaltyYear: string]: StatisticsForSingleLoyaltyEmailTypeCode[];
};

export type LoyaltyStatisticsModel =
  TableModel<StatisticsForSingleLoyaltyEmailTypeCode> & {
    byLoyaltyYear: Computed<LoyaltyStatisticsModel, ByLoyaltyYear, StoreModel>;
  };

export function getLoyaltyStatisticsModel(): LoyaltyStatisticsModel {
  return {
    ...tableModelFactory<StatisticsForSingleLoyaltyEmailTypeCode>(
      "loyaltyStatistics",
      "loyalty_statistics",
      (row) => row.id,
      [],
      null,
      null
    ),
    byLoyaltyYear: computed([(s) => s.initialData], (initialData) =>
      _.groupBy(initialData, "loyalty_year")
    ),
  };
}
