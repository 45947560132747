import { AgColDef } from "../../../components/AgTable/types";
import { useStoreActions } from "../../../hooks/ep";
import _ from "lodash";
import { toaster } from "../../../toaster";
import { CellValueChangedEvent } from "@ag-grid-community/core/dist/es6/events";

export const ALL_LOYALTY_RENR_CAMPAIGN_TYPES = [
  "normal",
  "academy",
  "academy_potential",
  "no_email",
];

export function useStudentAttr_LoyaltyRenrCampaignType_ColDef(): AgColDef {
  const handleUpdate = useStoreActions(
    (a) => a.student_attr.handleUpdateStudentAttr
  );
  return {
    field: "student_attr__loyalty_renr_campaign_type",
    headerName: "Email Type",
    headerTooltip: "_",
    tooltipComponent: "studentAttr_loyaltyRenrCampaignType_tooltip",
    type: "categoryColumn",
    width: 134,
    editable: true,
    singleClickEdit: true,
    cellClass: (params) => {
      return ["bp3-minimal", "bp3-tag", "bp3-interactive", "bp3-small"];
    },
    valueParser: (params) => _.trim(params.newValue || ""),
    valueSetter: (params) => {
      const isValid =
        typeof params.newValue === typeof "string" &&
        params.newValue !== params.oldValue &&
        ALL_LOYALTY_RENR_CAMPAIGN_TYPES.includes(params.newValue);
      if (isValid) {
        params.data.student_attr__loyalty_renr_campaign_type = params.newValue;
        return true;
      } else {
        toaster.error(
          `Please submit one of the following: ${ALL_LOYALTY_RENR_CAMPAIGN_TYPES.join(
            ", "
          )}`
        );
        return false;
      }
    },
    onCellValueChanged: async (ev: CellValueChangedEvent) => {
      handleUpdate({
        student_id: ev.data.StudentIndex,
        attr_codename: "loyalty_renr_campaign_type",
        attr_oldvalue: ev.oldValue,
        attr_newvalue: ev.newValue,
        student_snapshot: { ...ev.data },
      });
    },
  };
}
