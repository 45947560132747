export function getReeCustomerInteractionFormErrorMessage({
  callTopicsQueryText,
  callTopics,
  customerIssuesQueryText,
  customerIssues,
  notes,
  customerSentiment,
  churnLikelihood,
  attendees,
}) {
  const loneCallTopicNeedsExplanation =
    callTopics.length === 1 && callTopics[0].codeName === "other";
  const loneCustomerIssueNeedsExplanation =
    customerIssues.length === 1 && customerIssues[0].codeName === "other";
  const loneOtherNeedsExplanation =
    loneCallTopicNeedsExplanation || loneCustomerIssueNeedsExplanation;

  if (!!callTopicsQueryText) {
    return queryTextErrorMessage(callTopicsQueryText, "Call Topics");
  } else if (!callTopics.length) {
    return multiselectErrorMessage("Call Topics");
  } else if (!!customerIssuesQueryText) {
    return queryTextErrorMessage(customerIssuesQueryText, "Student Issues");
  } else if (!customerIssues.length) {
    return multiselectErrorMessage("Student Issues");
  } else if (loneOtherNeedsExplanation && !notes) {
    return textualErrorMessage("Notes");
  } else if (!customerSentiment) {
    return selectionErrorMessage("Student Sentiment");
  } else if (!churnLikelihood) {
    return selectionErrorMessage("Churn Likelihood");
  } else {
    return null;
  }
}
export function getEcpCustomerInteractionFormErrorMessage({
  interactionType,
  emailAddress,
  phoneNumber,
  riskReasonsQueryText,
  riskReasons,
  notes,
}) {
  const itxnIsEmail = interactionType === "Email";
  const itxnIsPhone = interactionType === "Phone";

  const emailAddressMissing = !emailAddress;
  const phoneNumberMissing = !phoneNumber;

  const emailAddressImproperlyMissing = emailAddressMissing && itxnIsEmail;
  const phoneNumberImproperlyMissing = phoneNumberMissing && itxnIsPhone;

  if (!interactionType) {
    return selectionErrorMessage("Interaction Type");
  } else if (emailAddressImproperlyMissing) {
    return singleselectErrorMessage("Email Address");
  } else if (phoneNumberImproperlyMissing) {
    return singleselectErrorMessage("Phone Number");
  } else if (!!riskReasonsQueryText) {
    return queryTextErrorMessage(riskReasonsQueryText, "Risk Reasons");
  } else {
    return null;
  }
}

export function getLeadCustomerInteractionFormErrorMessage({
  interactionType,
  interactionResult,
  notes,
}) {
  if (!interactionType) {
    return selectionErrorMessage("Interaction Type");
  } else {
    return null;
  }
}
export function queryTextErrorMessage(queryText, fieldLabel) {
  return `Please transfer the text "${queryText}" from the "${fieldLabel}" section into the Notes section`;
}

export function multiselectErrorMessage(fieldLabel) {
  return `Please select at least 1 option in the "${fieldLabel}" section`;
}

export function singleselectErrorMessage(fieldLabel) {
  return `Please select or enter a value for the "${fieldLabel}" section`;
}

export function textualErrorMessage(fieldLabel) {
  return `Please fill out the "${fieldLabel}" field`;
}

export function selectionErrorMessage(fieldLabel: string): string {
  return `Please choose a value for the "${fieldLabel}" field`;
}
