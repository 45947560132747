import { FormStore } from "./form-store";
import React, { useState } from "react";
import { ReeCustomerInteractionFormContent } from "./ree-customer-interaction-form-content";
import "styled-components/macro";
import { FormBase } from "./form-base";
import { Drawer } from "@blueprintjs/core";

import { DRAWER_WIDTH } from "../../styles/constants";
import { useStoreActions, useStoreState } from "../../hooks/ep";
import { EcpCustomerInteractionFormContent } from "./ecp-customer-interaction-form-content";
import { LeadCustomerInteractionFormContent } from "./lead-customer-interaction-form-content";

export function ReportActionModalWrapper() {
  const isOpen = useIsReportActionFormOpen();
  return isOpen && <ReportActionView />;
}

export function useActionName() {
  const { actionName } = useRaPayload2();
  return actionName;
}

export function useRaPayload2() {
  const ra_payload = useStoreState((a) => a.misc.ra_payload);
  if (ra_payload === null) {
    return { snapshot: null, actionName: null, key: null };
  } else {
    const snapshot = ra_payload.snapshot;
    const actionName = ra_payload.actionName;
    return { snapshot, actionName, key: `${snapshot.id}` };
  }
}

export function useFormTitle() {
  const isOpen = useIsReportActionFormOpen();

  const actionName = useActionName();

  if (!isOpen) {
    return "";
  } else if (actionName === "ree_customer_interaction") {
    return "Tell us more about your student interaction";
  } else if (actionName === "lead_customer_interaction") {
    return "Tell us more about your interaction";
  } else if (actionName === "ecp_customer_interaction") {
    return "Tell us more about your student interaction";
  } else {
    console.error(`In useFormTitle -- Unknown actionName=${actionName}`);
    return "";
  }
}

export function useIsReportActionFormOpen() {
  return !!useStoreState((a) => a.misc.ra_payload);
}

export function useFormStore() {
  let formActions = FormStore.useStoreActions((a) => a);
  let formState = FormStore.useStoreState((s) => s);
  return [formState, formActions];
}

function MyFormStoreProvider({ children }) {
  const { actionName, key, snapshot } = useRaPayload2();
  return (
    <FormStore.Provider
      initialData={{
        key,
        actionName,
        snapshot,
      }}
    >
      {children}
    </FormStore.Provider>
  );
}

function ReportActionFormContent() {
  return <ModalContentInner />;
}

function ModalContentInner() {
  const actionName = useActionName();
  const [formState, formActions] = useFormStore();
  const [activeField, setActiveField] = useState(
    formState.DEFAULT_ACTIVE_FIELD
  );

  let handleReportActionMapper;
  let formContent;
  if (actionName === "ree_customer_interaction") {
    formContent = (
      <ReeCustomerInteractionFormContent
        formState={formState}
        formActions={formActions}
        activeField={activeField}
        setActiveField={setActiveField}
      />
    );
    handleReportActionMapper = (a) =>
      a.ree_customerInteraction.handleReportCustomerInteraction;
  } else if (actionName === "ecp_customer_interaction") {
    formContent = (
      <EcpCustomerInteractionFormContent
        formState={formState}
        formActions={formActions}
        activeField={activeField}
        setActiveField={setActiveField}
      />
    );
    handleReportActionMapper = (a) =>
      a.ecp_customerInteraction.handleReportCustomerInteraction;
  } else if (actionName === "lead_customer_interaction") {
    formContent = (
      <LeadCustomerInteractionFormContent
        formState={formState}
        formActions={formActions}
        activeField={activeField}
        setActiveField={setActiveField}
      />
    );
    handleReportActionMapper = (a) =>
      a.lead_customerInteraction.handleReportCustomerInteraction;
  } else {
    throw Error();
  }

  const handleReport = useStoreActions(handleReportActionMapper);

  return (
    <FormBase
      formState={formState}
      formActions={formActions}
      handleReport={handleReport}
    >
      {formContent}
    </FormBase>
  );
}

function ReportActionDrawer() {
  const hideDrawer = useStoreActions((a) => a.misc.ra_closeModal);
  const isOpen = useIsReportActionFormOpen();
  return (
    <Drawer
      canEscapeKeyClose={false}
      isCloseButtonShown={true}
      isOpen={isOpen}
      onClose={hideDrawer}
      className="bp3-dark"
      size={DRAWER_WIDTH}
      title={useFormTitle()}
    >
      <div
        css={`
          padding: 24px;
          overflow-y: auto;
        `}
      >
        <MyFormStoreProvider>
          <ReportActionFormContent />
        </MyFormStoreProvider>
      </div>
    </Drawer>
  );
}

export const ReportActionView = ReportActionDrawer;
