import { TableModel, tableModelFactory } from "../table-model-factory";

export interface NextYearCourseScheduleRecommendationsModel
  extends TableModel {}

export function getNextYearCourseScheduleRecommendationsModel(): NextYearCourseScheduleRecommendationsModel {
  return {
    ...tableModelFactory(
      "nextYearCourseScheduleRecommendations",
      "next_year_course_schedule_recommendations",
      getNextYearCourseScheduleRecommendationsRowId
    ),
  };
}

export function getNextYearCourseScheduleRecommendationsRowId(row) {
  return row.EnrollmentIndex;
}
