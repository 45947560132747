import {
  Action,
  action,
  computed,
  Computed,
  memo,
  State,
  Thunk,
  thunkOn,
  ThunkOn,
} from "easy-peasy";
import { Injections } from "./store-injections";
import { StoreModel } from "./model";
import { TableModelSliceName } from "./table-models";
import _ from "lodash";
import { assert } from "../helpers/assertions";

export type TableRowId = string | number;
export type TableRow = { id?: TableRowId; [column: string]: any };
export type TableData<RowT extends TableRow = TableRow> = RowT[];
export type QueryParams = { [key: string]: any } | null;
export interface GetRowId<RowT extends TableRow = TableRow> {
  (row: Partial<RowT>): TableRowId;
}
export interface TableModel<RowT extends TableRow = TableRow> {
  NAME: TableModelSliceName;
  INITIAL_DATA_ENDPOINT: string;
  fetchQueryParams: QueryParams;
  GET_ROW_ID: GetRowId<RowT>;
  relationships: Relationship<RowT>[];
  initialData: TableData<RowT>;
  initialDataReceived: boolean;
  initialDataLoading: boolean;
  //
  resetData: Action<this, void>;
  onLogout: ThunkOn<this, Injections, StoreModel>;
  receiveInitialData: Action<this, TableData<RowT>>;
  upsertRow: Action<this, Partial<RowT>>;
  deleteRow: Action<this, TableRowId>;
  rowsById: Computed<
    this,
    // Ignore the "String" and "Number suffixes. Had to set it up
    // like this to get TypeScript to stop complaining about union types..
    { [rowIdString: string]: RowT; [rowIdNumber: number]: RowT },
    StoreModel
  >;
  getRow: Computed<this, (rowId: TableRowId) => undefined | RowT, StoreModel>;
  rowIds: Computed<this, Set<TableRowId>, StoreModel>;
  rowExists: Computed<this, { (row: RowT): boolean }, StoreModel>;
  //
  relationshipsByName: Computed<
    this,
    { [relationshipName: string]: Relationship<RowT> },
    StoreModel
  >;
  getRelated: Computed<
    this,
    (relationshipName: string, rowId: TableRowId) => null | RowT | RowT[],
    StoreModel
  >;
  relatedIdsMap: Computed<
    this,
    {
      [rowIdString: string]: {
        [relationshipName: string]: null | TableRowId | TableRowId[];
      };
      [rowIdNumber: number]: {
        [relationshipName: string]: null | TableRowId | TableRowId[];
      };
    },
    StoreModel
  >;
  relatedRowsMap: Computed<
    this,
    {
      [rowIdString: string]: {
        [relationshipName: string]: null | RowT | RowT[];
      };
      [rowIdNumber: number]: {
        [relationshipName: string]: null | RowT | RowT[];
      };
    },
    StoreModel
  >;
  //
  setFetchQueryParams: Action<this, QueryParams>;
  markInitialDataReceived: Action<this>;
  markInitialDataNotReceived: Action<this>;
  markInitialDataLoading: Action<this>;
  markInitialDataNotLoading: Action<this>;
  //
  maybeHandleFetchInitialData: Thunk<this, any, Injections, StoreModel>;
  handleFetchInitialData: Thunk<this, any, Injections, StoreModel>;
}

export type Arity = "OneToMany" | "OneToOne" | "ManyToOne" | "ManyToMany";
export interface Relationship<T extends TableRow = TableRow> {
  name: string;
  arity: Arity;
  field: string;
  foreignName: TableModelSliceName;
  foreignField: string | string[];
  foreignFilter?: { (row: T): boolean };
}

export function getField<T extends TableRow = TableRow>(
  row: T,
  field: string | string[]
): any {
  if (_.isString(field)) {
    return row[field];
  } else {
    return field.reduce(
      (previousValue, currentValue) => previousValue?.[currentValue],
      row
    );
  }
}

function getRelatedRows<T extends TableRow = TableRow>(
  row: T,
  relationship: Relationship<T>,
  storeState: State<StoreModel>
): null | T | T[] {
  const relatedModelSlice = storeState[relationship.foreignName];
  const rowValueForField = getField<T>(row, relationship.field);
  const relatedRows: T[] = relatedModelSlice.initialData
    .filter(
      (foreignRow) =>
        !relationship.foreignFilter || relationship.foreignFilter(foreignRow)
    )
    .filter(
      (foreignRow) =>
        getField<T>(foreignRow, relationship.foreignField) === rowValueForField
    );
  if (["OneToOne", "ManyToOne"].includes(relationship.arity)) {
    assert(relatedRows.length <= 1);
    if (relatedRows.length === 1) {
      return relatedRows[0];
    } else {
      return null;
    }
  } else {
    return relatedRows;
  }
}

export function tableModelFactory<RowT extends TableRow = TableRow>(
  sliceName: TableModelSliceName,
  initialDataEndpoint: string,
  getRowId: GetRowId<RowT>,
  relationships?: Relationship<RowT>[],
  transformInitialData?: any,
  initialDataFormat?: string,
  initialFetchQueryParams?: QueryParams
): TableModel<RowT> {
  const memoArray = memo((...values) => values, 10);

  return {
    NAME: sliceName,
    INITIAL_DATA_ENDPOINT: initialDataEndpoint,
    fetchQueryParams: initialFetchQueryParams ?? null,
    INITIAL_DATA_FORMAT: initialDataFormat ?? "json",
    GET_ROW_ID: getRowId,
    relationships: relationships || [],
    initialData: [],
    initialDataReceived: false,
    initialDataLoading: false,
    resetData: action((state): void => {
      state.initialData = [];
      state.initialDataReceived = false;
      state.initialDataLoading = false;
    }),
    onLogout: thunkOn(
      (__, { me }) => me.resetData,
      (actions) => {
        actions.resetData();
      }
    ),
    receiveInitialData: action((state, payload) => {
      let initialData: TableData<RowT> = payload;
      if (transformInitialData) {
        initialData = transformInitialData(initialData);
      }
      state.initialData = initialData.map((row) => ({
        ...row,
        id: getRowId(row),
      }));
    }),
    upsertRow: action((state, payload) => {
      payload = { ...payload, id: getRowId(payload as RowT) };
      const currIndex = state.initialData.findIndex(
        (row) => row.id === payload.id
      );
      if (currIndex === -1) {
        state.initialData.push(payload as RowT);
      } else {
        state.initialData[currIndex] = payload as RowT;
      }
    }),
    deleteRow: action((state, rowId) => {
      state.initialData = state.initialData.filter((row) => row.id !== rowId);
    }),
    relationshipsByName: computed([(s) => s.relationships], (rels) =>
      Object.fromEntries(rels.map((rel) => [rel.name, rel]))
    ),
    rowsById: computed([(s) => s.initialData], (initialData) =>
      Object.fromEntries(initialData.map((row) => [row.id, row]))
    ),
    getRow: computed([(s) => s.initialData], (initialData) => (rowId) => {
      return initialData.find((row) => row.id === rowId);
    }),
    getRelated: computed(
      [
        (s) => s.relationshipsByName,
        (s) => s.rowsById,
        (state, storeState) => storeState,
      ],
      (relationshipsByName, rowsById, storeState) =>
        (relationshipName, rowId) => {
          const relationship = relationshipsByName[relationshipName];
          const row = rowsById[rowId];
          if (_.isUndefined(relationship) || _.isUndefined(row)) {
            throw Error(
              JSON.stringify({ relationshipName, rowId, relationship, row })
            );
          }

          return getRelatedRows<RowT>(row, relationship, storeState);
        }
    ),
    relatedIdsMap: computed(
      [
        (s) => s.relationships,
        (s) => s.initialData,
        (state, storeState) =>
          memoArray(
            ..._.uniqBy(relationships || [], "foreignName")
              .map((rela) => rela.foreignName)
              .map((sliceName) => storeState[sliceName].initialData)
          ),
        // (state, storeState) =>
        //   Object.fromEntries(
        //     _.uniqBy(relationships || [], "foreignName")
        //       .map((rela) => rela.foreignName)
        //       .map((sliceName) => [
        //         sliceName,
        //         storeState[sliceName].initialData,
        //       ])
        //   ),
        // (state, storeState) => storeState,
      ],
      (
        relationships: Relationship<RowT>[],
        initialData: TableData<RowT>,
        storeStates
      ) => {
        // console.log("storeStates", storeStates);
        const foreignName_to_rels = _.groupBy<Relationship<RowT>>(
          relationships,
          (rel) => rel.foreignName
        );

        const field_to_fieldValue_to_rows: Map<
          string,
          Map<number | string, TableData<RowT>>
        > = new Map();

        // const field2mapkey = (fld) =>
        //   (_.isString(fld) ? [fld] : fld).join("..");

        _.uniq(relationships.map((rel) => rel.field)).forEach((field) => {
          field_to_fieldValue_to_rows.set(
            // field2mapkey(field),
            field,
            groupBy(initialData, (localRow) => getField<RowT>(localRow, field))
          );
        });

        const ret = {};

        Object.entries(foreignName_to_rels).forEach(
          (
            [foreignName, relsWithSameForeignName]: [
              string,
              Relationship<RowT>[]
            ],
            idx
          ) => {
            const foreignInitialData: TableData<RowT> = storeStates[idx];

            const foreignField_to_rels = _.groupBy<Relationship<RowT>>(
              relsWithSameForeignName,
              (rel) => rel.foreignField
            );

            Object.entries(foreignField_to_rels).forEach(
              ([foreignField, relsWithSameForeignNameAndField]: [
                string,
                Relationship<RowT>[]
              ]) => {
                const foreignFieldValue_to_foreignRows: Map<
                  number | string,
                  TableData<RowT>
                > = groupBy(foreignInitialData, (foreignRow) =>
                  getField<RowT>(foreignRow, foreignField)
                );

                foreignFieldValue_to_foreignRows.forEach(
                  (foreignRows, foreignFieldValue) => {
                    relsWithSameForeignNameAndField.forEach((rel) => {
                      const localRowsWithMatchingFieldValues =
                        field_to_fieldValue_to_rows
                          .get(rel.field)
                          // .get(field2mapkey(rel.field))
                          ?.get(foreignFieldValue) ?? [];

                      const filteredForeignRows = !rel.foreignFilter
                        ? foreignRows
                        : foreignRows.filter(rel.foreignFilter);

                      const filteredForeignRowIds = filteredForeignRows.map(
                        (r) => r.id
                      );

                      const relatedIdsValue = arity_to_finalizer[rel.arity](
                        filteredForeignRowIds
                      );

                      localRowsWithMatchingFieldValues.forEach((localRow) => {
                        const rowId = localRow.id;
                        ret[rowId] = {
                          ...(ret[rowId] || {}),
                          [rel.name]: relatedIdsValue,
                        };
                      });
                    });
                  }
                );
              }
            );
          }
        );
        return ret;
      }
    ),
    relatedRowsMap: computed(
      [
        (s) => s.relationships,
        (s) => s.relatedIdsMap,
        (state, storeState) =>
          memoArray(
            ..._.uniqBy(relationships || [], "foreignName")
              .map((rela) => rela.foreignName)
              .map((sliceName) => storeState[sliceName].rowsById)
          ),
        // (state, storeState) =>
        //   Object.fromEntries(
        //     _.uniqBy(relationships || [], "foreignName")
        //       .map((rela) => rela.foreignName)
        //       .map((sliceName) => [sliceName, storeState[sliceName].rowsById])
        //   ),
        // (state, storeState) => storeState,
      ],
      (relationships, relatedIdsMap, storeStates) => {
        const relName_to_rel = Object.fromEntries(
          relationships.map((rel) => [rel.name, rel])
        );

        const entries: [
          TableRowId,
          { [key: string]: null | TableRowId | TableRowId[] }
        ][] = Object.entries(relatedIdsMap);

        return Object.fromEntries(
          entries.map(([rowId, relName_to_relIds]) => {
            const relName_to_relRows = Object.fromEntries(
              Object.entries(relName_to_relIds).map(
                ([relName, relIdsValue]: [
                  string,
                  null | TableRowId | TableRowId[]
                ]) => {
                  const rel = relName_to_rel[relName];
                  const foreignRowsById =
                    storeStates[
                      relationships.findIndex(
                        (rell) => rell.foreignName === rel.foreignName
                      )
                    ];

                  const relIdsArray = _.isArray(relIdsValue)
                    ? relIdsValue
                    : [relIdsValue];

                  const relRowsValue: null | RowT | TableData<RowT> =
                    arity_to_finalizer[rel.arity](
                      relIdsArray.map((id) => foreignRowsById[id])
                    );

                  return [relName, relRowsValue];
                }
              )
            );
            return [rowId, relName_to_relRows];
          })
        );
      }
    ),
    //
    setFetchQueryParams: undefined,
    markInitialDataReceived: undefined,
    markInitialDataNotReceived: undefined,
    markInitialDataLoading: undefined,
    markInitialDataNotLoading: undefined,
    //
    maybeHandleFetchInitialData: undefined,
    handleFetchInitialData: undefined,
    rowIds: computed(
      [(s) => s.initialData, (s) => s.GET_ROW_ID],
      (initialData: TableData<RowT>, GET_ROW_ID: GetRowId<RowT>) =>
        new Set(initialData.map((row) => GET_ROW_ID(row)))
    ),
    rowExists: computed(
      [(s) => s.rowIds, (s) => s.GET_ROW_ID],
      (rowIds: Set<TableRowId>, GET_ROW_ID: GetRowId<RowT>) => (row) =>
        rowIds.has(GET_ROW_ID(row))
    ),
  };
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

const arity_to_finalizer = {
  OneToMany: (v) => v,
  ManyToMany: (v) => v,
  OneToOne: (v) => v[0] ?? null,
  ManyToOne: (v) => v[0] ?? null,
};
