import { TableRow } from "../../store/table-model-factory";
import _ from "lodash";
import moment from "moment";
import { EcpStudentTable } from "../../entities/ecp/ecp-student-entity";
import React from "react";
import { AgColDefs } from "../../components/AgTable/types";

/*
- [ ] Include all target students for each upsell campaign in the students table on the Upsell Campaigns page
    - [ ] include...
        - [ ] a column indicating if that student has a new order after the campaign started
        - [ ] a column indicating the campaign that they were targeted with
        - [ ] a column indicating the most recent email campaign type name **
*/
export function StudentTable({ cgnStatsHidden }) {
  return (
    <EcpStudentTable
      ribbonTitle={"Students"}
      fullScreenEnabled={cgnStatsHidden}
      height={cgnStatsHidden ? null : "600px"}
      prependedColDefs={prependedColDefs}
      finalizeRowData={finalizeRowData}
      whitelistedColIds={WHITELISTED_COL_IDS}
      blacklistedColIds={BLACKLISTED_COL_IDS}
      tableNameSuffix="next-course-recos"
    />
  );
}

const prependedColDefs: AgColDefs = [
  // {
  //   field: "isin_cgn1a",
  //   headerName: "Is In Campaign 1A",
  //   type: "booleanColumn",
  // },
  // {
  //   field: "isin_cgn2a",
  //   headerName: "Is In Campaign 2A",
  //   type: "booleanColumn",
  // },
  {
    field: "cgn1a_or_cgn2a_or_cgn3a_or_cgn8a",
    headerName: "Targeted For Which Campaign",
    type: "categoryColumn",
    width: 115,
  },
  {
    field: "has_customer_success_cgns_incremental_order",
    headerName: "Has Incremental Order",
    type: "booleanColumn",
    width: 128,
  },
  {
    field: "latest_customer_success_cgn_email_type_code_display_name",
    headerName: "Latest Sent Campaign Email Type",
    type: "categoryColumn",
  },
];

const BLACKLISTED_COL_IDS = [
  "LoyaltyEligible",
  "is_loyalty21_reenrolled",
  "loyalty21_status",
];
const WHITELISTED_COL_IDS = [
  "student_flag__exclude_from_next_course_recos_cgn",
];

function isPartOfCampaign(row: TableRow): boolean {
  return row.isin_any_customer_success_campaign;
}

function finalizeRowData(rowData: TableRow[]): TableRow[] {
  rowData = rowData.filter(isPartOfCampaign);
  rowData = _.sortBy(rowData, (row) => moment(row.latest_geniusComm_sent_at));
  rowData = _.sortBy(
    rowData,
    (row) => -row.has_cgn1a_or_cgn2a_or_cgn3a_incremental_order
  );
  rowData = _.sortBy(
    rowData,
    (row) => row.student_flag__exclude_from_next_course_recos_cgn
  );
  return rowData;
}
