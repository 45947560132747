import _ from "lodash";
import {
  DEFAULT_SELECTED_ENTITY_IDS,
  useSelectedEntityId,
} from "../../store/model-entity-selection";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { Unit } from "./unit";
import React from "react";
import AgTable from "../AgTable";
import { AgColDef } from "../AgTable/types";
import { useSelectedCustomer } from "./selection-unit";
import { getOrderGeniusUrl } from "../../entities/ecp/ecp-student-entity";
import { TableRow } from "../../store/table-model-factory";
import { timestampComparator } from "../AgTable/gridOptions";

export function StudentOrdersHistoryUnit({
  unitId,
}: {
  unitId: string;
}): JSX.Element {
  const loading = _.max([
    DEFAULT_SELECTED_ENTITY_IDS.student === useSelectedEntityId("student"),
    useEnsureData("ecp_student"),
    useEnsureData("ecp_order"),
  ]);

  return (
    <Unit unitId={unitId} loading={loading}>
      <StudentOrdersHistoryContent unitId={unitId} />
    </Unit>
  );
}

function StudentOrdersHistoryContent({
  unitId,
}: {
  unitId: string;
}): JSX.Element {
  const customer = useSelectedCustomer();
  const rowData = customer.orders.sort(compareHistoricalOrders);
  const colDefs = useColDefs();
  const height = _.min([500, 64 + 48 * rowData.length]);

  return (
    <AgTable
      tableName={`historical-orders--student-${customer.StudentIndex}`}
      tableNameSuffix={unitId}
      rowData={rowData}
      columnDefs={colDefs}
      ribbonTitle={"Historical Orders"}
      height={`${height}px`}
    />
  );
}

function useColDefs(): AgColDef[] {
  return [
    {
      headerName: "Genius Order ID",
      field: "OrderIndex",
      type: "linkedTextColumn",
      __linkValueGetter: getOrderGeniusUrl,
      __linkIsNotAnEmail: true,
      pinned: "left",
    },
    {
      headerName: "Created On",
      field: "CreatedOn",
      type: "dateColumn",
    },
    {
      headerName: "Start Date",
      field: "StartDate",
      type: "naiveDateColumn",
    },
    {
      headerName: "Status",
      field: "Status",
      type: "categoryColumn",
    },
    {
      headerName: "Type",
      field: "order_type",
      type: "categoryColumn",
    },
    {
      headerName: "Name",
      field: "Name",
      type: "textColumn",
      width: 260,
      suppressSizeToFit: true,
      tooltipField: "Name",
    },
    {
      headerName: "Grade Level",
      field: "grade_level",
      type: "integer",
    },
    {
      headerName: "Genius Parent Order ID",
      field: "ParentOrderIndex",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "Tran ID",
      field: "tranId",
      type: "textColumn",
    },
    {
      headerName: "NetSuite Internal ID",
      field: "NetSuiteInternalId",
      type: "textColumn",
    },
    {
      headerName: "Estimate",
      field: "Estimate",
      type: "moneyColumn",
    },
    {
      headerName: "Assigned to User ID",
      field: "AssignedToUserIndex",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "Expiration Date",
      field: "ExpirationDate",
      type: "dateColumn",
    },
    {
      headerName: "Changed On",
      field: "ChangedOn",
      type: "dateColumn",
    },
    {
      headerName: "Changed By",
      field: "changedBy",
      type: "integerIdentifierColumn",
    },
  ];
}

export function compareHistoricalOrders(a: TableRow, b: TableRow): number {
  const ret = timestampComparator(a.CreatedOn, b.CreatedOn);
  return -1 * ret;
}
