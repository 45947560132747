import React from "react";
import "styled-components/macro";
import { LoyaltyYearSelectionHeader } from "./loyalty-year";
import { LoyaltyPageBody } from "./loyalty-page-body";
import { LoyaltyStatisticsUnitDescriptionHeader } from "./loyalty-statistics-unit-description";
import { Col, Row } from "antd";
import {
  isDefaultLoyaltyYear,
  useSelectedLoyaltyYear,
} from "./loyalty-year-helpers";

export function LoyaltyPage() {
  const selectedLoyaltyYear = useSelectedLoyaltyYear();
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        & > .loyalty-year-selection-header {
          margin-bottom: 40px;
        }
      `}
    >
      <Row
        align="top"
        style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
      >
        <Col>
          <LoyaltyYearSelectionHeader />
        </Col>
        {isDefaultLoyaltyYear(selectedLoyaltyYear) && (
          <Col style={{ marginLeft: 40 }}>
            <LoyaltyStatisticsUnitDescriptionHeader />
          </Col>
        )}
      </Row>
      <LoyaltyPageBody />
    </div>
  );
}
