import React, { useMemo } from "react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { AgGridReact } from "@ag-grid-community/react";
import { AgGridReact as T_AgGridReact } from "@ag-grid-community/react/lib/agGridReact";

import "moment-timezone";

import _ from "lodash";
import { AgState } from "./types";

const MODULES = [ClientSideRowModelModule, CsvExportModule];

export function getStateOfGrid(gridRefCurrent: T_AgGridReact): AgState {
  return {
    columnState: gridRefCurrent.gridOptions.columnApi.getColumnState(),
    sortModel: gridRefCurrent.gridOptions.api.getSortModel(),
    filterModel: gridRefCurrent.gridOptions.api.getFilterModel(),
  };
}

// export const saveStateOfGrid = (state, gridRefCurrent) => {
//   // console.log("saveState START");
//   if (!gridRefCurrent) {
//     return null;
//   }
//
//   const gridState = getStateOfGrid(gridRefCurrent);
//
//   if (_.size(gridState.columnState)) {
//     state.columnState = gridState.columnState;
//   }
//   if (_.size(gridState.sortModel)) {
//     state.sortModel = gridState.sortModel;
//   }
//   if (_.size(gridState.filterModel)) {
//     state.filterModel = gridState.filterModel;
//   }
//   // window.groupState = gridRefCurrent.gridOptions.columnApi.getColumnGroupState();
//   // console.log("saveState END");
// };

export const restoreStateToGrid = (stateToRestore, gridRefCurrent) => {
  // console.log("restoreState START", stateToRestore);

  stateToRestore.columnState &&
    _.size(stateToRestore.columnState) &&
    gridRefCurrent.gridOptions.columnApi.setColumnState(
      stateToRestore.columnState
    );

  stateToRestore.sortModel &&
    _.size(stateToRestore.sortModel) &&
    gridRefCurrent.gridOptions.api.setSortModel(stateToRestore.sortModel);

  stateToRestore.filterModel &&
    _.size(stateToRestore.filterModel) &&
    gridRefCurrent.gridOptions.api.setFilterModel(stateToRestore.filterModel);

  // console.log("restoreState END");
};

// const clearState = gridRefCurrent => {
//   console.log("clearState");
//   gridRefCurrent.gridOptions.columnApi.resetColumnState();
//   // gridRefCurrent.gridOptions.columnApi.resetColumnGroupState();
//   gridRefCurrent.gridOptions.api.setSortModel(null);
//   gridRefCurrent.gridOptions.api.setFilterModel(null);
// };
export const resetAllFilters = (gridRefCurrent) => {
  console.log(gridRefCurrent.gridOptions.api.getFilterModel());
  gridRefCurrent.gridOptions.api.setFilterModel(null);
};

export function MyAgGridReact({ gridRef, rowData, columnDefs, gridOptions }) {
  return useMemo(
    () => (
      <AgGridReact
        reactNext={true}
        ref={gridRef}
        {...gridOptions}
        rowData={rowData}
        columnDefs={columnDefs}
        modules={MODULES}
      />
    ),
    // WARNING: DO NOT PUT `gridOptions` back in this list! Produces a ton of slowness when opening up the Show/Hide columns panel
    // eslint-disable-next-line
    [rowData]
  );
}
