// This must be the first line in src/index.js
import "./index.less";
import "./polyfills";
import "./disable-console-log-in-production.js";
import "./index.css";
import "./ag-grid-customization.scss";
import "./json_parseMore.js";
import "./duckdb-wasm-instantiation";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { epStore } from "./store";
import { firebase as fbase } from "./helpers/fbase";
import { StoreProvider } from "easy-peasy";
import { AgTableThemeProvider } from "./components/AgTable/theming";

// @ts-ignore
const window_Cypress = window.Cypress;

if (
  window_Cypress ||
  !process.env.NODE_ENV ||
  process.env.NODE_ENV !== "production"
) {
  // @ts-ignore
  window.store = epStore;
  // @ts-ignore
  window.firebase = fbase;
}

ReactDOM.render(
  <StoreProvider store={epStore}>
    <AgTableThemeProvider>
      <App />
    </AgTableThemeProvider>
  </StoreProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
