import React from "react";
import { ITooltipParams } from "@ag-grid-community/core";
import { Classes } from "@blueprintjs/core";
import "styled-components/macro";

export class StudentAttr_LoyaltyRenrCampaignType_Tooltip extends React.Component<ITooltipParams> {
  getReactContainerClasses() {
    return ["custom-tooltip", Classes.TOOLTIP, Classes.DARK];
  }

  render() {
    return (
      <div
        className={`${Classes.DARK} ${Classes.POPOVER_CONTENT} ${Classes.RUNNING_TEXT}`}
        css={`
          opacity: 1;
          width: fit-content;
        `}
      >
        <p>
          <span
            css={`
              font-weight: bold;
              margin-right: 12px;
            `}
          >
            {"normal"}
          </span>
          <span>
            {
              "This student should be sent the Normal re-enrollment campaign email"
            }
          </span>
        </p>
        <p>
          <span
            css={`
              font-weight: bold;
              margin-right: 12px;
            `}
          >
            {"academy"}
          </span>
          <span>
            {
              "This student should be sent the Academy re-enrollment campaign email"
            }
          </span>
        </p>
        <p>
          <span
            css={`
              font-weight: bold;
              margin-right: 12px;
            `}
          >
            {"academy_potential"}
          </span>
          <span>
            {
              "This student should be sent the Academy Potential re-enrollment campaign email"
            }
          </span>
        </p>
        <p>
          <span
            css={`
              font-weight: bold;
              margin-right: 12px;
            `}
          >
            {"no_email"}
          </span>
          <span>
            {"This student should not be sent a re-enrollment campaign email"}
          </span>
        </p>
      </div>
    );
  }
}
