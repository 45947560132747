import React from "react";
import styled from "styled-components/macro";
import { KPI_INTENT_COLORS } from "./cell-renderer";

export function AdmissionsCoordinatorsKPIPopoverTarget({ cellValue, intent }) {
  return <KPIValueCell intent={intent}>{cellValue}</KPIValueCell>;
}

const KPIValueCell = styled.div`
  color: ${(props) => KPI_INTENT_COLORS[props.intent] || "inherit"};
`;
