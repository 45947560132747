import { TableRow } from "../../store/table-model-factory";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import React from "react";
import _ from "lodash";
import { formatValue } from "./value-formatting";

export function LoyaltyStatisticsUnitTableBodyRow({
  row,
  shownColDefs,
}: {
  row: TableRow;
  shownColDefs: AgColDefs;
}): JSX.Element {
  return (
    <tr key={row.id}>
      {shownColDefs.map((cd) => (
        <LoyaltyStatisticsUnitTableBodyCell key={cd.field} row={row} cd={cd} />
      ))}
    </tr>
  );
}

function LoyaltyStatisticsUnitTableBodyCell({
  row,
  cd,
}: {
  row: TableRow;
  cd: AgColDef;
}): JSX.Element {
  const rawValue = _.isNumber(row[cd.field])
    ? Math.max(0, row[cd.field])
    : row[cd.field];
  const formattedValue = formatValue(rawValue, cd.type as string);
  return (
    <td key={cd.field} className={`coltype-${cd.type}`}>
      {formattedValue}
    </td>
  );
}
