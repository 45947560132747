/* Campaign Stats Table */
import {
  Button,
  Card,
  Elevation,
  H4,
  Icon,
  Intent,
  Tooltip,
} from "@blueprintjs/core";
import React, { useState } from "react";
import {
  ECP_CUSTOMER_SUCCESS_CGN_STATS_COLTABLE_CONFIGS,
  ECP_CUSTOMER_SUCCESS_CGN_STATS_EXPANDABLE_COLTABLE_CONFIGS,
} from "./stats-unit-table-coldefs";
import { get_sequence_name_from_id } from "./sequences";
import _ from "lodash";
import { TableDescriptions } from "../../components/table-descriptions";
import { NoData } from "../../components/AgTable/no-data";
import styled from "styled-components/macro";
// eslint-disable-next-line no-restricted-imports

export function StatsUnit({ rowData, cardTitle }) {
  const colDefs = ECP_CUSTOMER_SUCCESS_CGN_STATS_COLTABLE_CONFIGS;
  const subTableColDefs =
    ECP_CUSTOMER_SUCCESS_CGN_STATS_EXPANDABLE_COLTABLE_CONFIGS;

  const allSequenceIds: number[] = _.sortBy(
    _.uniq(rowData.map((row) => row.seq_id).filter((v) => v > 0))
  );

  const [openCardStat, setOpenCardStat] = useState(true);

  const studentGroups = _.uniq(rowData.map((row) => row.student_group_name));

  rowData = rowData
    .filter((row) => (studentGroups.length === 1 ? row.seq_id > 0 : true))
    .map((row) => ({
      ...row,
      id: row.student_group_name,
      seq_name: get_sequence_name_from_id(row.seq_id, allSequenceIds),
    }))
    .sort();

  return (
    <div
      css={`
        //position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
      `}
    >
      <Card
        elevation={Elevation.TWO}
        css={`
          position: relative;
          width: 100%;
          height: ${openCardStat ? "max-content" : "40px"};
          padding-top: ${openCardStat ? "20px" : "10px"};
          padding-bottom: ${openCardStat ? "20px" : "5px"};
        `}
      >
        <H4>{cardTitle}</H4>
        {_.size(rowData) ? (
          <>
            <div
              css={`
                position: absolute;
                right: 0;
                top: 0;
              `}
            >
              <Tooltip
                content={`${
                  openCardStat ? "Collapse " : "Expand "
                } ${cardTitle}`}
              >
                <Button
                  icon={
                    <Icon
                      icon={openCardStat ? "caret-up" : "caret-down"}
                      iconSize={Icon.SIZE_LARGE}
                    />
                  }
                  minimal
                  interactive
                  large
                  intent={Intent.NONE}
                  onClick={() => setOpenCardStat(!openCardStat)}
                />
              </Tooltip>
            </div>
            <TableDescriptions
              columnsFormat={colDefs}
              subTableColumnsFormat={subTableColDefs}
              dataSource={rowData}
              withoutBoxShadow
              css={`
                visibility: ${openCardStat ? "visible" : "hidden"};
                opacity: ${openCardStat ? 1 : 0};
              `}
            />
          </>
        ) : (
          <NoData />
        )}
      </Card>
    </div>
  );
}
