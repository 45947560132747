import React from "react";
import { Icon, Colors, Intent, IconName } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  AttributeUnitConfig,
  CampaignConfiguration,
  CampaignStatus,
  CampaignType,
} from "./types";
import styled from "styled-components/macro";

export function numberToOrdinal(n: number) {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function getFormattedAttributeValue(
  campaignConfiguration: CampaignConfiguration,
  attributeUnitConfig: AttributeUnitConfig
): string {
  const value = campaignConfiguration[attributeUnitConfig.field];

  const formattedValue = attributeUnitConfig.valueFormatter
    ? attributeUnitConfig.valueFormatter(value)
    : value;
  return formattedValue;
}

export function formatCampaignType(campaign_type: CampaignType): JSX.Element {
  let text: string;

  switch (campaign_type) {
    case CampaignType.CUSTOMER_SUCCESS:
      text = "Upsell Campaign";
      break;
    case CampaignType.NEW_CUSTOMER_SALES:
      text = "Sales & Marketing Campaign";
      break;
  }

  return <span>{text}</span>;
}

export function formatCampaignStatus(
  campaign_status: CampaignStatus
): JSX.Element {
  let iconIntent: Intent;
  let iconName: IconName;

  switch (campaign_status) {
    case CampaignStatus.ACTIVE:
      iconIntent = Intent.SUCCESS;
      iconName = IconNames.TICK_CIRCLE;
      break;
    case CampaignStatus.INACTIVE:
      iconIntent = Intent.DANGER;
      iconName = IconNames.DELETE;
      break;
    default:
      iconIntent = Intent.NONE;
      iconName = IconNames.HELP;
      break;
  }

  return (
    <span>
      <StyledCampaignStatusText>
        {campaign_status.toLocaleUpperCase()}
      </StyledCampaignStatusText>
      <Icon icon={iconName} intent={iconIntent} />
    </span>
  );
}

const StyledCampaignStatusText = styled.span`
  margin-right: 5px;
`;

function colorizeEmailTemplateVariables(
  html: string,
  color: string | typeof Colors
) {
  const regexEmailTemplateVariables = /\{+([^}]+)\}+(?![^<]*<\/a>)/g;

  return html.replace(
    regexEmailTemplateVariables,
    `<span style="color: ${color};">{$1}</span>`
  );
}

function colorizeAnchorHTMLTag(html: string, color: string | typeof Colors) {
  // Regex to match anchor HTML tag
  const regexAnchorHTMLTag =
    /<a\s+href="({+[^}]+}+)"(?:\s+target="_blank")?>([^<]+)<\/a>/g;

  return html.replace(
    regexAnchorHTMLTag,
    (match, url) =>
      `${match} <span style="color: ${color};">[url = ${url}]</span>`
  );
}

function colorizeHandlebarsConditionals(
  html: string,
  color: string | typeof Colors
) {
  // List of handlebars conditionals to colorize
  const handlebarsConditionals = ["{{#if", "{{else}}", "{{/if}}"];
  // Colorize each conditional
  return handlebarsConditionals.reduce((acc: string, conditional: string) => {
    const regex = new RegExp(conditional, "g");
    // Remove curly braces from conditional so that they don't get colored
    const strippedConditional = conditional.replace(/{|}/g, "");
    // Wrap conditional in span with color
    const coloredConditional = `<span style="color: ${color};">${strippedConditional}</span>`;
    // Replace conditional with colored conditional, always keep the same number of curly braces so they
    // can be colored by the next step.
    return acc.replace(
      regex,
      "{" + coloredConditional + (conditional.endsWith("}}") ? "}<br>" : "")
    );
  }, html);
}

export function colorizeHTMLTemplate(html: string) {
  const steps: ((html: string) => string)[] = [
    (html) => colorizeAnchorHTMLTag(html, Colors.LIME3),
    (html) => colorizeHandlebarsConditionals(html, Colors.LIME3),
    (html) => colorizeEmailTemplateVariables(html, Colors.ORANGE4),
  ];

  return steps.reduce((acc, step) => step(acc), html);
}
