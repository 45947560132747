import React from "react";
import _ from "lodash";
import { TableRow } from "../store/table-model-factory";
import { EcpStudentEnrollmentsSplitview } from "../components/splitviews/ecp-student-enrollments-splitview";
import {
  GAVEL_ACTION_COL_DEF,
  PHONE_ACTION_COL_DEF,
} from "../entities/ecp/ecp-student-entity";

export function EcpStudentServicesDashboardPage({
  fullScreenEnabled = true,
  ...restProps
}) {
  return (
    <EcpStudentEnrollmentsSplitview
      finalizeStudentRowData={finalizeRowData}
      whitelistedStudentTagIds={WHITELISTED_STUDENT_TAG_IDS}
      whitelistedEnrollmentTagIds={WHITELISTED_ENROLLMENT_TAG_IDS}
      studentActionColDefs={STUDENT__ACTION_COL_DEFS}
      viewId={"ss-dash"}
    />
  );
}

const STUDENT__ACTION_COL_DEFS = [
  PHONE_ACTION_COL_DEF,
  {
    headerName: "",
    field: "Guardian1Email",
    cellClass: "react-rendered-cell",
    cellRenderer: "ssdashEmailActionButtonCellRenderer",
    type: "textColumn",
    width: 52, // 176
    pinned: "right",
    cellRendererParams: {
      crp: {
        tooltipText: "Compose email from template",
      },
    },
  },
  GAVEL_ACTION_COL_DEF,
];

const WHITELISTED_STUDENT_TAG_IDS = [
  "at_risk",
  "behind_pace",
  "missing_in_action",
  "low_grade",
  "slow_start",
  "extension_candidate",
];

const WHITELISTED_ENROLLMENT_TAG_IDS = [
  "at_risk",
  "behind_pace",
  "missing_in_action",
  "low_grade",
  "slow_start",
  "extension_candidate",
];

function doesStudentHaveQualifyingTag(row) {
  return (
    row.tag__slow_start ||
    row.tag__behind_pace ||
    row.tag__missing_in_action ||
    row.tag__low_grade
  );
  // return row.tag__at_risk || row.tag__slow_start || row.tag__behind_pace;
}

function doesStudentHaveValidChurnScore(row) {
  const churnScore = row.churn_risk_score;
  return !(_.isNil(churnScore) || _.isNaN(churnScore));
}

// function isStudentInOutreachCooldownPeriod(row) {
//   if (row.wasCustomerInteractionToday) {
//     return false;
//   } else if (row.wasCustomerInteractionWithinPastTwoWeeks) {
//     return true;
//   } else {
//     return false;
//   }
// }

function isValidSSDashRow(row) {
  return (
    doesStudentHaveValidChurnScore(row) && doesStudentHaveQualifyingTag(row)
    // && !isStudentInOutreachCooldownPeriod(row)
  );
}

function finalizeRowData(rowData: TableRow[]) {
  rowData = rowData.filter(isValidSSDashRow);

  rowData = _.sortBy(rowData, (el) => -el.churn_risk_score);
  rowData = _.sortBy(rowData, (el) => -el.n_tags__at_risk);
  rowData = _.sortBy(rowData, (el) =>
    el.hasReportedCustomerInteraction
      ? -el.latestInteractionSecondsAgo
      : -10000000000000
  );

  return rowData;
}
