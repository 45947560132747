import { useColDefsForCampaignEmailsTable } from "../../entities/ecp/ecp-campaignEmail-entity";
import AgTable from "../../components/AgTable";
import React from "react";
import { DEFAULT_SHOULD_FETCH, useEndpoint } from "../../helpers/use-endpoint";
import { Spinner } from "@blueprintjs/core";

export function CampaignemailTable() {
  // let rowData = useStoreState((s) =>
  //   s.ecp_campaignEmail.rowData.filter((row) => isCgnEmail(row.email_type_code))
  // );
  const { loading, data: rowData } = useEndpoint(
    "ecp/ff_campaignEmails/customer_success",
    DEFAULT_SHOULD_FETCH,
    { responseFormat: "arrow" }
  );
  return loading || !rowData ? (
    <Spinner />
  ) : (
    <CampaignemailTableInner rowData={rowData} />
  );
}

export function CampaignemailTableInner({ rowData }) {
  const colDefs = useColDefsForCampaignEmailsTable();
  return (
    <AgTable
      // @ts-ignore
      rowData={rowData.toArray()}
      columnDefs={colDefs}
      tableName={"ecp_campaignEmail--customer-success"}
      ribbonTitle={"Campaign Emails"}
      height={"calc(100vh - 64px - 40px - 40px - 46px - 48px)"}
    />
  );
}

export const STUDENT_NEXT_COURSE_RECOS_EMAIL_TYPE_CODE_PATT =
  /^student__next_course_recos(\d+)(_\w+)?$/;
export const STUDENT_WINBACK_EMAIL_TYPE_CODE_PATT =
  /^student__winback(\d+)(_\w+)?$/;
export const STUDENT_SOR_WINBACK_EMAIL_TYPE_CODE_PATT =
  /^student__sor_winback(\d+)(_\w+)?$/;

function isCgnEmail(code: string): boolean {
  if (STUDENT_NEXT_COURSE_RECOS_EMAIL_TYPE_CODE_PATT.test(code)) {
    return true;
  } else if (STUDENT_WINBACK_EMAIL_TYPE_CODE_PATT.test(code)) {
    return true;
  } else if (STUDENT_SOR_WINBACK_EMAIL_TYPE_CODE_PATT.test(code)) {
    return true;
  } else {
    return false;
  }
}
