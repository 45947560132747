import React, { useState } from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import { CourseAssessmentMetricsTable } from "./course-assessment-metrics-table";
import { VendorAssessmentMetricsTable } from "./vendor-assessment-metrics-table";

const TAB_IDS = {
  vendorAssessmentMetrics: "vendor-assessment-metrics",
  courseAssessmentMetrics: "course-assessment-metrics",
};

export function VendorAssessmentTabsUnit() {
  const defaultTabId = TAB_IDS.courseAssessmentMetrics;
  const [selectedTabId, setSelectedTabId] = useState(defaultTabId);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
      `}
    >
      <Tabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        fill
        renderActiveTabPanelOnly={true}
        css={`
          margin-top: -15px;
        `}
      >
        <Tab
          id={TAB_IDS.courseAssessmentMetrics}
          title="Course Assessment Metrics"
          panel={<CourseAssessmentMetricsTable />}
        />
        <Tab
          id={TAB_IDS.vendorAssessmentMetrics}
          title="Vendor Assessment Metrics"
          panel={<VendorAssessmentMetricsTable />}
        />
      </Tabs>
    </div>
  );
}
