import { Recommendation } from "../types";
import React from "react";

export function History({
  nextCourseRecos,
}: {
  nextCourseRecos: Recommendation[];
}): JSX.Element {
  const deptHist = useDepartmentHistory(nextCourseRecos);
  return (
    <div
      css={`
        margin-left: 24px;
        display: grid;
        gap: 16px;
        grid-template-columns: 44px 1fr;
      `}
    >
      <div>Latest:</div>
      <span>
        <span>{deptHist.latestTakenCourseCanonicalName + " "}</span>
        <span className={"bp3-text-muted"}>
          ({deptHist.latestTakenCourseFullName})
        </span>
      </span>
    </div>
  );
}

function useDepartmentHistory(nextCourseRecos: Recommendation[]) {
  const arbitraryReco = nextCourseRecos[0];
  const latestTakenCourseCanonicalName = arbitraryReco.source;
  const latestTakenCourseFullName = arbitraryReco.CourseName;
  return { latestTakenCourseCanonicalName, latestTakenCourseFullName };
}
