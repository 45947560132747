import { useStoreState } from "../hooks/ep";

// export function useEcpStudentRow(id) {
//   return useStoreState((s) => s.ecp_student.rowDataById[id]);
// }

// export function getEcpStudentRow(id) {
//   const rowDataById = epStore.getState().ecp_student.rowDataById;
//   return _.size(rowDataById) ? rowDataById[id] : {};
// }

export function useRow(id, sliceName) {
  return useStoreState((s) => s[sliceName].rowsById[id]);
}
