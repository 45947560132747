import styled from "styled-components/macro";

export const Customer360PageContainerDiv = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-gap: 40px;

  grid-template:
    "selection  selection selection" min-content
    "basic      basic     basic    " min-content
    "recos      recos     recos    " 1fr
    "history    history   history  " min-content
    "footer     footer    footer   " min-content / 1fr 1fr 1fr;

  & .cmax-selection-next-course-recos-unit {
    grid-area: selection;
  }

  & .cmax-basic-next-course-recos-unit {
    grid-area: basic;
  }

  & .cmax-history-next-course-recos-unit {
    grid-area: history;
  }

  & .cmax-recommendations-unit {
    grid-area: recos;
  }

  & .cmax-footer-unit {
    height: 1px;
    margin-top: -1px;
    grid-area: footer;
  }
`;
