import { Computed, computed } from "easy-peasy";
import { StoreModel } from "./model";
import { TableModel, tableModelFactory, TableRow } from "./table-model-factory";
import { timeit } from "../helpers/timing";

export type ChurnRateAnalysisStudentsModel = TableModel & {
  rowData: Computed<ChurnRateAnalysisStudentsModel, TableRow[], StoreModel>;
};

export function getChurnRateAnalysisStudentsModel(): ChurnRateAnalysisStudentsModel {
  return {
    ...tableModelFactory(
      "churnRateAnalysisStudents",
      "churn_rate_analysis_students",
      (row) => row.StudentIndex || row.customer_id
    ),
    rowData: computed(
      [(state) => state.initialData],
      timeit((rowData) => rowData, "state.churnRateAnalysisStudents.rowData")
    ),
  };
}
