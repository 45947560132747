import React from "react";
import { Callout, Dialog, H4 } from "@blueprintjs/core";
import { useStoreActions, useStoreState } from "../hooks/ep";
import { Letter } from "react-letter";
import styled from "styled-components/macro";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";

export const SentEmailContentDialog = () => {
  /*
   * NOTE [2023-08-22]:
   * This component is used to display the content of an email that was sent by Customax.
   * It is rendered at the AppWrapper level so that it can be displayed on top of other components.
   * It is only rendered when the user clicks on the "View Email Content" button available in the right-pinned
   * column of the EcpCampaignemailTable component.
   *
   * This component is quite similar to the other component OpenMailtoDialog that prompts the user to choose how to
   * open Email Templates for student communications, but has a more complex logic than this component.
   *
   * It is desired to have a single component for rendering top level dialogs and keeping track on their state,
   * but this would require more changes to the codebase on untested components.
   *
   * To future developers, if you find some spare time in a work related to these components, please consider
   * refactoring the code aiming for re-usability.
   */
  const sentEmailContent = useStoreState((s) => s.misc.sentEmailContent);
  const handleCloseSentEmailViewerOverlays = useStoreActions(
    (a) => a.misc.handleCloseSentEmailViewerOverlays
  );
  const body = normalizeBodyColor(sentEmailContent?.body);
  return (
    <StyledDialog
      canEscapeKeyClose
      canOutsideClickClose
      key={"sent-email-content-dialog"}
      //@ts-ignore
      onClose={handleCloseSentEmailViewerOverlays}
      className={"bp3-dark"}
      icon="envelope"
      title={"Email Content"}
      isOpen={!!sentEmailContent}
      usePortal={true}
    >
      <div className="bp3-dialog-body">
        <H4>Subject</H4>
        <Callout key={"subject-callout"} intent={Intent.NONE}>
          <span>{sentEmailContent?.subject}</span>
        </Callout>
        <Separator />
        <H4>Body</H4>
        <Callout key={"body-callout"} intent={Intent.NONE}>
          <Letter html={body} />
        </Callout>
      </div>
    </StyledDialog>
  );
};

function normalizeBodyColor(htmlBody: string) {
  if (!htmlBody) {
    return "";
  }
  return htmlBody.replaceAll(/color:\s?#000000/g, "");
}

const StyledDialog = styled(Dialog)`
  min-width: 800px;
`;
const Separator = styled.div`
  height: 20px;
`;
