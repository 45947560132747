import React from "react";
import { Unit } from "../../../components/units/unit";
import { RecommendationsUnitContent } from "./unit-content/recommendations-unit-content";
import { useLoading } from "./hooks/use-loading";

export function RecommendationsUnit(): JSX.Element {
  const loading = useLoading();
  return (
    <Unit unitId={"recommendations"} loading={loading}>
      <RecommendationsUnitContent />
    </Unit>
  );
}
