import { Computed, computed, State } from "easy-peasy";
import { StoreModel } from "../model";
import {
  TableModel,
  tableModelFactory,
  TableRow,
} from "../table-model-factory";
import _ from "lodash";
import { addTagsField } from "../../entities/ecp/tags";
import {
  EcpTeachercourseModel,
  EcpTeachercourseTableRow,
} from "./model-ecp-teacherCourse";
import {
  EcpEnrollmentModel,
  transformInitialData,
} from "./model-ecp-enrollment";

export type EcpTeacherModel = TableModel & {
  rowData: Computed<EcpTeacherModel, TableRow[], StoreModel>;
};

export function getEcpTeacherModel(): EcpTeacherModel {
  return {
    ...tableModelFactory(
      "ecp_teacher",
      "ecp/ff_teachers",
      (row) => row.TeacherIndex,
      [
        {
          name: "ecp_teacher_teacherCourse",
          arity: "OneToMany",
          foreignName: "ecp_teacher_teacherCourse",
          foreignField: "TeacherIndex",
          field: "TeacherIndex",
        },
        {
          name: "teacherRetentionKpis",
          arity: "OneToMany",
          foreignName: "teacherRetentionKpis",
          foreignField: "TeacherIndex",
          field: "TeacherIndex",
        },
      ]
    ),
    rowData: computed(
      [
        (state) => state.initialData,
        (state) => state.relatedRowsMap,
        (state, storeState) => storeState.teacher_attr.valuesMapByTeacher,
      ],
      (rowData, relatedRowsMap, teacherAttrValuesMapByTeacher) =>
        rowData.map((row) => {
          const teacher_id = row.TeacherIndex;
          const attrValuesMap = teacherAttrValuesMapByTeacher[teacher_id];
          if (attrValuesMap) {
            row = { ...row, ...attrValuesMap };
          }
          const teacherCourses =
            relatedRowsMap[row.id]?.ecp_teacher_teacherCourse ?? [];
          const teacherRetentionKpis =
            relatedRowsMap[row.id]?.teacherRetentionKpis ?? [];
          const teacherCourseIds = teacherCourses.map((v) => v.id);
          const enrollments = teacherCourses
            .flatMap((tc) => tc.enrollments)
            .filter((enr) => !enr.is_irregularly_paced_course);
          const enrollmentIds = enrollments.map((v) => v.id);
          const miaEnrollmentIds = enrollments
            .filter((enr) => enr.tag__missing_in_action)
            .map((v) => v.id);
          const teacherRetentionRateCurrent =
            teacherRetentionKpis.length > 0
              ? teacherRetentionKpis[0].retention_rate_current
              : null;

          return {
            ...row,
            teacherCourses,
            teacherCourseIds,
            enrollments,
            enrollmentIds,
            miaEnrollmentIds,
            teacherRetentionRateCurrent,
          };
        })
    ),
  };
}

export function getTeacherTeachercourseModel(): EcpTeachercourseModel {
  return {
    ...tableModelFactory(
      "ecp_teacher_teacherCourse",
      "ecp/ff_teachers/teacherCourses",
      (row) => row.teacherCourse_id,
      [
        {
          name: "ecp_teacher_enrollment",
          arity: "OneToMany",
          foreignName: "ecp_teacher_enrollment",
          foreignField: "teacherCourse_id",
          field: "teacherCourse_id",
        },
      ],
      (initData) => addTagsField(initData, null)
    ),
    rowData: computed(
      [(state) => state.initialData, (state) => state.relatedRowsMap],
      (rowData, relatedRowsMap) =>
        rowData.map((row) => {
          const enrollments =
            relatedRowsMap[row.id]?.ecp_teacher_enrollment ?? [];
          const enrollmentIds = enrollments.map((v) => v.id);
          return {
            ...row,
            enrollments,
            enrollmentIds,
            num_enrollments: _.size(enrollments),
          };
        })
    ),
    rowsById: computed<
      EcpTeachercourseModel,
      { [key: string]: EcpTeachercourseTableRow },
      StoreModel,
      [{ (s: State<EcpTeachercourseModel>): EcpTeachercourseTableRow[] }]
    >([(s): EcpTeachercourseTableRow[] => s.rowData], (rowData) =>
      Object.fromEntries(rowData.map((row) => [row.id, row]))
    ),
  };
}

export function getTeacherEnrollmentModel(): EcpEnrollmentModel {
  return {
    ...tableModelFactory(
      "ecp_teacher_enrollment",
      "ecp/ff_teachers/enrollments",
      (row) => row.EnrollmentIndex,
      [],
      transformInitialData
    ),
    byCustomerId: computed([(s) => s.initialData], (initialData: TableRow[]) =>
      _.groupBy(initialData, "customer_id")
    ),
  };
}
