import { Computed, computed } from "easy-peasy";
import { StoreModel } from "../model";
import {
  TableModel,
  tableModelFactory,
  TableRow,
} from "../table-model-factory";

export type EcpCampaignemailTableRow = TableRow & {
  id: number;
  //
  email_type_code: string;
  email_type_name: string;
  sent_at: string;
  from_email: string;
  to_email: string;
  subject: string;
  cc_emails: string;
  // bcc_emails: string;
  //
  student_index: number;
  enrollment_index: number;
  enrollment_buzz_id: number;
  teacher_index: number;
  course_index: number;
  //
  teacher_fullname: string;
  // student_fullname: string;
  adc_emails: string;
  course_name: string;
  //
  // is_TO_student_email: boolean;
  // is_TO_guardian_email: boolean;
  // is_TO_teacher_email: boolean;
  opened_by_TO: boolean;
  clicked_by_TO: boolean;
};

export type EcpCampaignemailModel = TableModel<EcpCampaignemailTableRow> & {
  rowData: Computed<
    EcpCampaignemailModel,
    EcpCampaignemailTableRow[],
    StoreModel
  >;
};

function getAdmissionsCoordinator(stu) {
  const ac = stu["AdmissionsCoordinator"];
  if (!ac || !ac.trim()) {
    return null;
  } else {
    return removeRepeatedSpaceCharacters(
      removeNumericCharacters(ac.trim()).trim()
    );
  }
}

function removeRepeatedSpaceCharacters(str: string) {
  return str.replace(/\s+/g, " ");
}

function removeNumericCharacters(str: string) {
  return str.replace(/\d+/g, "");
}

export function getEcpCampaignemailModel(): EcpCampaignemailModel {
  return {
    ...tableModelFactory(
      "ecp_campaignEmail",
      "ecp/ff_campaignEmails",
      (row) => row.id,
      [
        {
          name: "ecp_student",
          arity: "ManyToOne",
          foreignName: "ecp_student",
          foreignField: "StudentIndex",
          field: "student_index",
        },
      ],
      null,
      "json"
    ),
    rowData: computed(
      [(state) => state.initialData, (state) => state.relatedRowsMap],
      (rowData, relatedRowsMap) =>
        rowData.map((row) => {
          const stu = relatedRowsMap[row.id]?.ecp_student || {};
          return {
            ...row,
            IsNCAA: stu["IsNCAA"] ?? null,
            SchoolRecord: stu["SchoolRecord"] ?? null,
            Counselor: stu["Counselor"] ?? null,
            AcademicAdvisor: stu["AcademicAdvisor"] ?? null,
            StudentServices: stu["StudentServices"] ?? null,
            AdmissionsCoordinator: getAdmissionsCoordinator(stu),
            GradeLevel: stu["GradeLevel"] ?? null,
          };
        })
    ),
  };
}
