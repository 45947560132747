import { useEnsureData } from "../helpers/use-ensure-data";
import _ from "lodash";
import React from "react";
import AgTable from "../components/AgTable";
import { Spinner } from "@blueprintjs/core";
import { useStoreState } from "easy-peasy";
import { AgColDef } from "../components/AgTable/types";
import {
  getStudentBuzzUrl,
  getStudentGeniusUrl,
} from "../entities/ecp/ecp-student-entity";
import { DEFAULT_TEACHER_ID } from "../components/splitviews/ecp-teacher-retention-rate-students-splitview";

export function TeacherRetentionKpisTable({
  fullScreenEnabled = true,
  teacherId = DEFAULT_TEACHER_ID,
  ribbonTitle = null,
  ...restProps
}): JSX.Element {
  const loading = _.max([useEnsureData("teacherRetentionKpis")]);

  return loading ? (
    <Spinner />
  ) : (
    <TeacherRetentionKpisContent
      fullScreenEnabled={fullScreenEnabled}
      teacherId={teacherId}
      ribbonTitle={ribbonTitle}
      {...restProps}
    />
  );
}

function TeacherRetentionKpisContent({
  teacherId,
  tableNameSuffix = null,
  tableName = null,
  ribbonTitle = null,
  ...restProps
}) {
  let rowData = useStoreState((s) => s.teacherRetentionKpis.initialData);

  if (teacherId !== DEFAULT_TEACHER_ID) {
    rowData = rowData.filter((row) => row.TeacherIndex === teacherId);
  }

  const colDefs = useTeacherRetentionKpisColDefs();

  return (
    <AgTable
      tableName={tableName || "teacher_retention_kpis"}
      tableNameSuffix={tableNameSuffix}
      rowData={rowData}
      columnDefs={colDefs}
      ribbonTitle={ribbonTitle}
      {...restProps}
    />
  );
}

function useTeacherRetentionKpisColDefs(): AgColDef[] {
  return [
    {
      headerName: "Student Name",
      field: "StudentName",
      type: "textColumn",
      width: 150,
      suppressSizeToFit: true,
      tooltipField: "StudentName",
      pinned: "left",
    },
    {
      headerName: "Student Re-Enrolled (Past Year)",
      field: "student_has_enrollment_within_past_1_to_365_days",
      width: 180,
      type: "booleanColumn",
      headerTooltip: "Student had classes with any teacher in the past year",
      pinned: "left",
    },
    {
      headerName: "Genius",
      field: "StudentIndex",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentGeniusUrl,
      __linkIsNotAnEmail: true,
    },
    {
      headerName: "Buzz",
      field: "LMSID",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentBuzzUrl,
      __linkIsNotAnEmail: true,
    },
    {
      headerName: "Genius Status",
      field: "Status",
      type: "categoryColumn",
      width: 130,
    },
    {
      headerName: "Academy Potential",
      field: "AcademyPotential",
      type: "booleanColumn",
      width: 110,
    },
    {
      headerName: "Grade Level",
      field: "GradeLevel",
      type: "integerColumn",
      filter: "categoryFilterer",
      cellClass: "ag-numeric-cell",
      width: 87,
    },
    {
      headerName: "Max Enr End Date",
      field: "max_enrollment_end_date",
      type: "dateColumn",
      width: 122,
    },
    {
      headerName: "Tenure [years]",
      field: "n_schoolyears_enrolled",
      type: "floatColumn",
      headerTooltip: "# of schoolyears enrolled with LSS",
      width: 100,
    },
    {
      headerName: "SoR",
      field: "SchoolRecord",
      type: "booleanColumn",
    },
    {
      headerName: "NCAA",
      field: "IsNCAA",
      type: "booleanColumn",
    },
    {
      headerName: "Academy",
      field: "AcademyStudent",
      type: "booleanColumn",
    },
    {
      headerName: "Retention Notes",
      field: "RetentionNotes",
      type: "textColumn",
      width: 300,
      suppressSizeToFit: true,
      tooltipField: "RetentionNotes",
      cellClass: ["ellipses-tooltip-cell"],
    },
    {
      headerName: "Admissions Coordinator",
      field: "AdmissionsCoordinator",
      type: "categoryColumn",
    },
    {
      headerName: "Academic Advisor",
      field: "AcademicAdvisor",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "AcademicAdvisor",
    },
    {
      headerName: "Counselor",
      field: "Counselor",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "Counselor",
    },
    {
      headerName: "Student Services",
      field: "StudentServices",
      type: "categoryColumn",
    },
    {
      headerName: "Student Phone",
      field: "Phone",
      type: "textColumn",
      tooltipField: "Phone",
    },
    {
      headerName: "Student Email",
      field: "Email",
      type: "textColumn",
      tooltipField: "Email",
    },
    {
      headerName: "Guardian1 Phone",
      field: "Guardian1Phone",
      type: "textColumn",
      tooltipField: "Guardian1Phone",
    },
    {
      headerName: "Guardian1 Email",
      field: "Guardian1Email",
      type: "textColumn",
      tooltipField: "Guardian1Email",
    },
    {
      headerName: "Teacher Name",
      field: "TeacherName",
      type: "textColumn",
      width: 150,
      suppressSizeToFit: true,
      tooltipField: "TeacherName",
    },
  ];
}
