import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { Select } from "@blueprintjs/select";
import { Button, Classes, Intent, MenuItem } from "@blueprintjs/core";

import { useEntitySelection } from "../../store/model-entity-selection";
import { Predicate } from "./student-selector";
import { useSelectedCustomer } from "../units/selection-unit";
import { compareHistoricalOrders } from "../units/orders-unit";

type OrderIndex = number;

function useAllCustomerOrdersOptions({
  filter,
}: {
  filter: null | Predicate;
}): OrderIndex[] {
  const customer = useSelectedCustomer();
  const customerOrders = customer.orders.sort(compareHistoricalOrders);

  return useMemo(() => {
    const filteredCustomerOrders = filter
      ? customerOrders.filter(filter)
      : customerOrders;

    return filteredCustomerOrders.map(
      (order) => order.OrderIndex
    ) as OrderIndex[];
  }, [customerOrders, filter]);
}

function useOrderIndexToName(): { [index: number]: string } {
  const customer = useSelectedCustomer();
  const customerOrders = customer.orders;

  return Object.fromEntries(
    customerOrders.map((order) => [order.OrderIndex, order.Name])
  );
}

export function OrderSelector({
  filter,
}: {
  filter: null | Predicate;
}): JSX.Element {
  const allCustomerOrdersOptions = useAllCustomerOrdersOptions({ filter });
  const orderIndexToName = useOrderIndexToName();
  const [selectedOrderIndex, selectOrderIndex] =
    useEntitySelection("order_index");

  const onItemSelect = useCallback(
    (v) => {
      selectOrderIndex(v);
    },
    [selectOrderIndex]
  );

  useEffect(() => {
    if (
      selectedOrderIndex &&
      !allCustomerOrdersOptions.includes(selectedOrderIndex as OrderIndex)
    ) {
      selectOrderIndex("");
    }
  }, [allCustomerOrdersOptions, selectedOrderIndex, selectOrderIndex]);

  return (
    <div
      css={`
        display: flex;
        align-items: center;
        & .order-index-select-btn {
          box-shadow: none !important;
        }
      `}
    >
      <Select
        intent="primary"
        itemPredicate={itemPredicateFactory(orderIndexToName)}
        itemRenderer={itemRendererFactory(orderIndexToName)}
        items={allCustomerOrdersOptions}
        onItemSelect={onItemSelect}
        popoverProps={POPOVER_PROPS}
      >
        <Button
          intent={Intent.NONE}
          className="order-index-select-btn"
          large
          disabled={!_.size(allCustomerOrdersOptions)}
          rightIcon="caret-down"
        >
          {selectedOrderIndex
            ? `${selectedOrderIndex} | ${orderIndexToName[selectedOrderIndex]}`
            : ""}
        </Button>
      </Select>
    </div>
  );
}

function itemRendererFactory(orderIndexToName) {
  return function itemRenderer(orderIndex, { handleClick, modifiers }) {
    return (
      <MenuItem
        active={false}
        key={orderIndex}
        label={orderIndexToName[orderIndex]}
        onClick={handleClick}
        text={orderIndex}
      />
    );
  };
}

function itemPredicateFactory(orderIndexToName) {
  return function itemPredicate(
    query: string,
    item: string,
    index: number,
    exactMatch: boolean
  ) {
    const orderName = orderIndexToName[item];
    const queryPatt = new RegExp(`^.*\\b${_.escapeRegExp(query)}.*$`, "gi");
    const isOrderNameMatch = orderName && queryPatt.test(orderName);
    const isItemMatch = queryPatt.test(item);

    if (exactMatch) {
      return item === query || orderName === query;
    } else if (!query.length) {
      return true;
    } else {
      return isItemMatch || isOrderNameMatch;
    }
  };
}

const POPOVER_PROPS = {
  targetClassName: Classes.ELEVATION_1,
  popoverClassName: "order-selector-popover",
};
