import React from "react";
import { Recommendation } from "../types";
import styled from "styled-components/macro";
import { Strength } from "./reco-strength";
import { Course } from "./course";

export function NextCourseReco({
  reco,
}: {
  reco: Recommendation;
}): JSX.Element {
  return (
    <Container className={"next-course-recommendation"}>
      <Strength proba={reco.proba} />
      <Strength proba={reco.proba__n_sor} />
      <Course {...reco} />
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
`;
