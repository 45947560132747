import { Button, MenuItem, Position } from "@blueprintjs/core";
import React, { useRef } from "react";
import { Suggest } from "@blueprintjs/select";
import "styled-components/macro";

const ENTER_RETURN_KEY_CODE = 13;

export type PhoneNumber = string;

export const PhoneNumberS = Suggest.ofType<PhoneNumber>();

export function PhoneNumberInput({
  queryText,
  setQueryText,
  selectedItem,
  onSelect,
  onClear,
  activeField,
  setActiveField,
  defaultOptions,
}) {
  const phoneNumberInputRef = useRef(null);

  const handleFocus = () => {
    phoneNumberInputRef.current && phoneNumberInputRef.current.focus();
    setActiveField("phoneNumber");
  };

  const isItemSelected = (r) => selectedItem === r;
  const onItemSelect = (phoneNumber) => {
    if (isItemSelected(phoneNumber)) {
      onClear();
    } else {
      onSelect(phoneNumber);
    }
  };
  // const onItemsSelect = (phoneNumber) => phoneNumber.map(onItemSelect);

  return (
    <div
      css={`
        .bp3-multi-select-popover .bp3-menu {
          //max-width: none !important;
          max-height: 220px !important;
          overflow-y: auto;
        }
      `}
    >
      <PhoneNumberS
        fill
        inputValueRenderer={(v) => v}
        createNewItemFromQuery={undefined}
        onKeyDown={(ev) => {
          if (ev.keyCode === ENTER_RETURN_KEY_CODE) {
            ev.preventDefault();
          }
        }}
        resetOnSelect={false}
        createNewItemRenderer={null}
        openOnKeyDown={false}
        items={defaultOptions}
        itemDisabled={isItemSelected}
        itemPredicate={phoneNumberPredicate}
        itemRenderer={phoneNumberItemRenderer(isItemSelected)}
        selectedItem={selectedItem}
        onItemSelect={onItemSelect}
        tagRenderer={phoneNumberTagRenderer}
        placeholder=""
        query={queryText}
        onQueryChange={setQueryText}
        // onFocus={handleFocus}
        popoverProps={{
          usePortal: false,
          minimal: true,
          position: Position.BOTTOM_LEFT,
        }}
        inputProps={{
          onFocus: handleFocus,
          name: "phoneNumber",
          autoFocus: true,
          inputRef: (r) => {
            phoneNumberInputRef.current = r;
          },
          leftIcon: "phone",
          large: true,
          onRemove: onClear,
        }}
        // TODO: Center for IE11
        // css={`
        //   .bp3-popover-content .bp3-menu {
        //     width: ${width}px;
        //   }
        // `}
      />
    </div>
  );
}

const phoneNumberPredicate = (
  query,
  phoneNumber: PhoneNumber,
  _index,
  exactMatch
) => {
  const normalizedTitle = phoneNumber.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return normalizedTitle.includes(normalizedQuery);
  }
};
const phoneNumberTagRenderer = (phoneNumber: PhoneNumber) => phoneNumber;
const phoneNumberItemRenderer =
  (isItemSelected) =>
  (phoneNumber: PhoneNumber, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const isSelected = isItemSelected(phoneNumber);
    return (
      <MenuItem
        active={modifiers.active}
        icon={isSelected ? "tick" : "blank"}
        disabled={false}
        key={phoneNumber}
        text={phoneNumber}
        // label={phoneNumber.longName}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    );
  };
