import React from "react";
import { Spinner } from "@blueprintjs/core";
import { ColDef } from "@ag-grid-community/core/dist/es6/entities/colDef";
import _ from "lodash";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { useStoreState } from "../../hooks/ep";
import { UserAction } from "../../user-actions/user_actions";
import AgTable from "../../components/AgTable";

export function ReeCustomerInteractionsTable(props) {
  const loading = useEnsureData("ree_customerInteraction");
  return loading ? (
    <Spinner />
  ) : (
    <ReeCustomerInteractionsTableInner {...props} />
  );
}

function ReeCustomerInteractionsTableInner({
  finalizeRowData = (v) => v,
  finalizeColDefs = (v) => v,
  ...restProps
}) {
  let rowData = useStoreState(
    (s) => s.ree_customerInteraction.initialData
  ) as UserAction[];
  rowData = _.sortBy(rowData, "submitted_at")
    .reverse()
    .map((row) => ({
      ...row,
      callTopics: getMSValuesString(row, "callTopics"),
      customerIssues: getMSValuesString(row, "customerIssues"),
    }));
  return (
    <AgTable
      tableName="ree_interactions"
      rowData={finalizeRowData(rowData)}
      columnDefs={finalizeColDefs(COLUMN_DEFS)}
      {...restProps}
    />
  );
}

function getMSValuesString(row: UserAction, fieldName: string): string {
  const {
    action_payload: {
      form_payload: { [fieldName]: msValues },
    },
  } = row;
  return msValues.map(({ longName }) => longName).join(", ");
}

const COLUMN_DEFS: ColDef[] = [
  { headerName: "Submitted By", field: "submitted_by", type: "textColumn" },
  {
    headerName: "Submitted At",
    field: "submitted_at",
    type: "timestampColumn",
  },
  { headerName: "Action", field: "action_code", type: "categoryColumn" },
  {
    headerName: "Student Name",
    field: "action_payload.customer_snapshot.customer_name",
    type: "textColumn",
  },
  {
    headerName: "Topics",
    field: "callTopics",
    type: "textColumn",
    width: 200,
    suppressSizeToFit: true,
    tooltipField: "callTopics",
  },
  {
    headerName: "Issues",
    field: "customerIssues",
    type: "textColumn",
    width: 200,
    suppressSizeToFit: true,
    tooltipField: "customerIssues",
  },
  {
    headerName: "Notes",
    field: "action_payload.form_payload.notes",
    type: "textColumn",
    width: 200,
    suppressSizeToFit: true,
    tooltipField: "action_payload.form_payload.notes",
  },
  {
    headerName: "Attendees",
    field: "action_payload.form_payload.attendees",
    type: "textColumn",
    width: 200,
    suppressSizeToFit: true,
    tooltipField: "action_payload.form_payload.attendees",
  },
  {
    headerName: "Phone Number",
    field: "action_payload.form_payload.phone",
    type: "textColumn",
  },
  {
    headerName: "Sentiment",
    field: "action_payload.form_payload.customerSentiment",
    type: "categoryColumn",
  },
  {
    headerName: "Churn Likelihood",
    field: "action_payload.form_payload.churnLikelihood",
    type: "categoryColumn",
  },
];
