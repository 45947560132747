import React from "react";
import { moneyVF } from "../gridOptions";
import _ from "lodash";
import { Tag } from "@blueprintjs/core";

export function ThePopoverTarget({ napo }) {
  if (_.isUndefined(napo) || _.isNull(napo) || _.isNaN(napo)) {
    return <span />;
  } else {
    const content = moneyVF(napo);
    return (
      <Tag intent="primary" interactive large minimal>
        {content}
      </Tag>
    );
  }
}
