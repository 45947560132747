import { AgColDefs } from "../../components/AgTable/types";
import { isNotDefaultStudentId } from "../../pages/loyalty/loyalty-students-splitview/splitview-helpers";
import { getStudentGeniusUrl } from "./ecp-student-entity";

export function useColDefs(studentId): AgColDefs {
  return [
    {
      headerName: "Genius Communication ID",
      field: "CommunicationIndex",
      type: "integerIdentifierColumn",
      hide: true,
    },
    {
      headerName: "Date",
      field: "sent_at",
      type: "timestampColumn",
      width: 260,
    },
    {
      headerName: "Student Name",
      field: "student_full_name",
      type: "textColumn",
      hide: isNotDefaultStudentId(studentId),
      width: 165,
    },
    {
      headerName: "Student Genius ID",
      field: "StudentIndex",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentGeniusUrl,
      __linkIsNotAnEmail: true,
      hide: isNotDefaultStudentId(studentId),
      width: 120,
    },
    {
      headerName: "User",
      field: "sender_full_name__normalized",
      type: "categoryColumn",
    },
    {
      headerName: "Category",
      field: "Category",
      type: "categoryColumn",
    },
    {
      headerName: "Automated Email Type",
      field: "EmailType",
      type: "categoryColumn",
      width: 165,
    },
    {
      headerName: "Subject",
      field: "Subject",
      type: "categoryColumn",
      width: 200,
    },
    {
      headerName: "Loyalty 21",
      field: "is_loyalty21_comm",
      type: "booleanColumn",
    },
    {
      headerName: "Loyalty 22",
      field: "is_loyalty22_comm",
      type: "booleanColumn",
    },
    {
      headerName: "Opened",
      field: "opened",
      type: "booleanColumn",
    },
    {
      headerName: "Contents",
      field: "ContentsPreview",
      type: "categoryColumn",
    },
    {
      headerName: "Read On",
      field: "student_opened_at",
      type: "timestampColumn",
      width: 260,
    },
  ];
}
