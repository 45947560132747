import { StyledFormGroup } from "../forms-stuff/styled-form-group";
import { CallTopicsInput } from "./call-topics-input";
import { NotesInput } from "./notes-input";
import { CustomerSentimentInput } from "./customer-sentiment-input";
import React from "react";
import "styled-components/macro";
import { ChurnLikelihoodInput } from "./churn-likelihood-input";
import { TooltipLabel } from "../forms-stuff/tooltip-label";
import { AttendeesInput } from "./attendees-input";
import { CustomerIssuesInput } from "./customer-issues-input";
import { PhoneInput } from "./phone-input";

export function ReeCustomerInteractionFormContent({
  formState,
  formActions,
  activeField,
  setActiveField,
}) {
  return (
    <>
      <StyledFormGroup
        label={
          <TooltipLabel
            labelText={"Call Topics"}
            tooltipText={CALL_TOPICS_HELPER_TEXT}
          />
        }
        labelFor="callTopics"
        helperText={CALL_TOPICS_HELPER_TEXT}
        activeField={activeField}
      >
        <CallTopicsInput
          queryText={formState.callTopicsQueryText}
          setQueryText={formActions.setCallTopicsQueryText}
          selectedItems={formState.callTopics}
          onAdd={formActions.pushCallTopic}
          onClear={formActions.clearCallTopics}
          onRemove={formActions.removeCallTopic}
          activeField={activeField}
          setActiveField={setActiveField}
        />
      </StyledFormGroup>
      <StyledFormGroup
        label={
          <TooltipLabel
            labelText={"Student Issues"}
            tooltipText={CUSTOMER_ISSUES_HELPER_TEXT}
          />
        }
        labelFor="customerIssues"
        helperText={CUSTOMER_ISSUES_HELPER_TEXT}
        activeField={activeField}
      >
        <CustomerIssuesInput
          queryText={formState.customerIssuesQueryText}
          setQueryText={formActions.setCustomerIssuesQueryText}
          selectedItems={formState.customerIssues}
          onAdd={formActions.pushCustomerIssue}
          onClear={formActions.clearCustomerIssues}
          onRemove={formActions.removeCustomerIssue}
          activeField={activeField}
          setActiveField={setActiveField}
        />
      </StyledFormGroup>
      <NotesInput
        value={formState.notes}
        onValueChange={formActions.setNotes}
        activeField={activeField}
        setActiveField={setActiveField}
      />
      <AttendeesInput
        value={formState.attendees}
        onValueChange={formActions.setAttendees}
        activeField={activeField}
        setActiveField={setActiveField}
      />
      <PhoneInput
        formState={formState}
        formActions={formActions}
        helperText={
          "If you connected with the student over the phone, please provide the phone number you used"
        }
        label={"Phone Number"}
        value={formState.phone}
        onValueChange={formActions.setPhone}
        activeField={activeField}
        setActiveField={setActiveField}
      />
      <CustomerSentimentInput
        value={formState.customerSentiment}
        onValueChange={formActions.setCustomerSentiment}
        activeField={activeField}
        setActiveField={setActiveField}
      />
      <ChurnLikelihoodInput
        value={formState.churnLikelihood}
        onValueChange={formActions.setChurnLikelihood}
        activeField={activeField}
        setActiveField={setActiveField}
      />
    </>
  );
}

const CALL_TOPICS_HELPER_TEXT = "What was the subject of your call?";
const CUSTOMER_ISSUES_HELPER_TEXT =
  "How would you characterize the issue(s) the student is facing?";
