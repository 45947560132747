import React from "react";

export function Course({
  target,
  reco_course__ProgramofStudyDisplayName,
}: {
  target: string;
  reco_course__ProgramofStudyDisplayName: string;
}): JSX.Element {
  return <span>{target}</span>;
}
