import React, { useCallback } from "react";
import {
  Menu,
  MenuItem,
  Position,
  Spinner,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import "styled-components/macro";
import { useBoolean } from "../../helpers/useBoolean";
import { StyledPopoverWrapper } from "../../popover-wrapper";
import _ from "lodash";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { useMeData } from "../../hooks/useMe";
import { UserRole } from "../../store/model-me";
import { STUDENT_TAGS_INFO } from "../../entities/ecp/tags";
import { useStoreActions } from "../../hooks/ep";

const ALL_MENU_ITEMS = ["tag__missing_in_action"];
const USER_ROLES__UNIVERSAL_ACCESS = [
  UserRole.SAXECAP_DEV,
  UserRole.ADMIN_READ_WRITE,
  UserRole.ADMIN_READ_ONLY,
];
const USER_ROLES__MISSING_IN_ACTION_ONLY = [
  UserRole.ACADEMIC_DEPARTMENT_CHAIR,
  UserRole.DEAN_OF_FACULTY,
];
export const CAN_VIEW_ENROLLMENT_EMAIL_ACTIONS = [
  ...USER_ROLES__UNIVERSAL_ACCESS,
  ...USER_ROLES__MISSING_IN_ACTION_ONLY,
];

function useAuthorizedMenuItems() {
  const { role } = useMeData();
  if (USER_ROLES__UNIVERSAL_ACCESS.includes(role)) {
    return ALL_MENU_ITEMS;
  } else if (USER_ROLES__MISSING_IN_ACTION_ONLY.includes(role)) {
    return ["tag__missing_in_action"];
  } else {
    return [];
  }
}

export function EnrollmentEmailActionButtonCellRenderer({
  data,
  crp,
}: {
  data: any;
  crp: { tooltipText?: string };
}) {
  const authorizedMenuItems = useAuthorizedMenuItems();
  const isLoading = useBoolean(false);
  const handleFetchMailtoLink = useStoreActions(
    (a) => a.misc.handleFetchMailtoLink
  );

  const enrollmentIndex = data["EnrollmentIndex"];

  const enabledMenuItems = ALL_MENU_ITEMS.filter((tagField) => data[tagField]);
  const isMenuItemDisabled = (menuItemId) =>
    !authorizedMenuItems.includes(menuItemId) ||
    !enabledMenuItems.includes(menuItemId);
  const isPopoverTargetDisabled =
    _.size(enabledMenuItems) === 0 || isLoading.value;

  const onClickFactory = useCallback(
    (emailTemplateId) => async () => {
      if (isPopoverTargetDisabled) {
        return;
      }
      isLoading.setTrue();
      await handleFetchMailtoLink({
        entityType: "enrollment",
        entityId: enrollmentIndex,
        emailTemplateId,
      });
      isLoading.setFalse();
    },
    // eslint-disable-next-line
    [
      enrollmentIndex,
      isPopoverTargetDisabled,
      isLoading.setTrue,
      isLoading.setFalse,
    ]
  );

  const MissingInActionIcon = STUDENT_TAGS_INFO.tagIcons.missing_in_action;

  const popoverContentMenu = (
    <Menu large>
      <MenuItem
        intent={Intent.WARNING}
        icon={<MissingInActionIcon />}
        text={STUDENT_TAGS_INFO.tagDisplayNames.missing_in_action}
        onClick={onClickFactory("tag__missing_in_action")}
        shouldDismissPopover={false}
        disabled={isMenuItemDisabled("tag__missing_in_action")}
      />
    </Menu>
  );

  return (
    <span>
      <StyledPopoverWrapper
        canEscapeKeyClose
        content={popoverContentMenu}
        position={Position.RIGHT_BOTTOM}
        usePortal={false}
        hoverCloseDelay={0}
        isOpen={isLoading.value ? false : null}
      >
        <Tooltip
          content={"Compose email from template"}
          boundary="viewport"
          hoverOpenDelay={1500}
          hoverCloseDelay={0}
          isOpen={isLoading.value ? false : null}
        >
          <Tag
            css={`
              font-size: 12px;
              height: 26px;
              & .bp3-icon,
              & .bp3-spinner {
                margin-right: 6px;
              }
            `}
            fill={true}
            minimal={true}
            intent={isPopoverTargetDisabled ? "none" : "primary"}
            large={false}
            icon={
              isLoading.value ? (
                <Spinner size={Spinner.SIZE_SMALL} />
              ) : (
                "envelope"
              )
            }
            interactive={!isPopoverTargetDisabled}
            disabled={isPopoverTargetDisabled}
          />
        </Tooltip>
      </StyledPopoverWrapper>
    </span>
  );
}
