import { Action, action, ActionOn, actionOn } from "easy-peasy";
import { StoreModel } from "./model";
import queryString from "query-string";

export type AdmissionsCoordinatorName = string;

const qpAdmissionsCoordinator = queryString.parse(window.location.search).ac;

const DEFAULT_SELECTED_AC_NAME = qpAdmissionsCoordinator ?? "Audrey Duhaime";

export interface AdmissionsCoordinatorSelectionModel {
  NAME: string;
  selectedAdmissionsCoordinatorName: AdmissionsCoordinatorName;
  selectAdmissionsCoordinatorName: Action<
    AdmissionsCoordinatorSelectionModel,
    AdmissionsCoordinatorName
  >;
  onLogout: ActionOn<AdmissionsCoordinatorSelectionModel, StoreModel>;
}

export const modelAdmissionsCoordinatorSelection: AdmissionsCoordinatorSelectionModel =
  {
    NAME: "admissionsCoordinatorSelection",
    selectedAdmissionsCoordinatorName: DEFAULT_SELECTED_AC_NAME,
    selectAdmissionsCoordinatorName: action(
      (state, newAdmissionsCoordinatorName) => {
        state.selectedAdmissionsCoordinatorName = newAdmissionsCoordinatorName;
      }
    ),
    onLogout: actionOn(
      (__, { me }) => me.resetData,
      (state, target) => {
        state.selectedAdmissionsCoordinatorName = DEFAULT_SELECTED_AC_NAME;
      }
    ),
  };
