import { Computed, computed } from "easy-peasy";
import { StoreModel } from "./model";
import { TableModel, tableModelFactory, TableRow } from "./table-model-factory";
import { timeit } from "../helpers/timing";
import _ from "lodash";
import {
  happenedToday,
  happenedWithinPastTwoWeeks,
} from "./ecp/model-ecp-customer-interactions";
import { timestampVF } from "../components/AgTable/gridOptions";

export type RecentEnrollmentCompletionStudentsModel = TableModel & {
  rowData: Computed<
    RecentEnrollmentCompletionStudentsModel,
    TableRow[],
    StoreModel
  >;
};

export function getRecentEnrollmentCompletionStudentsModel(): RecentEnrollmentCompletionStudentsModel {
  return {
    ...tableModelFactory(
      "recentEnrollmentCompletionStudents",
      "recent_enrollment_completions",
      (row) => row.StudentIndex || row.customer_id,
      [
        {
          name: "nextCourseRecos",
          arity: "OneToOne",
          foreignName: "nextCourseRecos",
          foreignField: "StudentIndex",
          field: "customer_id",
        },
      ]
    ),
    rowData: computed(
      [
        (state) => state.initialData,
        (state) => state.relatedIdsMap,
        (state) => state.relatedRowsMap,
      ],
      timeit((rowData, relatedIdsMap, relatedRowsMap) => {
        if (!_.size(rowData)) {
          return [];
        }
        return rowData.map((row) => {
          const next_course_recos =
            relatedRowsMap[row.id]?.nextCourseRecos?.next_course_recos ?? [];
          const hasNextCourseRecos = next_course_recos.length > 0;

          return {
            ...row,
            hasNextCourseRecos,
          };
        });
      }, "store.recentEnrollmentCompletionStudents.rowData")
    ),
  };
}
