import React, { useCallback } from "react";
import { Button, Menu, MenuItem, Position, Tooltip } from "@blueprintjs/core";
import { useStoreActions, useStoreState } from "../../hooks/ep";
import _ from "lodash";
import { CopyablePhoneTag } from "./phone-action-cell-renderer";
import { ReeStudentTableRow } from "../../store/ree/model-ree-student";
import { StyledPopoverWrapper } from "../../popover-wrapper";
import { TableRow } from "../../store/table-model-factory";

export function ActionButtonCellRenderer({ data, context, crp }) {
  const popoverContentMenu = useMenu(data, crp.includedMenuItems);

  return (
    <span>
      <StyledPopoverWrapper
        canEscapeKeyClose
        content={popoverContentMenu}
        position={Position.RIGHT_BOTTOM}
      >
        <Tooltip content="Actions" placement="topLeft" hoverOpenDelay={1500}>
          <Button
            minimal
            small
            icon="take-action"
            large={false}
            intent="primary"
          />
        </Tooltip>
      </StyledPopoverWrapper>
    </span>
  );
}

function composeMailtoLink(email, toName, fromName) {
  const et = {
    subject: "Hello from Laurel Springs Schools",
    body: `
Hi ${toName}, 

I hope that you are doing well. Do you have time to connect any time next week?

Best,
${fromName}
`.trim(),
  };
  const subject = encodeURIComponent(et.subject);
  const body = encodeURIComponent(et.body);
  return `mailto:${email}?subject=${subject}&body=${body}`;
}

function useSignedInUserName() {
  return useStoreState(
    (s) => s.me.initialData.nickname || s.me.initialData.first_name
  );
}

// eslint-disable-next-line no-unused-vars
function getPhoneMenuItem(data) {
  const phoneNumber = data["formatted_contact_phone_number"];

  let phoneMenuItem;
  if (!phoneNumber) {
    phoneMenuItem = null;
  } else {
    phoneMenuItem = (
      <MenuItem
        icon={null}
        text={<CopyablePhoneTag phoneNumber={phoneNumber} />}
        shouldDismissPopover={false}
      />
    );
  }
  return phoneMenuItem;
}

// eslint-disable-next-line no-unused-vars
function useStarMenuItem(data) {
  const customerId = data["customer_id"];
  const customerName = data["customer_name"];

  const starredCustomerIds = useStoreState((a) => a.stars.starredCustomerIds);
  // noinspection JSUnresolvedFunction
  const starred = starredCustomerIds.has(customerId);
  const starCustomer = useStoreActions((a) => a.stars.starCustomer);
  const unstarCustomer = useStoreActions((a) => a.stars.unstarCustomer);
  const onClickStarsAction = useCallback(
    async (ev) => {
      const storeAction = starred ? unstarCustomer : starCustomer;
      await storeAction({ customerId, customerName });
    },
    [starred, starCustomer, unstarCustomer, customerId, customerName]
  );
  return (
    <MenuItem
      icon="star"
      text={starred ? "Unstar" : "Star"}
      onClick={onClickStarsAction}
      shouldDismissPopover={true}
    />
  );
}

function useEmailMenuItem(data) {
  const emailAddress = _.toLower(data["PrimaryContactEmail"] || "");
  const firstName = data["formatted_contact_full_name"].split(" ")[0];
  const signedInUserName = useSignedInUserName();
  const onClickEmailCustomerContactAction = useCallback(() => {
    window.open(composeMailtoLink(emailAddress, firstName, signedInUserName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAddress, firstName]);
  return !emailAddress ? null : (
    <MenuItem
      icon="envelope"
      text="Email Contact"
      onClick={onClickEmailCustomerContactAction}
      shouldDismissPopover={true}
    />
  );
}

export function useReportCustomerInteraction(
  customerData: ReeStudentTableRow,
  actionName: string
) {
  const openDrawer = useStoreActions((a) => a.misc.ra_openModal);
  return useCallback(() => {
    const fullAction = { actionName, snapshot: customerData };
    openDrawer(fullAction);
  }, [customerData, openDrawer]);
}

export function useReportCustomerLeadInteraction(
  customerData: TableRow,
  actionName: string
) {
  const openDrawer = useStoreActions((a) => a.misc.ra_openModal);
  return useCallback(() => {
    const fullAction = { actionName, snapshot: customerData };
    openDrawer(fullAction);
  }, [customerData, openDrawer]);
}

function useReportCustomerInteractionMenuItem(data, actionName: string) {
  const reportCustomerInteraction = useReportCustomerInteraction(
    data,
    actionName
  );
  return (
    <MenuItem
      icon="chat"
      text={"Report Interaction"}
      onClick={reportCustomerInteraction}
      shouldDismissPopover={true}
    />
  );
}
function useReportCustomerLeadInteractionMenuItem(data, actionName: string) {
  const reportCustomerInteraction = useReportCustomerLeadInteraction(
    data,
    actionName
  );
  return (
    <MenuItem
      icon="chat"
      text={"Report Interaction"}
      onClick={reportCustomerInteraction}
      shouldDismissPopover={true}
    />
  );
}

function useMenu(data, includedMenuItems) {
  // let phoneMenuItem = getPhoneMenuItem(data);
  // const emailMenuItem = useEmailMenuItem(data);
  const reportReeCustomerInteractionMenuItem =
    useReportCustomerInteractionMenuItem(data, "ree_customer_interaction");
  const reportEcpCustomerInteractionMenuItem =
    useReportCustomerInteractionMenuItem(data, "ecp_customer_interaction");
  // const starMenuItem = useStarMenuItem(data);
  const reportLeadCustomerInteractionMenuItem =
    useReportCustomerLeadInteractionMenuItem(data, "lead_customer_interaction");
  return (
    <Menu large>
      {/*{phoneMenuItem}*/}
      {/*{emailMenuItem}*/}
      {includedMenuItems.includes("reportReeCustomerInteractionMenuItem") &&
        reportReeCustomerInteractionMenuItem}
      {includedMenuItems.includes("reportEcpCustomerInteractionMenuItem") &&
        reportEcpCustomerInteractionMenuItem}
      {includedMenuItems.includes("reportLeadCustomerInteractionMenuItem") &&
        reportLeadCustomerInteractionMenuItem}
      {/*{starMenuItem}*/}
    </Menu>
  );
}
