import { shortenYear, useSelectedLoyaltyYear } from "./loyalty-year-helpers";

function getLoyaltyStudentsTitle(loyalty_year: string) {
  return loyalty_year && `Loyalty${shortenYear(loyalty_year)} Students`;
}

function getLoyaltyStatisticsTitle(loyalty_year: string) {
  return loyalty_year && `Loyalty${shortenYear(loyalty_year)} Statistics`;
}

function getLoyaltyCommunicationsTitle(loyalty_year: string) {
  return loyalty_year && `Loyalty${shortenYear(loyalty_year)} Communications`;
}

///////////
// Hooks //
///////////

export function useLoyaltyStudentsTitle(): string {
  const loyalty_year: string = useSelectedLoyaltyYear();
  return getLoyaltyStudentsTitle(loyalty_year);
}

export function useLoyaltyStatisticsTitle(): string {
  const loyalty_year: string = useSelectedLoyaltyYear();
  return getLoyaltyStatisticsTitle(loyalty_year);
}

export function useLoyaltyCommunicationsTitle(): string {
  const loyalty_year: string = useSelectedLoyaltyYear();
  return getLoyaltyCommunicationsTitle(loyalty_year);
}
