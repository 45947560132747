import { Computed, computed } from "easy-peasy";
import { StoreModel } from "./model";
import { TableModel, tableModelFactory, TableRow } from "./table-model-factory";
import { timeit } from "../helpers/timing";
import _ from "lodash";
import {
  ENROLLMENT_COORDINATOR_INDEX__ALL,
  EnrollmentCoordinator,
} from "./model-ec-selection";

export type RetentionKpisModel = TableModel & {
  rowData: Computed<RetentionKpisModel, TableRow[], StoreModel>;
  activeEnrollmentCoordinators: Computed<
    RetentionKpisModel,
    EnrollmentCoordinator[],
    StoreModel
  >;
};

function enrollmentCoordinatorsSort(
  a: EnrollmentCoordinator,
  b: EnrollmentCoordinator
) {
  if (b.EC_Index === ENROLLMENT_COORDINATOR_INDEX__ALL) {
    return 999999;
  }
  if (a.EC_Index === ENROLLMENT_COORDINATOR_INDEX__ALL) {
    return -999999;
  }
  return (
    b.n_upcoming_renr_potential_students - a.n_upcoming_renr_potential_students
  );
}

function retentionKPIsSort(a: EnrollmentCoordinator, b: EnrollmentCoordinator) {
  return (
    b.pct_recent_completion_students__not_reenrolled -
    a.pct_recent_completion_students__not_reenrolled
  );
}

export function getRetentionKpisModel(): RetentionKpisModel {
  return {
    ...tableModelFactory(
      "retentionKpis",
      "retention_kpis",
      (row) => row.EC_Index
    ),
    rowData: computed(
      [(state) => state.initialData],
      timeit(
        (rowData) => rowData.sort(retentionKPIsSort),
        "store.retentionKpis.rowData"
      )
    ),
    activeEnrollmentCoordinators: computed(
      [(state) => state.initialData],
      timeit((rowData) => {
        if (!_.size(rowData)) {
          return [];
        }
        return rowData
          .filter((row) => row.SoR === "Overall")
          .filter(
            (row) =>
              row.n_recent_completion_students > 0 ||
              row.n_upcoming_renr_potential_students > 0
          )
          .sort(enrollmentCoordinatorsSort)
          .map(
            (row) =>
              ({
                EC_Index: row.EC_Index,
                EC_FullName: row.EC_FullName,
                n_upcoming_renr_potential_students:
                  row.n_upcoming_renr_potential_students,
              } as EnrollmentCoordinator)
          );
      }, "store.retentionKpis.activeEnrollmentCoordinators")
    ),
  };
}
