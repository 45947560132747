import React, { useCallback } from "react";
import { Spinner, Tag, Tooltip } from "@blueprintjs/core";
import { useClipboard } from "use-clipboard-copy";
import "styled-components/macro";
import { toaster } from "../../toaster";
import { useBoolean } from "../../helpers/useBoolean";
import {
  makeAuthorizedGetRequestToBackend,
  makeUrl,
} from "../../helpers/backendApi";

export function PhoneActionButtonCellRenderer({ data, crp }) {
  return (
    <span>
      <TelLinkTag
        phoneNumber={data[crp.phoneField]}
        tooltipText={crp.tooltipText}
      />
      {/*<CopyablePhoneTag phoneNumber={data["formatted_contact_phone_number"]} />*/}
    </span>
  );
}

export function formatPhoneNumber(s) {
  if (!s) {
    return "-Unknown-";
  }
  const sz = s.length;
  const last4 = s.slice(sz - 4, sz);
  const first3 = s.slice(sz - 7, sz - 4);
  const areaCode = s.slice(sz - 10, sz - 7);
  const countryCode = sz > 10 ? s.slice(0, sz - 10) : "1";
  const f_countryCode = countryCode ? `+${countryCode} ` : "";
  const prefix = f_countryCode + (areaCode && `(${areaCode})`) + " ";
  return `${prefix}${first3}-${last4}`;
}

export function CopyablePhoneTag({ phoneNumber }) {
  const isMissing = phoneNumber === "-Unknown-";
  const clipboard = useClipboard({
    copiedTimeout: 500,
    onSuccess: () => toaster.success(`Copied "${phoneNumber}" to clipboard`, 1),
  });
  const onClick = () => isMissing || clipboard.copy(phoneNumber);
  return (
    <Tag
      css={`
        font-size: 12px;
        height: 26px;
        & .bp3-icon {
          margin-right: 6px;
        }

        /*
        font-size: 14px;
        height: 30px;
        padding: 3px 7px;
        & .bp3-icon {
          margin-right: 6px;
        }
        */
      `}
      fill={true}
      minimal={true}
      intent={isMissing ? "none" : "primary"}
      large={false}
      icon="phone"
      interactive={!isMissing}
      onClick={onClick}
      disabled={isMissing}
    />
  );
}
// >
//   {clipboard.copied ? "Copied!" : phoneNumber}
// </Tag>

export function TelLinkTag({ phoneNumber, tooltipText }) {
  const isMissing = !phoneNumber || phoneNumber === "-Unknown-";
  const isDisabled = isMissing;

  const onClick = useCallback(async () => {
    if (isDisabled) {
      return;
    }
    window.open(`tel:${phoneNumber}`);
  }, [isDisabled]);

  return (
    <Tooltip
      content={tooltipText || "Call"}
      boundary="viewport"
      hoverOpenDelay={100}
    >
      <Tag
        css={`
          font-size: 12px;
          height: 26px;
          & .bp3-icon,
          & .bp3-spinner {
            margin-right: 6px;
          }
        `}
        fill={true}
        minimal={true}
        intent={isDisabled ? "none" : "primary"}
        large={false}
        icon={"phone"}
        interactive={!isDisabled}
        onClick={onClick}
        disabled={isDisabled}
      />
    </Tooltip>
  );
}
