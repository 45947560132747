import { Spinner } from "@blueprintjs/core";
import React from "react";

export function Unit({ unitId, loading, children }): JSX.Element {
  return (
    <div className={`cmax-unit ${unitId} cmax-${unitId}-unit`} key={unitId}>
      {loading ? <Spinner /> : children}
    </div>
  );
}
