import React from "react";
import { Title } from "./title";
import { Body } from "./body";
import { Container } from "./container";

export function RecommendationsUnitContent(): JSX.Element {
  return (
    <Container>
      <Title />
      <Body />
    </Container>
  );
}
