import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DateRange } from "@blueprintjs/datetime";
import { OnCellClicked } from "../AgTable/types";
import { CellClickedEvent } from "@ag-grid-community/core/dist/es6/events";
import "styled-components/macro";
import versionedLocalStorageKey from "../../helpers/versionedLocalStorageKey";
import { lighten } from "polished";
import {
  AdmissionsCoordinatorTable,
  SPECIAL_AC_NAMES,
} from "../../entities/admissionsCoordinator-entity";
import { LeadTable } from "../../entities/lead-entity";
import { isDateValid } from "@blueprintjs/datetime/lib/esnext/common/dateUtils";
export const DEFAULT_AC_NAME = "";
type AdmissionsCoordinatorName = string;

export function AdmissionsCoordinatorsLeadsSplitview(props) {
  return <AdmissionsCoordinatorsLeadsSplitviewInner {...props} />;
}

function get_selected_admissionsCoordinator_lsk(viewId) {
  return versionedLocalStorageKey(
    `${viewId}--admissionsCoordinator-leads-splitview-page--selectedAdmissionsCoordinatorName`
  );
}
function isDateRangeValid(dateRange) {
  return (
    isDateValid(dateRange[0]) &&
    isDateValid(dateRange[1]) &&
    dateRange[0] <= dateRange[1] &&
    dateRange[1] <= new Date()
  );
}
function AdmissionsCoordinatorsLeadsSplitviewInner({ viewId }): JSX.Element {
  viewId = viewId || "";
  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const initialDateRange: DateRange = [thirtyDaysAgo, today];
  const [selectedDateRange, setSelectedDateRange] =
    useState<DateRange>(initialDateRange);
  const selected_admissionsCoordinator_lsk =
    get_selected_admissionsCoordinator_lsk(viewId);
  const [
    selectedAdmissionsCoordinatorName,
    setSelectedAdmissionsCoordinatorName,
  ] = useState<AdmissionsCoordinatorName>(() => {
    const stored = localStorage.getItem(selected_admissionsCoordinator_lsk);
    return stored || DEFAULT_AC_NAME;
  });

  const onDateRangeSelected = (dateRange: DateRange) => {
    if (dateRange !== selectedDateRange && isDateRangeValid(dateRange)) {
      setSelectedAdmissionsCoordinatorName(DEFAULT_AC_NAME);
      setSelectedDateRange(dateRange);
    }
  };

  useEffect(() => {
    localStorage.setItem(
      selected_admissionsCoordinator_lsk,
      selectedAdmissionsCoordinatorName
    );
  }, [selectedAdmissionsCoordinatorName, selected_admissionsCoordinator_lsk]);

  const onCellClicked_UpperSplit = (ev: CellClickedEvent) => {
    const admissionsCoordinatorRow = ev.data;
    const admissionsCoordinatorName = admissionsCoordinatorRow.AC_FullName;
    if (admissionsCoordinatorName !== SPECIAL_AC_NAMES.Total) {
      setSelectedAdmissionsCoordinatorName((v) => {
        return admissionsCoordinatorName === v
          ? DEFAULT_AC_NAME
          : admissionsCoordinatorName;
      });
    }
  };

  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}
    >
      <UpperSplit
        onCellClicked={onCellClicked_UpperSplit}
        selectedAdmissionsCoordinatorName={selectedAdmissionsCoordinatorName}
        selectedDateRange={selectedDateRange}
        onDateRangeSelected={onDateRangeSelected}
      />
      {selectedAdmissionsCoordinatorName === DEFAULT_AC_NAME ? null : (
        <LowerSplit
          selectedAdmissionsCoordinatorName={selectedAdmissionsCoordinatorName}
          selectedDateRange={selectedDateRange}
        />
      )}
    </div>
  );
}

const TABLE_HEIGHT = "calc((100vh - 64px - 40px - 40px) / 2 - 20px - 48px)";

function UpperSplit({
  onCellClicked,
  selectedAdmissionsCoordinatorName,
  selectedDateRange,
  onDateRangeSelected,
}: {
  selectedAdmissionsCoordinatorName: string;
  onCellClicked: OnCellClicked;
  selectedDateRange: DateRange;
  onDateRangeSelected: Dispatch<SetStateAction<DateRange>>;
}): JSX.Element {
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        & .ag-row[row-id="${selectedAdmissionsCoordinatorName}"] {
          background-color: rgba(0, 21, 40, 0.7) !important;
          &.ag-row-hover {
            background-color: ${lighten(
              0.1,
              "rgba(0, 21, 40, 0.7)"
            )} !important;
          }
        }
      `}
    >
      <AdmissionsCoordinatorTable
        key={"ac-coordinator-table-upper"}
        onCellClicked={onCellClicked}
        fullScreenEnabled={
          selectedAdmissionsCoordinatorName === DEFAULT_AC_NAME
        }
        height={
          selectedAdmissionsCoordinatorName === DEFAULT_AC_NAME
            ? null
            : TABLE_HEIGHT
        }
        selectedDateRange={selectedDateRange}
        onDateRangeSelected={onDateRangeSelected}
      />
    </div>
  );
}

function LowerSplit({
  selectedAdmissionsCoordinatorName,
  selectedDateRange,
}: {
  selectedAdmissionsCoordinatorName: string;
  selectedDateRange: DateRange;
}): JSX.Element {
  if (selectedAdmissionsCoordinatorName !== DEFAULT_AC_NAME) {
    return (
      <LeadTable
        selectedAdmissionsCoordinatorName={selectedAdmissionsCoordinatorName}
        fullScreenEnabled={false}
        height={TABLE_HEIGHT}
        tableNameSuffix={`dc-dash__${String(
          selectedAdmissionsCoordinatorName
        ).replace(",", "_")}`}
        selectedDateRange={selectedDateRange}
      />
    );
  } else {
    return null;
  }
}
