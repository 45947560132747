import styled from "styled-components/macro";
import { Intent, ProgressBar, Text, Tag } from "@blueprintjs/core";
import React from "react";
import { isInvalidChurnScore } from "./validate-churn-score";

export function ChuScoPopoverTarget({ churnScore, width }) {
  /*
  This component is the colored bar + percentage you will
  actually see in the ag-grid table cell.
  */
  if (isInvalidChurnScore(churnScore)) {
    return (
      <Tag
        fill={false}
        minimal={true}
        intent={"primary"}
        large={false}
        interactive={true}
      >
        N/A
      </Tag>
    );
  }

  return (
    <ChuScoOuter className="churnscore-cell-target" width={width}>
      <ChuScoInner>
        <ChuScoBar churnScore={churnScore} />
        <ChuScoPercentage churnScore={churnScore} />
      </ChuScoInner>
    </ChuScoOuter>
  );
}

function ChuScoPercentage({ churnScore }) {
  return (
    <Text className="churn-score-percentage">{Math.round(churnScore)}%</Text>
  );
}

function ChuScoBar({ churnScore }) {
  const strokeIntent = useChurnRiskScoreStrokeIntent(churnScore);

  let mr = 8;
  if (churnScore < 10) mr = mr + 8.54;

  return (
    <ProgressBar
      css={`
        margin-right: ${mr}px;
        height: 10px;
        width: 100%;
      `}
      animate={false}
      stripes={false}
      value={churnScore / 100.0}
      intent={strokeIntent}
    />
  );
}

const ChuScoInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ChuScoOuter = styled.div`
  height: 18px;
  line-height: 18px;
  width: ${(props) => props.width ?? "100%"};
`;

function useChurnRiskScoreStrokeIntent(churnScore) {
  churnScore = Math.round(churnScore);
  if (churnScore <= 50) {
    return Intent.SUCCESS;
  } else if (churnScore >= 75) {
    return Intent.DANGER;
  } else {
    return Intent.WARNING;
  }
}
