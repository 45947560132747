import React, { useEffect, useState } from "react";
import { Spinner } from "@blueprintjs/core";
import { OnCellClicked } from "../AgTable/types";
import { CellClickedEvent } from "@ag-grid-community/core/dist/es6/events";
import "styled-components/macro";
import { useEnsureData } from "../../helpers/use-ensure-data";
import _ from "lodash";
import versionedLocalStorageKey from "../../helpers/versionedLocalStorageKey";
import { lighten } from "polished";
import { EcpTeacherTable } from "../../entities/ecp/ecp-teacher-entity";
import { TeacherRetentionKpisTable } from "../../pages/teacher-retention-kpis-table";

export const DEFAULT_TEACHER_ID = -1;

export function EcpTeacherRetentionRateStudentsSplitview(props) {
  const loading = _.max([
    useEnsureData("ecp_teacher"),
    useEnsureData("ecp_teacher_teacherCourse"),
    useEnsureData("ecp_teacher_enrollment"),
    useEnsureData("teacher_attr"),
    useEnsureData("teacherRetentionKpis"),
  ]);

  return loading ? (
    <Spinner />
  ) : (
    <EcpTeacherRetentionRateStudentsSplitviewInner {...props} />
  );
}

function EcpTeacherRetentionRateStudentsSplitviewInner(props): JSX.Element {
  const selected_teacher_lsk = get_selected_teacher_lsk();
  const [selectedTeacherId, setSelectedTeacherId] = useState<number>(() => {
    const stored = localStorage.getItem(selected_teacher_lsk);
    return +(stored ?? DEFAULT_TEACHER_ID);
  });

  useEffect(() => {
    localStorage.setItem(selected_teacher_lsk, selectedTeacherId.toString());
  }, [selectedTeacherId, selected_teacher_lsk]);

  const onCellClicked_UpperSplit = (ev: CellClickedEvent) => {
    const teacherRow = ev.data;
    const colDef = ev.colDef;
    const teacherId = teacherRow.id;

    if (!colDef.cellRenderer) {
      setSelectedTeacherId((v) =>
        teacherId === v ? DEFAULT_TEACHER_ID : teacherId
      );
    }
  };

  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}
    >
      <UpperSplit
        onCellClicked={onCellClicked_UpperSplit}
        selectedTeacherId={selectedTeacherId}
      />
      {selectedTeacherId === DEFAULT_TEACHER_ID ? null : (
        <LowerSplit selectedTeacherId={selectedTeacherId} />
      )}
    </div>
  );
}

const TABLE_HEIGHT = "calc((100vh - 64px - 40px - 40px) / 2 - 20px - 48px)";

function UpperSplit({
  onCellClicked,
  selectedTeacherId,
}: {
  selectedTeacherId: number;
  onCellClicked: OnCellClicked;
}): JSX.Element {
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        & .ag-row[row-id="${selectedTeacherId}"] {
          background-color: rgba(0, 21, 40, 0.7) !important;
          &.ag-row-hover {
            background-color: ${lighten(
              0.1,
              "rgba(0, 21, 40, 0.7)"
            )} !important;
          }
        }
      `}
    >
      <EcpTeacherTable
        onCellClicked={onCellClicked}
        fullScreenEnabled={selectedTeacherId === DEFAULT_TEACHER_ID}
        height={selectedTeacherId === DEFAULT_TEACHER_ID ? null : TABLE_HEIGHT}
      />
    </div>
  );
}

function LowerSplit({
  selectedTeacherId,
}: {
  selectedTeacherId: number;
}): JSX.Element {
  if (selectedTeacherId !== DEFAULT_TEACHER_ID) {
    return (
      <TeacherRetentionKpisTable
        teacherId={selectedTeacherId}
        fullScreenEnabled={false}
        height={TABLE_HEIGHT}
        tableNameSuffix={selectedTeacherId}
        tableName={"teacher_retention_rate_students"}
        ribbonTitle={
          "Students Enrolled 1 to 2 Years Ago (Excluding 12th Grade)"
        }
      />
    );
  } else {
    return null;
  }
}

function get_selected_teacher_lsk() {
  return versionedLocalStorageKey(
    "ecp-teacher-retention-rate-students-splitview-page--selectedTeacherId"
  );
}
