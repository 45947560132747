import { TableModel, tableModelFactory, TableRow } from "./table-model-factory";
import { Actions, Computed, computed, Thunk, thunk } from "easy-peasy";
import {
  ActionPayload_UpdateStudentAttr,
  ALL_STUDENT_ATTR_CODENAMES,
  submitUserAction__update_student_attr,
} from "../user-actions/submit-user-action__update_student_attr";
import { toaster } from "../toaster";
import { StoreModel } from "./model";
import { Injections } from "./store-injections";

export type StudentAttrModel = TableModel & {
  handleUpdateStudentAttr: Thunk<
    StudentAttrModel,
    ActionPayload_UpdateStudentAttr,
    Injections,
    StoreModel
  >;
  valueByAttrByStudent: Computed<
    StudentAttrModel,
    { [attr_codename: string]: { [student_id: number]: string } },
    StoreModel
  >;
  valuesMapByStudent: Computed<
    StudentAttrModel,
    { [student_id: number]: { [attr_codename: string]: string } },
    StoreModel
  >;
};

export function getStudentAttrModel(): StudentAttrModel {
  return {
    ...tableModelFactory(
      "student_attr",
      "student_attrs",
      (row) => `${row.student_id}|${row.attr_codename}`
    ),
    handleUpdateStudentAttr: thunk(
      async (
        actions: Actions<StudentAttrModel>,
        userActionPayload: ActionPayload_UpdateStudentAttr,
        { getState }
      ) => {
        const {
          student_id,
          attr_codename,
          attr_oldvalue,
          attr_newvalue,
          student_snapshot,
        } = userActionPayload;
        const state = getState();
        // @ts-ignore
        const rowId = state.GET_ROW_ID(userActionPayload);
        const oldRow = state.getRow(rowId);
        actions.upsertRow({
          id: rowId,
          student_id,
          attr_codename,
          attr_value: attr_newvalue,
        });
        const meat = `attr "${attr_codename}" from ${attr_oldvalue} --> ${attr_newvalue} for student "${student_snapshot.customer_name}"`;
        try {
          await submitUserAction__update_student_attr(userActionPayload);
          toaster.success(`Successfully updated ${meat}`, 2);
        } catch (e) {
          if (oldRow) {
            actions.upsertRow(oldRow);
          } else {
            actions.deleteRow(rowId);
          }
          toaster.warning(`Failed to update ${meat} -- please try again`);
          throw Error();
        }
      }
    ),
    valueByAttrByStudent: computed(
      [(s) => s.initialData],
      (initialData: TableRow[]) =>
        Object.fromEntries(
          ALL_STUDENT_ATTR_CODENAMES.map((attr_codename) => {
            const valueByStudent = Object.fromEntries(
              initialData
                .filter((row) => row.attr_codename === attr_codename)
                .map((row) => {
                  return [row.student_id, row.attr_value];
                })
            );
            return [attr_codename, valueByStudent];
          })
        )
    ),
    valuesMapByStudent: computed(
      [(s) => s.initialData],
      (initialData: TableRow[]) => {
        const ret = {};
        initialData.forEach(({ student_id, attr_codename, attr_value }) => {
          ret[student_id] = {
            ...(ret[student_id] || {}),
            [`student_attr__${attr_codename}`]: attr_value,
          };
        });
        return ret;
      }
    ),
  };
}
