import styled from "styled-components/macro";
import { Colors } from "@blueprintjs/colors";
import { ProgressBar, Tag } from "@blueprintjs/core";
import React from "react";
import _ from "lodash";

export function isInvalidPriorityScore(priorityScore) {
  return (
    _.isNull(priorityScore) ||
    _.isUndefined(priorityScore) ||
    _.isNaN(priorityScore) ||
    priorityScore < 0 ||
    priorityScore > 1
  );
}

export function PriorityScoreCellContent({ priorityScore, width }) {
  if (isInvalidPriorityScore(priorityScore)) {
    return (
      <Tag
        fill={false}
        minimal={true}
        intent={"primary"}
        large={false}
        interactive={true}
      >
        N/A
      </Tag>
    );
  }
  const title = `${(100.0 * priorityScore).toFixed(2)}`;
  return (
    <PriorityScoreOuter
      className="priorityscore-cell-target"
      width={width}
      title={title}
    >
      <PriorityScoreInner>
        <PriorityScoreBar priorityScore={priorityScore} />
      </PriorityScoreInner>
    </PriorityScoreOuter>
  );
}

function PriorityScoreBar({ priorityScore }) {
  const strokeColor = usePriorityScoreStrokeColor(priorityScore);
  return (
    <ProgressBar
      css={`
        height: 10px;
        width: 100%;
        .bp3-progress-meter {
          background-color: ${strokeColor} !important;
        }
      `}
      animate={false}
      stripes={false}
      value={priorityScore}
    />
  );
}

const PriorityScoreInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PriorityScoreOuter = styled.div`
  height: 18px;
  line-height: 18px;
  width: ${(props) => props.width ?? "100%"};
`;

function usePriorityScoreStrokeColor(priorityScore) {
  if (priorityScore >= 0.75) {
    return Colors.GREEN4;
  } else if (priorityScore >= 0.5) {
    return Colors.GREEN3;
  } else if (priorityScore >= 0.25) {
    return Colors.GREEN2;
  } else {
    return Colors.GREEN1;
  }
}
