import React from "react";
import { useLoyaltyStatisticsTitle } from "./titles";
import { Card, Elevation, H4, Spinner } from "@blueprintjs/core";
import "styled-components/macro";
import { LoyaltyStatisticsUnitTable } from "./loyalty-statistics-unit-table";

export function LoyaltyStatisticsUnit() {
  // rowData = rowData.map((row) => ({ ...row, id: row.email_type_code })).sort();

  const title = useLoyaltyStatisticsTitle();

  return !title ? (
    <Spinner />
  ) : (
    <div
      css={`
        //position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
      `}
    >
      <Card
        elevation={Elevation.TWO}
        css={`
          position: relative;
          min-width: 400px;
        `}
      >
        <H4>{title}</H4>
        <LoyaltyStatisticsUnitTable />
      </Card>
    </div>
  );
}
