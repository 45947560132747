import React from "react";
import "styled-components/macro";
import { SimpleTextarea } from "../forms-stuff/simple-textarea";

const HELPER_TEXT =
  "Who attended the call?  Please include names and roles / relationships.";

export function AttendeesInput({
  value,
  onValueChange,
  activeField,
  setActiveField,
}) {
  return (
    <SimpleTextarea
      text={value}
      setText={onValueChange}
      fieldLabel={"Attendees"}
      fieldHelperText={HELPER_TEXT}
      fieldId={"attendees"}
      activeFieldId={activeField}
      setActiveFieldId={setActiveField}
      height={"100px"}
      icon="people"
    />
  );
}
