import { TableRow } from "../../store/table-model-factory";
import { AgColDef } from "../AgTable/types";
import { getFormatter, textVF } from "../../entities/helpers/relation-agg-cr";
import { useEnsureData } from "../../helpers/use-ensure-data";
import React from "react";
import { Card, Elevation, H5 } from "@blueprintjs/core";
import {
  DEFAULT_SELECTED_ENTITY_IDS,
  useSelectedEntityId,
} from "../../store/model-entity-selection";

import { Unit } from "./unit";
import "styled-components/macro";
import _ from "lodash";
import { useSelectedCustomer } from "./selection-unit";
import { timestampVF } from "../AgTable/gridOptions";
import { useStudentFlag_DoNotEmail_ColDef } from "../../entities/ecp/student-coldefs/student-flag-do-not-email";

export function BasicUnit({ unitId }: { unitId: string }): JSX.Element {
  console.log("useSelectedEntityId", useSelectedEntityId("student"));
  const loading = _.max([
    DEFAULT_SELECTED_ENTITY_IDS.student === useSelectedEntityId("student"),
    useEnsureData("ecp_student"),
    // useEnsureData("ecp_enrollment"),
    useEnsureData("stars"),
    useEnsureData("student_flag"),
    useEnsureData("student_attr"),
    useEnsureData("ecp_customerInteraction"),
  ]);
  return (
    <Unit unitId={unitId} loading={loading}>
      <BasicUnitContent />
    </Unit>
  );
}

function BasicUnitContent(): JSX.Element {
  const basicUnitItems = useBasicUnitData();
  return (
    <Card
      elevation={Elevation.TWO}
      css={`
        height: 100%;
        padding: 20px !important;
      `}
    >
      <H5
        css={`
          margin: 0 0 40px 0 !important;
          padding: 0 !important;
        `}
      >
        {"Basic Attributes"}
      </H5>
      <div
        css={`
          //grid-template:
          //  ". ." 1fr
          //  ". ." 1fr
          //  ". ." 1fr
          //  ". ." 1fr / 1fr 1fr;

          //display: grid;
          //grid-template:
          //  ". . . ." 1fr
          //  ". . . ." 1fr / 1fr 1fr 1fr 1fr;
          //grid-auto-flow: column;
          //grid-gap: 40px;

          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-end;
          gap: 20px;

          & .basic-unit-item {
            width: 340px;
          }
        `}
      >
        {basicUnitItems.map((item) => (
          <div
            key={item.headerName}
            className={"basic-unit-item"}
            css={`
              display: flex;
            `}
          >
            {/*<Tag minimal large intent={Intent.NONE}>*/}
            <div
              className={"bp3-text-muted"}
              css={`
                display: inline-block !important;
                margin-right: 4px;
                margin-top: 0;
                margin-bottom: 0 !important;
              `}
            >
              {item.headerName}:
            </div>
            <span>{item.formattedValue}</span>
            {/*</Tag>*/}
          </div>
        ))}
      </div>
    </Card>
  );
}

interface BasicUnitItem {
  headerName: string;
  formattedValue: string;
}

function useBasicUnitData(): BasicUnitItem[] {
  const customer = useSelectedCustomer();
  const colDefs = [
    { headerName: "Name", field: "customer_name", type: "textColumn" },
    { headerName: "Grade Level", field: "GradeLevel", type: "integerColumn" },
    {
      headerName: "SoR",
      field: "SchoolRecord",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    },
    {
      headerName: "Academic Performance Plan (APP)?",
      field: "AcademicPerformancePlan",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    },
    {
      headerName: "Admissions Coordinator",
      field: "AdmissionsCoordinator",
      type: "textColumn",
    },
    {
      headerName: "Student Services",
      field: "StudentServices",
      type: "textColumn",
      valueFormatter: ({ value }) => (!value ? "-None-" : textVF(value)),
    },
    {
      headerName: "Genius Status",
      field: "Status",
      type: "categoryColumn",
    },
    {
      field: "latest_geniusComm_sent_at",
      headerName: "Latest Genius Communication Sent At",
      type: "timestampColumn",
      valueFormatter: ({ value }) =>
        _.isNil(value) ? "N/A" : timestampVF(value),
      sortingOrder: ["asc", "desc", null],
      // sort: "asc",
    },

    {
      ...useStudentFlag_DoNotEmail_ColDef(),
      valueFormatter: ({ value }) =>
        value ? "Yes (will not send)" : "No (will send)",
    },
  ];
  return colDefs.map((cd: AgColDef) => ({
    headerName: cd.headerName,
    formattedValue: getFormattedValue(customer, cd),
  }));
}

function getFormattedValue(data: TableRow, colDef: AgColDef): string {
  const value = colDef.valueGetter
    ? // @ts-ignore
      colDef.valueGetter({ data })
    : data[colDef.field];
  const fp = { value, data, colDef };
  const formattedValue = colDef.valueFormatter
    ? // @ts-ignore
      colDef.valueFormatter(fp)
    : ((fn) => (fn ? fn(fp) : fp.value))(getFormatter(colDef.type as string));
  return formattedValue;
}
