import { StyledFormGroup } from "./styled-form-group";
import { InputGroup } from "@blueprintjs/core";
import React, { useCallback } from "react";
import "styled-components/macro";

export function TextInput({
  value,
  onValueChange,
  activeField,
  setActiveField,
  helperText,
  name,
  label,
  leftIcon,
  isVisible = true,
}) {
  const onChange = useCallback(
    (ev) => {
      ev.preventDefault();
      onValueChange(ev.target.value);
    },
    [onValueChange]
  );

  const onFocus = useCallback(
    () => setActiveField(name),
    [setActiveField, name]
  );

  return !isVisible ? null : (
    <StyledFormGroup
      helperText={helperText}
      label={label}
      labelFor={name}
      activeField={activeField}
    >
      <InputGroup
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        leftIcon={leftIcon}
        type="text"
        large
      />
    </StyledFormGroup>
  );
}
