import React from "react";
import { Spinner } from "@blueprintjs/core";
import AgTable from "../components/AgTable";
import { AgColDef, AgColDefs } from "../components/AgTable/types";
import { useMyRole } from "./helpers/authorization";
import { TableRow } from "../store/table-model-factory";
import { useEndpoint } from "../helpers/use-endpoint";
import { DEFAULT_AC_NAME } from "../components/splitviews/ac-leads-splitview";

export function LeadTable({
  selectedAdmissionsCoordinatorName,
  fullScreenEnabled = true,
  selectedDateRange,
  ...restProps
}) {
  const queryParams = {
    startDate: selectedDateRange[0]
      ? selectedDateRange[0].toISOString().split("T")[0]
      : null,
    endDate: selectedDateRange[1]
      ? selectedDateRange[1].toISOString().split("T")[0]
      : null,
    acName:
      selectedAdmissionsCoordinatorName !== DEFAULT_AC_NAME
        ? selectedAdmissionsCoordinatorName
        : null,
  };
  let { loading, data } = useEndpoint("leads", true, queryParams);
  const finalizeRowData = (rowData) => {
    return rowData.map((row) => ({ id: row.ns_customer_id, ...row }));
  };
  return loading || !data ? (
    <Spinner />
  ) : (
    <LeadTableInner
      fullScreenEnabled={fullScreenEnabled}
      rowData={finalizeRowData(data)}
      {...restProps}
    />
  );
}

export function filterToLeadsForAdmissionsCoordinator(
  rowData: TableRow[],
  admissionsCoordinatorName
) {
  return rowData.filter((row) => row.AC_FullName === admissionsCoordinatorName);
}

export function LeadTableInner({
  rowData,
  fullScreenEnabled,
  finalizeColDefs = null,
  finalizeRowData = null,
  ...props
}) {
  const colDefs = useColumnDefs();

  return (
    <AgTable
      rowData={finalizeRowData ? finalizeRowData(rowData) : rowData}
      columnDefs={finalizeColDefs ? finalizeColDefs(colDefs) : colDefs}
      tableName={"leads"}
      ribbonTitle={"Leads"}
      {...props}
    />
  );
}

export function useColumnDefs(): AgColDefs {
  const myRole = useMyRole();
  let ret = [
    {
      headerName: "Customer ID",
      field: "ns_customer_id",
      type: "integerIdentifierColumn",
      suppressSizeToFit: true,
      tooltipField: "ns_customer_id",
      pinned: "left",
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer Parent ID",
      field: "ns_customer_parent",
      type: "integerIdentifierColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer Name",
      field: "ns_customer_full_name",
      type: "textColumn",
      pinned: "left",
      suppressSizeToFit: true,
      width: 180,
    },
    {
      headerName: "Customer Email",
      field: "ns_customer_email",
      type: "textColumn",
      suppressSizeToFit: true,
    },
    {
      headerName: "Created",
      field: "ns_customer_created_at",
      type: "timestampColumn",
      suppressSizeToFit: true,
    },
    {
      headerName: "Admissions Coordinator",
      field: "AC_FullName",
      type: "textColumn",
      suppressSizeToFit: true,
    },
    {
      headerName: "Stage",
      field: "ns_customer_stage",
      type: "categoryColumn",
      suppressSizeToFit: true,
    },
    {
      headerName: "Status",
      field: "ns_customer_status__mapped",
      type: "categoryColumn",
      suppressSizeToFit: true,
      width: 140,
    },
    {
      headerName: "Revenue",
      field: "life_time_value",
      headerTooltip: "Revenue obtained from a lead in the 'Customer' stage",
      type: "moneyColumn",
      suppressSizeToFit: true,
    },
    {
      headerName: "SoR",
      field: "SchoolRecord",
      type: "booleanColumn",
      headerTooltip:
        "SoR status of the student enrolled from a lead in the 'Customer' stage",
      __renderNansAsEmpty: true,
      suppressSizeToFit: true,
      width: 100,
    },
    {
      headerName: "Academy Student",
      field: "AcademyStudent",
      type: "booleanColumn",
      headerTooltip:
        "Academy status of the student enrolled from a lead in the 'Customer' stage",
      __renderNansAsEmpty: true,
      suppressSizeToFit: true,
      width: 140,
    },
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
  });
}
