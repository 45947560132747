import { AgColDef } from "../../../components/AgTable/types";
import { useCanDoLevelWrites, useMyEmail } from "../../helpers/authorization";
import { useStoreActions } from "../../../hooks/ep";
import { parseBoolean } from "../../../helpers/parse-boolean";
import { toaster } from "../../../toaster";

export const EMAILS_OF_USERS_ALLOWED_TO_EDIT_DO_NOT_SEND_MIA_EMAILS_STUDENT_FLAG =
  [
    "ltillman@laurelsprings.com",
    "skirby@laurelsprings.com",
    "mpalevich@laurelsprings.com",
    "dcoraluzzi@laurelsprings.com",
    "atormala@laurelsprings.com",
    "hlee@laurelsprings.com",
    "kcox@laurelsprings.com",
    "jferris@laurelsprings.com",
    // "dparadiso@laurelsprings.com",
    "jdrucis@laurelsprings.com",
  ];

export function useStudentFlag_DoNotSendMiaEmails_ColDef(): AgColDef {
  const myEmail = useMyEmail();
  const canWriteDoNotSendMiaEmails = useCanDoLevelWrites(2);
  const handleUpdate = useStoreActions(
    (a) => a.student_flag.handleUpdateStudentFlag
  );
  return {
    field: "student_flag__do_not_send_mia_emails",
    headerName: "Do Not Send MIA Emails",
    headerTooltip:
      "If checked (true), then Customax will not send the student MIA " +
      "emails, but will still send the student marketing emails " +
      "(e.g. recommended courses for next semester)",
    type: "booleanColumn",
    width: 166,
    ...(!(
      EMAILS_OF_USERS_ALLOWED_TO_EDIT_DO_NOT_SEND_MIA_EMAILS_STUDENT_FLAG.includes(
        myEmail
      ) || canWriteDoNotSendMiaEmails
    )
      ? {}
      : {
          cellRenderer: "checkBoxCellRenderer",
          cellRendererParams: {
            crp: {
              handler: handleUpdate,
              buildPayload: (data, field, value) => ({
                student_id: data.StudentIndex,
                flag_codename: "do_not_send_mia_emails",
                flag_oldvalue: !value,
                flag_newvalue: value,
                student_snapshot: { ...data },
              }),
            },
          },
          valueParser: (params) => parseBoolean(params.newValue),
          valueSetter: (params) => {
            const isValid =
              typeof params.newValue === "boolean" &&
              params.newValue !== params.oldValue;
            if (isValid) {
              params.data.student_flag__do_not_send_mia_emails =
                params.newValue;
              return true;
            } else {
              toaster.error(
                "Please submit the word 'true' or the word 'false'"
              );
              return false;
            }
          },
        }),
  };
}
