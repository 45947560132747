import { Computed, computed, reducer, Thunk, thunk } from "easy-peasy";
import { fbReducer } from "../helpers/fbase";
import { getFirebase } from "react-redux-firebase";
import { StoreModel } from "./model";
import { Injections } from "./store-injections";
import { toaster } from "../toaster";
// import versionedLocalStorageKey from "../helpers/versionedLocalStorageKey";
//
// const actionCodeSettings = () => ({
//   // URL you want to redirect back to. The domain for this
//   // URL must be whitelisted in the Firebase Console.
//   url: `https://${window.location.host}/magic`,
//   // This must be true.
//   handleCodeInApp: true
// });

export interface EmailPassword {
  email: string;
  password: string;
}
export interface FirebaseModel {
  red: any;
  firebaseState: Computed<FirebaseModel, any, StoreModel>;
  // login: Thunk<FirebaseModel, void, Injections, StoreModel>;
  logout: Thunk<FirebaseModel, void, Injections, StoreModel>;
  loginEmailPassword: Thunk<
    FirebaseModel,
    EmailPassword,
    Injections,
    StoreModel
  >;
  handlePasswordReset: Thunk<FirebaseModel, string, Injections, StoreModel>;
  // maybeProcessMagicLink: any;
  // hasFailedSigninAttempt: boolean;
  // setHasFailedSigninAttempt: Action<FirebaseModel, boolean>;
}

export const firebaseModel: FirebaseModel = {
  red: reducer(fbReducer),
  firebaseState: computed([(state) => state.red], (red) => {
    if (!red) {
      return null;
    }
    const fb = red.firebase;
    if (!fb) {
      return null;
    }
    return fb;
  }),
  logout: thunk(async (actions, payload, { getState }) => {
    await getFirebase().logout();
  }),
  handlePasswordReset: thunk(async (actions, email) => {
    getFirebase().resetPassword(email);
  }),
  // login: thunk(async (actions, { email }, { getState }) => {
  //   const fb = getFirebase();
  //
  //   try {
  //     window.localStorage.setItem(
  //       versionedLocalStorageKey("emailForSignIn"),
  //       email
  //     );
  //     await fb.auth().sendSignInLinkToEmail(email, actionCodeSettings());
  //     toaster.success(`Emailed sign-in link to ${email}`);
  //   } catch (err) {
  //     toaster.error(`Failed to email sign-in link to ${email}`);
  //   }
  // }),
  // hasFailedSigninAttempt: false,
  // // @ts-ignore
  // setHasFailedSigninAttempt: action((s, v) => (s.hasFailedSigninAttempt = v)),

  loginEmailPassword: thunk(
    async (actions, { email, password }, { getState, getStoreState }) => {
      const fb = getFirebase();
      try {
        const result = await fb
          .auth()
          .signInWithEmailAndPassword(email, password);
        toaster.success(`Successfully signed in ${email}`);
        // actions.setHasFailedSigninAttempt(false);
      } catch (err) {
        console.error({ err });
        if (err.code === "auth/user-not-found") {
          toaster.error(
            `Failed to sign in. Error: Unregistered Email. ` +
              `Please email team@saxecap.com to request Minerva access for this email address.`,
            5
          );
        } else if (err.code === "auth/wrong-password") {
          toaster.error(
            `Failed to sign in. Error: Incorrect Password. Please use the password ` +
              `issued to you by an administrator.`,
            5
          );
        } else {
          toaster.error(`Failed to sign in. Error: ${err}`);
        }
        // actions.setHasFailedSigninAttempt(true);
      }
    }
  ),

  // maybeProcessMagicLink: thunk(async () => {
  //   const fb = getFirebase();
  //   const emailLSKey = versionedLocalStorageKey("emailForSignIn");
  //   const email = window.localStorage.getItem(emailLSKey);
  //
  //   const inMagicFlow =
  //     !firebase.auth().currentUser &&
  //     fb.auth().isSignInWithEmailLink(window.location.href) &&
  //     email;
  //   if (!inMagicFlow) {
  //     if (email) toaster.info("Please request a new magic link.");
  //     return;
  //   }
  //
  //   try {
  //     await firebase.auth().signInWithEmailLink(email, window.location.href);
  //     toaster.success("Successfully signed in as " + email);
  //     window.localStorage.removeItem(emailLSKey);
  //     await fb.reloadAuth();
  //   } catch (error) {
  //     toaster.error("Failed to sign in as " + email);
  //     return;
  //   }
  // })
};
