import _ from "lodash";
import { useEnsureData } from "../../helpers/use-ensure-data";
import React from "react";
import AgTable from "../../components/AgTable";
import { AgColDef } from "../../components/AgTable/types";
import { useStoreState } from "../../hooks/ep";
import { Spinner } from "@blueprintjs/core";
import { getCommonMetricsColDefs } from "./common";

const INITIAL_CATEGORY_FILTERS = {
  Status: ["ACTIVE"],
};

export function CourseAssessmentMetricsTable({
  fullScreenEnabled = true,
  ...restProps
}): JSX.Element {
  const loading = _.max([
    useEnsureData("courseAssessmentMetrics"),
    useEnsureData("ecp_enrollment"),
  ]);

  return loading ? (
    <Spinner />
  ) : (
    <CourseAssessmentMetricsContent
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

function CourseAssessmentMetricsContent({ ...restProps }): JSX.Element {
  const rowData = useStoreState((s) => s.courseAssessmentMetrics.rowData);

  const colDefs = useCourseAssessmentMetricsColDefs();

  return (
    <AgTable
      tableName={"course-assessment-metrics"}
      rowData={rowData}
      columnDefs={colDefs}
      ribbonTitle={"Course Assessment Metrics"}
      initialCategoryFilters={INITIAL_CATEGORY_FILTERS}
      {...restProps}
    />
  );
}

function useCourseAssessmentMetricsColDefs(): AgColDef[] {
  const finalGradeHeaderTooltipCommonText =
    "calculated considering all enrollments for the course with 'COMPLETED' status and with a final grade greater than 0 and less than or equal to 100. Final grades marked as 'F', 'Incomplete', and 'Withdrawn' were not included in the calculation";

  return [
    {
      headerName: "Vendor",
      field: "canonical_course_vendor_name",
      type: "categoryColumn",
      pinned: "left",
    },
    {
      headerName: "Course Name",
      field: "Name",
      type: "textColumn",
      width: 228,
      suppressSizeToFit: true,
      tooltipField: "Name",
      pinned: "left",
    },
    {
      headerName: "Course Display Name",
      field: "DisplayName",
      type: "textColumn",
      width: 228,
      suppressSizeToFit: true,
      tooltipField: "DisplayName",
    },
    {
      headerName: "Course-Program Melded Display Name",
      field: "ProgramofStudyMeldedDisplayName",
      type: "textColumn",
      width: 228,
      suppressSizeToFit: true,
      tooltipField: "ProgramofStudyMeldedDisplayName",
    },
    {
      headerName: "Genius Course ID",
      field: "CourseIndex",
      width: 130,
      type: "integerIdentifierColumn",
    },
    {
      headerName: "Course Code",
      field: "CourseCode",
      width: 130,
      type: "textColumn",
    },
    {
      headerName: "Status",
      field: "Status",
      type: "categoryColumn",
    },
    {
      headerName: "Department",
      field: "DepartmentName",
      type: "categoryColumn",
    },
    {
      headerName: "Subject Area",
      field: "subject_area",
      type: "textColumn",
      width: 228,
      suppressSizeToFit: true,
      tooltipField: "subject_area",
    },
    {
      headerName: "Grade Lvl",
      field: "GradeLevel",
      type: "integerColumn",
      filter: "categoryFilterer",
    },
    {
      headerName: "Price",
      field: "course_price",
      type: "moneyColumn",
    },
    ...getCommonMetricsColDefs(true),
    {
      headerName: "Final Grade (Mean)",
      field: "final_grade_mean",
      type: "floatColumn",
      width: 130,
      __formatNanAsEmpty: true,
      headerTooltip: `Mean ${finalGradeHeaderTooltipCommonText}`,
    },
    {
      headerName: "Final Grade (Median)",
      field: "final_grade_median",
      type: "floatColumn",
      width: 130,
      __formatNanAsEmpty: true,
      headerTooltip: `Median ${finalGradeHeaderTooltipCommonText}`,
    },
    {
      headerName: "Final Grade (StdDev)",
      field: "final_grade_std",
      type: "floatColumn",
      width: 130,
      __formatNanAsEmpty: true,
      headerTooltip: `StdDev ${finalGradeHeaderTooltipCommonText}`,
    },
  ];
}
