import { Button, H5, Icon, Intent } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components/macro";

export function ToggleVisibilityButton({
  contentVisibilityStates,
  hideAll,
  showAll,
  buttonText,
  iconPosition = "left",
  hideText = "Hide",
  showText = "Show",
  ...restProps
}: {
  contentVisibilityStates: boolean[];
  hideAll: () => void;
  showAll: () => void;
  buttonText: string;
  iconPosition?: "left" | "right";
  hideText?: string;
  showText?: string;
  width?: number;
}): JSX.Element {
  const allContentVisible = contentVisibilityStates.every((state) => state);

  return (
    <StyledToggleVisibilityContainer>
      <StyledToggleVisibilityButton
        alignText={iconPosition}
        icon={
          iconPosition === "left" ? (
            <ToggleIcon allCardsShown={allContentVisible} />
          ) : undefined
        }
        rightIcon={
          iconPosition === "right" ? (
            <ToggleIcon allCardsShown={allContentVisible} />
          ) : undefined
        }
        large
        minimal
        intent={allContentVisible ? Intent.NONE : Intent.SUCCESS}
        onClick={allContentVisible ? hideAll : showAll}
        width={restProps.width || 320}
        {...restProps}
      >
        <StyledH5>{`${
          allContentVisible ? hideText : showText
        } ${buttonText}`}</StyledH5>
      </StyledToggleVisibilityButton>
    </StyledToggleVisibilityContainer>
  );
}

const StyledH5 = styled(H5)`
  padding-top: 5px;
`;

const StyledToggleVisibilityButton = styled(Button)<{ width: number }>`
  width: ${({ width }) => `${width}px`};
  justify-content: start;
`;

const StyledToggleVisibilityContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

function ToggleIcon({
  allCardsShown,
}: {
  allCardsShown: boolean;
}): JSX.Element {
  return (
    <Icon
      icon={allCardsShown ? "collapse-all" : "expand-all"}
      iconSize={Icon.SIZE_LARGE}
    />
  );
}
