import { StyledFormGroup } from "../forms-stuff/styled-form-group";
import { EmailAddressInput } from "./ecp--email-address-input";
import { NotesInput } from "./notes-input";
import React from "react";
import "styled-components/macro";
import { TooltipLabel } from "../forms-stuff/tooltip-label";
import { InteractionTypeInput } from "./interaction-type-input";
import { PhoneNumberInput } from "./ecp--phone-number-input";
import { RiskReasonsInput } from "./ecp-risk-reasons-input";

export function EcpCustomerInteractionFormContent({
  formState,
  formActions,
  activeField,
  setActiveField,
}) {
  const isEmailItxn = formState.interactionType === "Email";
  const isPhoneItxn = formState.interactionType === "Phone";
  return (
    <>
      <InteractionTypeInput
        value={formState.interactionType}
        onValueChange={formActions.setInteractionType}
        activeField={activeField}
        setActiveField={setActiveField}
      />
      {isEmailItxn && (
        <StyledFormGroup
          label={
            <TooltipLabel
              labelText={"Email Address"}
              tooltipText={EMAIL_ADDRESS_HELPER_TEXT}
            />
          }
          labelFor="emailAddress"
          helperText={EMAIL_ADDRESS_HELPER_TEXT}
          activeField={activeField}
        >
          <EmailAddressInput
            queryText={formState.emailAddress}
            setQueryText={formActions.setEmailAddress}
            selectedItem={formState.emailAddress}
            onSelect={formActions.setEmailAddress}
            onClear={formActions.clearEmailAddress}
            activeField={activeField}
            setActiveField={setActiveField}
            defaultOptions={formState.emailAddressOptions}
          />
        </StyledFormGroup>
      )}
      {isPhoneItxn && (
        <StyledFormGroup
          label={
            <TooltipLabel
              labelText={"Phone Number"}
              tooltipText={PHONE_NUMBER_HELPER_TEXT}
            />
          }
          labelFor="phoneNumber"
          helperText={PHONE_NUMBER_HELPER_TEXT}
          activeField={activeField}
        >
          <PhoneNumberInput
            queryText={formState.phoneNumber}
            setQueryText={formActions.setPhoneNumber}
            selectedItem={formState.phoneNumber}
            onSelect={formActions.setPhoneNumber}
            onClear={formActions.clearPhoneNumber}
            activeField={activeField}
            setActiveField={setActiveField}
            defaultOptions={formState.phoneNumberOptions}
          />
        </StyledFormGroup>
      )}
      {(isEmailItxn || isPhoneItxn) && (
        <StyledFormGroup
          label={
            <TooltipLabel
              labelText={"Risk Reasons"}
              tooltipText={RISK_REASONS_HELPER_TEXT}
            />
          }
          labelFor="riskReasons"
          helperText={RISK_REASONS_HELPER_TEXT}
          activeField={activeField}
        >
          <RiskReasonsInput
            queryText={formState.riskReasonsQueryText}
            setQueryText={formActions.setRiskReasonsQueryText}
            selectedItems={formState.riskReasons}
            onAdd={formActions.pushRiskReason}
            onClear={formActions.clearRiskReasons}
            onRemove={formActions.removeRiskReason}
            activeField={activeField}
            setActiveField={setActiveField}
          />
        </StyledFormGroup>
      )}
      <NotesInput
        value={formState.notes}
        onValueChange={formActions.setNotes}
        activeField={activeField}
        setActiveField={setActiveField}
      />
    </>
  );
}

const EMAIL_ADDRESS_HELPER_TEXT =
  "What was the email address? A dropdown of relevant parent/student email addresses is provided for convenience.";
const PHONE_NUMBER_HELPER_TEXT =
  "What was the phone number? A dropdown of relevant parent/student phone numbers is provided for convenience.";
const RISK_REASONS_HELPER_TEXT =
  "Why is this student at-risk? Please select all relevant options.";
