import {
  DataSnapshot,
  submitUserActionPostRequest,
  UserAction,
} from "./user_actions";
import { assert } from "../helpers/assertions";
import { AxiosResponse } from "axios";

export async function submitUserAction__update_student_flag(
  actionPayload: ActionPayload_UpdateStudentFlag
): Promise<AxiosResponse<UserAction_UpdateStudentFlag>> {
  verifyActionPayload(actionPayload);
  return await submitUserActionPostRequest(
    "update_student_flag",
    actionPayload
  );
}

function verifyActionPayload(
  actionPayload: ActionPayload_UpdateStudentFlag
): void {
  assert(typeof actionPayload.student_id === typeof 12345);
  assert(typeof actionPayload.flag_codename === typeof "hi");
  assert(typeof actionPayload.flag_oldvalue === typeof true);
  assert(typeof actionPayload.flag_newvalue === typeof true);
  assert(
    actionPayload.student_id === actionPayload.student_snapshot.StudentIndex
  );
  assert(ALL_STUDENT_FLAG_CODENAMES.includes(actionPayload.flag_codename));
}

export type StudentFlagCodename =
  | "no_action_required"
  | "do_not_email"
  | "do_not_send_mia_emails"
  | "exclude_from_next_course_recos_cgn";

export const ALL_STUDENT_FLAG_CODENAMES = [
  "no_action_required",
  "do_not_email",
  "do_not_send_mia_emails",
  "exclude_from_next_course_recos_cgn",
];

export interface ActionPayload_UpdateStudentFlag {
  student_id: number;
  flag_codename: StudentFlagCodename;
  flag_oldvalue: boolean;
  flag_newvalue: boolean;
  student_snapshot: DataSnapshot;
}

export interface UserAction_UpdateStudentFlag
  extends UserAction<"update_student_flag", ActionPayload_UpdateStudentFlag> {}

const STUDENT_FLAG_DEFAULTS = {
  student_flag__no_action_required: false,
  student_flag__do_not_email: false,
  student_flag__do_not_send_mia_emails: false,
  student_flag__exclude_from_next_course_recos_cgn: false,
};
export function ensureStudentFlagFieldDefaults(rows) {
  return rows.map((row) => ({ ...row, ...STUDENT_FLAG_DEFAULTS }));
}
