import React from "react";
import { Button, Colors, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import styled from "styled-components/macro";
import _ from "lodash";

const UNASSIGNED_DEFAULT_VALUE = "Unassigned";
const DEFAULT_MESSAGE_CLEAR_SELECTION = "Clear Selection";

export function SelectCellRenderer({ colDef, data, crp }) {
  const handleOnSelect = crp.handler;
  const unassignedDefaultValue =
    crp?.unassignedDefaultValue || UNASSIGNED_DEFAULT_VALUE;
  const allowClearSelection = crp?.allowClearSelection || false;

  const selectedItem =
    data[colDef.field] === unassignedDefaultValue ? null : data[colDef.field];

  let items = _.sortBy(
    _.without(crp.items, selectedItem, unassignedDefaultValue)
  );
  items =
    selectedItem && allowClearSelection
      ? [DEFAULT_MESSAGE_CLEAR_SELECTION, ...items]
      : items;

  return (
    <StyledSelect
      filterable={false}
      fill
      itemRenderer={(v, { handleClick }) => {
        return (
          <MenuItem
            active={false}
            key={v}
            label={""}
            onClick={handleClick}
            text={v}
            icon={v === DEFAULT_MESSAGE_CLEAR_SELECTION ? "delete" : undefined}
            intent={
              v === DEFAULT_MESSAGE_CLEAR_SELECTION ? "warning" : undefined
            }
          />
        );
      }}
      items={_.sortBy(items)}
      onItemSelect={async (item) => {
        if (item === DEFAULT_MESSAGE_CLEAR_SELECTION) {
          await handleOnSelect(crp.buildPayload(data, colDef.field, null));
        } else {
          await handleOnSelect(crp.buildPayload(data, colDef.field, item));
        }
      }}
    >
      <Button
        text={selectedItem || `- ${unassignedDefaultValue} -`}
        rightIcon="caret-down"
        style={{
          color: selectedItem ? Colors.WHITE : Colors.GRAY3,
          width: crp?.width || 120,
        }}
      />
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  & .bp3-button {
    padding: 0 10px;
    justify-content: space-between;
  }
`;
