import { TableModel, tableModelFactory } from "../table-model-factory";

export interface EcpOrderModel extends TableModel {}

export function getEcpOrderModel(): EcpOrderModel {
  return {
    ...tableModelFactory(
      "ecp_order",
      "ecp/base_orders",
      (row) => row.OrderIndex
    ),
  };
}
