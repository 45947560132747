import React from "react";
import { useEndpoint } from "../../helpers/use-endpoint";
import { Spinner } from "@blueprintjs/core";
import { TableRow } from "../../store/table-model-factory";
import {
  ColTableConfig,
  TableDescriptions,
} from "../../components/table-descriptions";
import _ from "lodash";

export function SummaryUnit({
  fullScreenEnabled = true,
  selectedAdmissionsCoordinatorName,
  ...restProps
}): JSX.Element {
  let { loading, data: kpiSummary } = useEndpoint<TableRow[]>(
    "admissions_coordinators_kpis",
    true,
    { acName: selectedAdmissionsCoordinatorName }
  );
  return loading ? (
    <Spinner />
  ) : _.size(kpiSummary) ? (
    <div
      css={`
        width: ${restProps.width ?? "90%"};
        margin: auto;
      `}
    >
      <SummaryUnitInner
        selectedAdmissionsCoordinatorName={selectedAdmissionsCoordinatorName}
        summaryRow={kpiSummary[0]}
      />
    </div>
  ) : null;
}

function SummaryUnitInner({ summaryRow }): JSX.Element {
  console.log("AC's KPIs (past 30 days)", summaryRow);

  return (
    <TableDescriptions
      columnsFormat={useAcKpiSummaryCols()}
      dataSource={[summaryRow]}
    />
  );
}

function useAcKpiSummaryCols(): ColTableConfig[] {
  return [
    {
      label: "Name",
      field: "AC_FullName",
      type: "text",
      fixed: "left",
      width: 95,
      tooltip: "The name of the admissions coordinator",
    },
    {
      label: "Leads",
      field: "Leads",
      type: "integer",
      width: 80,
      tooltip: "Number of leads created in the past 30 days",
    },
    {
      label: "Inquiries",
      field: "CurrentInquiries",
      type: "integer",
      width: 80,
      tooltip:
        "Number of leads created in the past 30 days that are currently in the 'Inquiry' stage",
    },
    {
      label: "Applications",
      field: "CurrentApplications",
      type: "integer",
      width: 80,
      tooltip:
        "Number of leads created in the past 30 days that have reached the 'Application' stage",
    },
    {
      label: "Commitments",
      field: "CurrentCommitments",
      type: "integer",
      width: 80,
      tooltip:
        "Number of leads created in the past 30 days that are currently with an 'Application - Commitment' status",
    },
    {
      label: "Enrollments",
      field: "Enrollments",
      width: 80,
      type: "integer",
      tooltip:
        "Number of leads created in the past 30 days that have reached the 'Customer' stage",
    },
    {
      label: "Lead : Enrollment Rate",
      field: "Enrollments_rate",
      type: "percentage",
      width: 100,
      tooltip:
        "Ratio between the number of leads that reached the 'Customer' stage and the total leads during the past 30 days",
    },
    {
      label: "Reported Interactions",
      field: "LeadInteractions",
      width: 100,
      type: "integer",
      tooltip: "Number of email or phone interactions reported in last 30 days",
    },
  ];
}
