import React from "react";
import {
  Classes,
  Popover,
  PopoverInteractionKind,
  Position,
} from "@blueprintjs/core";
import { ChuScoPopoverContent } from "./popover-content";
import { ChuScoPopoverTarget } from "./popover-target";
import "styled-components/macro";
import _ from "lodash";

export function ChurnScoreCellRenderer({
  value: churnScore,
  data: { id, StudentIndex, EnrollmentIndex, teacherCourse_id, TeacherIndex },
  // colDef,
  crp,
}) {
  // console.log({ colDef, churnScore, id, StudentIndex });
  let sliceName = crp?.sliceName;
  if (sliceName) {
  } else if (teacherCourse_id === id) {
    sliceName = "ecp_teacherCourse";
  } else if (EnrollmentIndex === id) {
    sliceName = "ecp_enrollment";
  } else if (TeacherIndex === id) {
    sliceName = "ecp_teacher";
  } else {
    sliceName = "ecp_student";
  }

  // const width = `${colDef.width}px`;

  const churnScoreIsMissing = _.isNil(churnScore) || _.isNaN(churnScore);

  if (
    sliceName === "ecp_teacherCourse" ||
    sliceName === "ecp_teacher" ||
    churnScoreIsMissing
  ) {
    return (
      <ChuScoPopoverTarget
        churnScore={churnScore}
        // width={width}
      />
    );
  } else {
    return (
      <ChuScoPopover
        churnScore={churnScore}
        id={id}
        sliceName={sliceName}
        // width={width}
      />
    );
  }
}

export function ChuScoPopover({ churnScore, id, sliceName, position, width }) {
  return (
    <Popover
      content={
        <ChuScoPopoverContent
          churnScore={churnScore}
          id={id}
          sliceName={sliceName}
        />
      }
      position={position ?? Position.RIGHT_TOP}
      interactionKind={PopoverInteractionKind.HOVER}
      popoverClassName={`${Classes.ELEVATION_4}`}
      hoverOpenDelay={300}
      fill={true}
      boundary={"viewport"}
      // targetClassName="bp3-fill"
    >
      <ChuScoPopoverTarget churnScore={churnScore} width={width} />
    </Popover>
  );
}
