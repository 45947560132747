import { DEFAULT_SHOULD_FETCH, useEndpoint } from "../../helpers/use-endpoint";
import { TableRow } from "../../store/table-model-factory";
import _ from "lodash";
import { Spinner } from "@blueprintjs/core";
import React from "react";
import AgTable from "../../components/AgTable";

export function IncrementalOrdersTable(): JSX.Element {
  const { loading, data } = useEndpoint(
    "ecp/customer_success_cgns_incremental_orders",
    DEFAULT_SHOULD_FETCH,
    { responseFormat: "arrow" }
  );
  const rowData = data;
  // @ts-ignore
  return loading || !_.size(rowData) ? (
    <Spinner />
  ) : (
    <IncrementalOrdersTableInner rowData={rowData} />
  );
}

function IncrementalOrdersTableInner({ rowData }): JSX.Element {
  const colDefs = [
    { headerName: "Campaign", field: "cgnXY", type: "categoryColumn" },
    {
      headerName: "Start Date",
      field: "StartDate",
      type: "dateColumn",
    },
    { headerName: "Created", field: "CreatedOn", type: "timestampColumn" },
    // {
    //   headerName: "Name",
    //   field: "Name",
    //   type: "textColumn",
    // },
    { headerName: "Order Type", field: "order_type", type: "categoryColumn" },
    {
      headerName: "Grade Level",
      field: "GradeLevel",
      type: "categoryColumn",
      width: 90,
    },
    {
      headerName: "Student Name",
      field: "StudentName",
      type: "textColumn",
    },
    {
      headerName: "Total Order Revenue",
      field: "OrderTotalPrice",
      type: "moneyColumn",
    },
    {
      headerName: "Transaction Date",
      field: "initial_txn_date",
      type: "dateColumn",
    },
    {
      headerName: "Notes",
      field: "Notes",
      type: "textColumn",
      tooltipField: "Notes",
      width: 200,
      suppressSizeToFit: true,
      cellClass: ["ellipses-tooltip-cell"],
    },
    {
      headerName: "Student Genius ID",
      field: "StudentIndex",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "Netsuite Order ID",
      field: "NetSuiteInternalId",
      type: "textColumn",
    },
    {
      headerName: "Netsuite Transaction ID",
      field: "tranId",
      type: "textColumn",
    },
  ];
  return (
    <AgTable
      rowData={rowData.toArray()}
      columnDefs={colDefs}
      tableName={"customer_success_cgns_incremental_orders_table"}
      ribbonTitle={"Incremental Orders"}
      height={"calc(100vh - 64px - 40px - 40px - 46px - 48px)"}
    />
  );
}
