import { AgColDef } from "../../../components/AgTable/types";
import { useStoreActions } from "../../../hooks/ep";
import _ from "lodash";
import { useCanDoLevelWrites } from "../../helpers/authorization";
import { ValueGetterParams } from "@ag-grid-community/core/dist/cjs/entities/colDef";

enum TeacherContactType {
  EMPLOYEE = "Employee",
  INDEPENDENT_CONTRACTOR = "Independent Contractor",
  Unassigned = "Unassigned",
}

const ALL_TEACHER_CONTRACT_TYPES = Object.values(TeacherContactType);

export function useTeacherAttr_ContractType_ColDef(): AgColDef {
  const canWriteTeacherAttr = useCanDoLevelWrites(2);
  const handleUpdate = useStoreActions(
    (a) => a.teacher_attr.handleUpdateTeacherAttr
  );
  return {
    field: "teacher_attr__teacher_contract_type",
    headerName: "Contract Type",
    type: "categoryColumn",
    width: 220,
    headerTooltip:
      "This column is designated for identifying the teacher's contract type and does not impact their data.",
    ...(canWriteTeacherAttr
      ? {
          cellRenderer: "selectCellRenderer",
          cellRendererParams: {
            crp: {
              handler: handleUpdate,
              buildPayload: (data, field, value) => ({
                teacher_id: data.TeacherIndex,
                attr_codename: "teacher_contract_type",
                attr_oldvalue: data.teacher_attr__teacher_contract_type,
                attr_newvalue: value,
                teacher_snapshot: {
                  TeacherEmail: data.TeacherEmail,
                  TeacherName: data.TeacherName,
                  TeacherIndex: data.TeacherIndex,
                },
              }),
              items: ALL_TEACHER_CONTRACT_TYPES,
              unassignedDefaultValue: TeacherContactType.Unassigned,
              allowClearSelection: true,
              width: 200,
            },
          },
          valueGetter: (params: ValueGetterParams): string => {
            const value = params.data[params.colDef.field];
            return _.isNil(value) || value === TeacherContactType.Unassigned
              ? "- Unassigned -"
              : value;
          },
        }
      : {}),
  };
}
