import React from "react";
import { Icon as BP3Icon } from "@blueprintjs/core/lib/esm/components/icon/icon";
import { IconName } from "@blueprintjs/icons";
import styled from "styled-components/macro";

export function BP3IconWithLegend({
  icon,
  legend,
  ...props
}: {
  icon: IconName;
  legend: string;
}) {
  const formattedLegend = legend.toUpperCase().substr(0, 2);
  return (
    <IconContainer className={"bp3-icon"} {...props}>
      <BP3Icon icon={icon} iconSize={12} {...props} />
      <IconLegend className={"bp3-icon"} {...props}>
        {formattedLegend}
      </IconLegend>
    </IconContainer>
  );
}

const IconContainer = styled.div``;

const IconLegend = styled.span`
  position: absolute;
  bottom: 4px;
  right: 12px;
  width: 16px;
  font-size: 8px;
  font-weight: bold;
`;
