import { Recommendation } from "../types";
import React from "react";
import { NextCourseReco } from "../next-course-reco";

export function reco2elem(reco: Recommendation, ix?: number): JSX.Element {
  return <NextCourseReco reco={reco} key={ix ?? reco2key(reco)} />;
}

export function reco2key(reco: Recommendation): string {
  return [
    reco.EnrollmentIndex.toString(),
    reco.DepartmentName,
    reco.source,
    reco.target,
  ].join("|");
}
