import React from "react";
import { Spinner } from "@blueprintjs/core";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { useStoreState } from "../../hooks/ep";
import { UserAction } from "../../user-actions/user_actions";
import AgTable from "../../components/AgTable";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import { getStudentGeniusUrl, getTeacherGeniusUrl } from "./ecp-student-entity";
import { getEnrollmentBuzzUrl } from "./ecp-enrollment-entity";

export function EcpSourceDataIssuesTable({
  fullScreenEnabled = true,
  ...restProps
}) {
  const loading = useEnsureData("ecp_sourceDataIssue");
  return loading ? (
    <Spinner />
  ) : (
    <EcpSourceDataIssuesTableInner
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

function EcpSourceDataIssuesTableInner(props) {
  let rowData = useStoreState(
    (s) => s.ecp_sourceDataIssue.initialData
  ) as UserAction[];

  const colDefs = useColDefs();

  return (
    <AgTable
      rowData={rowData}
      columnDefs={colDefs}
      tableName="ecp_sourceDataIssues"
      ribbonTitle={"Source Data Issues"}
      {...props}
    />
  );
}

function useColDefs(): AgColDefs {
  const ret = [
    {
      headerName: "Code",
      field: "issue_codename",
      type: "categoryColumn",
      hide: true,
    },
    // {
    //   headerName: "Enrollment",
    //   field: "CourseName",
    //   type: "linkedTextColumn",
    //   __linkValueGetter: getEnrollmentBuzzUrl,
    //   __linkIsNotAnEmail: true,
    //   width: 225,
    // },
    {
      headerName: "Student",
      field: "StudentName",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentGeniusUrl,
      __linkIsNotAnEmail: true,
      width: 160,
    },
    {
      headerName: "Teacher",
      field: "TeacherName",
      type: "linkedTextColumn",
      __linkValueGetter: getTeacherGeniusUrl,
      __linkIsNotAnEmail: true,
      width: 160,
    },
    {
      headerName: "Kind",
      field: "issue_displayname",
      type: "categoryColumn",
      // width: 190,
    },
    {
      headerName: "Description",
      field: "issue_description",
      type: "textColumn",
      width: 450,
      suppressSizeToFit: true,
      autoHeight: true,
      // wrapText: true,
      cellStyle: { minHeight: "48px" },
    },
    {
      headerName: "Remediation",
      field: "issue_remediation",
      type: "textColumn",
      tooltipField: "issue_remediation",
      width: 300,
      suppressSizeToFit: true,
      cellClass: ["ellipses-tooltip-cell"],
      // autoHeight: true,
      // // wrapText: true,
      // cellStyle: { minHeight: "48px" },
    },
    {
      headerName: "Student Grade Level",
      field: "StudentGradeLevel",
      type: "categoryColumn",
    },
    {
      headerName: "Student Email",
      field: "StudentEmail",
      type: "textColumn",
      hide: true,
    },
    {
      headerName: "Guardian1 Email",
      field: "Guardian1Email",
      type: "textColumn",
      hide: true,
    },
    {
      headerName: "Teacher Email",
      field: "TeacherEmail",
      type: "textColumn",
      hide: true,
    },
  ] as AgColDef[];

  return ret;
}
