import {
  TableData,
  TableModel,
  tableModelFactory,
  TableRow,
} from "../table-model-factory";
import { Action, action } from "easy-peasy";
import { Table } from "apache-arrow/table";

export interface LoyaltyCommunicationsModel
  extends Omit<TableModel, "receiveInitialData"> {
  // onSelectEntityId: ThunkOn<LoyaltyCommunicationsModel, Injections, StoreModel>;
  // initialData_loyaltyCommsOnly: TableRow[];
  receiveInitialData: Action<LoyaltyCommunicationsModel, TableData>;
}

export function getLoyaltyCommunicationsModel(): LoyaltyCommunicationsModel {
  const getRowId = (row) => row.id;
  const transformInitialData = null;
  return {
    ...tableModelFactory(
      "loyaltyCommunications",
      "loyalty_communications",
      getRowId,
      [],
      transformInitialData,
      "arrow"
    ),
    // handleFetchInitialData: thunk(
    //   async (actions, _, { getState, getStoreState }) => {
    //     const { INITIAL_DATA_ENDPOINT, fetchQueryParams } = getState();
    //
    //     // const loyalty_year =
    //     //   getStoreState().entitySelection.selectedEntityIds.loyalty_year;
    //
    //     const queryParams = {
    //       responseFormat: "json",
    //       // loyalty_year,
    //       ...fetchQueryParams,
    //     };
    //
    //     const url = makeUrl(INITIAL_DATA_ENDPOINT, queryParams);
    //
    //     const data = await makeAuthorizedGetRequestToBackend3({
    //       url,
    //     });
    //
    //     actions.receiveInitialData(data);
    //     actions.markInitialDataReceived();
    //   }
    // ),
    // maybeHandleFetchInitialData: thunk(
    //   async (actions, payload, { getState, dispatch }) => {
    //     const {
    //       initialDataLoading,
    //       initialDataReceived,
    //       NAME,
    //       fetchQueryParams,
    //     } = getState();
    //     if (!initialDataLoading && !initialDataReceived) {
    //       actions.markInitialDataLoading();
    //       try {
    //         if (!initialDataReceived && !!fetchQueryParams?.loyalty_year) {
    //           await dispatch[NAME].handleFetchInitialData();
    //         }
    //       } catch (e) {
    //         if (payload && payload.verbose) {
    //           toaster.error(
    //             "Sorry - something went wrong. Try refreshing this page. If this message appears again, please email team@saxecap.com.",
    //             5
    //           );
    //         }
    //         throw e;
    //       } finally {
    //         actions.markInitialDataNotLoading();
    //       }
    //     }
    //   }
    // ),
    // onSelectEntityId: thunkOn(
    //   (actions, { entitySelection }) => entitySelection.selectEntityId,
    //   (actions, targetPayload, { getState }) => {
    //     const [newEntityType, newEntityId]: [EntityType, EntityId] =
    //       targetPayload.payload;
    //
    //     if (newEntityType === "loyalty_year") {
    //       const existing_loyalty_year_value =
    //         getState().fetchQueryParams?.loyalty_year ??
    //         DEFAULT_SELECTED_ENTITY_IDS.loyalty_year;
    //
    //       if (newEntityId !== existing_loyalty_year_value) {
    //         const newFetchQueryParams = {
    //           loyalty_year: newEntityId,
    //         };
    //         actions.setFetchQueryParams(newFetchQueryParams);
    //         actions.resetData();
    //       }
    //     }
    //   }
    // ),
    receiveInitialData: action((state, payload) => {
      let initialData = payload;
      if (transformInitialData) {
        initialData = transformInitialData(initialData);
      }
      state.initialData = (
        initialData as unknown as Table
      ).toArray() as unknown as TableRow[];
      // state.initialData_loyaltyCommsOnly = new DataFrame(
      //   initialData as unknown as Table
      // )
      //   .filter(col("subject_contains_loyalty21").eq(lit(true)))
      //   .toArray() as unknown as TableRow[];
    }),
    // initialData_loyaltyCommsOnly: [],
  };
}
