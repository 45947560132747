import React from "react";
import styled from "styled-components/macro";
import { DailyQueueUnit } from "./daily-queue-unit";
import { SummaryUnit } from "./summary-unit";
import {
  AdmissionsCoordinatorSelector,
  useAdmissionsCoordinatorSelection,
  useAllAdmissionsCoordinators,
} from "../../components/selection/admissions-coordinator-selector";
import { Spinner } from "@blueprintjs/core";
import { useMeData } from "../../hooks/useMe";
import { UserRole } from "../../store/model-me";
import { AC_DAILY_QUEUE_ADMIN__ROLES } from "../../entities/helpers/authorization";

export function isAdminRoleForACDailyQueue(role: UserRole): boolean {
  return AC_DAILY_QUEUE_ADMIN__ROLES.includes(role);
}

export function AdmissionsCoordinatorDailyQueuePage({
  viewId,
  ...props
}): JSX.Element {
  viewId = viewId || "";

  const me = useMeData();
  const isAdminRole = isAdminRoleForACDailyQueue(me.role);

  const { loading, data: allAdmissionsCoordinators } =
    useAllAdmissionsCoordinators();
  const [selectedAdmissionsCoordinatorName, selectAdmissionsCoordinatorName] =
    useAdmissionsCoordinatorSelection();
  const isAuthorized = () => {
    return (
      isAdminRole ||
      allAdmissionsCoordinators.includes(selectedAdmissionsCoordinatorName)
    );
  };
  return loading ? (
    <Spinner />
  ) : isAuthorized() ? (
    <ACDailyQueuePageContainer>
      {selectedAdmissionsCoordinatorName && (
        <>
          <AdmissionsCoordinatorSelector
            allAdmissionsCoordinatorNames={allAdmissionsCoordinators}
            selectedAdmissionsCoordinatorName={
              selectedAdmissionsCoordinatorName
            }
            selectAdmissionsCoordinatorName={selectAdmissionsCoordinatorName}
            hide={!isAdminRole}
          />
          <SummaryUnit
            selectedAdmissionsCoordinatorName={
              selectedAdmissionsCoordinatorName
            }
            width={"100%"}
          />
        </>
      )}
      <DailyQueueUnit
        selectedAdmissionsCoordinatorName={selectedAdmissionsCoordinatorName}
      />
    </ACDailyQueuePageContainer>
  ) : null;
}

const ACDailyQueuePageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 40px;
`;
