import React, { useCallback, useEffect, useState } from "react";
import { useEntitySelection } from "../../store/model-entity-selection";
import { SelectionUnit } from "../../components/units/selection-unit";
import { ActionableStudentCourseScheduling } from "./actionable-student-course-scheudling";
import { useStoreState } from "../../hooks/ep";
import _ from "lodash";
import { filterValidOrdersForCourseSchedulePropagation } from "./single-student-propagate-course-schedule-into-genius";

export function CourseSchedulingSelectionUnit(): JSX.Element {
  const [
    selectedShouldFilterActionableStudentsCourseScheduling,
    selectShouldFilterActionableStudentsCourseScheduling,
  ] = useEntitySelection("should_filter_actionable_students_course_scheduling");

  const baseStuFilter = useCallback((stu) => {
    return (
      stu.next_year_course_schedule_recommendations &&
      stu.next_year_course_schedule_recommendations.length
    );
  }, []);

  const actionableStudentsCourseSchedulingFilter = useCallback((stu) => {
    return (
      stu.orders &&
      stu.orders.some(filterValidOrdersForCourseSchedulePropagation)
    );
  }, []);

  const combinedStuFilter = useCallback(
    (stu) => {
      return (
        baseStuFilter(stu) && actionableStudentsCourseSchedulingFilter(stu)
      );
    },
    [baseStuFilter, actionableStudentsCourseSchedulingFilter]
  );

  const [noActionableStudents, setNoActionableStudents] = useState(false);
  const students = useStoreState((s) => s.ecp_student.rowData);

  useEffect(() => {
    const studentsFiltered = students.filter(combinedStuFilter);
    const noStudentsFiltered = !_.size(studentsFiltered);
    setNoActionableStudents(noStudentsFiltered);
  }, [students, combinedStuFilter, setNoActionableStudents]);

  const finalStuFilter = useCallback(
    (stu) => {
      return noActionableStudents ||
        !selectedShouldFilterActionableStudentsCourseScheduling
        ? baseStuFilter(stu)
        : combinedStuFilter(stu);
    },
    [
      noActionableStudents,
      selectedShouldFilterActionableStudentsCourseScheduling,
      baseStuFilter,
      combinedStuFilter,
    ]
  );

  return (
    <SelectionUnit
      unitId={"selection-next-year-course-schedule-recommendations"}
      modelSliceForBaseStuFilter={"nextYearCourseScheduleRecommendations"}
      baseStuFilter={finalStuFilter}
      additionalFilterComponent={
        <ActionableStudentCourseScheduling
          noActionableStudents={noActionableStudents}
        />
      }
    />
  );
}
