import {
  tableModelFactory,
  TableModel,
  TableRow,
} from "../table-model-factory";
import { Computed, computed } from "easy-peasy";
import _ from "lodash";
import { StoreModel } from "../model";
import { addTagsField } from "../../entities/ecp/tags";

export type EcpEnrollmentModel = TableModel & {
  byCustomerId: Computed<
    EcpEnrollmentModel,
    { [key: number]: TableRow[] },
    StoreModel
  >;
};

export const transformInitialData = (initData) => {
  initData = addTagsField(initData, null);
  return initData;
};

export function getEcpEnrollmentModel(): EcpEnrollmentModel {
  return {
    ...tableModelFactory(
      "ecp_enrollment",
      "ecp/ff_enrollments",
      (row) => row.EnrollmentIndex,
      [],
      transformInitialData
    ),
    byCustomerId: computed([(s) => s.initialData], (initialData: TableRow[]) =>
      _.groupBy(initialData, "customer_id")
    ),
  };
}
