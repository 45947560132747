import React from "react";
import { useStoreState } from "../../hooks/ep";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { ReportActionModalWrapper } from "../../components/reporting-actions/modal-or-drawer";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import AgTable from "../../components/AgTable";
import { getCountColDef } from "../helpers/relation-agg-cr";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import { useMyRole } from "../helpers/authorization";
import { addTagsField, ENROLLMENT_TAGS_INFO } from "./tags";

export function EcpTeachercourseTable({
  fullScreenEnabled = true,
  ...restProps
}) {
  const loading = _.max([
    useEnsureData("ecp_teacherCourse"),
    useEnsureData("ecp_enrollment"),
  ]);
  return loading ? (
    <Spinner />
  ) : (
    <EcpTeachercourseTableInner
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

function EcpTeachercourseTableInner(props) {
  let rowData = useStoreState((s) => s.ecp_teacherCourse.rowData);

  // rowData = addTagsField(rowData);

  const colDefs = useColumnDefs();

  return (
    <>
      <AgTable
        rowData={rowData}
        columnDefs={colDefs}
        tableName={"ecp_teacherCourse"}
        {...props}
      />
      <ReportActionModalWrapper />
    </>
  );
}

export function useColumnDefs(): AgColDefs {
  const myRole = useMyRole();
  let ret = [
    {
      headerName: "Teacher",
      field: "TeacherName",
      type: "categoryColumn",
      width: 225,
      suppressSizeToFit: true,
      tooltipField: "TeacherName",
      pinned: "left",
    },
    {
      headerName: "Course",
      field: "CourseName",
      type: "textColumn",
      width: 225,
      suppressSizeToFit: true,
      tooltipField: "CourseName",
      pinned: "left",
    },
    {
      headerName: "Department",
      field: "DepartmentName",
      type: "categoryColumn",
    },
    {
      headerName: "Subject Area",
      field: "subject_area",
      type: "categoryColumn",
    },
    {
      headerName: "Risk",
      field: "churn_risk_score",
      type: "floatColumn",
      cellRenderer: "churnScoreCellRenderer",
      cellClass: "react-rendered-cell",
      headerTooltip: "Avg failure/extension risk across enrollments",
      width: 200,
    },
    getCountColDef({
      baseEntityName: "ecp_teacherCourse",
      headerName: "Enr",
      field: "enrollmentIds",
      entityName: "ecp_enrollment",
      headerTooltip: "Number of active enrollments",
      filter: "categoryFilterer",
      cellClass: "react-rendered-cell ag-numeric-cell",
      actualField: "num_enrollments",
      type: "integerColumn",
    }),
    {
      headerName: "At-Risk Enr",
      field: "n_tags__at_risk",
      type: "integerColumn",
      filter: "categoryFilterer",
      cellClass: "ag-numeric-cell",
      headerTooltip: "# of active enrollments with >70% failure/extension risk",
      width: 100,
    },
    {
      headerName: "% At-Risk Enr",
      field: "pct_tags__at_risk",
      type: "percentageColumn",
      headerTooltip: "% of active enrollments with >70% failure/extension risk",
    },
    {
      headerName: "Current Avg Grade",
      field: "curr_avg_grade",
      width: 100,
      type: "percentageColumn",
    },
    {
      headerName: "Historical Non-Completion / Failure Rate",
      field: "historical_pct__label_is_failure",
      width: 147,
      type: "percentageColumn",
      headerTooltip:
        "% of past enrollments for this teacher:course combo with failures/extensions",
    },
    {
      headerName: "Hist Teacher NC/F Rate",
      field: "teacher___historical_pct__label_is_failure",
      width: 147,
      type: "percentageColumn",
      headerTooltip:
        "% of past enrollments for this teacher with failures/extensions",
    },
    {
      headerName: "Hist Course NC/F Rate",
      field: "vacourse___historical_pct__label_is_failure",
      width: 147,
      type: "percentageColumn",
      headerTooltip:
        "% of past enrollments for this course with failures/extensions",
    },
    {
      headerName: "Hist Subject NC/F Rate",
      field: "subject___historical_pct__label_is_failure",
      width: 147,
      type: "percentageColumn",
      headerTooltip:
        "% of past enrollments for this subject-area with failures/extensions",
    },
    {
      headerName: "Max Days MIA",
      field: "max__n_active_days_without_submissions",
      headerTooltip:
        "Maximum # days since latest buzz submission across enrollments",
      width: 82,
      type: "integerColumn",
      valueGetter: (params): number =>
        Math.floor(params.data.max__n_active_days_without_submissions),
    },
    { headerName: "Teacher Email", field: "TeacherEmail", type: "textColumn" },
    {
      headerName: "Tags",
      field: "tags",
      type: "tagsColumn",
      // cellRenderer: "tagsCellRenderer",
      pinned: "right",
      width: 193,
      filterParams: {
        fp: ENROLLMENT_TAGS_INFO,
      },
      cellRendererParams: {
        crp: ENROLLMENT_TAGS_INFO,
      },
    },
    {
      headerName: "",
      field: "TeacherEmail",
      cellClass: "react-rendered-cell",
      cellRenderer: "teacherCourseEmailActionButtonCellRenderer",
      type: "textColumn",
      width: 52, // 176
      pinned: "right",
      cellRendererParams: {
        crp: {
          tooltipText: "Email Teacher",
        },
      },
    },
    ...DEFAULT_HIDDEN_COLDEFS,
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
  });
}

const DEFAULT_HIDDEN_COLDEFS = [];
