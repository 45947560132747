import React, { useEffect, useState } from "react";
import { Spinner } from "@blueprintjs/core";
import { CellClickedEvent } from "@ag-grid-community/core/dist/es6/events";
import "styled-components/macro";
import _ from "lodash";
import { lighten } from "polished";
import { useEnsureData } from "../../../helpers/use-ensure-data";
import { toaster } from "../../../toaster";
import { OnCellClicked } from "../../../components/AgTable/types";
import { FinalizeRowData } from "../../../components/AgTable/ag_table";
import { LoyaltyStudentsTable } from "../../../entities/ecp/loyalty-students";
import { LoyaltyCommunicationsTable } from "../../../entities/ecp/loyalty-communications";
import {
  DEFAULT_STUDENT_ID,
  get_selected_student_lsk,
  getFullScreenEnabled,
  getTableHeight,
  isDefaultStudentId,
  isNotDefaultStudentId,
  TABLE_HEIGHT,
} from "./splitview-helpers";

const INITIAL_CATEGORY_FILTERS = { LoyaltyEligible: [true] };

export function LoyaltyStudentsAndCommunicationsSplitview(props) {
  const loading = [
    useEnsureData("loyaltyStudents"),
    useEnsureData("loyaltyCommunications"),
    useEnsureData("ecp_enrollment"),
    useEnsureData("stars"),
    useEnsureData("student_flag"),
    useEnsureData("student_attr"),
    useEnsureData("ecp_customerInteraction"),
  ];
  return _.max(loading) ? (
    <Spinner />
  ) : (
    <LoyaltyStudentsAndCommunicationsSplitviewInner {...props} />
  );
}

function LoyaltyStudentsAndCommunicationsSplitviewInner({
  finalizeStudentRowData,
  viewId,
}): JSX.Element {
  viewId = viewId || "";
  const selected_student_lsk = get_selected_student_lsk(viewId);
  const [selectedStudentId, setSelectedStudentId] = useState<number>(() => {
    const stored = localStorage.getItem(selected_student_lsk);
    return +(stored ?? DEFAULT_STUDENT_ID);
  });

  const [studentSelectionDisabled, setStudentSelectionDisabled] =
    useState(true);

  useEffect(() => {
    if (!studentSelectionDisabled) {
      localStorage.setItem(selected_student_lsk, selectedStudentId.toString());
    }
  }, [selectedStudentId, selected_student_lsk, studentSelectionDisabled]);

  const onCellClicked_UpperSplit = (ev: CellClickedEvent) => {
    const studentRow = ev.data;
    const colDef = ev.colDef;
    const studentId = studentRow.id;

    if (studentSelectionDisabled && !ev.column.isCellEditable(ev.node)) {
      setStudentSelectionDisabled(false);
    }

    if (!colDef.cellRenderer && !ev.column.isCellEditable(ev.node)) {
      setSelectedStudentId((v) =>
        studentId === v ? DEFAULT_STUDENT_ID : studentId
      );
    }
  };

  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}
    >
      <UpperSplit
        onCellClicked={onCellClicked_UpperSplit}
        studentSelectionDisabled={studentSelectionDisabled}
        selectedStudentId={selectedStudentId}
        finalizeRowData={finalizeStudentRowData}
      />
      {studentSelectionDisabled ||
      isDefaultStudentId(selectedStudentId) ? null : (
        <>
          <div
            css={`
              height: 40px;
            `}
          >
            {" "}
          </div>
          <LowerSplit selectedStudentId={selectedStudentId} />
        </>
      )}
    </div>
  );
}

function UpperSplit({
  onCellClicked,
  studentSelectionDisabled,
  selectedStudentId,
  finalizeRowData,
}: {
  selectedStudentId: number;
  studentSelectionDisabled: boolean;
  onCellClicked: OnCellClicked;
  finalizeRowData: FinalizeRowData;
}): JSX.Element {
  const height = getTableHeight(selectedStudentId, studentSelectionDisabled);
  const fullScreenEnabled = getFullScreenEnabled(
    selectedStudentId,
    studentSelectionDisabled
  );
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        & .ag-row[row-id="${selectedStudentId}"] {
          background-color: rgba(0, 21, 40, 0.7) !important;
          &.ag-row-hover {
            background-color: ${lighten(
              0.1,
              "rgba(0, 21, 40, 0.7)"
            )} !important;
          }
        }
      `}
    >
      <LoyaltyStudentsTable
        onCellClicked={onCellClicked}
        fullScreenEnabled={fullScreenEnabled}
        height={height}
        finalizeRowData={finalizeRowData}
        tableNameSuffix="loyalty"
        initialCategoryFilters={INITIAL_CATEGORY_FILTERS}
      />
    </div>
  );
}

function LowerSplit({
  selectedStudentId,
}: {
  selectedStudentId: number;
}): JSX.Element {
  if (isNotDefaultStudentId(selectedStudentId)) {
    return (
      <LoyaltyCommunicationsTable
        studentId={selectedStudentId}
        fullScreenEnabled={false}
        height={TABLE_HEIGHT}
        tableNameSuffix={`loyalty__${selectedStudentId}`}
      />
    );
  } else {
    return null;
  }
}
