import { Recommendation, RecosByDept } from "../types";
import { useSelectedCustomer } from "../../../../components/units/selection-unit";
import _ from "lodash";
import { sortArrayObjectValues } from "../sort-array-values";
import { TableRow } from "../../../../store/table-model-factory";
import { CustomerId } from "../../../../dev-page";

export const DEPT_NAME_COLNAME = "DepartmentName";

export function useNextCourseRecommendationsByDepartmentName(): RecosByDept {
  const customer = useSelectedCustomer();
  return getSortedRecosByDept(customer);
}

// export function useSingleDepartmentNextCourseRecos(
//   customerId: CustomerId,
//   departmentName: DepartmentName
// ): Recommendation[] {
//   return useSelected;
// }

function getSortedRecosByDept(customer: TableRow): RecosByDept {
  const nextCourseRecos = customer.next_course_recos;
  if (!_.size(nextCourseRecos ?? [])) {
    return {};
  } else {
    const recosByDept: RecosByDept = _.groupBy<Recommendation>(
      nextCourseRecos,
      DEPT_NAME_COLNAME
    );
    return sortArrayObjectValues<Recommendation>(
      recosByDept,
      compareRecommendations
    );
  }
}

export function compareRecommendations(
  a: Recommendation,
  b: Recommendation
): number {
  return b.proba - a.proba;
}
