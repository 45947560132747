import { useBoolean } from "../../helpers/useBoolean";
import { useFullScreenModeEnabled } from "../../store/model-misc";
import { LoyaltyStatisticsUnit } from "./loyalty-statistics-unit";
import { LoyaltySpacerUnit } from "./loyalty-spacer-unit";
import { LoyaltyTableTabsUnit } from "./loyalty-table-tabs-unit";
import React from "react";
import "styled-components/macro";
import {
  isDefaultLoyaltyYear,
  useSelectedLoyaltyYear,
} from "./loyalty-year-helpers";
import { Spinner } from "@blueprintjs/core";

export function LoyaltyPageBody() {
  const fullscreenOff = !useFullScreenModeEnabled();
  const selectedLoyaltyYear = useSelectedLoyaltyYear();
  const cgnStatsHidden = useBoolean(false);

  // useEffect(() => {
  //   if (selectedLoyaltyYear === "2022" && !cgnStatsHidden.value) {
  //     cgnStatsHidden.setValue(true);
  //   } else if (selectedLoyaltyYear !== "2022" && cgnStatsHidden.value) {
  //     cgnStatsHidden.setValue(false);
  //   }
  // }, [selectedLoyaltyYear]);

  const showCgnStats = fullscreenOff && !cgnStatsHidden.value; //&& selectedLoyaltyYear !== "2022";
  const ecpPriEnrStusPageProps = getEcpPriEnrStusPageProps(!showCgnStats);
  return !selectedLoyaltyYear ? (
    <Spinner />
  ) : (
    <div
      css={`
        width: 100%;
        height: 100%;
      `}
    >
      {showCgnStats && !isDefaultLoyaltyYear(selectedLoyaltyYear) && (
        <LoyaltyStatisticsUnit />
      )}
      {showCgnStats && isDefaultLoyaltyYear(selectedLoyaltyYear) && (
        <LoyaltySpacerUnit />
      )}
      {isDefaultLoyaltyYear(selectedLoyaltyYear) && (
        <LoyaltyTableTabsUnit {...ecpPriEnrStusPageProps} />
      )}
    </div>
  );
}

interface EcpPriEnrStusPageProps {
  height?: string;
}

function getEcpPriEnrStusPageProps(
  allowStudentSplitviewClickToSelect: boolean
): EcpPriEnrStusPageProps {
  if (allowStudentSplitviewClickToSelect) {
    return {
      height: "calc(max(400px, 100vh - 40px - 40px - 64px - 48px - 262px))",
    };
  }
}
