import React, { MouseEventHandler, useCallback } from "react";
import {
  Button,
  Callout,
  H4,
  H5,
  IconName,
  Intent,
  Menu as BPMenu,
  Popover,
  Position,
  Spinner,
} from "@blueprintjs/core";
import { RbnBtn } from "../AgTable/ribbon";
import * as COLORS from "../../styles/colors";
import { useMe, useMeData } from "../../hooks/useMe";
import { useBoolean } from "../../helpers/useBoolean";
import { useStoreActions } from "../../hooks/ep";
import { UserOutlined } from "@ant-design/icons";
import "styled-components/macro";
import {
  STATLINE_NAMES,
  useBuzzAndGeniusDataRefreshedAt,
  useNetsuiteDataRefreshedAt,
} from "./data-freshness-helpers";
import { useTimeOfDay } from "../../hooks/use-time-of-day";
import { useInsideReactRouterSwitch } from "../../hooks/use-inside-react-router-switch";

export function UserSettings(): JSX.Element {
  const { loading } = useMe();
  const meData = useMeData();
  const insideReactRouterSwitch = useInsideReactRouterSwitch();

  if (loading || !insideReactRouterSwitch) {
    return <Spinner size={Spinner.SIZE_SMALL} />;
  } else if (!loading && !meData) {
    return <div> </div>;
  } else {
    return <UserSettingsInner />;
  }
}

function UserSettingsInner(): JSX.Element {
  const menu = (
    <BPMenu large className="bp3-dark">
      <div
        css={`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 16px;
        `}
      >
        <GreetingHeader />
        <BuzzAndGeniusDataFreshnessCallout />
        <NetsuiteDataFreshnessCallout />
        <LogoutButton />
      </div>
    </BPMenu>
  );
  const isOpen = useBoolean(false);
  return (
    <Popover
      content={menu}
      position={Position.BOTTOM}
      isOpen={isOpen.value}
      onClose={isOpen.setFalse}
      boundary={"viewport"}
    >
      <RbnBtn
        dataTestId="account_button"
        ghost={false}
        type={"primary"}
        size="large"
        icon={<UserOutlined />}
        tooltipDisabled={isOpen.value}
        tooltipPlacement="bottom"
        title="Settings"
        hoverColor={COLORS.primary}
        onClick={isOpen.setTrue}
      />
    </Popover>
  );
}

function GreetingHeader(): JSX.Element {
  const meData = useMeData();
  const timeOfDay = useTimeOfDay();
  const greeting = `Good ${timeOfDay}, ${meData.nickname || meData.first_name}`;

  return (
    <H4
      css={`
        padding: 0 !important;
        margin: 0 0 16px 0 !important;
      `}
    >
      {greeting}
    </H4>
  );
}

function LogoutButton() {
  const handleLogout = useStoreActions((a) => a.firebase.logout);

  const onClick = useCallback<MouseEventHandler>(
    async (event) => {
      await handleLogout();
    },
    [handleLogout]
  );

  return (
    <Button
      data-testid="logout_button"
      intent="primary"
      icon="power"
      fill={true}
      onClick={onClick}
    >
      Logout
    </Button>
  );
}

function BuzzAndGeniusDataFreshnessCallout(): JSX.Element {
  return (
    <FreshnessCallout
      title={STATLINE_NAMES.buzzAndGeniusDataRefreshedAt}
      contents={useBuzzAndGeniusDataRefreshedAt()}
      icon="updated"
    />
  );
}

function NetsuiteDataFreshnessCallout(): JSX.Element {
  return (
    <FreshnessCallout
      title={STATLINE_NAMES.netsuiteDataRefreshedAt}
      contents={useNetsuiteDataRefreshedAt()}
      icon="office"
    />
  );
}

function FreshnessCallout({
  title,
  contents,
  icon,
  intent = Intent.SUCCESS,
}: {
  title: string;
  contents: string | undefined | null;
  icon: IconName;
  intent?: Intent;
}): JSX.Element {
  return (
    <Callout
      icon={icon}
      intent={intent}
      css={`
        margin: 0 0 16px 0 !important;
      `}
    >
      <H5>{title}</H5>
      {contents || <Spinner size={Spinner.SIZE_SMALL} />}
    </Callout>
  );
}
