import { DataSnapshot, submitUserActionPostRequest } from "./user_actions";
import { assert } from "../helpers/assertions";

export async function submitUserAction__update_student_attr(
  actionPayload: ActionPayload_UpdateStudentAttr
) {
  verifyActionPayload(actionPayload);
  return await submitUserActionPostRequest(
    "update_student_attr",
    actionPayload
  );
}

function verifyActionPayload(
  actionPayload: ActionPayload_UpdateStudentAttr
): void {
  assert(typeof actionPayload.student_id === typeof 12345);
  assert(typeof actionPayload.attr_codename === typeof "hi");
  assert(typeof actionPayload.attr_oldvalue === typeof "string");
  assert(typeof actionPayload.attr_newvalue === typeof "string");
  assert(
    actionPayload.student_id === actionPayload.student_snapshot.StudentIndex
  );
  assert(
    ALL_STUDENT_ATTR_CODENAMES.includes(
      actionPayload.attr_codename as StudentAttrCodename
    )
  );
}

export type StudentAttrCodename = "loyalty_renr_campaign_type";
export const ALL_STUDENT_ATTR_CODENAMES = ["loyalty_renr_campaign_type"];

export interface ActionPayload_UpdateStudentAttr {
  student_id: number;
  attr_codename: StudentAttrCodename;
  attr_oldvalue: string;
  attr_newvalue: string;
  student_snapshot: DataSnapshot;
}

const STUDENT_ATTR_DEFAULTS = {
  student_attr__loyalty_renr_campaign_type: (row) => {
    if (!!row.AcademyStudent) {
      return "academy";
    } else if (!!row.AcademyPotential) {
      return "academy_potential";
    } else {
      return "normal";
    }
  },
};
export function ensureStudentAttrFieldDefaults(rows) {
  return rows.map((row) => ({
    ...row,
    ...Object.fromEntries(
      Object.entries(STUDENT_ATTR_DEFAULTS).map(([name, fn]) => [name, fn(row)])
    ),
  }));
}
