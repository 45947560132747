import { useStoreActions, useStoreState } from "../../hooks/ep";
import { Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";
import React from "react";
import "styled-components/macro";
import _ from "lodash";
import { ActionCreator } from "easy-peasy";
import { useSyncSelectedWithQueryParams } from "./use-sync-qp-with-selected";
import { NumberParam, useQueryParam } from "use-query-params";
import {
  ENROLLMENT_COORDINATOR_INDEX__ALL,
  EnrollmentCoordinator,
  EnrollmentCoordinatorIndex,
} from "../../store/model-ec-selection";
import styled from "styled-components/macro";

export function useEnrollmentCoordinatorSelection(): [
  EnrollmentCoordinatorIndex,
  ActionCreator<EnrollmentCoordinatorIndex>
] {
  const selectedEnrollmentCoordinatorIndex = useStoreState(
    (s) => s.enrollmentCoordinatorSelection.selectedEnrollmentCoordinatorIndex
  );
  const selectEnrollmentCoordinatorIndex = useStoreActions(
    (a) => a.enrollmentCoordinatorSelection.selectEnrollmentCoordinatorIndex
  );
  return [selectedEnrollmentCoordinatorIndex, selectEnrollmentCoordinatorIndex];
}

export function EnrollmentCoordinatorSelector({
  selectedEnrollmentCoordinatorIndex,
  selectEnrollmentCoordinatorIndex,
  hide = false,
}): JSX.Element {
  const allEnrollmentCoordinators = useStoreState(
    (s) => s.retentionKpis.activeEnrollmentCoordinators
  );
  const allEnrollmentCoordinatorIndexes = allEnrollmentCoordinators.map(
    (ec) => ec.EC_Index
  );

  const [
    qpSelectedEnrollmentCoordinatorIndex,
    qpSelectEnrollmentCoordinatorIndex,
  ] = useQueryParam("ecIndex", NumberParam);

  useSyncSelectedWithQueryParams(
    allEnrollmentCoordinatorIndexes,
    selectedEnrollmentCoordinatorIndex,
    selectEnrollmentCoordinatorIndex,
    qpSelectedEnrollmentCoordinatorIndex,
    qpSelectEnrollmentCoordinatorIndex
  );
  const selectedEnrollmentCoordinator = allEnrollmentCoordinators.find(
    (ec) => ec.EC_Index === selectedEnrollmentCoordinatorIndex
  );

  return hide ? null : (
    <ECSelectorContainer>
      <label className="bp3-label">
        Enrollment Coordinator
        <Select
          large
          intent="primary"
          className="ec-select"
          itemPredicate={itemPredicate}
          itemRenderer={(v, { handleClick, modifiers }) => {
            const studentsCount =
              v.EC_Index !== ENROLLMENT_COORDINATOR_INDEX__ALL
                ? `${v.n_upcoming_renr_potential_students} students`
                : "";
            return (
              <MenuItem
                active={false}
                key={v.EC_Index}
                label={studentsCount}
                onClick={handleClick}
                text={v.EC_FullName}
              />
            );
          }}
          items={allEnrollmentCoordinators}
          onItemSelect={(v) => {
            qpSelectEnrollmentCoordinatorIndex(v.EC_Index, "pushIn");
          }}
        >
          {selectedEnrollmentCoordinator && (
            <Button
              large
              text={selectedEnrollmentCoordinator.EC_FullName}
              rightIcon="caret-down"
            />
          )}
        </Select>
      </label>
    </ECSelectorContainer>
  );
}

function itemPredicate(
  query: string,
  item: EnrollmentCoordinator,
  index: number,
  exactMatch: boolean
) {
  const textItem = item.EC_FullName.toLowerCase();
  query = query.toLowerCase();
  if (exactMatch) {
    return textItem === query;
  } else if (!query.length) {
    return true;
  } else {
    return _.startsWith(textItem, query);
  }
}

const ECSelectorContainer = styled.div`
  width: 200px;
  & .bp3-button {
    width: 100%;
    justify-content: space-between;
  }
`;
