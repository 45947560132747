import {
  SelectEntityIdOfType,
  useEntitySelection,
  useSelectedEntityId,
} from "../../store/model-entity-selection";
import { useEffect } from "react";
import _ from "lodash";
import { LoyaltyYear } from "./types";

export const DEFAULT_LOYALTY_YEAR = "2022";
export const ALL_LOYALTY_YEARS: LoyaltyYear[] = ["2021", "2022"];

export function useSelectDefaultLoyaltyYearWhenSelectedIsNotValid(
  selectedLoyaltyYear: string,
  selectLoyaltyYear: SelectEntityIdOfType
): void {
  useEffect(() => {
    if (!isValidLoyaltyYear(selectedLoyaltyYear)) {
      selectLoyaltyYear(DEFAULT_LOYALTY_YEAR);
    }
  }, [selectedLoyaltyYear, selectLoyaltyYear]);
}

export function useLoyaltyYearSelection(): [LoyaltyYear, SelectEntityIdOfType] {
  return useEntitySelection("loyalty_year") as [
    LoyaltyYear,
    SelectEntityIdOfType
  ];
}

export function isValidLoyaltyYear(selectedLoyaltyYear: string) {
  return ALL_LOYALTY_YEARS.includes(selectedLoyaltyYear as LoyaltyYear);
}

export function isDefaultLoyaltyYear(selectedLoyaltyYear: string) {
  return selectedLoyaltyYear === DEFAULT_LOYALTY_YEAR;
}

export function selectableLoyaltyYearItemPredicate(
  query: string,
  item: LoyaltyYear,
  index: number,
  exactMatch: boolean
) {
  if (exactMatch) {
    return item === query;
  } else if (!query.length) {
    return true;
  } else {
    return _.startsWith(item, query);
  }
}

export function useSelectedLoyaltyYear(): LoyaltyYear {
  return useSelectedEntityId("loyalty_year") as LoyaltyYear;
}

export function shortenYear(loyalty_year: string): string {
  return loyalty_year.slice(2);
}
