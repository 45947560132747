import React, { useEffect } from "react";
import { Customer360PageContainerDiv } from "./customer360-page-container-div";
import {
  SelectionUnit,
  useSelectedCustomer,
} from "../../components/units/selection-unit";
import { BasicUnit } from "../../components/units/basic-unit";
import { HistoryUnit } from "../../components/units/history-unit";
import { RecommendationsUnit } from "./recommendations/recommendations-unit";
import {
  DEFAULT_SELECTED_ENTITY_IDS,
  useSelectEntityId,
} from "../../store/model-entity-selection";

export function Customer360Page(props): JSX.Element {
  const setSelectedCustomerId = useSelectEntityId("student");
  useEffect(() => {
    return () => setSelectedCustomerId(DEFAULT_SELECTED_ENTITY_IDS.student);
  }, []);
  const customer = useSelectedCustomer();

  return (
    <Customer360PageContainerDiv>
      <SelectionUnit
        unitId={"selection-next-course-recos"}
        modelSliceForBaseStuFilter={"nextCourseRecos"}
        baseStuFilter={(stu) =>
          stu.next_course_recos && stu.next_course_recos.length
        }
      />
      <BasicUnit unitId={"basic-next-course-recos"} />
      <RecommendationsUnit />
      <HistoryUnit
        unitId={"history-next-course-recos"}
        departmentNamesInitialCategoryFilter={
          customer &&
          Array.from(
            new Set(customer.next_course_recos.map((obj) => obj.DepartmentName))
          )
        }
      />
    </Customer360PageContainerDiv>
  );
}
