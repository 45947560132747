import React from "react";
import "styled-components/macro";
import {
  Popover,
  PopoverInteractionKind,
  Position,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import _ from "lodash";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { EcpEnrollmentTable } from "../../entities/ecp/ecp-enrollment-entity";
import { isExtremelyMissingInAction } from "../../entities/ecp/tags";
import styled from "styled-components/macro";
import { memo } from "easy-peasy";
import { extractTagStrings } from "./tagsColumnFilter";
import { BaseVector } from "apache-arrow";

const TAGS_EXCLUDING_IRREGULARLY_PACED_COURSES = [
  "at_risk",
  "missing_in_action",
];

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > * {
    margin-right: 16px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

const StyledTag = styled(Tag)`
  &&&&& {
    background-color: rgba(138, 155, 168, 0.1) !important;
  }
`;

export function TagsCellRenderer({ value: tags, data, crp }) {
  if (tags instanceof BaseVector) {
    tags = tags.toArray();
  }
  crp = crp || {};
  const tagIcons = crp.tagIcons || {};
  const tagDisplayNames = crp.tagDisplayNames || {};
  const tagSortOrder = crp.tagSortOrder || null;

  let trueTagIds = extractTagStrings(tags || "");

  if (tagSortOrder) {
    trueTagIds = _.sortBy(trueTagIds, (tagId) => tagSortOrder.indexOf(tagId));
  } else {
    trueTagIds.sort();
  }

  if (tagSortOrder === null) {
    return trueTagIds.join(", ");
  }

  return (
    <StyledTag fill large minimal round intent={Intent.NONE}>
      <StyledDiv>
        {tagSortOrder.map((tagId) => {
          const Icon = tagIcons?.[tagId];
          let intent: Intent;
          let displayName = tagDisplayNames[tagId];
          if (isExtremelyMissingInAction(tagId, data)) {
            intent = Intent.DANGER;
            displayName = "Extremely MIA";
          }
          return (
            <MyTag
              key={tagId}
              id={tagId}
              data={data}
              icon={Icon ? <Icon intent={intent} /> : null}
              displayName={displayName}
              isTrue={trueTagIds.includes(tagId)}
            />
          );
        })}
      </StyledDiv>
    </StyledTag>
  );
  // return (
  //   <div
  //     css={`
  //       width: 100%;
  //       height: 100%;
  //       display: flex;
  //       justify-content: flex-start;
  //       align-items: center;
  //
  //       & > * {
  //         margin-right: 16px;
  //       }
  //       & > *:last-child {
  //         margin-right: 0;
  //       }
  //     `}
  //   >
  //     {tagSortOrder.map((tagId) => (
  //       <MyTag
  //         key={tagId}
  //         id={tagId}
  //         icon={tagIcons[tagId]}
  //         displayName={tagDisplayNames[tagId]}
  //         isTrue={trueTagIds.includes(tagId)}
  //       />
  //     ))}
  //   </div>
  // );
}

type IxnKindT =
  | typeof PopoverInteractionKind.HOVER
  | typeof PopoverInteractionKind.HOVER_TARGET_ONLY;

/*
<RelatedEnrollmentsTag
          nEnr={nEnrWithTag}
          enrTableProps={enrTableProps}
        />
 */

const StyledTooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledTooltipTagWrapper = styled.span`
  display: flex;
  align-items: center;
`;
const StyledTooltipTag = styled(Tag)`
  margin-right: 6px;
  font-size: 14px;
`;
const getTooltipContent = memo((nEnrWithTag, displayName, id) => {
  return (
    <StyledTooltipContentWrapper>
      <StyledTooltipTagWrapper>
        <StyledTooltipTag
          intent={Intent.NONE}
          interactive={false}
          minimal
          disabled
          round
        >
          {nEnrWithTag}
        </StyledTooltipTag>
        <span>{displayName || id}</span>
      </StyledTooltipTagWrapper>
      <span className={"bp3-text-muted bp3-text-small"}>
        (click icon to view)
      </span>
    </StyledTooltipContentWrapper>
  );
}, 1000);

function getTooltipProps(data, id, displayName) {
  data = data || {};
  const nEnrWithTagField = `n_tags__${id}`;
  const nEnrWithTag = data[nEnrWithTagField];
  const hasEnrWithTag = (nEnrWithTag ?? 0) > 0;

  let content = displayName || id;
  let interactionKind: IxnKindT = PopoverInteractionKind.HOVER_TARGET_ONLY;
  let hoverCloseDelay = 0;
  let position: Position = Position.BOTTOM;
  let enrTableProps;
  if (hasEnrWithTag) {
    const studentId = data["StudentIndex"];
    const teacherCourseId = data["teacherCourse_id"];
    enrTableProps = {
      studentId,
      teacherCourseId,
      prefilterTagId: id,
      prefilterRegularlyPacedCourses:
        studentId && TAGS_EXCLUDING_IRREGULARLY_PACED_COURSES.includes(id),
    };
    content = getTooltipContent(nEnrWithTag, displayName, id);
    // hoverCloseDelay = 200;
    interactionKind = PopoverInteractionKind.HOVER_TARGET_ONLY;
    position = Position.TOP;
  }
  return [
    enrTableProps,
    { content, interactionKind, hoverCloseDelay, position },
  ];
}

const EmptyTagSpan = styled.span`
  visibility: hidden;
`;

export function MyTag({ id, icon, data, displayName, isTrue = true }) {
  const [enrTableProps, tooltipProps] = getTooltipProps(data, id, displayName);

  if (icon && isTrue) {
    const iconWithTooltip = (
      <Tooltip boundary={"viewport"} {...tooltipProps}>
        {icon}
      </Tooltip>
    );
    if (!_.isNil(enrTableProps)) {
      return (
        <Popover
          targetClassName={"clickable-tag-icon"}
          autoFocus={false}
          usePortal={true}
          boundary={"viewport"}
          position={Position.BOTTOM}
          hoverCloseDelay={300}
          interactionKind={PopoverInteractionKind.CLICK}
          content={
            <div
              css={`
                padding: 0;
                width: 50vw;
              `}
            >
              <EcpEnrollmentTable
                height={"40vh"}
                fullScreenEnabled={false}
                {...enrTableProps}
              />
            </div>
          }
        >
          {iconWithTooltip}
        </Popover>
      );
    } else {
      return iconWithTooltip;
    }
  } else if (icon) {
    return <EmptyTagSpan>{icon}</EmptyTagSpan>;
    /*return <BP3Icon icon={"blank"} iconSize={BP3Icon.SIZE_STANDARD} />;*/
  } else {
    return <span className={"bp3-text"}>{displayName || id}</span>;
  }
}
