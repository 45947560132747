import React from "react";
import { IconName, Spinner } from "@blueprintjs/core";
import { useEnsureData } from "../../../helpers/use-ensure-data";
import { VALID_NEW_USER_ROLES } from "../../../entities/helpers/writes-auth";
import { VALID_TIMEZONES } from "../../../entities/ecp/ecp-user-entity";
import { TextInput } from "../../forms-stuff/text-input";

export function CreateNewUser_FormContent({
  formState,
  formActions,
  activeField,
  setActiveField,
}) {
  const loading = useEnsureData("ecp_user");
  return loading ? (
    <Spinner />
  ) : (
    <Inner
      formState={formState}
      formActions={formActions}
      activeField={activeField}
      setActiveField={setActiveField}
    />
  );
}

export function Inner({ formState, formActions, activeField, setActiveField }) {
  const textInputConfigs: {
    type: string;
    label: string;
    helperText: string;
    leftIcon: IconName;
  }[] = [
    {
      type: "email",
      label: "Email",
      helperText:
        "The new user's email address. A user with this email address must not already exist",
      leftIcon: "envelope",
    },
    {
      type: "role",
      label: "Role",
      helperText: `The new user's role. One of the following: ${VALID_NEW_USER_ROLES.join(
        ", "
      )}`,
      leftIcon: "hat",
    },
    {
      type: "first_name",
      label: "First Name",
      helperText:
        "The new user's first name. This is how they will be addressed by Apollo in the app and in emails",
      leftIcon: "id-number",
    },
    {
      type: "last_name",
      label: "Last Name",
      helperText: "The new user's last name",
      leftIcon: "barcode",
    },
    {
      type: "timezone",
      label: "Timezone",
      helperText: `The new user's timezone. One of the following: ${VALID_TIMEZONES.join(
        ", "
      )}`,
      leftIcon: "globe",
    },
  ];
  return (
    <>
      {textInputConfigs.map(({ type, label, helperText, leftIcon }) => (
        <TextInput
          key={type}
          helperText={helperText}
          label={label}
          name={type}
          leftIcon={leftIcon}
          value={formState[type]}
          onValueChange={(value) => formActions.setField({ type, value })}
          activeField={activeField}
          setActiveField={setActiveField}
        />
      ))}
    </>
  );
}
