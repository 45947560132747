import React, { Dispatch, SetStateAction, useMemo } from "react";
import { CampaignConfiguration, CampaignSelectorOption } from "./types";
import { ItemRenderer, Select } from "@blueprintjs/select";
import { Button, H4, MenuItem } from "@blueprintjs/core";
import _ from "lodash";
import styled from "styled-components/macro";

export function useAllSelectableCampaigns(
  data: CampaignConfiguration[]
): CampaignSelectorOption[] {
  return useMemo(() => {
    let ret = data
      ? data.map(({ campaign_codename, display_name, campaign_status }) => ({
          campaign_codename,
          display_name,
          campaign_status,
        }))
      : [];

    ret = _.sortBy(ret, ({ campaign_codename }) => {
      const matches = campaign_codename.match(/\d+/);
      return matches ? parseInt(matches[0], 10) : Infinity;
    });

    return ret;
  }, [data]);
}

export function CampaignSelector({
  allSelectableCampaigns,
  selectedCampaign,
  setSelectedCampaign,
}: {
  allSelectableCampaigns: CampaignSelectorOption[];
  selectedCampaign: CampaignSelectorOption;
  setSelectedCampaign: Dispatch<SetStateAction<CampaignSelectorOption>>;
}): JSX.Element {
  const renderCampaign: ItemRenderer<CampaignSelectorOption> = (
    campaignConfiguration,
    { handleClick, modifiers }
  ) => {
    return (
      <MenuItem
        active={false}
        key={campaignConfiguration.campaign_codename}
        label={campaignConfiguration.campaign_status.toUpperCase()}
        onClick={handleClick}
        text={formatCampaignSelectorDisplayedText(
          campaignConfiguration.campaign_codename,
          campaignConfiguration.display_name
        )}
      />
    );
  };

  return (
    <CampaignSelectorContainer>
      <StyledH4>{"Campaign"}</StyledH4>
      <Select
        className="full-width"
        fill
        items={allSelectableCampaigns}
        itemRenderer={renderCampaign}
        intent="primary"
        large
        onItemSelect={(v) => {
          setSelectedCampaign(v);
        }}
        filterable={false}
        popoverProps={{ portalClassName: "full-width" }}
      >
        <Button
          large
          text={formatCampaignSelectorDisplayedText(
            selectedCampaign.campaign_codename,
            selectedCampaign.display_name
          )}
          rightIcon="double-caret-vertical"
        />
      </Select>
    </CampaignSelectorContainer>
  );
}

function formatCampaignSelectorDisplayedText(
  campaign_codename: string,
  display_name: string
): string {
  if (!campaign_codename) {
    return "Select a campaign";
  }

  const match = campaign_codename.match(/cgn(\d+)a/);

  if (match) {
    const campaignNumber = match[1];
    return `Campaign ${campaignNumber} - ${display_name}`;
  }
}

const CampaignSelectorContainer = styled.div`
  width: 600px;
  display: flex;
  row-direction: row;
  & .bp3-button {
    width: 600px;
    justify-content: space-between;
  }
  & .bp3-button-text {
    flex-grow: 1;
  }
`;

const StyledH4 = styled(H4)`
  padding-top: 10px;
  margin-right: 10px;
`;
