import {
  Popover,
  PopoverInteractionKind,
  PopoverPosition,
  Position,
  Tag,
} from "@blueprintjs/core";
import styled from "styled-components/macro";
import React, { ReactNode } from "react";
import { DescriptionItemConfig, FlatDescriptions } from "./flat-descriptions";
import { buildDescriptionItem } from "./overview--description-item";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import {
  getFormatter,
  useRelatedsSum,
} from "../entities/helpers/relation-agg-cr";
import { floatVF } from "./AgTable/gridOptions";
import { TableRow } from "../store/table-model-factory";
import { ColTableConfig } from "./table-descriptions";

export function HierarchicalRelatedsIdsTag({
  colConfig,
  children,
  data,
  position,
}: HierarchicalRelatedIdsTagProps): JSX.Element {
  return (
    <Popover
      autoFocus={false}
      usePortal={true}
      boundary={"viewport"}
      position={position ?? Position.BOTTOM}
      content={
        <HierarchicalRelatedIdsTagPopoverContent
          colConfig={colConfig}
          data={data}
        />
      }
      interactionKind={PopoverInteractionKind.HOVER}
    >
      <StyledTag interactive={true} intent={Intent.PRIMARY}>
        {children}
      </StyledTag>
    </Popover>
  );
}

function HierarchicalRelatedIdsTagPopoverContent({
  colConfig,
  data,
}: HierarchicalRelatedIdsTagPopoverContentProps): JSX.Element {
  return (
    <div
      css={`
        padding: 0;
      `}
    >
      <FlatDescriptions withoutBoxShadow>
        {colConfig.childItemConfigs.map((itemConfig, key) =>
          buildDescriptionItem({
            key,
            itemConfig,
            data,
          })
        )}
      </FlatDescriptions>
    </div>
  );
}

export function HierarchicalRelatedEntitiesTag({
  itemConfig,
  children,
  data,
  position,
}: HierarchicalRelatedEntitiesTagProps): JSX.Element {
  const getTagIntent =
    itemConfig.extra?.getTagIntent ?? ((data) => Intent.PRIMARY);
  const tagIntent = getTagIntent(data);
  return (
    <Popover
      autoFocus={false}
      usePortal={true}
      boundary={"viewport"}
      position={position ?? Position.BOTTOM}
      content={
        <HierarchicalRelatedEntitiesTag_PopoverContent
          itemConfig={itemConfig}
          data={data}
        />
      }
      interactionKind={PopoverInteractionKind.HOVER}
    >
      <StyledTag interactive={true} intent={tagIntent}>
        {children}
      </StyledTag>
    </Popover>
  );
}

export function HierarchicalRelatedEntitiesSumTag({
  itemConfig,
  data,
  ...restProps
}: Omit<HierarchicalRelatedEntitiesTagProps, "children">): JSX.Element {
  const value = data?.[itemConfig.field];
  const summation = useRelatedsSum(
    value.entityName,
    value.entityIds,
    itemConfig.extra.foreignField
  );
  const formatter = getFormatter(itemConfig.extra.type) ?? floatVF;
  const formattedSummation = formatter(summation);
  return (
    <HierarchicalRelatedEntitiesTag
      itemConfig={itemConfig}
      data={data}
      {...restProps}
    >
      {formattedSummation}
    </HierarchicalRelatedEntitiesTag>
  );
}

function HierarchicalRelatedEntitiesTag_PopoverContent({
  itemConfig,
  data,
}: HierarchicalRelatedEntitiesTagPopoverContentProps): JSX.Element {
  return (
    <div
      css={`
        padding: 0;
      `}
    >
      <FlatDescriptions withoutBoxShadow>
        {itemConfig.childItemConfigs.map((itemConfig, key) =>
          buildDescriptionItem({
            key,
            itemConfig,
            data,
          })
        )}
      </FlatDescriptions>
    </div>
  );
}

export interface HierarchicalRelatedEntitiesTagPopoverContentProps {
  itemConfig: DescriptionItemConfig;
  data: TableRow;
}

export interface HierarchicalRelatedEntitiesTagProps
  extends HierarchicalRelatedEntitiesTagPopoverContentProps {
  children: ReactNode | ReactNode[];
  position?: PopoverPosition;
}

export interface HierarchicalRelatedIdsTagPopoverContentProps {
  colConfig: ColTableConfig;
  data: TableRow;
}

export interface HierarchicalRelatedIdsTagProps
  extends HierarchicalRelatedIdsTagPopoverContentProps {
  children: ReactNode | ReactNode[];
  position?: PopoverPosition;
}

const StyledTag = styled(Tag)`
  font-size: 14px;
  padding: 3px 7px;
`;
