import React from "react";
import styled from "styled-components/macro";
import { floatVF } from "../gridOptions";
import { KPI_INTENT_COLORS } from "./cell-renderer";

export const PopoverContentWrapper = styled.div`
  padding: 13px 24px 23px 24px;
  max-height: 440px;
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
`;
const LabelRow = styled.div`
  padding-top: 10px;
  width: 100%;
`;
const ContentRow = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid: 1fr / 1fr min-content;
  column-gap: 10px;
  justify-content: space-between;
`;
const ValueContainer = styled.div`
  min-width: 30px;
  color: ${(props) => KPI_INTENT_COLORS[props.intent] || "inherit"};
  text-align: right;
`;
export function AdmissionsCoordinatorsKPIPopoverContent({
  cellValue,
  data,
  kpiName,
  crp,
  intent,
}) {
  return (
    <PopoverContentWrapper>
      <ContentRow>
        <div> {`Total ${kpiName}:`} </div>
        <ValueContainer intent={intent}>{cellValue}</ValueContainer>
      </ContentRow>
      <ContentRow>
        <div> {`${kpiName} per day:`} </div>
        <ValueContainer intent={intent}>
          {floatVF({ value: data[crp.dailyRelatedField] })}
        </ValueContainer>
      </ContentRow>
      <LabelRow>
        <span>{"In "}</span>
        <b>{data.business_days_count}</b>
        <span>{" business days"}</span>
      </LabelRow>
    </PopoverContentWrapper>
  );
}
