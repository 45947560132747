import React from "react";
import { useStoreState } from "../../hooks/ep";
import AgTable from "../../components/AgTable";
import { AgColDefs, AgRows } from "../../components/AgTable/types";
import _ from "lodash";
import {
  ENROLLMENT_COORDINATOR_INDEX__ALL,
  EnrollmentCoordinatorIndex,
} from "../../store/model-ec-selection";
import { useRetentionAnalysisStudentsColDefs } from "./retention-analysis-student-coldefs";

const finalizeRowData = (rowData: AgRows): AgRows => {
  const finalizedRowData = rowData.map((row) => ({
    ...row,
    priority_scaled: row.enrollment_completion_priority / 100.0,
  }));
  return _.sortBy(finalizedRowData, "-enrollment_completion_priority");
};

export function RecentEnrollmentCompletionsUnit({
  selectedEnrollmentCoordinatorIndex,
  ...restProps
}: {
  selectedEnrollmentCoordinatorIndex: EnrollmentCoordinatorIndex;
}): JSX.Element {
  const rowData = useStoreState(
    (s) => s.recentEnrollmentCompletionStudents.rowData
  );

  const filteredRowData =
    selectedEnrollmentCoordinatorIndex !== ENROLLMENT_COORDINATOR_INDEX__ALL
      ? rowData.filter(
          (row) => row.EC_Index === selectedEnrollmentCoordinatorIndex
        )
      : rowData;
  const colDefs = useRecentEnrollmentCompletionStudentsColumnDefs();
  const initialCategoryFilters = {
    has_future_enrollment: {
      value: [false],
    },
  };
  const height = _.min([500, 64 + 64 * _.max([3, filteredRowData.length])]);
  return (
    <AgTable
      rowData={finalizeRowData(filteredRowData)}
      columnDefs={colDefs}
      categoryFilters={initialCategoryFilters}
      tableName={"recentEnrollmentCompletionStudents"}
      ribbonTitle={"Recent Enrollment Completions"}
      height={`${height}px`}
      {...restProps}
    />
  );
}

function useRecentEnrollmentCompletionStudentsColumnDefs(): AgColDefs {
  const colDefModifiers = [
    {
      headerName: "Days since Completion",
      field: "n_days_elapsed_since_max_enrollment_end_date",
      modifyField: "n_days_remaining_until_max_enrollment_end_date",
      headerTooltip:
        "Days that have passed since student's last enrollment completion",
    },
    {
      modifyField: "priority_scaled",
      headerTooltip:
        "Priority calculated from the days passed since a student's last enrollment completion multiplied by the student's future revenue potential",
    },
  ];
  return useRetentionAnalysisStudentsColDefs(colDefModifiers);
}
