import React, { useState } from "react";
import styled from "styled-components/macro";
import { Tab, Tabs } from "@blueprintjs/core";
import { ScoreFactors } from "./score-factors";
import { isValidChurnScore } from "./validate-churn-score";

export const PopConWrapper = styled.div`
  padding: 13px 24px 23px 24px;
  //height: 500px;
  max-height: 440px;
  width: 500px;
  overflow-y: auto;
`;

// export const PopConHeader = styled(H4)`
//   margin: 0;
// `;

const TAB_IDS = {
  scoreFactors: "score-factors",
};

export function ChuScoPopoverContent({ churnScore, id, sliceName }) {
  const defaultTabId = TAB_IDS.scoreFactors;
  const [selectedTabId, setSelectedTabId] = useState(defaultTabId);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };
  return (
    <PopConWrapper>
      <Tabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        renderActiveTabPanelOnly={true}
      >
        {isValidChurnScore(churnScore) && (
          <Tab
            id={TAB_IDS.scoreFactors}
            title="Factors"
            panel={<ScoreFactors id={id} sliceName={sliceName} />}
          />
        )}
      </Tabs>
    </PopConWrapper>
  );
}
