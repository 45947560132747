import { action } from "easy-peasy";
import "styled-components/macro";

export const setFactory = (name) =>
  action((state, payload) => {
    state[name] = payload;
  });
export const pushFactory = (name) =>
  action((state, payload) => {
    state[name].push(payload);
  });
export const onChangeFactory = (handleValueChange, constantValue) => (ev) => {
  ev.preventDefault();
  const value = constantValue ?? ev?.target?.value ?? ev?.currentTarget?.value;
  // console.log("onChangeFactory: value:", value);
  handleValueChange(value);
};
export const nfFloat = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
