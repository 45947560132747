import { Card, Elevation } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components/macro";

export const ContainerCard = styled(Card)`
  height: 100%;
  padding: 20px !important;

  & > .recommendations-unit-content--title {
    margin: 0 0 40px 0 !important;
    padding: 0 !important;
  }

  & > .recommendations-unit-content--body {
    display: flex;
    flex-wrap: wrap;
    gap: 64px;
  }
`;

export function Container({ children }): JSX.Element {
  return <ContainerCard elevation={Elevation.TWO}>{children}</ContainerCard>;
}
