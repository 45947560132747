import { createContextStore } from "easy-peasy";
import "styled-components/macro";

function model({ initialData, formStoreModelFactory }) {
  return formStoreModelFactory(initialData);
}

export const FormStore = createContextStore(model);

export function useFormStoreState() {
  return FormStore.useStoreState((s) => s);
}

export function useFormStoreActions() {
  return FormStore.useStoreActions((a) => a);
}
