import React, { useEffect } from "react";
import {
  DEFAULT_SELECTED_ENTITY_IDS,
  useSelectEntityId,
} from "../../store/model-entity-selection";
import { useSelectedCustomer } from "../../components/units/selection-unit";
import { SingleStudentCourseSchedulingTabContainerDiv } from "./single-student-course-scheduling-tab-container-div";
import { BasicUnit } from "../../components/units/basic-unit";
import { HistoryUnit } from "../../components/units/history-unit";
import { SingleStudentNextYearCourseScheduleRecommendationsUnit } from "./single-student-next-year-course-schedule-recommendations";
import { StudentOrdersHistoryUnit } from "../../components/units/orders-unit";
import { SingleStudentPropagateCourseScheduleIntoGeniusUnit } from "./single-student-propagate-course-schedule-into-genius";
import { CourseSchedulingSelectionUnit } from "./course-scheduling-selection-unit";

export function SingleStudentCourseSchedulingTab(props): JSX.Element {
  const setSelectedCustomerId = useSelectEntityId("student");
  useEffect(() => {
    return () => setSelectedCustomerId(DEFAULT_SELECTED_ENTITY_IDS.student);
  }, []);
  const customer = useSelectedCustomer();

  return (
    <SingleStudentCourseSchedulingTabContainerDiv>
      <CourseSchedulingSelectionUnit />
      <BasicUnit unitId={"basic-next-year-course-schedule-recommendations"} />
      <SingleStudentPropagateCourseScheduleIntoGeniusUnit />
      <SingleStudentNextYearCourseScheduleRecommendationsUnit />
      <StudentOrdersHistoryUnit
        unitId={"orders-history-next-year-course-schedule-recommendations"}
      />
      <HistoryUnit
        unitId={"history-next-year-course-schedule-recommendations"}
        departmentNamesInitialCategoryFilter={
          customer &&
          Array.from(
            new Set(
              customer.next_year_course_schedule_recommendations.map(
                (obj) => obj.DepartmentName
              )
            )
          )
        }
      />
    </SingleStudentCourseSchedulingTabContainerDiv>
  );
}
