import {
  TableModel,
  tableModelFactory,
  TableRow,
} from "../table-model-factory";
import { computed, Computed } from "easy-peasy";
import { StoreModel } from "../model";
import _ from "lodash";

export type VendorAssessmentMetricsModel = TableModel & {
  rowData: Computed<VendorAssessmentMetricsModel, TableRow[], StoreModel>;
};

export function getVendorAssessmentMetricsModel(): VendorAssessmentMetricsModel {
  return {
    ...tableModelFactory(
      "vendorAssessmentMetrics",
      "/ecp/vendor_assessment_metrics",
      (row) => row.canonical_course_vendor_name,
      [
        {
          name: "courseAssessmentMetrics",
          arity: "OneToMany",
          foreignName: "courseAssessmentMetrics",
          foreignField: "canonical_course_vendor_name",
          field: "canonical_course_vendor_name",
        },
        {
          name: "ecp_enrollment",
          arity: "OneToMany",
          foreignName: "ecp_enrollment",
          foreignField: "canonical_course_vendor_name",
          field: "canonical_course_vendor_name",
        },
      ]
    ),
    rowData: computed(
      [(state) => state.initialData, (state) => state.relatedRowsMap],
      (rowData, relatedRowsMap) =>
        rowData.map((row) => {
          const courses = relatedRowsMap[row.id]?.courseAssessmentMetrics ?? [];
          const courseIds = courses.map((v) => v.id);

          const enrollments = relatedRowsMap[row.id]?.ecp_enrollment ?? [];
          const enrollmentIds = enrollments.map((v) => v.id);

          return {
            ...row,
            courses,
            courseIds,
            num_courses: _.size(courses),
            enrollments,
            enrollmentIds,
            num_enrollments: _.size(enrollments),
          };
        })
    ),
  };
}
