import {
  GetRowId,
  TableModel,
  tableModelFactory,
  TableRow,
  TableRowId,
} from "../table-model-factory";
import { Actions, Computed, computed, State, Thunk, thunk } from "easy-peasy";
import {
  ActionPayload_ReeCustomerInteraction,
  submitUserAction__ree_customer_interaction,
  UserAction_ReeCustomerInteraction,
} from "../../user-actions/submit-user-action__ree_customer_interaction";
import _ from "lodash";
import { toaster } from "../../toaster";
import { UserAction } from "../../user-actions/user_actions";
import { StoreModel } from "../model";
import { Injections } from "../store-injections";
import moment from "moment-timezone";

export interface ReeCustomerInteractionModel
  extends Omit<
    TableModel<UserAction_ReeCustomerInteraction>,
    "rowIds" | "rowExists"
  > {
  handleReportCustomerInteraction: Thunk<
    ReeCustomerInteractionModel,
    ActionPayload_ReeCustomerInteraction,
    Injections,
    StoreModel
  >;
  byCustomerId: Computed<
    ReeCustomerInteractionModel,
    { [key: number]: UserAction[] },
    StoreModel
  >;
  customerId_to_latest: Computed<
    ReeCustomerInteractionModel,
    { [key: number]: UserAction },
    StoreModel
  >;
  rowIds: Computed<ReeCustomerInteractionModel, Set<TableRowId>, StoreModel>;
  rowExists: Computed<
    ReeCustomerInteractionModel,
    { (row: TableRow): boolean },
    StoreModel
  >;
}

export function getReeCustomerInteractionModel(): ReeCustomerInteractionModel {
  return {
    ...tableModelFactory<UserAction_ReeCustomerInteraction>(
      "ree_customerInteraction",
      "ree/customer_interactions",
      (row) => row.id
    ),
    handleReportCustomerInteraction: thunk(
      async (
        actions: Actions<ReeCustomerInteractionModel>,
        userActionPayload: ActionPayload_ReeCustomerInteraction,
        { getState }
      ) => {
        const state: State<ReeCustomerInteractionModel> = getState();
        // @ts-ignore
        const maxRowId = _.max([...state.rowIds]) ?? 0;
        // @ts-ignore
        const rowId = state.GET_ROW_ID(userActionPayload);
        const oldRow = state.getRow(rowId);
        const nextRowId = maxRowId + 1;
        actions.upsertRow({
          id: nextRowId,
          action_payload: userActionPayload,
        });
        try {
          const { data } = await submitUserAction__ree_customer_interaction(
            userActionPayload
          );
          toaster.success("Successfully reported student interaction", 2);
          actions.deleteRow(nextRowId);
          // @ts-ignore
          actions.upsertRow(data);
        } catch (e) {
          if (oldRow) {
            actions.upsertRow(oldRow);
          } else {
            actions.deleteRow(rowId);
          }
          toaster.warning(
            "Failed to report student interaction -- please try again"
          );
          throw Error();
        }
      }
    ),
    byCustomerId: computed(
      [(s) => s.initialData],
      (initialData: UserAction_ReeCustomerInteraction[]) =>
        _.groupBy(initialData, "action_payload.customer_snapshot.customer_id")
    ),
    customerId_to_latest: computed(
      [(s) => s.byCustomerId],
      (byCustomerId: { [key: string]: UserAction_ReeCustomerInteraction[] }) =>
        Object.fromEntries(
          Object.entries(byCustomerId).map(([customerId, itxns]) => [
            customerId,
            _.maxBy(itxns, "submitted_at"),
          ])
        )
    ),
    rowIds: computed(
      [(s): TableRow[] => s.initialData, (s): GetRowId => s.GET_ROW_ID],
      (initialData: TableRow[], GET_ROW_ID: GetRowId) =>
        new Set(
          initialData.map((row: UserAction_ReeCustomerInteraction) =>
            GET_ROW_ID(row)
          )
        )
    ),
    rowExists: computed(
      [(s): Set<TableRowId> => s.rowIds, (s): GetRowId => s.GET_ROW_ID],
      (rowIds: Set<TableRowId>, GET_ROW_ID: GetRowId) =>
        (row: UserAction_ReeCustomerInteraction) =>
          rowIds.has(GET_ROW_ID(row))
    ),
  };
}

const PACIFIC_TIME = "America/Los_Angeles";

export function happenedToday(itxnTimestamp?: string): boolean {
  if (_.isNull(itxnTimestamp) || _.isUndefined(itxnTimestamp)) {
    return false;
  }
  const itxnTimestampPT = moment(itxnTimestamp).tz(PACIFIC_TIME);
  const currentTimestampPT: moment.Moment = moment.utc().tz(PACIFIC_TIME);

  const minTimestampPT = getMinTimestampPT(currentTimestampPT);

  // console.log({
  //   itxnTimestamp,
  //   itxnTimestampPT: isoformat(itxnTimestampPT),
  //   currentTimestampPT: isoformat(currentTimestampPT),
  //   minTimestampPT: isoformat(minTimestampPT),
  // });
  return itxnTimestampPT >= minTimestampPT;
}

function getMinTimestampPT(currentTimestampPT: moment.Moment): moment.Moment {
  const currentHourIndexPT = currentTimestampPT.hour();
  console.log({ currentHourIndexPT });

  let ret = currentTimestampPT.clone();
  if (currentHourIndexPT < 4) {
    ret = ret.subtract(1, "day");
  }
  return ret.set({
    hour: 4,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
}
