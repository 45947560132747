import { Button, MenuItem, Position } from "@blueprintjs/core";
import React, { useLayoutEffect, useRef } from "react";
import { MultiSelect } from "@blueprintjs/select";
import "styled-components/macro";

const ENTER_RETURN_KEY_CODE = 13;

export type CallTopicCodeName =
  | "courses"
  | "routine_checkin"
  | "promo_offered"
  | "no_answer"
  | "pricing"
  | "disconnected_phone"
  | "left_voicemail"
  | "sor_vs_nonsor"
  | "student_performance"
  | "student_engagement"
  | "billing"
  | "academy"
  | "partners"
  | "ncaa"
  | "pacing"
  | "ap_courses"
  | "retaking_courses"
  | "open_orders"
  | "contact_info_incorrect"
  | "online_vs_brick_and_mortar"
  | "home_life"
  | "other";

export interface CallTopic {
  codeName: CallTopicCodeName;
  longName: string;
}

export const CallTopicsMS = MultiSelect.ofType<CallTopic>();

const DEFAULT_REASONS: CallTopic[] = [
  { codeName: "no_answer", longName: "Didn't Answer Phone" },
  { codeName: "left_voicemail", longName: "Left Voicemail" },
  { codeName: "disconnected_phone", longName: "Disconnected Phone Number" },
  { codeName: "courses", longName: "Courses" },
  {
    codeName: "online_vs_brick_and_mortar",
    longName: "Online vs. Brick-&-Mortar",
  },
  {
    codeName: "sor_vs_nonsor",
    longName: "SoR vs. Non-SoR Enrollment",
  },
  { codeName: "academy", longName: "Academy" },
  { codeName: "partners", longName: "Partners" },
  { codeName: "ncaa", longName: "NCAA" },
  { codeName: "pacing", longName: "Pacing" },
  { codeName: "pricing", longName: "Pricing" },
  { codeName: "billing", longName: "Billing" },
  { codeName: "ap_courses", longName: "AP Courses" },
  { codeName: "retaking_courses", longName: "Retaking Courses" },
  { codeName: "open_orders", longName: "Open Orders" },
  { codeName: "home_life", longName: "Home Life" },
  { codeName: "student_performance", longName: "Student Performance" },
  { codeName: "student_engagement", longName: "Student Engagement" },
  { codeName: "routine_checkin", longName: "Routine Check-In" },
  { codeName: "promo_offered", longName: "Promo Offered" },
  { codeName: "contact_info_incorrect", longName: "Incorrect Contact Info" },
  { codeName: "other", longName: "Other" },
];

export function CallTopicsInput({
  queryText,
  setQueryText,
  selectedItems,
  onAdd,
  onClear,
  onRemove,
  activeField,
  setActiveField,
}) {
  const callTopicInputRef = useRef(null);

  const handleFocus = () => {
    callTopicInputRef.current.focus();
    setActiveField("callTopics");
  };
  // const handleBlur = ev => {
  //   ev.preventDefault();
  //   callTopicInputRef.current.blur();
  //   setActiveField(null);
  // };

  const clearButton =
    selectedItems.length > 0 ? (
      <Button icon="cross" minimal={true} onClick={onClear} />
    ) : undefined;
  const isItemSelected = (r) => selectedItems.includes(r);
  const onItemSelect = (callTopic) => {
    if (isItemSelected(callTopic)) {
      onRemove(callTopic.codeName);
    } else {
      onAdd(callTopic);
    }
  };
  const onItemsSelect = (callTopics) => callTopics.map(onItemSelect);
  useLayoutEffect(() => {
    // noinspection JSUnresolvedFunction
    handleFocus();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      css={`
        .bp3-multi-select-popover .bp3-menu {
          //max-width: none !important;
          max-height: 220px !important;
          overflow-y: auto;
        }
      `}
    >
      <CallTopicsMS
        fill
        createNewItemFromQuery={undefined}
        onKeyDown={(ev) => {
          if (ev.keyCode === ENTER_RETURN_KEY_CODE) {
            ev.preventDefault();
          }
        }}
        resetOnSelect={true}
        createNewItemRenderer={null}
        openOnKeyDown={false}
        items={DEFAULT_REASONS}
        itemsEqual="codeName"
        itemDisabled={isItemSelected}
        itemPredicate={callTopicsPredicate}
        itemRenderer={callTopicItemRenderer(isItemSelected)}
        selectedItems={selectedItems}
        onItemSelect={onItemSelect}
        tagRenderer={callTopicTagRenderer}
        onItemsPaste={onItemsSelect}
        placeholder="Choose all that apply..."
        query={queryText}
        onQueryChange={setQueryText}
        // onFocus={handleFocus}
        popoverProps={{
          usePortal: false,
          minimal: true,
          position: Position.BOTTOM_LEFT,
        }}
        tagInputProps={{
          onFocus: handleFocus,
          name: "callTopics",
          autoFocus: true,
          inputRef: (r) => {
            callTopicInputRef.current = r;
          },
          leftIcon: "locate",
          large: true,
          onRemove: onRemove,
          rightElement: clearButton,
          tagProps: { large: false, minimal: true },
        }}
        // TODO: Center for IE11
        // css={`
        //   .bp3-popover-content .bp3-menu {
        //     width: ${width}px;
        //   }
        // `}
      />
    </div>
  );
}

const callTopicsPredicate = (
  query,
  callTopic: CallTopic,
  _index,
  exactMatch
) => {
  const normalizedTitle = callTopic.codeName.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return normalizedTitle.includes(normalizedQuery);
  }
};
const callTopicTagRenderer = (callTopic: CallTopic) => callTopic.longName;
const callTopicItemRenderer =
  (isItemSelected) =>
  (callTopic: CallTopic, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const isSelected = isItemSelected(callTopic);
    return (
      <MenuItem
        active={modifiers.active}
        icon={isSelected ? "tick" : "blank"}
        disabled={false}
        key={callTopic.codeName}
        // label={callTopic.longName}
        onClick={handleClick}
        text={callTopic.longName}
        shouldDismissPopover={false}
      />
    );
  };
