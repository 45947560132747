import {
  tableModelFactory,
  TableModel,
  TableRow,
} from "../table-model-factory";
import { Computed, computed } from "easy-peasy";
import _ from "lodash";
import { StoreModel } from "../model";

export type ReeEnrollmentModel = TableModel & {
  byCustomerId: Computed<
    ReeEnrollmentModel,
    { [key: number]: TableRow[] },
    StoreModel
  >;
};

export function getReeEnrollmentModel(): ReeEnrollmentModel {
  return {
    ...tableModelFactory(
      "ree_enrollment",
      "ree/churnrisk_student_enrollments",
      (row) => row.EnrollmentIndex
    ),
    byCustomerId: computed([(s) => s.initialData], (initialData) =>
      _.groupBy(initialData, "customer_id")
    ),
  };
}
