import React, { useEffect, useState } from "react";
import { Spinner } from "@blueprintjs/core";
import { OnCellClicked } from "../AgTable/types";
import { CellClickedEvent } from "@ag-grid-community/core/dist/es6/events";
import { EcpStudentTable } from "../../entities/ecp/ecp-student-entity";
import { EcpEnrollmentTable } from "../../entities/ecp/ecp-enrollment-entity";
import "styled-components/macro";
import { useEnsureData } from "../../helpers/use-ensure-data";
import _ from "lodash";
import versionedLocalStorageKey from "../../helpers/versionedLocalStorageKey";
import { lighten } from "polished";
import { FinalizeRowData } from "../AgTable/ag_table";

export const DEFAULT_STUDENT_ID = -1;
type StudentId = number;

const WHITELISTED_COL_IDS = [
  "student_flag__do_not_email",
  "student_flag__do_not_send_mia_emails",
];

export function EcpStudentEnrollmentsSplitview(props) {
  const loading = [
    useEnsureData("ecp_customerInteraction"),
    useEnsureData("ecp_enrollment"),
    useEnsureData("ecp_student"),
    useEnsureData("stars"),
    useEnsureData("student_attr"),
    useEnsureData("student_flag"),
  ];
  return _.max(loading) ? (
    <Spinner />
  ) : (
    <EcpStudentEnrollmentsSplitviewInner {...props} />
  );
}

function get_selected_student_lsk(viewId) {
  return versionedLocalStorageKey(
    `${viewId}--ecp-student-enrollment-splitview-page--selectedStudentId`
  );
}

function EcpStudentEnrollmentsSplitviewInner({
  finalizeStudentRowData,
  whitelistedStudentTagIds = null,
  studentActionColDefs = null,
  whitelistedEnrollmentTagIds = null,
  viewId,
}): JSX.Element {
  viewId = viewId || "";
  const selected_student_lsk = get_selected_student_lsk(viewId);
  const [selectedStudentId, setSelectedStudentId] = useState<number>(() => {
    const stored = localStorage.getItem(selected_student_lsk);
    return +(stored ?? DEFAULT_STUDENT_ID);
  });

  useEffect(() => {
    localStorage.setItem(selected_student_lsk, selectedStudentId.toString());
  }, [selectedStudentId, selected_student_lsk]);

  const onCellClicked_UpperSplit = (ev: CellClickedEvent) => {
    const studentRow = ev.data;
    const colDef = ev.colDef;
    const studentId = studentRow.id;
    // console.log({
    //   studentRow,
    //   colDef,
    //   studentId,
    //   selectedStudentId,
    //   DEFAULT_STUDENT_ID,
    // });
    if (!colDef.cellRenderer) {
      setSelectedStudentId((v) =>
        studentId === v ? DEFAULT_STUDENT_ID : studentId
      );
    }
  };

  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}
    >
      {/*<StudentIdSelect*/}
      {/*  selectedStudentId={selectedStudentId}*/}
      {/*  setSelectedStudentId={setSelectedStudentId}*/}
      {/*/>*/}
      <UpperSplit
        onCellClicked={onCellClicked_UpperSplit}
        selectedStudentId={selectedStudentId}
        finalizeRowData={finalizeStudentRowData}
        whitelistedTagIds={whitelistedStudentTagIds}
        actionColDefs={studentActionColDefs}
        tableNameSuffix={viewId === "ss-dash" ? "ss-dash" : null}
      />
      {selectedStudentId === DEFAULT_STUDENT_ID ? null : (
        <LowerSplit
          selectedStudentId={selectedStudentId}
          whitelistedTagIds={whitelistedEnrollmentTagIds}
          tableNameSuffix={viewId === "ss-dash" ? "ss-dash" : "students"}
        />
      )}
    </div>
  );
}

const TABLE_HEIGHT = "calc((100vh - 64px - 40px - 40px) / 2 - 20px - 48px)";

function UpperSplit({
  onCellClicked,
  selectedStudentId,
  finalizeRowData,
  whitelistedTagIds,
  actionColDefs,
  tableNameSuffix = null,
}: {
  selectedStudentId: number;
  onCellClicked: OnCellClicked;
  finalizeRowData: FinalizeRowData;
  whitelistedTagIds: string[] | null;
  actionColDefs: any;
  tableNameSuffix?: string;
}): JSX.Element {
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        & .ag-row[row-id="${selectedStudentId}"] {
          background-color: rgba(0, 21, 40, 0.7) !important;
          &.ag-row-hover {
            background-color: ${lighten(
              0.1,
              "rgba(0, 21, 40, 0.7)"
            )} !important;
          }
        }
      `}
    >
      <EcpStudentTable
        onCellClicked={onCellClicked}
        fullScreenEnabled={selectedStudentId === DEFAULT_STUDENT_ID}
        height={selectedStudentId === DEFAULT_STUDENT_ID ? null : TABLE_HEIGHT}
        finalizeRowData={finalizeRowData}
        whitelistedTagIds={whitelistedTagIds}
        actionColDefs={actionColDefs}
        whitelistedColIds={WHITELISTED_COL_IDS}
        tableNameSuffix={tableNameSuffix}
      />
    </div>
  );
}

function LowerSplit({
  selectedStudentId,
  whitelistedTagIds,
  tableNameSuffix = null,
}: {
  selectedStudentId: number;
  whitelistedTagIds: string[] | null;
  tableNameSuffix?: string;
}): JSX.Element {
  if (selectedStudentId !== DEFAULT_STUDENT_ID) {
    return (
      <EcpEnrollmentTable
        studentId={selectedStudentId}
        fullScreenEnabled={false}
        height={TABLE_HEIGHT}
        whitelistedTagIds={whitelistedTagIds}
        initialCategoryFilters={undefined}
        tableNameSuffix={
          tableNameSuffix
            ? `${tableNameSuffix}__${selectedStudentId}`
            : selectedStudentId
        }
      />
    );
  } else {
    return null;
  }
}
