import { useMe } from "../../hooks/useMe";
import React from "react";
import _ from "lodash";
import { Breadcrumbs } from "@blueprintjs/core";
import { Routes } from "../../pages-routes";

const BREADCRUMBS = [
  {
    href: "/ecp",
    icon: "predictive-analysis",
    text: "Student Value Maximization",
  },
  { href: "/ecp/ss-dash", icon: "headset", text: "Student Services Dashboard" },
  {
    href: "/ecp/loyalty",
    icon: "endorsed",
    text: "Loyalty",
  },
  {
    href: Routes.ecp_campaigns__customer_success,
    icon: "route",
    text: "Upsell Campaigns", // "Customer Success Campaigns"
  },
  {
    href: Routes.ecp_campaigns__new_customer_sales,
    icon: "tag",
    text: "Sales & Marketing Campaigns",
  },
  {
    href: Routes.ecp_campaigns_overview,
    icon: "envelope",
    text: "Campaign Overview",
  },
  {
    href: "/ecp/dc-dash",
    icon: "diagram-tree",
    text: "Department Chair Dashboard",
  },
  { href: "/ecp/students", icon: "learning", text: "Students" },
  { href: "/ecp/enrollments", icon: "book", text: "Enrollments" },
  { href: "/ecp/teachers", icon: "briefcase", text: "Teachers" },
  {
    href: "/ecp/interactions",
    icon: "chat",
    text: "Reported Student Interactions",
  },
  {
    href: "/ecp/automation-tracking",
    icon: "pulse",
    text: "Automation Tracking",
  },
  {
    href: "/ecp/data-validation",
    icon: "confirm",
    text: "Data Validation",
  },
  {
    href: Routes.ecp_customer360,
    icon: "layout-circle",
    text: "Student 360",
  },
  {
    href: Routes.ecp_nextCourseRecommendations,
    icon: "pivot-table",
    text: "Students for Next Course Recommendations",
  },
  {
    href: Routes.ecp_canonicalCourseStats,
    icon: "panel-stats",
    text: "Canonical Course Stats",
  },
  //
  {
    href: "/ree",
    icon: "repeat",
    text: "Student Re-Enrollment",
  },
  { href: "/ree/students", icon: "learning", text: "Students" },
  { href: "/ree/orders", icon: "credit-card", text: "Orders" },
  { href: "/ree/enrollments", icon: "book", text: "Enrollments" },
  { href: "/ree/interactions", icon: "chat", text: "Interactions" },
  {
    href: Routes.ac_kpis,
    icon: "timeline-line-chart",
    text: "Sales & Marketing KPIs",
  },
  {
    href: Routes.ac_daily_queue,
    icon: "time",
    text: "AC Daily Queue",
  },
  {
    href: Routes.retention_kpis,
    icon: "timeline-bar-chart",
    text: "Retention KPIs",
  },
  {
    href: Routes.ec_daily_queue,
    icon: "time",
    text: "EC Daily Queue",
  },
  {
    href: "/ecp/users",
    icon: "people",
    text: "Users",
  },
];

export function MyBreadcrumbs() {
  const me = useMe();

  return me.loading || !me.success ? null : <MyBreadcrumbsInner />;
}

function MyBreadcrumbsInner() {
  const pathname = window.location.pathname;
  const locationPathnameParts = pathname.split("/");
  // const pfx = locationPathnameParts[1];

  const items = BREADCRUMBS.filter((el) => {
    const elPathnameParts = el.href.split("/");
    let shouldKeep = true;
    _.range(locationPathnameParts.length).forEach((i) => {
      if (shouldKeep !== false && i < elPathnameParts.length) {
        shouldKeep = locationPathnameParts[i] === elPathnameParts[i];
      }
    });
    return shouldKeep;
  });

  return (
    <div
      css={`
        //position: absolute;
        //margin-top: 4px;
        //margin-left: 12px;
        display: flex;
        align-items: center;
        width: fit-content;
      `}
    >
      <Breadcrumbs
        // currentBreadcrumbRenderer={renderCurrentBreadcrumb}
        items={items}
      />
    </div>
  );
}
