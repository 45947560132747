import { useEffect } from "react";
import _ from "lodash";

type OptionT = null | undefined | string | number;
type NewValueType<D> = D | ((latestValue: D) => D);
type QPSelect<D> = (
  newValue: NewValueType<D>,
  updateType?: "replace" | "push" | "replaceIn" | "pushIn" | undefined
) => void;

export function useSyncSelectedWithQueryParams(
  allOptions: OptionT[],
  selectedOption: OptionT,
  selectOption: { (opt: OptionT): void },
  qpSelectedOption: OptionT,
  qpSelectOption: QPSelect<OptionT>
) {
  useEffect(() => {
    const defaultOption = allOptions[0];
    const qpIsUnset = _.isNil(qpSelectedOption);
    const qpIsSet = !qpIsUnset;
    const qpNeqSelected = qpSelectedOption !== selectedOption;
    const selectedOptionIsAccessible = allOptions.includes(selectedOption);
    const qpSelectedOptionIsAccessible = allOptions.includes(qpSelectedOption);
    const selectedOptionNeqDefault = selectedOption !== defaultOption;

    // console.log("In useSyncSelectedWithQueryParams: ", {
    //   selectedOption,
    //   qpSelectedOption,
    //   allOptions,
    //   qpIsSet,
    //   qpNeqSelected,
    //   qpSelectedOptionIsAccessible,
    //   selectedOptionNeqDefault,
    //   selectedOptionIsAccessible,
    //   qpIsUnset,
    //   defaultOption,
    // });

    if (allOptions.length === 0) {
    } else if (qpIsSet && qpNeqSelected && qpSelectedOptionIsAccessible) {
      selectOption(qpSelectedOption);
    } else if (qpIsSet && qpNeqSelected && !qpSelectedOptionIsAccessible) {
      qpSelectOption(undefined, "pushIn");
    } else if (selectedOptionNeqDefault && !selectedOptionIsAccessible) {
      qpSelectOption(defaultOption, "pushIn");
    } else if (qpIsUnset && selectedOptionIsAccessible) {
      qpSelectOption(selectedOption, "pushIn");
    }
  }, [
    selectedOption,
    selectOption,
    allOptions,
    qpSelectedOption,
    qpSelectOption,
  ]);
}
