import React from "react";
import { Redirect, Route } from "react-router";
import { useIsAuthorized } from "./helpers/useAuthorization";
import { ReeStudentTable } from "./entities/ree/ree-student-entity";
import useRedirect from "./hooks/useRedirect";
// import { NapoPage } from "./napo";
// import { RollupPage } from "./components/rollup/RollupPage";
// import { ManualPage } from "./components/ManualPage";
import { UserRole } from "./store/model-me";
import { Icon as BP3Icon } from "@blueprintjs/core";
import AppWrapper from "./components/wrapper/AppWrapper";
import { ReeCustomerInteractionsTable } from "./entities/ree/ree-customerInteraction-entity";
import { ReeOrderTable } from "./entities/ree/ree-order-entity";
import { ReeEnrollmentTable } from "./entities/ree/ree-enrollment-entity";
import { EcpStudentServicesDashboardPage } from "./pages/ecp-ss-dash-page";
import { EcpAutomationTrackingPage } from "./pages/ecp-automation-tracking-page";
import { LoyaltyPage } from "./pages/loyalty/loyalty-page";
import { EcpCustomerInteractionsPage } from "./pages/ecp-customerInteractions-page";
import { EcpStudentsPage } from "./pages/ecp-students-page";
import { EcpEnrollmentsPage } from "./pages/ecp-enrollments-page";
import { EcpTeachersPage } from "./pages/ecp-teachers-page";
import { SigninPage } from "./pages/signin-page";
import { EcpDepartmentChairDashboardPage } from "./pages/ecp-dc-dash-page";
import { EcpSourceDataIssuesPage } from "./pages/ecp-sourceDataIssues-page";
import { Customer360Page } from "./pages/customer360-page/customer360-page";
import { NextCourseRecommendationsPage } from "./NextCourseRecommendationsPage";
import { CanonicalCourseStatsPage } from "./CanonicalCourseStatsPage";
import { EcpCustomerSuccessCampaignPage } from "./pages/customer-success-campaigns/page";
import _ from "lodash";
import { NewCustomerSuccessCampaignPage } from "./pages/new-customer-sales-campaigns/page";
import { DevPage } from "./dev-page";
import { AdmissionsCoordinatorsKPIsDashboardPage } from "./pages/ac-kpis-dash-page";
import { AdmissionsCoordinatorDailyQueuePage } from "./pages/ac-daily-queue-page/ac-daily-queue-page";
import { EcpUsersPage } from "./pages/ecp-users-page";
import { EnrollmentCoordinatorDailyQueuePage } from "./pages/ec-daily-queue-page/ec-daily-queue-page";
import { BP3IconWithLegend } from "./components/custom-icon/icon-with-legend";
import { RetentionKPIsPage } from "./pages/retention-kpis-page/retention-kpis-page";
import { CourseSchedulingPage } from "./pages/course-scheduling-page/course-scheduling-page";
import { CampaignOverviewPage } from "./pages/campaign-overview/campaign-overview-page";
import { VendorAssessmentPage } from "./pages/vendor-assessment-page/vendor-assessment-page";

export enum Routes {
  ree_students = "/ree/students",
  ree_orders = "/ree/orders",
  ree_enrollments = "/ree/enrollments",
  ree_interactions = "/ree/interactions",
  //
  ecp_students = "/ecp/students",
  ecp_ss_dash = "/ecp/ss-dash",
  ecp_loyalty = "/ecp/loyalty",
  ecp_campaigns__customer_success = "/ecp/campaigns/customer-success",
  ecp_campaigns__new_customer_sales = "/ecp/campaigns/new-customer-sales",
  ecp_campaigns_overview = "/ecp/campaigns/overview",
  ecp_dc_dash = "/ecp/dc-dash",
  ecp_enrollments = "/ecp/enrollments",
  ecp_teachers = "/ecp/teachers",
  ecp_interactions = "/ecp/interactions",
  ecp_automationTracking = "/ecp/automation-tracking",
  ecp_dataValidation = "/ecp/data-validation",
  ecp_users = "/ecp/users",
  //
  root = "/",
  dev = "/dev",
  signin = "/signin",
  ecp_customer360 = "/ecp/customer360",
  ecp_nextCourseRecommendations = "/ecp/next-course-recommendations",
  ecp_canonicalCourseStats = "/ecp/canonical-course-stats",
  //
  ac_kpis = "/ecp/acs-kpis",
  ac_daily_queue = "/ecp/ac-daily-queue",
  retention_kpis = "/ecp/retention-kpis",
  ec_daily_queue = "/ecp/ec-daily-queue",
  DEFAULT = ree_students,
  //
  ecp_courseScheduling = "/ecp/course-scheduling",
  //
  vendor_assessment = "/ecp/vendor-assessment",
}

export const DEFAULT_ROUTES_BY_ROLE = {
  [UserRole.SAXECAP_DEV]: Routes.ecp_students,
  [UserRole.ADMIN_READ_WRITE]: Routes.ecp_students,
  [UserRole.ADMIN_READ_ONLY]: Routes.ecp_students,
  [UserRole.DEAN_OF_FACULTY]: Routes.ecp_dc_dash,
  [UserRole.ACADEMIC_DEPARTMENT_CHAIR]: Routes.ecp_dc_dash,
  [UserRole.ENROLLMENT_MANAGER]: Routes.ecp_ss_dash,
  [UserRole.ENROLLMENT_COORDINATOR]: Routes.ecp_ss_dash,
  [UserRole.COUNSELOR]: Routes.ecp_students,
  [UserRole.ADMISSIONS_COORDINATOR]: Routes.ac_daily_queue,
  [UserRole.DEFAULT]: Routes.ecp_students,
};

export const SAXECAP_ROUTES_PATT = /^\/.*$/;
export const ADMIN_ROUTES_PATT =
  /^((?!\/(dev|ecp\/(next-course-recommendations|canonical-course-stats|course-scheduling))).)*$/;
export const DEFAULT_ROUTES_PATT = /^signin$/;
export const DC_PATT =
  /^\/ecp\/(dc-dash|students|teachers|enrollments|interactions|signin|automation-tracking|campaigns\/customer-success)$/; // Department Chair
export const SS_PATT =
  /^((?!\/(dev|ecp\/(canonical-course-stats|dc-dash|users))).)*$/; // Student Services
export const EM_PATT = /^((?!\/(dev|ecp\/(canonical-course-stats|users))).)*$/;

export const AC_PATT = /^((?!\/(dev|ecp\/(canonical-course-stats|users))).)*$/;

export const COUNSELOR_PATT = /^\/ecp\/(students|signin)$/;

export const ROLE_ALLOWED_ROUTES_PATTS = {
  [UserRole.SAXECAP_DEV]: SAXECAP_ROUTES_PATT,
  [UserRole.ADMIN_READ_WRITE]: ADMIN_ROUTES_PATT,
  [UserRole.ADMIN_READ_ONLY]: ADMIN_ROUTES_PATT,
  [UserRole.DEAN_OF_FACULTY]: DC_PATT,
  [UserRole.ACADEMIC_DEPARTMENT_CHAIR]: DC_PATT,
  [UserRole.ENROLLMENT_MANAGER]: EM_PATT,
  [UserRole.ENROLLMENT_COORDINATOR]: SS_PATT,
  [UserRole.ADMISSIONS_COORDINATOR]: AC_PATT,
  [UserRole.COUNSELOR]: COUNSELOR_PATT,
  [UserRole.DEFAULT]: DEFAULT_ROUTES_PATT,
};
const SALES_AND_MARKETING_KPIS__READS__USER_EMAILS = [
  "dhansen@laurelsprings.com",
  "ayerganian@laurelsprings.com",
  "SGeyer@laurelsprings.com",
];

const COURSE_SCHEDULING__AUTHORIZED__USER_EMAILS = [
  "hmcclure@laurelsprings.com",
  "mviola@laurelsprings.com",
];

export const USER_EXTRA_ALLOWED_ROUTES_PATTS = {
  ...Object.fromEntries(
    SALES_AND_MARKETING_KPIS__READS__USER_EMAILS.map((userEmail) => [
      userEmail,
      /^\/ecp\/(acs-kpis)$/,
    ])
  ),
  ...Object.fromEntries(
    COURSE_SCHEDULING__AUTHORIZED__USER_EMAILS.map((userEmail) => [
      userEmail,
      /^\/ecp\/(course-scheduling)$/,
    ])
  ),
};

export const LEFTNAV_TAB_ORDERING = [
  Routes.ecp_ss_dash,
  Routes.ecp_dc_dash,
  Routes.ecp_campaigns__customer_success,
  Routes.ecp_loyalty,
  Routes.ecp_automationTracking,
];
const unsortedLeftNavMenuItems = [
  // {
  //   urlPath: Routes.ree_students,
  //   title: "Students",
  //   icon: <BP3Icon icon={"learning"} />,
  // },
  // {
  //   urlPath: Routes.ree_orders,
  //   title: "Orders",
  //   icon: <BP3Icon icon={"credit-card"} />,
  // },
  // {
  //   urlPath: Routes.ree_enrollments,
  //   title: "Enrollments",
  //   icon: <BP3Icon icon={"book"} />,
  // },
  // {
  //   urlPath: Routes.ree_interactions,
  //   title: "Reported Customer Interactions",
  //   icon: <BP3Icon icon={"chat"} />,
  // },
  {
    urlPath: Routes.ecp_ss_dash,
    title: "Student Services Dashboard",
    icon: <BP3Icon icon={"headset"} />,
  },
  {
    urlPath: Routes.ecp_dc_dash,
    title: "Department Chair Dashboard",
    icon: <BP3Icon icon={"diagram-tree"} />,
  },
  {
    urlPath: Routes.ecp_loyalty,
    title: "Loyalty",
    icon: <BP3Icon icon={"endorsed"} />,
  },
  {
    urlPath: Routes.ecp_campaigns__customer_success,
    title: "Upsell Campaigns", // "Customer Success Campaigns",
    icon: <BP3Icon icon={"route"} />,
  },
  {
    urlPath: Routes.ecp_campaigns__new_customer_sales,
    title: "Sales & Marketing Campaigns",
    icon: <BP3Icon icon={"tag"} />,
  },
  {
    urlPath: Routes.ecp_campaigns_overview,
    title: "Campaign Overview",
    icon: <BP3Icon icon={"envelope"} />,
  },
  {
    urlPath: Routes.ecp_students,
    title: "Students",
    icon: <BP3Icon icon={"learning"} />,
  },
  {
    urlPath: Routes.ac_kpis,
    title: "Sales & Marketing KPIs",
    icon: <BP3Icon icon={"timeline-line-chart"} />,
  },
  {
    urlPath: Routes.ac_daily_queue,
    title: "AC Daily Queue",
    icon: <BP3IconWithLegend icon={"time"} legend={"AC"} />,
  },
  {
    urlPath: Routes.retention_kpis,
    title: "Retention KPIs",
    icon: <BP3Icon icon={"timeline-bar-chart"} />,
  },
  {
    urlPath: Routes.ec_daily_queue,
    title: "EC Daily Queue",
    icon: <BP3IconWithLegend icon={"time"} legend={"EC"} />,
  },
  {
    urlPath: Routes.ecp_enrollments,
    title: "Enrollments",
    icon: <BP3Icon icon={"book"} />,
  },
  {
    urlPath: Routes.ecp_teachers,
    title: "Teachers",
    icon: <BP3Icon icon={"briefcase"} />,
  },
  {
    urlPath: Routes.vendor_assessment,
    title: "Vendor Assessment",
    icon: <BP3Icon icon={"chart"} />,
  },
  {
    urlPath: Routes.ecp_interactions,
    title: "Reported Student Interactions",
    icon: <BP3Icon icon={"chat"} />,
  },
  {
    urlPath: Routes.ecp_automationTracking,
    title: "Automation Tracking",
    icon: <BP3Icon icon={"pulse"} />,
  },
  {
    urlPath: Routes.ecp_dataValidation,
    title: "Data Validation",
    // TODO: Should have red badge with len(source_data_issues) in center in white. Badge can be Blueprint round Tag with forced 50/50 height/width
    // TODO: May want to change the icon to "issue" or "high-priority" as well (latter would require BP3 version bump)
    icon: <BP3Icon icon={"confirm"} />,
  },
  {
    urlPath: Routes.ecp_customer360,
    title: "Student 360",
    icon: <BP3Icon icon={"layout-circle"} />,
  },
  {
    urlPath: Routes.ecp_courseScheduling,
    title: "Course Scheduling",
    icon: <BP3Icon icon={"calendar"} />,
  },
  {
    urlPath: Routes.ecp_nextCourseRecommendations,
    title: "Students for Next Course Recommendations",
    icon: <BP3Icon icon={"pivot-table"} />,
  },
  // {
  //   urlPath: Routes.ecp_canonicalCourseStats,
  //   title: "Canonical Course Stats",
  //   icon: <BP3Icon icon={"panel-stats"} />,
  // },
  {
    urlPath: Routes.ecp_users,
    title: "Users",
    icon: <BP3Icon icon={"people"} />,
  },
];
export const leftNavMenuItems = _.sortBy(
  unsortedLeftNavMenuItems,
  unsortedLeftNavMenuItems.map((item, ix) => {
    const order = LEFTNAV_TAB_ORDERING.indexOf(item.urlPath);
    if (item.urlPath === Routes.ecp_users) return 99;
    const defaultOrder = unsortedLeftNavMenuItems.length + ix;
    return order === -1 ? defaultOrder : order;
  })
);

function MyRoute({ route, Component, protect }) {
  let WrappedComponent;
  if (protect) {
    WrappedComponent = (props) => {
      const auth = useIsAuthorized(route);
      const doneLoading = !auth.loading;
      const isDefinitelyNotAuthorized = doneLoading && !auth.isAuthorized;
      const isDefinitelyAuthorized = doneLoading && auth.isAuthorized;
      useRedirect(() => isDefinitelyNotAuthorized, Routes.signin);
      return isDefinitelyAuthorized ? (
        <AppWrapper hideLeftNav={false}>
          <Component {...props} />
        </AppWrapper>
      ) : null;
    };
  } else {
    WrappedComponent = (props) => (
      <AppWrapper hideLeftNav={false}>
        <Component {...props} />
      </AppWrapper>
    );
  }
  return <Route exact path={route} component={WrappedComponent} key={route} />;
}

export const AllRoutes = [
  MyRoute({
    route: Routes.signin,
    Component: SigninPage,
    protect: false,
  }),
  MyRoute({
    route: Routes.ree_students,
    Component: ReeStudentTable,
    protect: true,
  }),
  MyRoute({
    route: Routes.ree_orders,
    Component: ReeOrderTable,
    protect: true,
  }),
  MyRoute({
    route: Routes.ree_enrollments,
    Component: ReeEnrollmentTable,
    protect: true,
  }),
  MyRoute({
    route: Routes.ree_interactions,
    Component: ReeCustomerInteractionsTable,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_enrollments,
    Component: EcpEnrollmentsPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_students,
    Component: EcpStudentsPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_teachers,
    Component: EcpTeachersPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.vendor_assessment,
    Component: VendorAssessmentPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_ss_dash,
    Component: EcpStudentServicesDashboardPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_loyalty,
    Component: LoyaltyPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_campaigns__customer_success,
    Component: EcpCustomerSuccessCampaignPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_campaigns__new_customer_sales,
    Component: NewCustomerSuccessCampaignPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_campaigns_overview,
    Component: CampaignOverviewPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_dc_dash,
    Component: EcpDepartmentChairDashboardPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_interactions,
    Component: EcpCustomerInteractionsPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_automationTracking,
    Component: EcpAutomationTrackingPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_dataValidation,
    Component: EcpSourceDataIssuesPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_customer360,
    Component: Customer360Page,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_courseScheduling,
    Component: CourseSchedulingPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_nextCourseRecommendations,
    Component: NextCourseRecommendationsPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_canonicalCourseStats,
    Component: CanonicalCourseStatsPage,
    protect: true,
  }),
  // MyRoute({
  //   route: Routes.ecp_students,
  //   Component: EcpStudentTable,
  //   protect: true,
  // }),
  MyRoute({
    route: Routes.dev,
    Component: DevPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ac_kpis,
    Component: AdmissionsCoordinatorsKPIsDashboardPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ac_daily_queue,
    Component: AdmissionsCoordinatorDailyQueuePage,
    protect: true,
  }),
  MyRoute({
    route: Routes.retention_kpis,
    Component: RetentionKPIsPage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ec_daily_queue,
    Component: EnrollmentCoordinatorDailyQueuePage,
    protect: true,
  }),
  MyRoute({
    route: Routes.ecp_users,
    Component: EcpUsersPage,
    protect: true,
  }),
  <Route render={() => <Redirect to={Routes.signin} />} key="default_route" />,
];
