import * as duckdb from "@duckdb/duckdb-wasm/dist/duckdb-esm.js";
import { AsyncDuckDB } from "@duckdb/duckdb-wasm/dist/duckdb-esm.js";
process.release = { name: "node" };

export let duckdb_db: AsyncDuckDB = null;

export async function instantiateDuckdb() {
  // Import the ESM bundle (supports tree-shaking)

  // ..., or load the bundles from jsdelivr
  const JSDELIVR_BUNDLES = duckdb.getJsDelivrBundles();

  // Select a bundle based on browser checks
  const bundle = await duckdb.selectBundle(JSDELIVR_BUNDLES);
  // Instantiate the asynchronus version of DuckDB-wasm
  const worker = new Worker(bundle.mainWorker!);
  const logger = new duckdb.ConsoleLogger();
  const db = new duckdb.AsyncDuckDB(logger, worker);
  await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
  return db;
}

instantiateDuckdb()
  .then((db) => {
    console.log("db", db);
    duckdb_db = db;
  })
  .then(() => {
    console.log("duckdb_db", duckdb_db);
  });
