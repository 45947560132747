import { useLoyaltyCommunicationsTitle } from "./titles";
import { Tab } from "@blueprintjs/core";
import React from "react";
import "styled-components/macro";
import { LOYALTY_TAB_IDS } from "./loyalty-table-tabs-unit";
import { LoyaltyCommunicationsTable } from "../../entities/ecp/loyalty-communications";

export function useLoyaltyCommunicationsTab() {
  const title = useLoyaltyCommunicationsTitle();
  return (
    <Tab
      id={LOYALTY_TAB_IDS.communications}
      title={title}
      panel={
        <LoyaltyCommunicationsTable
          onlyLoyaltyComms
          height={"calc(100vh - 64px - 40px - 40px - 46px - 48px)"}
          tableNameSuffix="loyalty"
        />
      }
    />
  );
}
