import { ReeStudentTable } from "../ree/ree-student-entity";
import { ReeOrderTable } from "../ree/ree-order-entity";
import { ReeEnrollmentTable } from "../ree/ree-enrollment-entity";
import { assertType } from "../../helpers/assertions";
import { UserRole } from "../../store/model-me";
import { EcpStudentTable } from "../ecp/ecp-student-entity";
import { EcpEnrollmentTable } from "../ecp/ecp-enrollment-entity";
import { EcpTeachercourseTable } from "../ecp/ecp-teacherCourse-entity";
import { EcpTeacherTable } from "../ecp/ecp-teacher-entity";
import { LoyaltyStudentsTable } from "../ecp/loyalty-students";
import { LoyaltyCommunicationsTable } from "../ecp/loyalty-communications";
import { RetentionKPIsPageInner } from "../../pages/retention-kpis-page/retention-kpis-page";
import { CourseAssessmentMetricsTable } from "../../pages/vendor-assessment-page/course-assessment-metrics-table";
import { VendorAssessmentMetricsTable } from "../../pages/vendor-assessment-page/vendor-assessment-metrics-table";

interface EntityTableComponentType {
  (props: any): JSX.Element;
}

export type EntityName =
  | "ree_student"
  | "ree_order"
  | "ree_enrollment"
  | "ecp_student"
  | "ecp_enrollment"
  | "ecp_teacher"
  | "ecp_teacher_enrollment"
  | "ecp_teacher_teacherCourse"
  | "ecp_teacherCourse"
  | "loyaltyStudents"
  | "loyaltyCommunications"
  | "retentionKpis"
  | "courseAssessmentMetrics"
  | "vendorAssessmentMetrics";

export const ALL_ENTITY_NAMES: EntityName[] = [
  "ree_student",
  "ree_order",
  "ree_enrollment",
  "ecp_student",
  "ecp_enrollment",
  "ecp_teacher",
  "ecp_teacherCourse",
  "loyaltyStudents",
  "loyaltyCommunications",
  "retentionKpis",
];

export function isAuthorizedForEntity(
  entityName: EntityName,
  myRole: UserRole,
  isBackendRequest: boolean = false
): boolean {
  return true;
}

const ENTITY_TABLE_COMPONENTS: {
  [name in EntityName]: EntityTableComponentType;
} = {
  ree_student: ReeStudentTable,
  ree_order: ReeOrderTable,
  ree_enrollment: ReeEnrollmentTable,
  ecp_student: EcpStudentTable,
  ecp_enrollment: EcpEnrollmentTable,
  ecp_teacherCourse: EcpTeachercourseTable,
  ecp_teacher: EcpTeacherTable,
  ecp_teacher_enrollment: EcpEnrollmentTable,
  ecp_teacher_teacherCourse: EcpTeachercourseTable,
  loyaltyStudents: LoyaltyStudentsTable,
  loyaltyCommunications: LoyaltyCommunicationsTable,
  retentionKpis: RetentionKPIsPageInner,
  courseAssessmentMetrics: CourseAssessmentMetricsTable,
  vendorAssessmentMetrics: VendorAssessmentMetricsTable,
};

export function getEntityTableComponent(entityName: EntityName) {
  assertType<EntityName>(entityName);
  return ENTITY_TABLE_COMPONENTS[entityName];
}
