import React from "react";
import { useEnsureData } from "./helpers/use-ensure-data";
import { AgColDef } from "./components/AgTable/types";
import { TableRow } from "./store/table-model-factory";
import { Spinner } from "@blueprintjs/core";
import { useStoreState } from "./hooks/ep";
import _ from "lodash";
import AgTable from "./components/AgTable";
import { getCountColDef } from "./entities/helpers/relation-agg-cr";
import {
  getStudentBuzzUrl,
  getStudentGeniusUrl,
} from "./entities/ecp/ecp-student-entity";
export function NextCourseRecommendationsPage() {
  const loading = _.max([
    useEnsureData("ecp_student"),
    useEnsureData("ecp_enrollment"),
    useEnsureData("stars"),
    useEnsureData("student_flag"),
    useEnsureData("student_attr"),
    useEnsureData("ecp_customerInteraction"),
    useEnsureData("nextCourseRecos"),
  ]);
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
      `}
    >
      {loading ? <Spinner /> : <Content />}
    </div>
  );
}

function Content() {
  const rowData = useRowData();
  const colDefs = useColDefs();

  return <AgTable rowData={rowData} tableName={"xyz"} columnDefs={colDefs} />;
}

function useRowData(): TableRow[] {
  let rowData = useStoreState(
    (s) => s.ecp_student.rowDataWithStudentFlagFieldsAndStudentAttrFields
  );
  rowData = rowData.map((student) => ({
    ...student,
    n_next_course_recos: student.next_course_recos?.length ?? 0,
    n_next_course_reco_depts: _.uniqBy(
      student.next_course_recos ?? [],
      "DepartmentName"
    ).length,
  }));
  return rowData;
}

function useColDefs(): AgColDef[] {
  return [
    {
      headerName: "Student Name",
      field: "customer_name",
      width: 150,
      suppressSizeToFit: true,
      tooltipField: "customer_name",
      type: "linkedTextColumn",
      filter: "categoryFilterer",
      // __linkField: "Website",
      __linkValueGetter: ({ data }) =>
        `/ecp/customer360?student=${encodeURIComponent(data.StudentIndex)}`,
      __linkIsInternal: true,
      __linkIsNotAnEmail: true,
    },
    {
      headerName: "Genius Stu",
      field: "StudentIndex",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentGeniusUrl,
      __linkIsNotAnEmail: true,
      width: 97,
    },
    {
      headerName: "Buzz Stu",
      field: "LMSID",
      type: "linkedTextColumn",
      __linkValueGetter: (params) => getStudentBuzzUrl(params),
      __linkIsNotAnEmail: true,
    },
    {
      headerName: "Genius Status",
      field: "Status",
      type: "categoryColumn",
      width: 100,
    },
    {
      headerName: "# Next Course Recommendations",
      field: "n_next_course_recos",
      type: "integerColumn",
      width: 100,
    },
    {
      headerName: "# Next Course Reco Departments",
      field: "n_next_course_reco_depts",
      type: "integerColumn",
      width: 100,
    },
    getCountColDef({
      baseEntityName: "ecp_student",
      headerName: "Enr",
      field: "enrollmentIds",
      entityName: "ecp_enrollment",
      headerTooltip: "Number of active enrollments",
      filter: "categoryFilterer",
      cellClass: "react-rendered-cell ag-numeric-cell",
      actualField: "num_enrollments",
      type: "integerColumn",
    }),
    {
      headerName: "Grade Level",
      field: "GradeLevel",
      type: "integerColumn",
      filter: "categoryFilterer",
      cellClass: "ag-numeric-cell",
      width: 87,
    },
    {
      headerName: "Latest Activity",
      field: "buzz_last_activity_date",
      type: "dateColumn",
      width: 125,
    },
    {
      headerName: "Avg Grade",
      field: "curr_avg_grade",
      type: "percentageColumn",
      width: 97,
    },
    {
      headerName: "Max Enr End Date",
      field: "max_enrollment_end_date",
      type: "dateColumn",
      width: 122,
    },
    {
      headerName: "FC 100% Completion Date",
      field: "forecasted_100p_completion_date",
      type: "dateColumn",
      width: 122,
      headerTooltip:
        "The latest forecasted 100% completion date among the student's active enrollments (based on current assignment completion pace for each enrollment)",
    },
    {
      headerName: "% Behind",
      field: "pct_behind",
      type: "percentageColumn",
      width: 106,
    },
    {
      headerName: "Latest Submission",
      field: "MAX(latest_buzz_submission_timestamp)",
      type: "dateColumn",
      width: 125,
    },
    {
      headerName: "Tenure [years]",
      field: "n_schoolyears_enrolled",
      type: "floatColumn",
      headerTooltip: "# of schoolyears enrolled with LSS",
      width: 100,
    },
    {
      headerName: "SoR",
      field: "SchoolRecord",
      type: "booleanColumn",
    },
    {
      headerName: "NCAA",
      field: "IsNCAA",
      type: "booleanColumn",
    },
    {
      headerName: "Academy",
      field: "AcademyStudent",
      type: "booleanColumn",
    },
    {
      headerName: "Loyalty Eligible",
      field: "LoyaltyEligible",
      type: "booleanColumn",
      width: 110,
    },
    {
      headerName: "Loyalty21 Re-Enrolled?",
      field: "is_loyalty21_reenrolled",
      type: "booleanColumn",
      width: 131,
      hide: true,
    },
    {
      headerName: "Loyalty22 Re-Enrolled?",
      field: "is_loyalty22_reenrolled",
      type: "booleanColumn",
      width: 131,
    },
    {
      headerName: "Loyalty21 Status",
      field: "loyalty21_status",
      type: "categoryColumn",
      width: 110,
      hide: true,
    },
    {
      headerName: "May Not Return",
      field: "MaynotReturn",
      type: "booleanColumn",
    },
    {
      headerName: "Not Returning",
      field: "NotReturning",
      type: "booleanColumn",
    },
    {
      headerName: "Enrolling After Loyalty",
      field: "EnrollingafterLoyalty",
      type: "booleanColumn",
    },
    {
      headerName: "Retention Notes",
      field: "RetentionNotes",
      type: "textColumn",
      width: 300,
      suppressSizeToFit: true,
      tooltipField: "RetentionNotes",
      cellClass: ["ellipses-tooltip-cell"],
    },
    {
      headerName: "Admissions Coordinator",
      field: "AdmissionsCoordinator",
      type: "categoryColumn",
    },
    {
      headerName: "Academic Advisor",
      field: "AcademicAdvisor",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "AcademicAdvisor",
    },
    {
      headerName: "Counselor",
      field: "Counselor",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "Counselor",
    },
    {
      headerName: "Student Services",
      field: "StudentServices",
      type: "categoryColumn",
    },
    {
      headerName: "Student Phone",
      field: "Phone",
      type: "textColumn",
      tooltipField: "Phone",
    },
    {
      headerName: "Student Email",
      field: "Email",
      type: "textColumn",
      tooltipField: "Email",
    },
    {
      headerName: "Guardian1 Phone",
      field: "Guardian1Phone",
      type: "textColumn",
      tooltipField: "Guardian1Phone",
    },
    {
      headerName: "Guardian1 Email",
      field: "Guardian1Email",
      type: "textColumn",
      tooltipField: "Guardian1Email",
    },
  ];
}
