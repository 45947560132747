import React from "react";
import { Spinner } from "@blueprintjs/core";
import AgTable from "../../components/AgTable";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import { useMyRole } from "../../entities/helpers/authorization";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { useStoreState } from "../../hooks/ep";
import { ENROLLMENT_COORDINATOR_INDEX__ALL } from "../../store/model-ec-selection";

export function RetentionKPIsPage({ ...props }): JSX.Element {
  const loading = useEnsureData("retentionKpis");

  return loading ? <Spinner /> : <RetentionKPIsPageInner {...props} />;
}
export function RetentionKPIsPageInner({ ...restProps }): JSX.Element {
  let rowData = useStoreState((s) => s.retentionKpis.rowData);
  rowData = rowData.filter((row) => row.SoR === "Overall");
  const pinnedTotalsRow =
    [
      rowData.find((row) => row.EC_Index === ENROLLMENT_COORDINATOR_INDEX__ALL),
    ] || [];
  const filteredRowData = rowData
    .filter(
      (row) =>
        row.n_recent_completion_students > 0 ||
        row.n_upcoming_renr_potential_students > 0
    )
    .filter((row) => row.EC_Index !== ENROLLMENT_COORDINATOR_INDEX__ALL);
  const colDefs = useRetentionKPIsColDefs();
  return (
    <AgTable
      rowData={filteredRowData}
      columnDefs={colDefs}
      tableName={"retentionKPIs"}
      ribbonTitle={"Retention KPIs"}
      bottomPinnedRows={pinnedTotalsRow}
      {...restProps}
    />
  );
}

function useRetentionKPIsColDefs(): AgColDefs {
  const myRole = useMyRole();

  let ret = [
    {
      headerName: "EC Index",
      field: "EC_Index",
      type: "integerIdentifierColumn",
      hide: true,
    },
    {
      headerName: "Enrollment Coordinator",
      field: "EC_FullName",
      type: "textColumn",
    },
    {
      headerName: "# Students Completing Courses in Next 45 Days",
      field: "n_upcoming_renr_potential_students",
      type: "integerColumn",
      headerTooltip: "Number of students completing courses in next 45 days",
      width: 220,
    },
    {
      headerName:
        "% Students Completing Courses in Next 45 Days who Have Not Re-Enrolled",
      field: "pct_upcoming_renr_potential_students__not_reenrolled",
      type: "percentageColumn",
      headerTooltip:
        "Percentage of students completing courses in next 45 days who have not re-enrolled",
      width: 320,
    },
    {
      headerName: "# Students in Past 365 Days Who Have Not Re-Enrolled",
      field: "n_recent_completion_students",
      type: "integerColumn",
      headerTooltip:
        "Number of students in past 365 days who have not re-enrolled",
      width: 250,
    },
    {
      headerName: "% Students in Past 365 Days Who Have Not Re-Enrolled",
      field: "pct_recent_completion_students__not_reenrolled",
      type: "percentageColumn",
      headerTooltip:
        "Percentage of students in past 365 days who have not re-enrolled",
      width: 250,
    },
    {
      headerName: "# Students Re-Enrolled Within Past 365 Days",
      field: "n_renr_stus_within_past_1_to_365_days",
      type: "integerColumn",
      tooltipField: "Students that have re-enrolled within the past 365 days",
      hide: true,
    },
    {
      headerName: "# Students Actively Enrolled Within Past 366 to 730 Days",
      field: "n_active_stus_within_past_366_to_730_days",
      type: "integerColumn",
      tooltipField:
        "Students actively enrolled 366-730 days ago that were not in 12th grade at the time",
      hide: true,
    },
    {
      headerName: "Overall Churn Rate",
      field: "retention_churn_rate_current_year",
      type: "percentageColumn",
      headerTooltip:
        "Ratio of students that have re-enrolled within the past 365 days from the total of students actively enrolled 366-730 days ago, excluding students in 12th grade",
    },
  ] as AgColDef[];

  ret = ret
    .filter((cd) => !!cd)
    .filter((cd) => {
      // @ts-ignore
      return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
    });
  return ret;
}
