import { AgColDefs } from "../../components/AgTable/types";

export const HIDDEN_COL_IDS_BY_LOYALTY_YEAR = {
  "2021": ["n_re_enrollments"],
  "2022": [
    // "nuniq_students",
    "pct_opened_by_TO",
    "incremental_n_re_enrollments",
  ],
};
export const LOYALTY_STATISTICS_UNIT_TABLE_COLDEFS: AgColDefs = [
  {
    field: "email_type_code",
    headerName: "Email Type Code",
    type: "categoryColumn",
    hide: true,
  },
  {
    field: "email_type_name",
    headerName: "Email Type",
    type: "categoryColumn",
    pin: "left",
  },
  {
    field: "nuniq_students",
    headerName: "# Students Targeted",
    type: "integerColumn",
    headerTooltip:
      "The number of distinct students targeted by Loyalty Re-Enrollment emails",
  },
  {
    field: "pct_opened_by_TO",
    headerName: "% Opened",
    type: "percentageColumn",
    headerTooltip:
      "The percentage of Loyalty Re-Enrollment emails that have been opened by their intended recipient (student/parent)",
  },
  {
    field: "n_re_enrollments",
    headerName: "# Re-Enrollments",
    type: "integerColumn",
    headerTooltip: "The number of students who have re-enrolled",
  },
  {
    field: "incremental_n_re_enrollments",
    headerName: "# Incremental Re-Enrollments",
    type: "integerColumn",
    headerTooltip: [
      `For each campaign email wave/type: The number of students that subsequently re-enrolled before the next campaign email wave. `,
      `For example, the value below for "Stu: 2021 Loyalty Academy 2" is the number of students who received a "Stu: 2021 Loyalty Academy 2" email, `,
      `then re-enrolled before the next campaign email wave, during which they would have been sent "Stu: 2021 Loyalty Academy 3" emails if they had not yet re-enrolled. `,
      `The value for "Overall" is the total number of loyalty21-eligible students that have been re-enrolled (regardless of whether they were emailed).`,
    ].join(""),
  },
  // {
  //   field: "n_in_progress",
  //   headerName: "# In Progress",
  //   type: "integerColumn",
  //   headerTooltip:
  //     "The number of targeted students that are now in the process of being re-enrolled",
  // },
];
