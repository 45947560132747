import {
  configCgnXY_CustomerSuccess_Stats,
  CustomerSuccess_CampaignStats,
  CustomerSuccess_CampaignStatsEndpoint,
} from "./common";
import { useFullScreenModeEnabled } from "../../store/model-misc";
import { useBoolean } from "../../helpers/useBoolean";
import { TableTabsUnit } from "./table-tabs-unit";
import React, { useState } from "react";
import { StatsUnit } from "./stats-unit";
import { Button, H5, Icon, Intent } from "@blueprintjs/core";
import _ from "lodash";

export function EcpCustomerSucessCampaignPageInner({
  cgnXYstatsData,
  customerSuccessCampaignStatsConfig,
}: {
  cgnXYstatsData: CustomerSuccess_CampaignStatsEndpoint;
  customerSuccessCampaignStatsConfig: configCgnXY_CustomerSuccess_Stats[];
}) {
  const fullscreenOff = !useFullScreenModeEnabled();
  const commsTableHidden = useBoolean(true);
  const [cgnStatsHidden, setCgnStatsHidden] = useState(false);
  const showCgnStats = fullscreenOff && !cgnStatsHidden;
  const showCommsTable = !showCgnStats && !commsTableHidden.value;

  const campaignStatsSorted = _.orderBy(customerSuccessCampaignStatsConfig, [
    "order",
    "cgnXY_codename",
  ]);

  const tableTabsProps = showCommsTable
    ? {
        cgnStatsHidden,
        height:
          "calc(max(400px, 100vh - 40px - 40px - 40px - 76px - 64px - 48px - 262px))",
      }
    : { cgnStatsHidden };
  return (
    <div
      css={`
        width: 100%;
        height: fit-content;
      `}
    >
      <div
        css={`
          width: 100%;
          height: fit-content;
          display: flex;
          flex-wrap: wrap;
          gap: 40px;
          justify-content: space-between;
          margin-bottom: 40px;
        `}
      >
        <div
          css={`
            width: 100%;
            height: fit-content;
            display: flex;
            flex-wrap: wrap;
            justify-content: right;
          `}
        >
          <Button
            rightIcon={
              <Icon
                icon={cgnStatsHidden ? "expand-all" : "collapse-all"}
                iconSize={Icon.SIZE_STANDARD}
              />
            }
            minimal
            interactive
            large
            intent={cgnStatsHidden ? Intent.SUCCESS : Intent.DANGER}
            onClick={() => setCgnStatsHidden(!cgnStatsHidden)}
          >
            <H5 css={"padding-top: 5px;"}>
              {cgnStatsHidden ? "Show" : "Hide"}
              {" all campaign stats "}
            </H5>
          </Button>
        </div>
        {showCgnStats &&
          campaignStatsSorted.map((value, index) => {
            if (value.__isHidden) {
              return null;
            }

            const rowData: CustomerSuccess_CampaignStats[] =
              cgnXYstatsData[value.cgnXY_codename];
            const cardTitle = value.cgnXY_title;

            return (
              <StatsUnit rowData={rowData} cardTitle={cardTitle} key={index} />
            );
          })}
      </div>
      <TableTabsUnit {...tableTabsProps} />
    </div>
  );
}
