import { Button, H4, MenuItem, Spinner } from "@blueprintjs/core";
import React from "react";
import { Select } from "@blueprintjs/select";
import {
  EntityId,
  SelectEntityIdOfType,
} from "../../store/model-entity-selection";
import {
  ALL_LOYALTY_YEARS,
  isValidLoyaltyYear,
  selectableLoyaltyYearItemPredicate,
  useLoyaltyYearSelection,
  useSelectDefaultLoyaltyYearWhenSelectedIsNotValid,
} from "./loyalty-year-helpers";

export function LoyaltyYearSelectionHeader() {
  return (
    <H4 className={"loyalty-year-selection-header"}>
      {"Year:"}
      <LoyaltyYearSelect />
    </H4>
  );
}

export function LoyaltyYearSelect() {
  const [selectedLoyaltyYear, selectLoyaltyYear] = useLoyaltyYearSelection();

  useSelectDefaultLoyaltyYearWhenSelectedIsNotValid(
    selectedLoyaltyYear,
    selectLoyaltyYear
  );

  return isValidLoyaltyYear(selectedLoyaltyYear) ? (
    <LoyaltyYearSelectInner
      selectedLoyaltyYear={selectedLoyaltyYear}
      selectLoyaltyYear={selectLoyaltyYear}
    />
  ) : (
    <Spinner />
  );
}

export function LoyaltyYearSelectInner({
  selectedLoyaltyYear,
  selectLoyaltyYear,
}: {
  selectedLoyaltyYear: EntityId;
  selectLoyaltyYear: SelectEntityIdOfType;
}) {
  return (
    <Select
      large
      itemPredicate={selectableLoyaltyYearItemPredicate}
      itemRenderer={itemRenderer}
      items={ALL_LOYALTY_YEARS}
      onItemSelect={selectLoyaltyYear}
    >
      <Button
        minimal
        text={<H4>{selectedLoyaltyYear}</H4>}
        rightIcon="caret-down"
        css={`
          .bp3-button-text > * {
            margin: 0 !important;
          }
        `}
      />
    </Select>
  );
}

export function itemRenderer(loyaltyYear, { handleClick, modifiers }) {
  return (
    <MenuItem
      active={false}
      key={loyaltyYear}
      label={""}
      onClick={handleClick}
      text={loyaltyYear}
    />
  );
}
