import { Classes, Spinner } from "@blueprintjs/core";
import React, { useMemo } from "react";
import { AgColDef } from "../../components/AgTable/types";
import { renderHeaderCell } from "./loyalty-statistics-unit-table-header";
import {
  HIDDEN_COL_IDS_BY_LOYALTY_YEAR,
  LOYALTY_STATISTICS_UNIT_TABLE_COLDEFS,
} from "./loyalty-statistics-unit-table-coldefs";
import { useStoreState } from "../../hooks/ep";
import { useEnsureData } from "../../helpers/use-ensure-data";
import "styled-components/macro";
// eslint-disable-next-line
import styled from "styled-components";
import { LoyaltyStatisticsUnitTableBodyRow } from "./loyalty-statistics-unit-table-body";
import { useSelectedLoyaltyYear } from "./loyalty-year-helpers";

export function LoyaltyStatisticsUnitTable(): JSX.Element {
  const loading = useEnsureData("loyaltyStatistics");
  return loading ? <Spinner /> : <LoyaltyStatisticsUnitTableInner />;
}

function useColDefs(selectedLoyaltyYear: string): AgColDef[] {
  return useMemo(
    () =>
      LOYALTY_STATISTICS_UNIT_TABLE_COLDEFS.map((cd) => ({
        ...cd,
        hide:
          cd.hide ||
          HIDDEN_COL_IDS_BY_LOYALTY_YEAR[selectedLoyaltyYear].includes(
            cd.field
          ),
      })),
    [selectedLoyaltyYear]
  );
}

export function LoyaltyStatisticsUnitTableInner(): JSX.Element {
  const selectedLoyaltyYear = useSelectedLoyaltyYear();
  const rowData =
    useStoreState(
      (s) => s.loyaltyStatistics.byLoyaltyYear[selectedLoyaltyYear]
    ) || [];

  const colDefs = useColDefs(selectedLoyaltyYear);
  const shownColDefs = useMemo(() => colDefs.filter(isColumnShown), [colDefs]);
  return (
    <StyledTableWrapper>
      <StyledTable className={Classes.HTML_TABLE}>
        <thead>
          <tr>{shownColDefs.map(renderHeaderCell)}</tr>
        </thead>
        <tbody>
          {rowData.map((row) => (
            <LoyaltyStatisticsUnitTableBodyRow
              key={row.id}
              row={row}
              shownColDefs={shownColDefs}
            />
          ))}
        </tbody>
      </StyledTable>
    </StyledTableWrapper>
  );
}

const StyledTableWrapper = styled.div`
  height: 400px;
  width: fit-content;
  overflow-y: auto;
`;

const StyledTable = styled.table`
  & > tbody > tr > td {
    &.coltype-integerColumn,
    &.coltype-floatColumn,
    &.coltype-percentageColumn {
      text-align: right;
    }
  }
`;

function isColumnShown(cd: AgColDef): boolean {
  return !cd.hide;
}
