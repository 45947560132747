import {
  Cgn10a_CampaignStats,
  Cgn4a_CampaignStats,
  Cgn5a_CampaignStats,
  Cgn9a_CampaignStats,
} from "./common";
import { useFullScreenModeEnabled } from "../../store/model-misc";
import { useBoolean } from "../../helpers/useBoolean";
import { StatsUnit } from "./stats-unit";
import { TableTabsUnit } from "./table-tabs-unit";
import React from "react";
import _ from "lodash";

const CGN4A_CARD_TITLE = "Hot Lead Outreach Stats";
const CGN5A_CARD_TITLE = "Cold Lead Outreach Stats";
const CGN9A_CARD_TITLE = "Cold Lead Outreach V2 Stats";
const CGN10A_CARD_TITLE = "Uncontacted Lead Outreach Stats";
const CGN18A_CARD_TITLE = "Inactive Lead Summer School Outreach";

export function NewCustomerSuccessCampaignPageInner({
  cgn4aData,
  cgn5aData,
  cgn9aData,
  cgn10aData,
  cgn18aData,
}: {
  cgn4aData: Cgn4a_CampaignStats[];
  cgn5aData: Cgn5a_CampaignStats[];
  cgn9aData: Cgn9a_CampaignStats[];
  cgn10aData: Cgn10a_CampaignStats[];
  cgn18aData: Cgn10a_CampaignStats[];
}) {
  // TODO: Remove once control group stats properly calculated in minerva-ds
  //       using rolling control group additions
  cgn4aData = nullifyControlGroupOutcomes(cgn4aData);
  cgn5aData = nullifyControlGroupOutcomes(cgn5aData);
  cgn9aData = nullifyControlGroupOutcomes(cgn9aData);
  cgn10aData = nullifyControlGroupOutcomes(cgn10aData);

  const fullscreenOff = !useFullScreenModeEnabled();
  const commsTableHidden = useBoolean(true);
  const cgnStatsHidden = useBoolean(false);
  const showCgnStats = fullscreenOff && !cgnStatsHidden.value;
  const showCommsTable = !showCgnStats && !commsTableHidden.value;
  const tableTabsProps = showCommsTable
    ? {
        cgnStatsHidden,
        height:
          "calc(max(400px, 100vh - 40px - 40px - 40px - 76px - 64px - 48px - 262px))",
      }
    : { cgnStatsHidden };
  return (
    <div
      css={`
        width: 100%;
        height: fit-content;
      `}
    >
      <div
        css={`
          width: 100%;
          height: fit-content;
          display: flex;
          flex-wrap: wrap;
          gap: 40px;
          justify-content: space-between;
        `}
      >
        {showCgnStats && (
          <StatsUnit
            rowData={cgn4aData}
            cgnStatsHidden={cgnStatsHidden}
            cardTitle={CGN4A_CARD_TITLE}
          />
        )}
        {showCgnStats && (
          <StatsUnit
            rowData={cgn5aData}
            cgnStatsHidden={cgnStatsHidden}
            cardTitle={CGN5A_CARD_TITLE}
          />
        )}
        {showCgnStats && !!_.size(cgn9aData) && (
          <StatsUnit
            rowData={cgn9aData}
            cgnStatsHidden={cgnStatsHidden}
            cardTitle={CGN9A_CARD_TITLE}
          />
        )}
        {showCgnStats && !!_.size(cgn10aData) && (
          <StatsUnit
            rowData={cgn10aData}
            cgnStatsHidden={cgnStatsHidden}
            cardTitle={CGN10A_CARD_TITLE}
          />
        )}
        {showCgnStats && !!_.size(cgn18aData) && (
          <StatsUnit
            rowData={cgn18aData}
            cgnStatsHidden={cgnStatsHidden}
            cardTitle={CGN18A_CARD_TITLE}
          />
        )}
      </div>
      {showCgnStats && (
        <div
          css={`
            height: 40px;
          `}
        >
          {" "}
        </div>
      )}
      <TableTabsUnit {...tableTabsProps} />
    </div>
  );
}

function nullifyControlGroupOutcomes(
  cgnXyData:
    | Cgn4a_CampaignStats[]
    | Cgn5a_CampaignStats[]
    | Cgn9a_CampaignStats[]
    | Cgn10a_CampaignStats[]
) {
  return cgnXyData.map((singleCgnGroupStats) => {
    if (isControlGroup(singleCgnGroupStats)) {
      return {
        ...singleCgnGroupStats,
        n_new_entities: NaN,
        total_new_revenue: NaN,
        avg_new_revenue: NaN,
      };
    } else {
      return singleCgnGroupStats;
    }
  });
}

function isControlGroup(
  cgnXyData:
    | Cgn4a_CampaignStats
    | Cgn5a_CampaignStats
    | Cgn9a_CampaignStats
    | Cgn10a_CampaignStats
) {
  return cgnXyData.customer_group_name === "Control";
}
