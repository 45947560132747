import { useMeData } from "../../hooks/useMe";
import { UserRole } from "../../store/model-me";
import { useMemo } from "react";
import { ALL_ENTITY_NAMES, EntityName, isAuthorizedForEntity } from "./core";
import { isAuthorizedForLevelWrites, WriteLevel } from "./writes-auth";

export function useMyRole(): UserRole {
  return useMeData().role;
}

export function useMyEmail(): string {
  return useMeData().email;
}

export function useMyTimezone(): string {
  return useMeData().timezone;
}

export function useAuthorizedForEntity(
  entityName: EntityName,
  isBackendRequest: boolean = false
): boolean {
  const myRole = useMyRole();
  return isAuthorizedForEntity(entityName, myRole, isBackendRequest);
}

export function useAuthorizedEntityNames(): EntityName[] {
  const myRole = useMyRole();
  return useMemo(
    () => ALL_ENTITY_NAMES.filter((v) => isAuthorizedForEntity(v, myRole)),
    [myRole]
  );
}

export function useCanDoLevelWrites(level: WriteLevel): boolean {
  const myRole = useMyRole();
  const myEmail = useMyEmail();
  return isAuthorizedForLevelWrites(level, myRole, myEmail);
}

export const CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES = [
  UserRole.SAXECAP_DEV,
  UserRole.ADMIN_READ_WRITE,
  UserRole.DEAN_OF_FACULTY,
  UserRole.ACADEMIC_DEPARTMENT_CHAIR,
];

export const CAN_READ_FINANCIALS__ROLES = [
  UserRole.SAXECAP_DEV,
  UserRole.ADMIN_READ_WRITE,
  UserRole.ADMIN_READ_ONLY,
  UserRole.DEAN_OF_FACULTY,
  UserRole.ENROLLMENT_MANAGER,
  UserRole.ENROLLMENT_COORDINATOR,
];

export const AC_DAILY_QUEUE_ADMIN__ROLES = [
  UserRole.SAXECAP_DEV,
  UserRole.ADMIN_READ_WRITE,
  UserRole.ADMIN_READ_ONLY,
];
