import React from "react";
import styled from "styled-components/macro";
import { Checkbox } from "@blueprintjs/core";

export function CheckBoxCellRenderer({ colDef, data, crp }) {
  const handleSubmitCheckBox = crp.handler;

  return (
    <StyledDiv>
      <Checkbox
        onChange={async (e) => {
          await handleSubmitCheckBox(
            crp.buildPayload(data, colDef.field, e.target.checked)
          );
        }}
        defaultChecked={data[colDef.field]}
      />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
`;
