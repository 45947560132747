import React from "react";
import styled from "styled-components/macro";
import { Spinner } from "@blueprintjs/core";
import { useEnsureData } from "../../helpers/use-ensure-data";
import _ from "lodash";
import { UpcomingRenrPotentialUnit } from "./upcoming-renr-potential-unit";
import { RecentEnrollmentCompletionsUnit } from "./recent-enrollment-completions-unit";
import {
  EnrollmentCoordinatorSelector,
  useEnrollmentCoordinatorSelection,
} from "../../components/selection/enrollment-coordinator-selector";
import { SummaryUnit } from "./summary-unit";
import { ChurnRateAnalysisStudentsUnit } from "./churn-rate-analyisis-students-unit";
import { useMyRole } from "../../entities/helpers/authorization";
import { UserRole } from "../../store/model-me";

export function EnrollmentCoordinatorDailyQueuePage(): JSX.Element {
  const loading = _.max([
    useEnsureData("retentionKpis"),
    useEnsureData("upcomingRenrPotentialStudents"),
    useEnsureData("recentEnrollmentCompletionStudents"),
    useEnsureData("churnRateAnalysisStudents"),
    useEnsureData("nextCourseRecos"),
  ]);
  return loading ? <Spinner /> : <EnrollmentCoordinatorDailyQueuePageInner />;
}
function EnrollmentCoordinatorDailyQueuePageInner(): JSX.Element {
  const myRole = useMyRole();
  const [selectedEnrollmentCoordinatorIndex, selectEnrollmentCoordinatorIndex] =
    useEnrollmentCoordinatorSelection();
  return (
    <ECDailyQueuePageContainer>
      <EnrollmentCoordinatorSelector
        selectedEnrollmentCoordinatorIndex={selectedEnrollmentCoordinatorIndex}
        selectEnrollmentCoordinatorIndex={selectEnrollmentCoordinatorIndex}
      />
      <SummaryUnit
        selectedEnrollmentCoordinatorIndex={selectedEnrollmentCoordinatorIndex}
      />
      <RecentEnrollmentCompletionsUnit
        selectedEnrollmentCoordinatorIndex={selectedEnrollmentCoordinatorIndex}
      />
      <UpcomingRenrPotentialUnit
        selectedEnrollmentCoordinatorIndex={selectedEnrollmentCoordinatorIndex}
      />
      {myRole === UserRole.SAXECAP_DEV && (
        <ChurnRateAnalysisStudentsUnit
          selectedEnrollmentCoordinatorIndex={
            selectedEnrollmentCoordinatorIndex
          }
        />
      )}
    </ECDailyQueuePageContainer>
  );
}
const ECDailyQueuePageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 40px;
`;
