import React, { useEffect, useState } from "react";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import AgTable from "../../components/AgTable";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import { getStudentGeniusUrls } from "./ecp-student-entity";
import { getEnrollmentBuzzUrl } from "./ecp-enrollment-entity";
import { useMeData } from "../../hooks/useMe";
import { DEFAULT_SHOULD_FETCH, useEndpoint } from "../../helpers/use-endpoint";

interface EcpCampaignemailTableProps {
  selectedCampaignType: string;
  selectCampaignType: (string) => void;
  fullScreenEnabled?: boolean;
  [x: string]: any;
}

export function EcpCampaignemailTable({
  selectedCampaignType,
  selectCampaignType,
  fullScreenEnabled = true,
  ...restProps
}: EcpCampaignemailTableProps) {
  const { loading: loading_ecp_campaignEmail, data: rowData } = useEndpoint(
    "ecp/mia_emails/last180days",
    DEFAULT_SHOULD_FETCH,
    { responseFormat: "arrow" }
  );
  const loading = _.max([
    loading_ecp_campaignEmail,
    !rowData,
    // useEnsureData("ecp_campaignEmail"),
    // useEnsureData("ecp_student"),
  ]);
  return loading ? (
    <Spinner />
  ) : (
    <EcpCampaignemailTableInner
      rowData={rowData}
      selectedCampaignType={selectedCampaignType}
      selectCampaignType={selectCampaignType}
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

function EcpCampaignemailTableInner({
  rowData,
  selectedCampaignType,
  selectCampaignType,
  ...props
}: EcpCampaignemailTableProps) {
  // let rowData = useStoreState((s) => s.ecp_campaignEmail.rowData);
  const [categoryFilters, setCategoryFilters] = useState<any>(
    INITIAL_CATEGORY_FILTERS
  );

  useEffect(() => {
    if (
      !!selectCampaignType &&
      !_.isEqual(categoryFilters?.email_type_name?.value, [
        selectedCampaignType,
      ])
    ) {
      selectCampaignType(null);
    }
  }, [categoryFilters]);

  useEffect(() => {
    if (selectedCampaignType) {
      setCategoryFilters({
        ...categoryFilters,
        email_type_name: {
          value: [selectedCampaignType],
        },
      });
    }
  }, [selectedCampaignType]);

  const colDefs = useColDefsForCampaignEmailsTable();

  return (
    <AgTable
      rowData={rowData.toArray()}
      columnDefs={colDefs}
      tableName={"mia_emails_last_180_days"}
      ribbonTitle={"MIA Emails (last 180 days)"}
      // tableName={"ecp_campaignEmail"}
      // ribbonTitle={"Campaign Emails"}
      categoryFilters={categoryFilters}
      setCategoryFilters={setCategoryFilters}
      {...props}
    />
  );
}

const INITIAL_CATEGORY_FILTERS = {
  email_type_name: {
    value: [
      "Enr: Newly MIA",
      "Enr: MIA Follow-Up 1",
      "Enr: MIA Follow-Up 2",
      "Enr: MIA Follow-Up 3",
      "Enr: MIA Follow-Up 4+",
      "Summer Enr: Newly MIA",
      "Summer Enr: MIA Follow-Up 1",
      "Summer Enr: MIA Follow-Up 2",
      "Summer Enr: MIA Follow-Up 3",
      "Summer Enr: MIA Follow-Up 4+",
    ],
  },
};

export function useColDefsForCampaignEmailsTable(): AgColDefs {
  const { role: myRole, timezone: myTz } = useMeData();
  let ret = [
    {
      field: "email_type_code",
      headerName: "Email Type Code",
      type: "categoryColumn",
      hide: true,
    },
    {
      field: "email_type_name",
      headerName: "Email Type",
      type: "categoryColumn",
    },
    {
      field: "sent_at",
      headerName: "Sent At",
      type: "timestampColumn",
      __timezone: myTz,
    },
    {
      field: "sent_at",
      headerName: "Date",
      type: "dateColumn",
    },
    {
      field: "from_email",
      headerName: "FROM",
      type: "categoryColumn",
    },
    {
      field: "to_email",
      headerName: "TO",
      type: "textColumn",
    },
    {
      field: "subject",
      headerName: "Subject",
      type: "textColumn",
    },
    {
      field: "cc_emails",
      headerName: "CC",
      type: "textColumn",
    },
    // {
    //   field: "bcc_emails",
    //   headerName: "BCC",
    //   type: "textColumn",
    // },
    {
      headerName: "Student",
      field: "student_fullnames",
      type: "multiLinkedTextColumn",
      width: 231,
      __linksValueGetter: getStudentGeniusUrls,
      __linksAreNotEmails: true,
    },
    {
      headerName: "Grade Level",
      field: "GradeLevel",
      type: "integerColumn",
      filter: "categoryFilterer",
      width: 87,
    },
    {
      headerName: "Teacher",
      field: "teacher_fullname",
      type: "textColumn",
    },
    {
      headerName: "ADC",
      field: "adc_emails",
      type: "tagsColumn",
    },
    {
      headerName: "Buzz Enr",
      field: "enrollment_buzz_id",
      type: "linkedTextColumn",
      __linkValueGetter: getEnrollmentBuzzUrl,
      __linkIsNotAnEmail: true,
    },
    {
      headerName: "Course",
      field: "course_name",
      type: "textColumn",
    },
    {
      field: "opened_by_TO",
      headerName: "Opened",
      type: "booleanColumn",
    },
    {
      field: "clicked_by_TO",
      headerName: "Clicked",
      type: "booleanColumn",
    },
    {
      headerName: "SoR",
      field: "SchoolRecord",
      type: "booleanColumn",
    },
    {
      headerName: "NCAA",
      field: "IsNCAA",
      type: "booleanColumn",
    },
    {
      headerName: "Academic Advisor",
      field: "AcademicAdvisor",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "AcademicAdvisor",
    },
    {
      headerName: "Counselor",
      field: "Counselor",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "Counselor",
    },
    {
      headerName: "Admissions Coordinator",
      field: "AdmissionsCoordinator",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "AdmissionsCoordinator",
    },
    {
      headerName: "",
      field: "id",
      cellClass: "react-rendered-cell",
      cellRenderer: "emailViewerButtonCellRenderer",
      width: 52,
      pinned: "right",
    },
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
  });
}
