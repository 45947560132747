import React from "react";
import { useStoreState } from "../../hooks/ep";
import AgTable from "../../components/AgTable";
import { AgRow, AgRows } from "../../components/AgTable/types";
import _ from "lodash";
import {
  ENROLLMENT_COORDINATOR_INDEX__ALL,
  EnrollmentCoordinatorIndex,
} from "../../store/model-ec-selection";
import { useRetentionAnalysisStudentsColDefs } from "./retention-analysis-student-coldefs";

function finalizeRowData(rowData: AgRows): AgRows {
  const finalizedRowData = rowData.map((row) => ({
    ...row,
    priority_scaled: row.upcoming_renr_potential_priority / 100.0,
  }));
  return finalizedRowData.sort(
    (a: AgRow, b: AgRow) =>
      b.upcoming_renr_potential_priority - a.upcoming_renr_potential_priority
  );
}
export function UpcomingRenrPotentialUnit({
  selectedEnrollmentCoordinatorIndex,
  ...restProps
}: {
  selectedEnrollmentCoordinatorIndex: EnrollmentCoordinatorIndex;
}): JSX.Element {
  const rowData = useStoreState((s) => s.upcomingRenrPotentialStudents.rowData);
  const filteredRowData =
    selectedEnrollmentCoordinatorIndex !== ENROLLMENT_COORDINATOR_INDEX__ALL
      ? rowData.filter(
          (row) => row.EC_Index === selectedEnrollmentCoordinatorIndex
        )
      : rowData;
  const colDefs = useRetentionAnalysisStudentsColDefs();
  const initialCategoryFilters = {
    GradeLevel: {
      value: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13],
    },
    has_future_enrollment: {
      value: [false],
    },
  };
  const height = _.min([500, 64 + 64 * _.max([3, filteredRowData.length])]);
  return (
    <AgTable
      rowData={finalizeRowData(filteredRowData)}
      columnDefs={colDefs}
      categoryFilters={initialCategoryFilters}
      tableName={"upcomingRenrPotentialStudents"}
      ribbonTitle={"Upcoming Re-Enrollment Potential"}
      height={`${height}px`}
      {...restProps}
    />
  );
}
