import { Computed, computed, Resolver } from "easy-peasy";
import { StoreModel } from "../model";
import {
  TableModel,
  tableModelFactory,
  TableRow,
} from "../table-model-factory";
import { happenedToday } from "./model-ree-customer-interactions";

export type ReeStudentTableRow = TableRow & {
  customer_id: number;
  StudentIndex: number;
  customer_name: string;
  PrimaryContactPhoneNumber: string;
  PrimaryContactEmail: string;
  SecondaryContactPhoneNumber: string;
  SecondaryContactEmail: string;
  starred: number;
};

interface RowDataById {
  [key: string]: ReeStudentTableRow;
  [key: number]: ReeStudentTableRow;
}

export type ReeStudentModel = TableModel<ReeStudentTableRow> & {
  rowData: Computed<ReeStudentModel, ReeStudentTableRow[], StoreModel>;
};

export function getReeStudentModel(): ReeStudentModel {
  return {
    ...tableModelFactory(
      "ree_student",
      "ree/churnrisk_students",
      (row) => row.customer_id,
      [
        {
          name: "ree_order",
          arity: "OneToMany",
          foreignName: "ree_order",
          foreignField: "customer_id",
          field: "customer_id",
        },
        {
          name: "ree_enrollment",
          arity: "OneToMany",
          foreignName: "ree_enrollment",
          foreignField: "customer_id",
          field: "customer_id",
        },
        {
          name: "recommendationActionReport",
          arity: "OneToOne",
          foreignName: "ree_customerInteraction",
          foreignField: ["action_payload", "customer_id"],
          field: "customer_id",
        },
        {
          name: "star",
          arity: "OneToOne",
          foreignName: "stars",
          foreignField: "customer_id",
          field: "customer_id",
        },
      ]
    ),
    rowData: computed<
      ReeStudentModel,
      ReeStudentTableRow[],
      StoreModel,
      Resolver<ReeStudentModel, StoreModel>[]
    >(
      [
        (state) => state.initialData,
        (state) => state.relatedIdsMap,
        (state) => state.relatedRowsMap,
        (state, storeState) =>
          storeState.ree_customerInteraction.customerId_to_latest,
      ],
      (rowData, relatedIdsMap, relatedRowsMap, latestItxnById) =>
        rowData.map((row) => {
          const starred = +!!relatedIdsMap[row.id]?.star;

          const latestInteraction = latestItxnById[row.id];
          const hasReportedCustomerInteraction = !!latestInteraction;
          const wasCustomerInteractionToday = happenedToday(
            latestInteraction?.submitted_at
          );
          const latestInteractionTimestamp = latestInteraction?.submitted_at;

          const orders = relatedRowsMap[row.id]?.ree_order ?? [];
          const orderIds = orders.map((v) => v.id);

          const enrollments = relatedRowsMap[row.id]?.ree_enrollment ?? [];
          const enrollmentIds = enrollments.map((v) => v.id);
          return {
            ...row,
            starred,
            hasReportedCustomerInteraction,
            wasCustomerInteractionToday,
            latestInteractionTimestamp,
            orders,
            orderIds,
            enrollments,
            enrollmentIds,
          };
        })
    ),
  };
}
