import _ from "lodash";
import { TableRow } from "../../store/table-model-factory";
import { Icon as BP3Icon, Intent } from "@blueprintjs/core";
import React from "react";
import { assert } from "../../helpers/assertions";
import { timeit } from "../../helpers/timing";

const TAG_ID_PATT = /tag__(.+)/;

export function isTagIdWhitelisted(
  tagId: string,
  whitelistedTagIds: string[] | null
): boolean {
  return _.isNil(whitelistedTagIds) || whitelistedTagIds.includes(tagId);
}

export function gatherTagIds(
  row: TableRow,
  whitelistedTagIds: string[] | null
): [string[], string[]] {
  const tagFields = Object.keys(row).filter(
    (key) =>
      TAG_ID_PATT.test(key) &&
      isTagIdWhitelisted(key.match(TAG_ID_PATT)[1], whitelistedTagIds)
  );
  // console.log({ ret });
  tagFields.sort();
  const tagIds = tagFields.map((key) => key.match(TAG_ID_PATT)[1]);
  return [tagFields, tagIds];
}

export function gatherTrueTags(row, tagFields: string[], tagIds: string[]) {
  return _.zip(tagFields, tagIds)
    .filter(([tagField, tagId]) => row[tagField])
    .map(([tagField, tagId]) => tagId)
    .join(",");
}

export const addTagsField = timeit(
  (rowData: TableRow[], whitelistedTagIds = null): TableRow[] => {
    if (!_.size(rowData)) {
      return [];
    }
    const [tagFields, tagIds] = gatherTagIds(rowData[0], whitelistedTagIds);
    rowData = rowData.map((row) => ({
      ...row,
      tags: gatherTrueTags(row, tagFields, tagIds),
    }));
    return rowData;
  },
  "addTagsField"
);

// function getWhitelistedTrueTags(row: TableRow, whitelistedTagIds = null) {
//   return row.tags.filter(t =>
// }

export interface TagIconProps {
  intent?: Intent;
}

export const ENROLLMENT_TAGS_INFO = {
  tagIcons: {
    at_risk: ({ intent = Intent.DANGER }: TagIconProps) => (
      <BP3Icon
        icon={"diagnosis"}
        intent={intent}
        iconSize={BP3Icon.SIZE_STANDARD}
      />
    ),
    behind_pace: ({ intent = Intent.WARNING }: TagIconProps) => (
      <BP3Icon
        icon={"history"}
        intent={intent}
        iconSize={BP3Icon.SIZE_STANDARD}
      />
    ),
    missing_in_action: ({ intent = Intent.WARNING }: TagIconProps) => (
      <BP3Icon
        icon={"signal-search"}
        intent={intent}
        iconSize={BP3Icon.SIZE_STANDARD}
      />
    ),
    slow_start: ({ intent = Intent.WARNING }: TagIconProps) => (
      <BP3Icon
        icon={"route"}
        intent={intent}
        iconSize={BP3Icon.SIZE_STANDARD}
      />
    ),
    low_grade: ({ intent = Intent.WARNING }: TagIconProps) => (
      <BP3Icon
        icon={"calculator"}
        intent={intent}
        iconSize={BP3Icon.SIZE_STANDARD}
      />
    ),
    extension_candidate: ({ intent = Intent.PRIMARY }: TagIconProps) => (
      <BP3Icon
        icon={"lifesaver"}
        intent={intent}
        iconSize={BP3Icon.SIZE_STANDARD}
      />
    ),
    super_pacer: ({ intent = Intent.SUCCESS }: TagIconProps) => (
      <BP3Icon
        icon={"fast-forward"}
        intent={intent}
        iconSize={BP3Icon.SIZE_STANDARD}
      />
    ),

    // extension_candidate: shop, shopping-cart, new-prescription
  },
  tagSortOrder: [
    "at_risk",
    "behind_pace",
    "missing_in_action",
    "slow_start",
    "low_grade",
    "extension_candidate",
    "super_pacer",
  ],
  // tagIcons: {
  //   slow_start: "clock",
  //   extension_candidate: "person",
  //   at_risk: "warning-sign",
  //   missing_in_action: "eye-off",
  // },
  tagDisplayNames: {
    at_risk: "At-Risk",
    behind_pace: "Behind Pace",
    missing_in_action: "MIA",
    slow_start: "Slow Start",
    low_grade: "Low Grade",
    extension_candidate: "Extension Candidate",
    super_pacer: "Super Pacer",
  },
};

export function isExtremelyMissingInAction(tagId, data) {
  if (data.is_irregularly_paced_course) {
    return false;
  }
  const nDays =
    data.n_active_days_without_submissions ??
    data.max__n_active_days_without_submissions ??
    0;
  return tagId === "missing_in_action" && nDays > 21;
}

export const STUDENT_TAGS_INFO = {
  tagIcons: {
    ...ENROLLMENT_TAGS_INFO.tagIcons,
    academy_upsell: ({ intent = Intent.SUCCESS }: TagIconProps) => (
      <BP3Icon
        icon={"shopping-cart"}
        intent={intent}
        iconSize={BP3Icon.SIZE_STANDARD}
      />
    ),
  },
  tagSortOrder: [...ENROLLMENT_TAGS_INFO.tagSortOrder, "academy_upsell"],
  tagDisplayNames: {
    ...ENROLLMENT_TAGS_INFO.tagDisplayNames,
    academy_upsell: "Academy Candidate",
  },
};

export function getStudentTagsInfo(whitelistedTagIds = null) {
  return getWhitelistedTagsInfo(STUDENT_TAGS_INFO, whitelistedTagIds);
}

export function getEnrollmentTagsInfo(whitelistedTagIds = null) {
  return getWhitelistedTagsInfo(ENROLLMENT_TAGS_INFO, whitelistedTagIds);
}

export function getWhitelistedTagsInfo(fullTagsInfo, whitelistedTagIds = null) {
  whitelistedTagIds = whitelistedTagIds || fullTagsInfo.tagSortOrder;
  return {
    tagIcons: Object.fromEntries(
      Object.entries(fullTagsInfo.tagIcons).filter(([key, value]) =>
        whitelistedTagIds.includes(key)
      )
    ),
    tagSortOrder: fullTagsInfo.tagSortOrder.filter((v) =>
      whitelistedTagIds.includes(v)
    ),
    tagDisplayNames: Object.fromEntries(
      Object.entries(fullTagsInfo.tagDisplayNames).filter(([key, value]) =>
        whitelistedTagIds.includes(key)
      )
    ),
    whitelistedTagIds,
  };
}

export function getTagsColumnWidth(tagsInfo): number {
  assert(
    !!tagsInfo.tagSortOrder,
    `Error: tagsInfo.tagSortOrder=${tagsInfo.tagSortOrder}`
  );
  return 34 + 32 * _.size(tagsInfo.tagSortOrder);
}
