import { assert } from "../helpers/assertions";
import { DataSnapshot, submitUserActionPostRequest } from "./user_actions";

export async function submitUserAction_update_teacher_attr(
  action_payload: ActionPayload_UpdateTeacherAttr
) {
  verifyActionPayload(action_payload);
  return await submitUserActionPostRequest(
    "update_teacher_attr",
    action_payload
  );
}

function verifyActionPayload(
  actionPayload: ActionPayload_UpdateTeacherAttr
): void {
  assert(typeof actionPayload.teacher_id === "number");
  assert(typeof actionPayload.attr_codename === "string");
  assert(
    actionPayload.teacher_id === actionPayload.teacher_snapshot.TeacherIndex
  );
}

export type TeacherAttrCodename = "teacher_contract_type";
export const ALL_TEACHER_ATTR_CODENAMES = ["teacher_contract_type"];

export interface ActionPayload_UpdateTeacherAttr {
  teacher_id: number;
  attr_codename: TeacherAttrCodename;
  attr_oldvalue: string;
  attr_newvalue: string;
  teacher_snapshot: DataSnapshot;
}
