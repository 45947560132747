import React from "react";
import { useStoreState } from "../../hooks/ep";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { ReportActionModalWrapper } from "../../components/reporting-actions/modal-or-drawer";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import AgTable from "../../components/AgTable";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import { useMyRole } from "../helpers/authorization";
import {
  getReportedCustomerInteractionCheckmarkCellClass,
  getStudentBuzzUrl,
  getStudentGeniusUrl,
} from "./ecp-student-entity";
import { useStudentAttr_LoyaltyRenrCampaignType_ColDef } from "./student-coldefs/student-attr-loyalty-renr-campaign-type";
import { CAN_READ_FINANCIALS__ROLES } from "../helpers/authorization";

export function LoyaltyStudentsTable({
  fullScreenEnabled = true,
  ...restProps
}) {
  const loading = _.max([
    useEnsureData("loyaltyStudents"),
    useEnsureData("ecp_enrollment"),
    useEnsureData("stars"),
    useEnsureData("student_flag"),
    useEnsureData("student_attr"),
    useEnsureData("ecp_customerInteraction"),
  ]);
  return loading ? (
    <Spinner />
  ) : (
    <LoyaltyStudentsTableInner
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

function LoyaltyStudentsTableInner({
  prependedColDefs = null,
  whitelistedTagIds = null,
  tableName = null,
  tableNameSuffix = null,
  initialCategoryFilters = null,
  ...restProps
}) {
  const rowData = useStoreState(
    (s) => s.loyaltyStudents.rowDataWithStudentFlagFieldsAndStudentAttrFields
  );

  const colDefs = useColumnDefs(prependedColDefs, whitelistedTagIds);

  return (
    <>
      <AgTable
        rowData={rowData}
        columnDefs={colDefs}
        tableName={tableName || "loyaltyStudents"}
        tableNameSuffix={tableNameSuffix}
        initialCategoryFilters={initialCategoryFilters}
        {...restProps}
      />
      <ReportActionModalWrapper />
    </>
  );
}

export function useColumnDefs(
  prependedColDefs = null,
  whitelistedTagIds = null
): AgColDefs {
  const myRole = useMyRole();

  const studentAttr_loyaltyRenrCampaignType_colDef =
    useStudentAttr_LoyaltyRenrCampaignType_ColDef();

  let ret = [
    {
      field: "starred",
      headerName: " ",
      type: "categoryColumn",
      cellClass: "react-rendered-cell",
      cellRenderer: "starCellRenderer",
      width: 60,
      pinned: "left",
    },
    {
      field: "hasReportedCustomerInteraction",
      headerName: "",
      pinned: "left",
      hide: true,
      type: "booleanColumn",
      tooltipField: "formattedLatestInteractionTimestamp",
      cellClass: getReportedCustomerInteractionCheckmarkCellClass,
    },
    studentAttr_loyaltyRenrCampaignType_colDef,
    {
      headerName: "Student Name",
      field: "customer_name",
      type: "textColumn",
      width: 150,
      suppressSizeToFit: true,
      tooltipField: "customer_name",
    },
    {
      headerName: "Loyalty Eligible",
      field: "LoyaltyEligible",
      type: "booleanColumn",
      width: 110,
    },
    {
      headerName: "Loyalty21 Re-Enrolled?",
      field: "is_loyalty21_reenrolled",
      type: "booleanColumn",
      width: 131,
      hide: true,
    },
    {
      headerName: "Loyalty22 Re-Enrolled?",
      field: "is_loyalty22_reenrolled",
      type: "booleanColumn",
      width: 131,
    },
    {
      headerName: "Loyalty22 Status",
      field: "loyalty22_status",
      type: "categoryColumn",
      width: 110,
    },
    {
      headerName: "Loyalty22 Academy Upsell Status",
      field: "loyalty22_academy_upsell_status",
      type: "categoryColumn",
      width: 174,
    },
    {
      headerName: "Genius Status",
      field: "Status",
      type: "categoryColumn",
      width: 100,
    },
    // {
    //   field: "latest_geniusComm_sent_at",
    //   headerName: "Latest Genius Communication Sent At",
    //   type: "timestampColumn",
    //   sortingOrder: ["asc", "desc", null],
    //   // sort: "asc",
    //   width: 240,
    // },
    {
      field: "AcademyPotential",
      headerName: "Academy Potential",
      type: "booleanColumn",
      width: 110,
    },
    // {
    //   field: "hasReportedCustomerInteraction",
    //   headerName: "",
    //   type: "booleanColumn",
    // },
    {
      headerName: "Genius",
      field: "StudentIndex",
      type: "linkedTextColumn",
      // __linkField: "student_genius_url",
      __linkValueGetter: getStudentGeniusUrl,
      __linkIsNotAnEmail: true,
      pinned: "left",
    },
    {
      headerName: "Buzz",
      field: "LMSID",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentBuzzUrl,
      __linkIsNotAnEmail: true,
      pinned: "left",
      // hide: true,
    },
    {
      headerName: "Grade Level",
      field: "GradeLevel",
      type: "integerColumn",
      filter: "categoryFilterer",
      cellClass: "ag-numeric-cell",
      width: 87,
    },
    {
      headerName: "Max Enr End Date",
      field: "max_enrollment_end_date",
      type: "dateColumn",
      width: 122,
    },
    {
      headerName: "Tenure [years]",
      field: "n_schoolyears_enrolled",
      type: "floatColumn",
      headerTooltip: "# of schoolyears enrolled with LSS",
      width: 100,
    },
    {
      headerName: "SoR",
      field: "SchoolRecord",
      type: "booleanColumn",
    },
    {
      headerName: "NCAA",
      field: "IsNCAA",
      type: "booleanColumn",
    },
    {
      headerName: "Academy",
      field: "AcademyStudent",
      type: "booleanColumn",
    },
    {
      headerName: "May Not Return",
      field: "MaynotReturn",
      type: "booleanColumn",
    },
    {
      headerName: "Not Returning",
      field: "NotReturning",
      type: "booleanColumn",
    },
    {
      headerName: "Enrolling After Loyalty",
      field: "EnrollingafterLoyalty",
      type: "booleanColumn",
    },
    {
      headerName: "Retention Notes",
      field: "RetentionNotes",
      type: "textColumn",
      width: 300,
      suppressSizeToFit: true,
      tooltipField: "RetentionNotes",
      cellClass: ["ellipses-tooltip-cell"],
    },
    {
      headerName: "Admissions Coordinator",
      field: "AdmissionsCoordinator",
      type: "categoryColumn",
    },
    {
      headerName: "Academic Advisor",
      field: "AcademicAdvisor",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "AcademicAdvisor",
    },
    {
      headerName: "Counselor",
      field: "Counselor",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "Counselor",
    },
    {
      headerName: "Student Services",
      field: "StudentServices",
      type: "categoryColumn",
    },
    {
      headerName: "Student Phone",
      field: "Phone",
      type: "textColumn",
      tooltipField: "Phone",
    },
    {
      headerName: "Student Email",
      field: "Email",
      type: "textColumn",
      tooltipField: "Email",
    },
    {
      headerName: "Guardian1 Phone",
      field: "Guardian1Phone",
      type: "textColumn",
      tooltipField: "Guardian1Phone",
    },
    {
      headerName: "Guardian1 Email",
      field: "Guardian1Email",
      type: "textColumn",
      tooltipField: "Guardian1Email",
    },
    ...DEFAULT_HIDDEN_COLDEFS,
  ] as AgColDef[];

  ret = [...(prependedColDefs ?? []), ...ret];

  ret = ret
    .filter((cd) => !!cd)
    .filter((cd) => {
      // @ts-ignore
      return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
    });
  return ret;
}

const DEFAULT_HIDDEN_COLDEFS = [
  {
    headerName: "Historical Order Value",
    field: "life_time_value",
    type: "moneyColumn",
    hide: true,
    __authorized_roles: CAN_READ_FINANCIALS__ROLES,
  },
  {
    headerName: "Value at Risk",
    field: "value_at_risk",
    type: "moneyColumn",
    headerTooltip: "(# SY to Grad) * (LTV per SY)",
    hide: true,
    __authorized_roles: CAN_READ_FINANCIALS__ROLES,
  },
  {
    headerName: "Historical Order Value per SY",
    field: "ltv_per_sy_enrolled",
    type: "moneyColumn",
    hide: true,
    __authorized_roles: CAN_READ_FINANCIALS__ROLES,
  },
  {
    headerName: "Date of Birth",
    field: "DOB",
    type: "dateColumn",
    hide: true,
  },
];
