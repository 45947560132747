import { Button, MenuItem, Position } from "@blueprintjs/core";
import React, { useLayoutEffect, useRef } from "react";
import { MultiSelect } from "@blueprintjs/select";
import "styled-components/macro";

const ENTER_RETURN_KEY_CODE = 13;

export type RiskReasonCodeName =
  | "financial_issues"
  | "family_issues"
  | "struggling_in_subject"
  | "busy_with_outside_activities"
  | "time_management_issues";

export interface RiskReason {
  codeName: RiskReasonCodeName;
  longName: string;
}

export const RiskReasonsMS = MultiSelect.ofType<RiskReason>();

export const DEFAULT_RISK_REASONS: RiskReason[] = [
  {
    codeName: "financial_issues",
    longName: "Financial Issues",
  },
  {
    codeName: "family_issues",
    longName: "Family Issues",
  },
  {
    codeName: "struggling_in_subject",
    longName: "Struggling in Subject",
  },
  {
    codeName: "busy_with_outside_activities",
    longName: "Busy with Outside Activities",
  },
  {
    codeName: "time_management_issues",
    longName: "Time Management Issues",
  },
];

export function RiskReasonsInput({
  queryText,
  setQueryText,
  selectedItems,
  onAdd,
  onClear,
  onRemove,
  activeField,
  setActiveField,
}) {
  const riskReasonInputRef = useRef(null);

  const handleFocus = () => {
    riskReasonInputRef.current.focus();
    setActiveField("riskReasons");
  };
  // const handleBlur = ev => {
  //   ev.preventDefault();
  //   riskReasonInputRef.current.blur();
  //   setActiveField(null);
  // };

  const clearButton =
    selectedItems.length > 0 ? (
      <Button icon="cross" minimal={true} onClick={onClear} />
    ) : undefined;
  const isItemSelected = (r) => selectedItems.includes(r);
  const onItemSelect = (riskReason) => {
    if (isItemSelected(riskReason)) {
      onRemove(riskReason.codeName);
    } else {
      onAdd(riskReason);
    }
  };
  const onItemsSelect = (riskReasons) => riskReasons.map(onItemSelect);
  useLayoutEffect(() => {
    // noinspection JSUnresolvedFunction
    handleFocus();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      css={`
        .bp3-multi-select-popover .bp3-menu {
          //max-width: none !important;
          max-height: 220px !important;
          overflow-y: auto;
        }
      `}
    >
      <RiskReasonsMS
        fill
        createNewItemFromQuery={undefined}
        onKeyDown={(ev) => {
          if (ev.keyCode === ENTER_RETURN_KEY_CODE) {
            ev.preventDefault();
          }
        }}
        resetOnSelect={true}
        createNewItemRenderer={null}
        openOnKeyDown={false}
        items={DEFAULT_RISK_REASONS}
        itemsEqual="codeName"
        itemDisabled={isItemSelected}
        itemPredicate={riskReasonsPredicate}
        itemRenderer={riskReasonItemRenderer(isItemSelected)}
        selectedItems={selectedItems}
        onItemSelect={onItemSelect}
        tagRenderer={riskReasonTagRenderer}
        onItemsPaste={onItemsSelect}
        placeholder="Choose all that apply..."
        query={queryText}
        onQueryChange={setQueryText}
        // onFocus={handleFocus}
        popoverProps={{
          usePortal: false,
          minimal: true,
          position: Position.BOTTOM_LEFT,
        }}
        tagInputProps={{
          onFocus: handleFocus,
          name: "riskReasons",
          autoFocus: true,
          inputRef: (r) => {
            riskReasonInputRef.current = r;
          },
          leftIcon: "diagnosis",
          large: true,
          onRemove: onRemove,
          rightElement: clearButton,
          tagProps: { large: false, minimal: true },
        }}
        // TODO: Center for IE11
        // css={`
        //   .bp3-popover-content .bp3-menu {
        //     width: ${width}px;
        //   }
        // `}
      />
    </div>
  );
}

const riskReasonsPredicate = (
  query,
  riskReason: RiskReason,
  _index,
  exactMatch
) => {
  const normalizedTitle = riskReason.longName.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return normalizedTitle.includes(normalizedQuery);
  }
};
const riskReasonTagRenderer = (riskReason: RiskReason) => riskReason.longName;
const riskReasonItemRenderer =
  (isItemSelected) =>
  (riskReason: RiskReason, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const isSelected = isItemSelected(riskReason);
    return (
      <MenuItem
        active={modifiers.active}
        icon={isSelected ? "tick" : "blank"}
        disabled={false}
        key={riskReason.codeName}
        text={riskReason.longName}
        // label={riskReason.longName}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    );
  };
