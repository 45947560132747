import React, { useEffect } from "react";
import { Spinner } from "@blueprintjs/core";
import AgTable from "../components/AgTable";
import { AgColDef, AgColDefs } from "../components/AgTable/types";
import { useMyRole } from "./helpers/authorization";
import { DEFAULT_AC_NAME } from "../components/splitviews/ac-leads-splitview";
import { useEnsureData } from "../helpers/use-ensure-data";
import { useStoreActions, useStoreState } from "../hooks/ep";
import _ from "lodash";

function getQueryParamsFromDateRange(dateRange) {
  if (!dateRange) return {};
  return {
    startDate: dateRange[0] ? dateRange[0].toISOString().split("T")[0] : null,
    endDate: dateRange[1] ? dateRange[1].toISOString().split("T")[0] : null,
  };
}
export function LeadsQueueTable({
  selectedAdmissionsCoordinatorName,
  fullScreenEnabled = true,
  selectedDateRange = null,
  ...restProps
}) {
  const setFetchQueryParams = useStoreActions(
    (a) => a.leadsQueue.setFetchQueryParams
  );
  const handleFetchInitialData = useStoreActions(
    (a) => a.leadsQueue.handleFetchInitialData
  );
  useEffect(() => {
    const queryParams = {
      ...getQueryParamsFromDateRange(selectedDateRange),
      acName:
        selectedAdmissionsCoordinatorName !== DEFAULT_AC_NAME
          ? selectedAdmissionsCoordinatorName
          : null,
    };
    setFetchQueryParams(queryParams);
    handleFetchInitialData();
  }, [
    handleFetchInitialData,
    selectedAdmissionsCoordinatorName,
    selectedDateRange,
    setFetchQueryParams,
  ]);

  const loading = _.max([
    useEnsureData("leadsQueue"),
    useEnsureData("lead_customerInteraction"),
  ]);

  const rowData = useStoreState(
    (s) => s.leadsQueue.rowDataWithCustomerInteractionFlag
  );
  // Adding the following condition as a temporal fix because the useEffect hook above was keeping the previously
  // selected AC's data visible in the LeadsQueueTable while the new AC's data was being fetched. Now showing a Spinner.
  if (
    _.size(rowData) &&
    !rowData.every(
      (row) => row.AC_FullName === selectedAdmissionsCoordinatorName
    )
  ) {
    return <Spinner />;
  }
  return loading ? (
    <Spinner />
  ) : (
    <LeadsQueueTableInner
      fullScreenEnabled={fullScreenEnabled}
      rowData={rowData}
      {...restProps}
    />
  );
}

export function LeadsQueueTableInner({
  rowData,
  fullScreenEnabled,
  finalizeColDefs = null,
  finalizeRowData = null,
  ...props
}) {
  const colDefs = useColumnDefs();

  return (
    <AgTable
      rowData={finalizeRowData ? finalizeRowData(rowData) : rowData}
      columnDefs={finalizeColDefs ? finalizeColDefs(colDefs) : colDefs}
      tableName={"leads-queue"}
      ribbonTitle={"Leads Queue"}
      {...props}
    />
  );
}
const N_ACTIVE_LEAD_STATUSES = 6; // From minerva-ds code at: minerva_ds/lib/entities/base_leads/nodes/base_leads.py.ACTIVE_LEAD_STATUSES_PRIORITY

export function useColumnDefs(): AgColDefs {
  const myRole = useMyRole();
  let ret = [
    {
      headerName: "",
      field: "has_customer_interaction",
      type: "booleanColumn",
      pinned: "left",
      headerTooltip: "Interaction reported",
    },
    {
      headerName: "Customer ID",
      field: "ns_customer_id",
      type: "integerIdentifierColumn",
      suppressSizeToFit: true,
      tooltipField: "ns_customer_id",
      pinned: "left",
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer Parent ID",
      field: "ns_customer_parent",
      type: "integerIdentifierColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer Name",
      field: "ns_customer_full_name",
      type: "linkedTextColumn",
      __linkValueGetter: (params) => params.data.ns_customer_url ?? "",
      pinned: "left",
      suppressSizeToFit: true,
      width: 180,
    },
    {
      headerName: "Priority",
      field: "active_status_priority",
      type: "floatColumn",
      cellRenderer: "priorityScoreCellRenderer",
      valueGetter: (params) =>
        (N_ACTIVE_LEAD_STATUSES - params.data.active_status_priority + 1) /
        N_ACTIVE_LEAD_STATUSES,
      cellClass: "react-rendered-cell",
      headerTooltip: "Lead priority according to current status",
      width: 160,
    },
    {
      headerName: "Status",
      field: "ns_customer_status__mapped",
      type: "categoryColumn",
      suppressSizeToFit: true,
      width: 140,
    },
    {
      headerName: "Customer Email",
      field: "ns_customer_email",
      type: "textColumn",
      suppressSizeToFit: true,
    },
    {
      headerName: "Customer Phone",
      field: "ns_customer_phone",
      type: "textColumn",
      suppressSizeToFit: true,
    },
    {
      headerName: "Created",
      field: "ns_customer_created_at",
      type: "timestampColumn",
      suppressSizeToFit: true,
    },
    {
      headerName: "Admissions Coordinator",
      field: "AC_FullName",
      type: "textColumn",
      suppressSizeToFit: true,
    },
    {
      headerName: "Stage",
      field: "ns_customer_stage",
      type: "categoryColumn",
      suppressSizeToFit: true,
    },
    {
      headerName: "",
      field: "ns_customer_phone",
      cellClass: "react-rendered-cell",
      cellRenderer: "phoneActionButtonCellRenderer",
      type: "textColumn",
      width: 52,
      pinned: "right",
      cellRendererParams: {
        crp: {
          phoneField: "ns_customer_phone",
          tooltipText: "Call customer",
        },
      },
    },
    {
      headerName: "",
      field: "email_action",
      cellClass: "react-rendered-cell",
      cellRenderer: "dailyqueueEmailActionButtonCellRenderer",
      type: "textColumn",
      width: 52,
      pinned: "right",
      cellRendererParams: {
        crp: {
          tooltipText: "Compose email from template",
        },
      },
    },
    {
      headerName: "",
      field: "action",
      cellClass: "react-rendered-cell",
      cellRenderer: "actionButtonCellRenderer",
      type: "textColumn",
      width: 48,
      pinned: "right",
      cellRendererParams: {
        crp: {
          includedMenuItems: ["reportLeadCustomerInteractionMenuItem"],
        },
      },
    },
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
  });
}
