import _ from "lodash";
import React from "react";
import { ITooltipParams } from "@ag-grid-community/core";
import { Classes } from "@blueprintjs/core";
import { floatVF, integerVF, timestampComparator } from "./gridOptions";
import "styled-components/macro";
import { getField } from "../../store/table-model-factory";

function isMissing(v) {
  return _.isNil(v) || _.isNaN(v);
}

export class StatsTooltip extends React.Component<ITooltipParams> {
  getReactContainerClasses() {
    return ["custom-tooltip", Classes.TOOLTIP, Classes.DARK];
  }

  render() {
    try {
      const { colDef, rowIndex } = this.props;
      const { field, valueFormatter, type, headerTooltip, comparator } = colDef;
      const isHeader = rowIndex === undefined;
      const isDateTimeLike = ["dateColumn", "timestampColumn"].includes(type);
      const isNumeric = [
        "floatColumn",
        "moneyColumn",
        "integerColumn",
        "percentageColumn",
      ].includes(type);
      const isText = ["textColumn", "categoryColumn"].includes(type);

      if (!_.isString(field)) {
        return null;
      }

      let sum = 0.0;
      let max = null;
      let min = null;
      let nonNilCount = 0;
      let nilCount = 0;
      this.props.api.forEachNodeAfterFilter((node, index) => {
        const nodeValue =
          getField(node.data, field) ?? getField(node.data, field.split("."));
        if (
          isMissing(nodeValue) ||
          (isDateTimeLike && nodeValue === "") ||
          (isNumeric && !_.isNumber(nodeValue))
        ) {
          nilCount++;
        } else {
          sum += nodeValue;

          if (_.isNull(max)) {
            max = nodeValue;
          } else {
            const compareMax = isDateTimeLike
              ? timestampComparator(max, nodeValue) < 0
              : max < nodeValue;

            if (compareMax) {
              max = nodeValue;
            }
          }

          if (_.isNull(min)) {
            min = nodeValue;
          } else {
            const compareMin = isDateTimeLike
              ? timestampComparator(min, nodeValue) > 0
              : min > nodeValue;

            if (compareMin) {
              min = nodeValue;
            }
          }

          nonNilCount++;
        }
      });

      const displayedRowsCount = nilCount + nonNilCount;

      const avg = nonNilCount > 0 ? sum / nonNilCount : NaN;

      const maxIsMissing = isMissing(max);
      const minIsMissing = isMissing(min);
      const avgIsMissing = isMissing(avg);
      const sumIsMissing = isMissing(sum);

      const notAllMissing = !(
        maxIsMissing &&
        minIsMissing &&
        avgIsMissing &&
        sumIsMissing
      );

      return isHeader &&
        displayedRowsCount > 0 &&
        notAllMissing &&
        nonNilCount >= 0 ? (
        <div
          className={`${Classes.DARK} ${Classes.POPOVER_CONTENT} ${Classes.RUNNING_TEXT}`}
          css={`
            opacity: 1;
            width: fit-content;
          `}
        >
          {headerTooltip !== "_" && (
            <>
              <p>{headerTooltip}</p>
              <br />
            </>
          )}
          {!isText && (
            <p>
              <span
                className="bp3-text-muted"
                css={`
                  margin-right: 12px;
                `}
              >
                Max:
              </span>
              <span>
                {nonNilCount === 0 || maxIsMissing
                  ? "N/A"
                  : valueFormatter({ value: max, colDef })}
              </span>
            </p>
          )}
          {!isText && (
            <p>
              <span
                className="bp3-text-muted"
                css={`
                  margin-right: 12px;
                `}
              >
                Min:
              </span>
              <span>
                {nonNilCount === 0 || minIsMissing
                  ? "N/A"
                  : valueFormatter({ value: min, colDef })}
              </span>
            </p>
          )}
          {isNumeric && (
            <p>
              <span
                className="bp3-text-muted"
                css={`
                  margin-right: 12px;
                `}
              >
                Avg:
              </span>
              <span>
                {nonNilCount === 0 || avgIsMissing
                  ? "N/A"
                  : type === "integerColumn"
                  ? floatVF(avg)
                  : valueFormatter({ value: avg, colDef })}
              </span>
            </p>
          )}
          {["floatColumn", "moneyColumn", "integerColumn"].includes(type) && (
            <p>
              <span
                className="bp3-text-muted"
                css={`
                  margin-right: 12px;
                `}
              >
                Sum:
              </span>
              <span>
                {nonNilCount === 0 || sumIsMissing
                  ? "N/A"
                  : valueFormatter({ value: sum, colDef })}
              </span>
            </p>
          )}
          <br />
          <p>
            <span
              className="bp3-text-muted"
              css={`
                margin-right: 12px;
              `}
            >
              Missing:
            </span>
            <span>
              {nonNilCount === 0
                ? "ALL"
                : integerVF(nilCount) + " / " + integerVF(displayedRowsCount)}
            </span>
          </p>
        </div>
      ) : null;
    } catch (e) {
      return null;
    }
  }
}
