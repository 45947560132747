import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import React from "react";
import { Button, Dialog } from "@blueprintjs/core";
import { toaster } from "../../toaster";
import {
  makeAuthorizedPostRequestToBackend,
  makeUrl,
} from "../../helpers/backendApi";
import { convertDateStringAmericanFormatToIsoFormat } from "../../helpers/convertDateStringAmericanFormatToIsoFormat";

export function CoursePropagationDialog({
  isOpen,
  onClose,
  nextSchoolYearStartDateFormatted,
  orderIndexToAddCoursesTo,
  orderNameToAddCoursesTo,
  nextCourseNamesByIndex,
  studentName,
}: {
  isOpen: boolean;
  onClose: () => void;
  nextSchoolYearStartDateFormatted: string;
  orderIndexToAddCoursesTo: number;
  orderNameToAddCoursesTo: string;
  nextCourseNamesByIndex: Map<number, string>;
  studentName: string;
}) {
  const constructPropagateCourseSchedulePayload = () => ({
    order_index: orderIndexToAddCoursesTo,
    next_school_year_start_date: convertDateStringAmericanFormatToIsoFormat(
      nextSchoolYearStartDateFormatted
    ),
    course_indexes: Array.from(nextCourseNamesByIndex.keys()),
  });

  const propagateCourseSchedule = async () => {
    onClose();
    try {
      await makeAuthorizedPostRequestToBackend({
        url: makeUrl("course_schedule"),
        data: constructPropagateCourseSchedulePayload(),
        axiosConfig: undefined,
      });

      toaster.info(
        `The course schedule for ${studentName} is being propagated to their order named 
        ${orderNameToAddCoursesTo} with ID ${orderIndexToAddCoursesTo}. 
        The propagation job can still fail, so please wait up to 5 minutes and check Genius 
        to see if the courses were added succesfully.`,
        10
      );
    } catch (err) {
      const backendErrorMessage = err.response?.data?.error;
      const genericErrorMessage = `Sorry, something went wrong while propagating the course schedule for ${studentName}. Please, try again. If the error persists, please email team@saxecap.com.`;
      const errorMessage = backendErrorMessage
        ? `Student: ${studentName} - ${backendErrorMessage}`
        : genericErrorMessage;

      toaster.error(errorMessage, 7);
    }
  };

  return (
    <Dialog
      canEscapeKeyClose
      canOutsideClickClose
      onClose={onClose}
      className={"bp3-dark"}
      icon="calendar"
      title={"Propagate Course Schedule Into Genius"}
      isOpen={isOpen}
    >
      <div className="bp3-dialog-body">
        <div style={{ marginBottom: "20px" }}>
          Are you sure you want to add the following courses with a start date
          of <b>{nextSchoolYearStartDateFormatted}</b> to the order{" "}
          <b>
            {orderIndexToAddCoursesTo} | {orderNameToAddCoursesTo}
          </b>
          ?
        </div>
        <ul style={{ padding: 0, margin: 0 }}>
          {Array.from(nextCourseNamesByIndex).map(
            ([courseIndex, courseName]) => (
              <li
                key={courseIndex}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  listStyleType: "none",
                }}
              >
                <span style={{ marginLeft: "40px" }}>{courseName}</span>
                <span style={{ color: "grey", marginRight: "40px" }}>
                  (ID: {courseIndex})
                </span>
              </li>
            )
          )}
        </ul>
      </div>
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <Button intent={Intent.DANGER} onClick={onClose}>
            No (cancel propagation)
          </Button>
          <Button intent={Intent.SUCCESS} onClick={propagateCourseSchedule}>
            Yes (initiate propagation)
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
