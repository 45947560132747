export default {
  apiKey: "AIzaSyCVJHVLVm0FdXqXeMMup7VKHeNWT208ejc",
  authDomain: "minerva-core.firebaseapp.com",
  databaseURL: "https://minerva-core.firebaseio.com",
  projectId: "minerva-core",
  storageBucket: "minerva-core.appspot.com",
  messagingSenderId: "1019105861670",
  appId: "1:1019105861670:web:6533a64e7a89ffa3f946e7",
  measurementId: "G-2HF35XXGDD",
};
