import { useEnsureData } from "../../helpers/use-ensure-data";
import { Unit } from "./unit";
import { Predicate, StudentSelector } from "../selection/student-selector";
import { GradeLevelSelector } from "../selection/grade-level-selector";
import React, { useCallback, useMemo } from "react";
import "styled-components/macro";
import { TableRow } from "../../store/table-model-factory";
import {
  ALL_GRADE_LEVELS,
  ALL_SOR,
  useEntitySelection,
  useSelectedEntityId,
} from "../../store/model-entity-selection";
import { useStoreState } from "../../hooks/ep";
import _ from "lodash";
import { H4, Spinner } from "@blueprintjs/core";
import { SoRSelector } from "../selection/sor-selector";
import { BackendConnectedModelSliceName } from "../../store/model";

export function SelectionUnit({
  unitId,
  modelSliceForBaseStuFilter,
  baseStuFilter,
  additionalFilterComponent,
}: {
  unitId: string;
  modelSliceForBaseStuFilter: BackendConnectedModelSliceName;
  baseStuFilter: Predicate;
  additionalFilterComponent?;
}): JSX.Element {
  const loading = _.max([
    useEnsureData("ecp_student"),
    useEnsureData(modelSliceForBaseStuFilter),
  ]);

  const [selectedGradeLevel, selectGradeLevel] =
    useEntitySelection("grade_level");

  const [selectedSoR, selectSoR] = useEntitySelection("sor");

  const gradeLevelStuFilter: Predicate = useCallback(
    (stu) =>
      selectedGradeLevel === ALL_GRADE_LEVELS ||
      ("GradeLevel" in stu && stu["GradeLevel"] === selectedGradeLevel),
    [selectedGradeLevel]
  );

  const sorStuFilter: Predicate = useCallback(
    (stu) =>
      selectedSoR === ALL_SOR ||
      ("SchoolRecord" in stu && stu["SchoolRecord"] === !!selectedSoR),
    [selectedSoR]
  );

  return (
    <Unit unitId={unitId} loading={loading}>
      <div
        css={`
          display: flex;
          flex-direction: row;
          align-items: center;

          & > h4:not(:first-child) {
            margin-left: 20px;
          }
        `}
      >
        {loading ? <Spinner /> : <SoRSelectionHeader filter={baseStuFilter} />}
        {loading ? (
          <Spinner />
        ) : (
          <GradeLevelSelectionHeader filter={baseStuFilter} />
        )}
        {loading ? (
          <Spinner />
        ) : (
          <StudentSelectionHeader
            filter={(stu) =>
              baseStuFilter(stu) &&
              sorStuFilter(stu) &&
              gradeLevelStuFilter(stu)
            }
          />
        )}
        {loading ? <Spinner /> : additionalFilterComponent}
      </div>
    </Unit>
  );
}

export function RowH4({ children }): JSX.Element {
  return (
    <H4
      css={`
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;

        & > span:first-child {
          margin-right: 8px;
        }
      `}
    >
      {children}
    </H4>
  );
}

export function SoRSelectionHeader({ filter }: { filter: Predicate }) {
  return (
    <RowH4>
      <span>Type:</span>
      <SoRSelector filter={filter} />
    </RowH4>
  );
}

export function GradeLevelSelectionHeader({ filter }: { filter: Predicate }) {
  return (
    <RowH4>
      <span>Grade:</span>
      <GradeLevelSelector filter={filter} />
    </RowH4>
  );
}

export function StudentSelectionHeader({ filter }: { filter: Predicate }) {
  return (
    <RowH4>
      <span>Student:</span>
      <StudentSelector filter={filter} />
    </RowH4>
  );
}

export function useSelectedCustomer(): TableRow {
  const selectedCustomerId = +useSelectedEntityId("student");
  const byId = useStoreState(
    (s) => s.ecp_student.rowDataWithStudentFlagFieldsAndStudentAttrFieldsById
  );
  return useMemo(() => byId[selectedCustomerId], [selectedCustomerId, byId]);
}
