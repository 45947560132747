import { useStoreActions } from "../../../hooks/ep";
import { Button, Icon, Intent } from "@blueprintjs/core";
import React from "react";
import { Tooltip } from "antd";
import { AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY } from "../../../styles/constants";
import { TooltipPlacement } from "antd/es/tooltip";
import { useCanCreateNewUser } from "../../../hooks/use-can-create-new-user";
import { CreateNewUser_FormContent } from "../content/create-new-user";
import { createNewUser_formStoreModelFactory } from "../models/create-new-user";
import { useOpenDrawerForm } from "../../../store/model-drawer-form";

export function CreateNewUserButton({ ...props }) {
  const openDrawer = useOpenDrawerForm();
  const handleSubmit = useStoreActions((a) => a.ecp_user.handleCreateNewUser);
  const canCreateNewUser = useCanCreateNewUser();
  const formStoreModelFactory = createNewUser_formStoreModelFactory;

  const onClick = () => {
    const initialData = {};
    const title = "Please enter details for the new user";
    const ContentComponent = CreateNewUser_FormContent;

    openDrawer({
      initialData,
      title,
      ContentComponent,
      handleSubmit,
      formStoreModelFactory,
    });
  };

  return (
    canCreateNewUser && (
      <Tooltip
        title={"Create new user"}
        placement={"topLeft" as TooltipPlacement}
        mouseEnterDelay={AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY}
      >
        <Button
          css={`
            margin-left: 8px;
            margin-right: 8px;
          `}
          small
          minimal
          intent={Intent.SUCCESS}
          icon={<Icon icon="new-person" />}
          onClick={onClick}
        />
      </Tooltip>
    )
  );
}
