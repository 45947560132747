import React from "react";
import "styled-components/macro";

export function LoyaltySpacerUnit() {
  return (
    <div
      css={`
        height: 40px;
      `}
    >
      {" "}
    </div>
  );
}
