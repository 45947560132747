import { TableModel, tableModelFactory } from "../table-model-factory";

export interface NextCourseRecosModel extends TableModel {}

export function getNextCourseRecosModel(): NextCourseRecosModel {
  return {
    ...tableModelFactory(
      "nextCourseRecos",
      "next_course_recos_by_student__all",
      getNextCourseRecosRowId
    ),
  };
}

export function getNextCourseRecosRowId(row) {
  return row.StudentIndex;
  // return [
  //   row.StudentIndex.toString(),
  //   row.EnrollmentIndex.toString(),
  //   row.target,
  //   row.reco_course__ProgramofStudyDisplayName,
  // ].join("|");
}
