import { Icon as BP3Icon, Intent, ProgressBar } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components/macro";
import { Col, Row } from "antd";
// import { getScoreInfo } from "../../../helpers/get-score-info";
import { useRow } from "../../../helpers/use-ree-student-row";
import _ from "lodash";
import moment from "moment";

const YesChurnIcon = () => <BP3Icon icon="warning-sign" intent="danger" />;
const NoChurnIcon = () => <BP3Icon icon="shield" intent="success" />;
const ScoFacExplanation = styled.div`
  margin-bottom: 8px;
`;

function ScoFacIcon({ contrib }) {
  return contrib > 0 ? <YesChurnIcon /> : <NoChurnIcon />;
}

function ScoFacBar({ contrib, maxContrib }) {
  return (
    <ProgressBar
      animate={false}
      stripes={false}
      intent={contrib < 0 ? Intent.SUCCESS : Intent.DANGER}
      value={Math.abs(contrib / maxContrib)}
    />
  );
}

function ScoreFactor({ name, contrib, valueExplanation, maxContrib }) {
  return (
    <Row
      className="feature-contribution-row"
      key={name}
      type="flex"
      justify="space-between"
      align="middle"
      gutter={16}
    >
      <Col>
        <ScoFacIcon contrib={contrib} />
      </Col>
      <Col
        css={`
          flex-grow: 1;
        `}
      >
        <ScoFacExplanation>{valueExplanation}</ScoFacExplanation>
        <ScoFacBar contrib={contrib} maxContrib={maxContrib} />
      </Col>
    </Row>
  );
}

const ScoFacWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > .feature-contribution-row {
    margin: 24px 0 0 0 !important;
  }
  & > .feature-contribution-row:first-child {
    margin-top: 0 !important;
  }
`;

const nfGradePct = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

const nfDays = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

function isna(v) {
  return _.isNil(v) || _.isNaN(v);
}

function notna(v) {
  return !isna(v);
}

export function parseScoreFactorValues(sfv) {
  if (_.isString(sfv)) {
    return parseStringScoreFactorValues(sfv);
  } else {
    return sfv;
  }
}

function parseStringScoreFactorValues(s) {
  try {
    return JSON.parse(s);
  } catch (err) {}

  try {
    return JSON.parseMore(s);
  } catch (err) {}

  return s;
}

export function getAvgPctAhead(sfvPacing) {
  const pctAheadVals = sfvPacing
    .map(getPctBehindPace)
    .filter((v) => v !== null);
  const avgPctAhead = _.mean(pctAheadVals);
  return avgPctAhead;
}

function getPctBehindPace(v) {
  // return v["PercentCompleteAdjusted"] - v["cutoff_percent"];
  const ret = -(100 * v["AssignmentsBehind"]) / v["eTotalAssignments"];
  if (_.isNil(ret) || _.isNaN(ret)) {
    return null;
  } else if (ret < -100) {
    return null;
  } else if (ret > 100) {
    return null;
  } else {
    return ret;
  }
}

function getStudentScoreInfoItem(key, studentRow) {
  let ret = {};

  const churnRiskScore = studentRow["churn_risk_score"];
  const sfv = parseScoreFactorValues(studentRow[key]);

  if (key === "churn_risk_score") {
    if (_.isNil(churnRiskScore) || _.isNaN(churnRiskScore)) {
      return null;
    } else if (churnRiskScore < 50.0) {
      ret = {
        contrib: (-2.0 * (50.0 - churnRiskScore)) / 100.0,
        valueExplanation: "Other positive factors",
      };
    } else {
      ret = {
        contrib: (2.0 * (churnRiskScore - 50.0)) / 100.0,
        valueExplanation: "Other negative factors",
      };
    }
  } else if (key === "sfv_pacing") {
    const avgPctAhead = getAvgPctAhead(sfv);

    if (_.isNil(avgPctAhead) || _.isNaN(avgPctAhead)) {
      return null;
    }

    const isAheadOnAvg = avgPctAhead > 0;
    const avgPctAheadString = nfGradePct.format(
      isAheadOnAvg ? avgPctAhead : -avgPctAhead
    );
    const direction = isAheadOnAvg ? "ahead of" : "behind";

    let contrib = -avgPctAhead / 33;
    contrib = contrib > 0 ? _.min([0.9, contrib]) : _.max([-0.9, contrib]);

    const valueExplanation = `Student is ${avgPctAheadString}% ${direction} pace on average`;

    ret = {
      contrib,
      valueExplanation,
    };
  } else if (key === "sfv_performance") {
    const currentGrades = sfv
      .filter((v) => notna(v["eCurrentGrade"]))
      .map((v) => v["eCurrentGrade"]);
    const avgCurrentGrade = _.mean(currentGrades);

    if (_.isNil(avgCurrentGrade) || _.isNaN(avgCurrentGrade)) {
      return null;
    }

    let contrib = (80 - avgCurrentGrade) / 25;
    contrib = contrib > 0 ? _.min([0.99, contrib]) : _.max([-0.99, contrib]);

    const valueExplanation = `Current average grade is ${nfGradePct.format(
      avgCurrentGrade
    )}%`;

    ret = {
      contrib,
      valueExplanation,
    };
  } else if (key === "sfv_recency") {
    const daysVals = sfv
      .filter((v) => !!v["buzz_last_activity_date"])
      .map(({ buzz_last_activity_date, cutoff }) => {
        const lastActivityTimestamp = moment(buzz_last_activity_date);
        const cutoffTimestamp = moment(cutoff);
        const daysSinceLastActive = cutoffTimestamp.diff(
          lastActivityTimestamp,
          "days"
        );
        return daysSinceLastActive;
      });
    const avgDaysSinceLastActive = _.mean(daysVals);
    const avgDaysSinceLastActiveString = nfDays.format(avgDaysSinceLastActive);

    if (_.isNil(avgDaysSinceLastActive) || _.isNaN(avgDaysSinceLastActive)) {
      return null;
    }

    let contrib =
      avgDaysSinceLastActive < 3
        ? (0.7 * (avgDaysSinceLastActive - 3)) / 3
        : (0.7 * avgDaysSinceLastActive) / 60;
    contrib = contrib > 0 ? _.min([0.7, contrib]) : _.max([-0.7, contrib]);

    const valueExplanation = `${avgDaysSinceLastActiveString} days on average since last activity on Buzz`;

    ret = { contrib, valueExplanation };
  }
  ret = { ...ret, name: key, humanName: key, value: studentRow[key] };
  // console.log(ret);
  return ret;
}

function getEnrollmentScoreInfoItem(key, enrollmentRow) {
  let ret = {};

  const churnRiskScore = enrollmentRow["churn_risk_score"];
  const sfv = enrollmentRow[key];

  if (key === "churn_risk_score") {
    if (churnRiskScore < 50.0) {
      ret = {
        contrib: (-2.0 * (50.0 - churnRiskScore)) / 100.0,
        valueExplanation: "Other positive factors",
      };
    } else {
      ret = {
        contrib: (2.0 * (churnRiskScore - 50.0)) / 100.0,
        valueExplanation: "Other negative factors",
      };
    }
  } else if (key === "sfv_pacing") {
    const pctAhead = getPctBehindPace(sfv);
    if (_.isNil(pctAhead)) {
      return null;
    }
    const isAhead = pctAhead > 0;
    const avgPctAheadString = nfGradePct.format(isAhead ? pctAhead : -pctAhead);
    const direction = isAhead ? "ahead of" : "behind";

    let contrib = -pctAhead / 33;
    contrib = contrib > 0 ? _.min([0.9, contrib]) : _.max([-0.9, contrib]);

    const valueExplanation = `Student is ${avgPctAheadString}% ${direction} pace`;

    ret = {
      contrib,
      valueExplanation,
    };
  } else if (key === "sfv_performance") {
    const currentGrade = sfv["eCurrentGrade"];
    if (_.isNil(currentGrade) || _.isNaN(currentGrade)) {
      return null;
    }
    let contrib = (80 - currentGrade) / 25;
    contrib = contrib > 0 ? _.min([0.99, contrib]) : _.max([-0.99, contrib]);

    const valueExplanation = `Current grade is ${nfGradePct.format(
      currentGrade
    )}%`;

    ret = {
      contrib,
      valueExplanation,
    };
  } else if (key === "sfv_recency") {
    const { buzz_last_activity_date, cutoff } = sfv;
    if (!buzz_last_activity_date) {
      return null;
    }
    const lastActivityTimestamp = moment(buzz_last_activity_date);
    const cutoffTimestamp = moment(cutoff);
    const daysSinceLastActive = cutoffTimestamp.diff(
      lastActivityTimestamp,
      "days"
    );
    const isMultipleDays = daysSinceLastActive !== 1;
    const ess = isMultipleDays ? "s" : "";

    let contrib =
      daysSinceLastActive < 3
        ? (0.7 * (daysSinceLastActive - 3)) / 3
        : (0.7 * daysSinceLastActive) / 60;
    contrib = contrib > 0 ? _.min([0.7, contrib]) : _.max([-0.7, contrib]);

    const valueExplanation = `${daysSinceLastActive} day${ess} since last activity on Buzz`;

    ret = {
      contrib,
      valueExplanation,
    };
  }
  ret = { ...ret, name: key, humanName: key, value: enrollmentRow[key] };
  // console.log(ret);
  return ret;
}

function getStudentScoreInfo(studentRow) {
  // console.log(studentRow);
  let ret = [getStudentScoreInfoItem("churn_risk_score", studentRow)];
  Object.entries(studentRow).forEach(([key, val]) => {
    if (val && _.startsWith(key, "sfv_")) {
      ret.push(getStudentScoreInfoItem(key, studentRow));
    }
  });
  ret = ret.filter((v) => !!v).filter((v) => !_.isUndefined(v.contrib));
  ret = _.sortBy(ret, ({ contrib }) => -Math.abs(contrib));
  return ret;
}

function getEnrollmentScoreInfo(enrollmentRow) {
  // console.log(enrollmentRow);
  let ret = [getEnrollmentScoreInfoItem("churn_risk_score", enrollmentRow)];
  Object.entries(enrollmentRow).forEach(([key, val]) => {
    if (val && _.startsWith(key, "sfv_")) {
      ret.push(getEnrollmentScoreInfoItem(key, enrollmentRow));
    }
  });
  ret = ret.filter((v) => !!v).filter((v) => !_.isUndefined(v.contrib));
  ret = _.sortBy(ret, ({ contrib }) => -Math.abs(contrib));
  return ret;
}

const SCORE_INFO_FUNCS = {
  ecp_student: getStudentScoreInfo,
  loyaltyStudents: getStudentScoreInfo,
  ecp_enrollment: getEnrollmentScoreInfo,
};

export function ScoreFactors({ id, sliceName }) {
  const row = useRow(id, sliceName);
  const scoreInfo = SCORE_INFO_FUNCS[sliceName](row);
  const top3_scoreInfo = scoreInfo.filter(
    ({ valueExplanation }) => !!valueExplanation
  );
  return (
    <ScoFacWrapper>
      {/*<PopConHeader>Score Factors</PopConHeader>*/}
      {top3_scoreInfo.map((info, idx) => (
        <ScoreFactor key={idx} maxContrib={1.0} {...info} />
      ))}
    </ScoFacWrapper>
  );
}
