import React from "react";
import { SimpleRadio } from "../forms-stuff/simple-radio";
import styled from "styled-components/macro";

export function AllAnyRadios({ selectedValue, setSelectedValue }) {
  return (
    <StyledDiv>
      <SimpleRadio
        key={"any"}
        value={"any"}
        label={"Any"}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        onFocus={() => {}}
        large={false}
      />
      <SimpleRadio
        key={"all"}
        value={"all"}
        label={"All"}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        onFocus={() => {}}
        large={false}
      />
      <SimpleRadio
        key={"only"}
        value={"only"}
        label={"Only"}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        onFocus={() => {}}
        large={false}
      />
      <SimpleRadio
        key={"exactly"}
        value={"exactly"}
        label={"Exactly"}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        onFocus={() => {}}
        large={false}
      />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  & > *:first-child {
    margin-right: 12px;
  }
`;
