import {
  getReeCustomerInteractionModel,
  ReeCustomerInteractionModel,
} from "./ree/model-ree-customer-interactions";

import { getStarsModel, StarsModel } from "./model-stars";
import { getReeOrderModel, ReeOrderModel } from "./ree/model-ree-order";
import { getReeStudentModel, ReeStudentModel } from "./ree/model-ree-student";
import {
  getReeEnrollmentModel,
  ReeEnrollmentModel,
} from "./ree/model-ree-enrollment";
import {
  EcpCustomerInteractionModel,
  getEcpCustomerInteractionModel,
} from "./ecp/model-ecp-customer-interactions";
import {
  EcpSourceDataIssueModel,
  getEcpSourceDataIssueModel,
} from "./ecp/model-ecp-sourceDataIssue";
import {
  EcpEnrollmentModel,
  getEcpEnrollmentModel,
} from "./ecp/model-ecp-enrollment";
import { EcpStudentModel, getEcpStudentModel } from "./ecp/model-ecp-student";
import {
  EcpTeachercourseModel,
  getEcpTeachercourseModel,
} from "./ecp/model-ecp-teacherCourse";
import {
  EcpCampaignemailModel,
  getEcpCampaignemailModel,
} from "./ecp/model-ecp-campaignEmail";
import {
  EcpTeacherModel,
  getEcpTeacherModel,
  getTeacherEnrollmentModel,
  getTeacherTeachercourseModel,
} from "./ecp/model-ecp-teacher";
import { EcpUserModel, getEcpUserModel } from "./ecp/model-ecp-user";
import { getStudentFlagModel, StudentFlagModel } from "./model-studentFlag";
import { getStudentAttrModel, StudentAttrModel } from "./model-studentAttr";
import {
  getNextCourseRecosModel,
  NextCourseRecosModel,
} from "./ecp/model-ecp-nextCourseRecos";
import {
  getLeadCustomerInteractionModel,
  LeadCustomerInteractionModel,
} from "./model-lead-customer-interactions";
import { getLeadsQueueModel, LeadsQueueModel } from "./model-leadsQueue";
import {
  buildEcpUserMetricModel,
  EcpUserMetricModel,
} from "./ecp/model-ecp-userMetric";
import {
  getLoyaltyCommunicationsModel,
  LoyaltyCommunicationsModel,
} from "./ecp/model-loyaltyCommunications";
import {
  getLoyaltyStudentsModel,
  LoyaltyStudentsModel,
} from "./ecp/model-loyaltyStudents";
import {
  getLoyaltyStatisticsModel,
  LoyaltyStatisticsModel,
} from "./ecp/model-loyaltyStatistics";
import {
  getUpcomingRenrPotentialStudentsModel,
  UpcomingRenrPotentialStudentsModel,
} from "./model-upcoming-renr-potential-students";
import {
  getRecentEnrollmentCompletionStudentsModel,
  RecentEnrollmentCompletionStudentsModel,
} from "./model-recent-enrollment-completion-students";
import {
  ChurnRateAnalysisStudentsModel,
  getChurnRateAnalysisStudentsModel,
} from "./model-churn-rate-analysis-students";
import {
  getRetentionKpisModel,
  RetentionKpisModel,
} from "./model-retention-kpis";
import {
  getNextYearCourseScheduleRecommendationsModel,
  NextYearCourseScheduleRecommendationsModel,
} from "./ecp/model-ecp-nextYearCourseScheduleRecommendations";
import { EcpOrderModel, getEcpOrderModel } from "./ecp/model-ecp-order";
import { getTeacherAttrModel, TeacherAttrModel } from "./model-teacherAttr";
import {
  CourseAssessmentMetricsModel,
  getCourseAssessmentMetricsModel,
} from "./ecp/model-ecp-courseAssessmentMetrics";
import {
  getVendorAssessmentMetricsModel,
  VendorAssessmentMetricsModel,
} from "./ecp/model-ecp-vendorAssessmentMetrics";
import {
  getTeacherRetentionKpisModel,
  TeacherRetentionKpisModel,
} from "./ecp/model-ecp-teacherRetentionKpis";

export interface TableModels {
  ree_student: ReeStudentModel;
  ree_order: ReeOrderModel;
  ree_enrollment: ReeEnrollmentModel;
  ree_customerInteraction: ReeCustomerInteractionModel;
  ecp_student: EcpStudentModel;
  ecp_teacherCourse: EcpTeachercourseModel;
  ecp_enrollment: EcpEnrollmentModel;
  ecp_order: EcpOrderModel;
  ecp_customerInteraction: EcpCustomerInteractionModel;
  ecp_sourceDataIssue: EcpSourceDataIssueModel;
  student_flag: StudentFlagModel;
  student_attr: StudentAttrModel;
  teacher_attr: TeacherAttrModel;
  ecp_campaignEmail: EcpCampaignemailModel;
  ecp_teacher: EcpTeacherModel;
  ecp_teacher_teacherCourse: EcpTeachercourseModel;
  ecp_teacher_enrollment: EcpEnrollmentModel;
  ecp_user: EcpUserModel;
  ecp_userMetric: EcpUserMetricModel;
  nextCourseRecos: NextCourseRecosModel;
  stars: StarsModel;
  leadsQueue: LeadsQueueModel;
  lead_customerInteraction: LeadCustomerInteractionModel;
  loyaltyStudents: LoyaltyStudentsModel;
  loyaltyCommunications: LoyaltyCommunicationsModel;
  loyaltyStatistics: LoyaltyStatisticsModel;
  upcomingRenrPotentialStudents: UpcomingRenrPotentialStudentsModel;
  recentEnrollmentCompletionStudents: RecentEnrollmentCompletionStudentsModel;
  churnRateAnalysisStudents: ChurnRateAnalysisStudentsModel;
  retentionKpis: RetentionKpisModel;
  teacherRetentionKpis: TeacherRetentionKpisModel;
  nextYearCourseScheduleRecommendations: NextYearCourseScheduleRecommendationsModel;
  courseAssessmentMetrics: CourseAssessmentMetricsModel;
  vendorAssessmentMetrics: VendorAssessmentMetricsModel;
}

export type TableModelSliceName =
  | "ree_student"
  | "ree_order"
  | "ree_enrollment"
  | "ree_customerInteraction"
  | "ecp_student"
  | "ecp_teacherCourse"
  | "ecp_enrollment"
  | "ecp_order"
  | "ecp_customerInteraction"
  | "ecp_sourceDataIssue"
  | "ecp_campaignEmail"
  | "ecp_teacher"
  | "ecp_teacher_teacherCourse"
  | "ecp_teacher_enrollment"
  | "ecp_user"
  | "ecp_userMetric"
  | "admissionsCoordinator"
  | "leadsQueue"
  | "lead_customerInteraction"
  | "stars"
  | "student_flag"
  | "student_attr"
  | "teacher_attr"
  | "nextCourseRecos"
  | "loyaltyStudents"
  | "loyaltyCommunications"
  | "loyaltyStatistics"
  | "upcomingRenrPotentialStudents"
  | "recentEnrollmentCompletionStudents"
  | "churnRateAnalysisStudents"
  | "retentionKpis"
  | "teacherRetentionKpis"
  | "nextYearCourseScheduleRecommendations"
  | "courseAssessmentMetrics"
  | "vendorAssessmentMetrics";

export const tableModels: TableModels = {
  ree_student: getReeStudentModel(),
  ree_order: getReeOrderModel(),
  ree_enrollment: getReeEnrollmentModel(),
  ree_customerInteraction: getReeCustomerInteractionModel(),
  ecp_student: getEcpStudentModel(),
  ecp_teacherCourse: getEcpTeachercourseModel(),
  ecp_enrollment: getEcpEnrollmentModel(),
  ecp_order: getEcpOrderModel(),
  ecp_customerInteraction: getEcpCustomerInteractionModel(),
  ecp_sourceDataIssue: getEcpSourceDataIssueModel(),
  ecp_campaignEmail: getEcpCampaignemailModel(),
  ecp_teacher: getEcpTeacherModel(),
  ecp_teacher_teacherCourse: getTeacherTeachercourseModel(),
  ecp_teacher_enrollment: getTeacherEnrollmentModel(),
  ecp_user: getEcpUserModel(),
  ecp_userMetric: buildEcpUserMetricModel(),
  stars: getStarsModel(),
  student_flag: getStudentFlagModel(),
  student_attr: getStudentAttrModel(),
  teacher_attr: getTeacherAttrModel(),
  nextCourseRecos: getNextCourseRecosModel(),
  leadsQueue: getLeadsQueueModel(),
  lead_customerInteraction: getLeadCustomerInteractionModel(),
  loyaltyStudents: getLoyaltyStudentsModel(),
  loyaltyCommunications: getLoyaltyCommunicationsModel(),
  loyaltyStatistics: getLoyaltyStatisticsModel(),
  upcomingRenrPotentialStudents: getUpcomingRenrPotentialStudentsModel(),
  recentEnrollmentCompletionStudents:
    getRecentEnrollmentCompletionStudentsModel(),
  churnRateAnalysisStudents: getChurnRateAnalysisStudentsModel(),
  retentionKpis: getRetentionKpisModel(),
  nextYearCourseScheduleRecommendations:
    getNextYearCourseScheduleRecommendationsModel(),
  courseAssessmentMetrics: getCourseAssessmentMetricsModel(),
  vendorAssessmentMetrics: getVendorAssessmentMetricsModel(),
  teacherRetentionKpis: getTeacherRetentionKpisModel(),
};
