import React from "react";
import { AgColDef } from "./components/AgTable/types";
import { TableRow } from "./store/table-model-factory";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import AgTable from "./components/AgTable";
import { useEndpoint } from "./helpers/use-endpoint";

export function CanonicalCourseStatsPage() {
  const { loading, data } = useEndpoint("/ecp/canonical_course_stats");
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
      `}
    >
      {loading || !data || !_.size(data as TableRow[]) ? (
        <Spinner />
      ) : (
        <Content rowData={data} />
      )}
    </div>
  );
}

function Content({ rowData }) {
  rowData = rowData.map((row) => ({ ...row, id: row["MD Name"] }));
  const colDefs = useColDefs();

  return (
    <AgTable
      rowData={rowData}
      tableName={"canonical-course-stats"}
      columnDefs={colDefs}
    />
  );
}

function useColDefs(): AgColDef[] {
  return [
    {
      headerName: "MD Name",
      field: "MD Name",
      type: "textColumn",
      width: 193,
    },
    {
      headerName: "Department",
      field: "Department",
      type: "categoryColumn",
    },
    {
      headerName: "Plurality Grade Level",
      field: "Plur GL",
      type: "categoryColumn",
      width: 100,
    },
    {
      headerName: "% Plurality Grade Level",
      field: "% Plur GL",
      type: "percentageColumn",
      width: 116,
    },
    {
      headerName: "# Enr",
      field: "# Enr",
      type: "integerColumn",
      width: 100,
    },
    {
      headerName: "# Enr Ended (Past 9mo)",
      field: "# Enr Ended (Past 9mo)",
      type: "integerColumn",
      width: 125,
    },
    {
      headerName: "# Enr Ended (Past 12mo)",
      field: "# Enr Ended (Past 12mo)",
      type: "integerColumn",
      width: 130,
    },
    {
      headerName: "# Depts w/ Enr",
      field: "# Depts w/ Enr",
      type: "integerColumn",
      width: 97,
    },
    {
      headerName: "# Depts w/ Enr Ended (Past 9mo)",
      field: "# Depts w/ Enr Ended (Past 9mo)",
      type: "integerColumn",
      width: 150,
    },
    {
      headerName: "# Depts w/ Enr Ended (Past 12mo)",
      field: "# Depts w/ Enr Ended (Past 12mo)",
      type: "integerColumn",
      width: 155,
    },
    ...ALL_GRADE_LEVEL_COLUMN_NAMES.map((colname) =>
      createGradeLevelColumn(colname)
    ),
  ];
}

const ALL_GRADE_LEVEL_COLUMN_NAMES = [
  "<UNK>",
  "KG",
  "1G",
  "2G",
  "3G",
  "4G",
  "5G",
  "6G",
  "7G",
  "8G",
  "9G",
  "10G",
  "11G",
  "12G",
  "13G",
];

function createGradeLevelColumn(colname: string): AgColDef {
  return {
    headerName: colname,
    field: colname,
    type: "percentageColumn",
    width: COLNAME_LENGTH_TO_COL_WIDTH[colname.length],
    __renderZerosAsEmpty: true,
  };
}

const COLNAME_LENGTH_TO_COL_WIDTH = { 2: 64, 3: 72, 5: 95 };
