import { UserRole } from "../../store/model-me";

type WriteLevelNumber = 1 | 2 | 3 | 4;
export type WriteLevel = WriteLevelNumber;

export const CAN_DO_LEVEL2_WRITES__USER_ROLES = [
  UserRole.SAXECAP_DEV,
  UserRole.ADMIN_READ_WRITE,
  UserRole.DEAN_OF_FACULTY,
  UserRole.ACADEMIC_DEPARTMENT_CHAIR,
  UserRole.ENROLLMENT_MANAGER,
  UserRole.ENROLLMENT_COORDINATOR,
  UserRole.ADMISSIONS_COORDINATOR,
];

export const CAN_DO_LEVEL3_WRITES__USER_ROLES = [
  UserRole.SAXECAP_DEV,
  UserRole.ADMIN_READ_WRITE,
];

export const CAN_DO_LEVEL4_WRITES__USER_ROLES = [UserRole.SAXECAP_DEV];

export const CAN_DO_LEVEL2_WRITES__USER_EMAILS = [];
export const CAN_DO_LEVEL3_WRITES__USER_EMAILS = [];
export const CAN_DO_LEVEL4_WRITES__USER_EMAILS = [];

export const VALID_NEW_USER_ROLES = [
  UserRole.ADMIN_READ_WRITE,
  UserRole.ADMIN_READ_ONLY,
  UserRole.DEAN_OF_FACULTY,
  UserRole.ACADEMIC_DEPARTMENT_CHAIR,
  UserRole.ENROLLMENT_MANAGER,
  UserRole.ENROLLMENT_COORDINATOR,
  UserRole.ADMISSIONS_COORDINATOR,
  UserRole.COUNSELOR,
];

export function isAuthorizedForLevelWrites(
  level: WriteLevel,
  myRole: UserRole,
  myEmail: string
): boolean {
  if (level === 1) {
    return true;
  } else if (level === 2) {
    return (
      CAN_DO_LEVEL2_WRITES__USER_ROLES.includes(myRole) ||
      CAN_DO_LEVEL2_WRITES__USER_EMAILS.includes(myEmail)
    );
  } else if (level === 3) {
    return (
      CAN_DO_LEVEL3_WRITES__USER_ROLES.includes(myRole) ||
      CAN_DO_LEVEL3_WRITES__USER_EMAILS.includes(myEmail)
    );
  } else if (level === 4) {
    return (
      CAN_DO_LEVEL4_WRITES__USER_ROLES.includes(myRole) ||
      CAN_DO_LEVEL4_WRITES__USER_EMAILS.includes(myEmail)
    );
  }
}
