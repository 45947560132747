import React from "react";
import { PriorityScoreCellContent } from "./cell-content";
import "styled-components/macro";
import _ from "lodash";

export function PriorityScoreCellRenderer({ value: priorityScore }) {
  const priorityIsMissing =
    _.isNil(priorityScore) || _.isNaN(priorityScore) || priorityScore === 0.0;
  return (
    <PriorityScoreCellContent
      priorityScore={priorityIsMissing ? 0.0 : priorityScore}
    />
  );
}
