import { StyledFormGroup } from "../forms-stuff/styled-form-group";
import { EmailAddressInput } from "./ecp--email-address-input";
import { NotesInput } from "./notes-input";
import React from "react";
import "styled-components/macro";
import { TooltipLabel } from "../forms-stuff/tooltip-label";
import { InteractionTypeInput } from "./interaction-type-input";
import { PhoneNumberInput } from "./ecp--phone-number-input";
import { InteractionResultInput } from "./lead--interaction-result-input";

const LEAD_CUSTOMER_INTERACTION_NOTES_HELPER_TEXT =
  "Help us understand the nature of your lead outreach and how the customer responded";

export function LeadCustomerInteractionFormContent({
  formState,
  formActions,
  activeField,
  setActiveField,
}) {
  return (
    <>
      <InteractionTypeInput
        value={formState.interactionType}
        onValueChange={formActions.setInteractionType}
        activeField={activeField}
        setActiveField={setActiveField}
      />
      <StyledFormGroup
        label={
          <TooltipLabel
            labelText={"Interaction Result"}
            tooltipText={LEAD_INTERACTION_RESULT_HELPER_TEXT}
          />
        }
        labelFor="leadInteractionResult"
        helperText={LEAD_INTERACTION_RESULT_HELPER_TEXT}
        activeField={activeField}
      >
        <InteractionResultInput
          selectedItem={formState.interactionResult}
          onSelect={formActions.setInteractionResult}
          interactionType={formState.interactionType}
          activeField={activeField}
          setActiveField={setActiveField}
        />
      </StyledFormGroup>
      <NotesInput
        value={formState.notes}
        onValueChange={formActions.setNotes}
        activeField={activeField}
        setActiveField={setActiveField}
        helperText={LEAD_CUSTOMER_INTERACTION_NOTES_HELPER_TEXT}
      />
    </>
  );
}

const LEAD_INTERACTION_RESULT_HELPER_TEXT =
  "What was the result of the interaction? Select one of the options provided for convenience in the dropdown.";
