import { Button } from "@blueprintjs/core";
import React, { useRef } from "react";
import "styled-components/macro";
import { message, Tooltip } from "antd";
import { useDrawerForm } from "../../store/model-drawer-form";
import { useFormStoreActions, useFormStoreState } from "./form-store";

export function FormWrapper({ children }) {
  const formState = useFormStoreState();
  const formActions = useFormStoreActions();
  const isSubmitted = useRef(false);
  const onSubmit = useOnSubmit(isSubmitted);
  const readyToSubmit = !formState.errorMessage;
  const notReadyToSubmit = !readyToSubmit;

  return (
    <form
      onSubmit={onSubmit}
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        & > * {
          margin-bottom: 32px !important;
        }
        & > *:last-child {
          margin-bottom: unset !important;
        }
      `}
    >
      {children}
      <Tooltip
        placement="bottom"
        title={formState.errorMessage && <span>{formState.errorMessage}</span>}
        mouseEnterDelay={0.1}
        mouseLeaveDelay={0.1}
      >
        <span>
          <Button
            css={`
              margin-top: 64px;
            `}
            fill
            type="submit"
            intent="primary"
            disabled={notReadyToSubmit || isSubmitted.current}
          >
            Submit
          </Button>
        </span>
      </Tooltip>
    </form>
  );
}

function useOnSubmit(
  isSubmitted: React.MutableRefObject<boolean>,
  onSuccess?: any,
  onError?: any
) {
  const formState = useFormStoreState();
  const { close, handleSubmit } = useDrawerForm();

  return async (ev) => {
    ev.preventDefault();
    if (!formState.dataIsSubmittable) {
      message.error("Please fill out all form fields before submitting");
    } else if (isSubmitted.current) {
      // No double-submissions!
      console.error("Already submited");
    } else {
      try {
        isSubmitted.current = true;
        await handleSubmit(formState.actionPayload);
        if (onSuccess) {
          onSuccess(formState);
        }
        close();
      } catch (err) {
        isSubmitted.current = false;
        if (onError) {
          onError(err, formState);
        }
      }
    }
  };
}
