import { DescriptionItemConfig } from "./flat-descriptions";
import React, { ReactNode } from "react";
import { integerVF } from "./AgTable/gridOptions";
import { Position, Tooltip } from "@blueprintjs/core";
import { Descriptions } from "antd";
import { useStoreState } from "../hooks/ep";
import { TableRow, TableRowId } from "../store/table-model-factory";
import {
  getFormatter,
  RelatedEntitiesSumTag,
  RelatedEntitiesTag,
} from "../entities/helpers/relation-agg-cr";
import "styled-components/macro";
import {
  HierarchicalRelatedEntitiesSumTag,
  HierarchicalRelatedEntitiesTag,
} from "./hier-rel-ent-counts-tag";
import { EntityName } from "../entities/helpers/core";
import _ from "lodash";

export function useRelatedIds(
  baseEntityName: EntityName,
  relationshipName: string,
  rowId: TableRowId
): TableRowId[] {
  return (useStoreState(
    (s) => s[baseEntityName]?.relatedIdsMap?.[rowId]?.[relationshipName]
  ) ?? []) as TableRowId[];
}

export function buildDescriptionItem({
  key,
  data,
  itemConfig,
  ...restProps
}: {
  key: number | string;
  data: TableRow | undefined | TableRow[];
  itemConfig: DescriptionItemConfig;
}): JSX.Element {
  const value = data?.[itemConfig.field];
  const visible = itemConfig.getVisible ? itemConfig.getVisible(data) : true;

  if (!visible) {
    return null;
  }
  let contents: ReactNode;
  if (value === undefined || value === null || !data) {
    contents = "N/A";
  } else if (itemConfig.type === "element") {
    contents = itemConfig.renderer(key, data, itemConfig);
  } else if (itemConfig.type === "relatedIds") {
    contents = (
      <RelatedEntitiesTag
        entityName={itemConfig.baseEntityName}
        entityIds={data[itemConfig.fieldIds]}
      >
        {value}
      </RelatedEntitiesTag>
    );
  } else if (itemConfig.type === "relatedsCount") {
    const formattedValue = integerVF({ value: _.size(value.entityIds) });
    if (itemConfig.childItemConfigs?.length) {
      contents = (
        <HierarchicalRelatedEntitiesTag itemConfig={itemConfig} data={data}>
          {formattedValue}
        </HierarchicalRelatedEntitiesTag>
      );
    } else {
      contents = (
        <RelatedEntitiesTag
          entityName={value.entityName}
          entityIds={value.entityIds}
        >
          {formattedValue}
        </RelatedEntitiesTag>
      );
    }
  } else if (itemConfig.type === "relatedsSum") {
    if (itemConfig.childItemConfigs?.length) {
      contents = (
        <HierarchicalRelatedEntitiesSumTag
          itemConfig={itemConfig}
          data={data}
        />
      );
    } else {
      contents = (
        <RelatedEntitiesSumTag
          entityName={value.entityName}
          entityIds={value.entityIds}
          foreignField={itemConfig.extra.foreignField}
          type={itemConfig.extra.type}
        />
      );
    }
  } else {
    const formatter = getFormatter(itemConfig.type);
    const formattedValue = formatter ? formatter(value) : value;
    if (itemConfig.childItemConfigs?.length) {
      contents = (
        <HierarchicalRelatedEntitiesTag itemConfig={itemConfig} data={data}>
          {formattedValue}
        </HierarchicalRelatedEntitiesTag>
      );
    } else {
      contents = formattedValue;
    }
  }

  const label = getDescriptionItemLabel(itemConfig.label, itemConfig.tooltip);

  return (
    <Descriptions.Item key={key} label={label} {...restProps}>
      {contents}
    </Descriptions.Item>
  );
}

export function getDescriptionItemLabel(
  label: string,
  tooltip?: string | JSX.Element
): JSX.Element | string {
  if (!tooltip) {
    return label;
  } else {
    return (
      <Tooltip
        content={tooltip}
        boundary={"viewport"}
        position={Position.TOP}
        usePortal={true}
        hoverOpenDelay={500}
      >
        {label}
      </Tooltip>
    );
  }
}
