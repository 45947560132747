import moment from "moment";
// noinspection ES6CheckImport
import { Line } from "@nivo/line";
import { Classes, Tag } from "@blueprintjs/core";
import React from "react";
import { Spin } from "antd";
import _ from "lodash";
import { useEndpoint } from "../../helpers/use-endpoint";
import { floatVF, integerVF, moneyVF } from "./gridOptions";
import { NIVO_LINE_THEME } from "./nivo-line-theme";

export function NivoLineFromEndpoint({ endpoint, ...restProps }) {
  const { data, loading } = useEndpoint(endpoint);

  return loading ? (
    <Spin size="large" />
  ) : (
    <NivoTimeSeriesLineInner {...restProps} {...data} />
  );
}

export function NivoTimeSeriesLineInner({
  height,
  width,
  enableSlices = false,
  enablePoints = false,
  //
  data,
  yType,
  yMin,
  yMax,
  yTicks,
  yTitle,
  xTicks,
  xTitle,
  curveType,
}) {
  const yFormatter = succinctFormatterFactory(yType);
  if (_.isArray(xTicks)) {
    xTicks = xTicks.map((d) => moment(d).add(8, "hours").toDate());
  }
  return (
    <Line
      data={data}
      theme={NIVO_LINE_THEME}
      lineWidth={2}
      height={height}
      width={width}
      margin={{ top: 20, right: 32, bottom: 59, left: 94 }}
      animate={true}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        precision: "day",
        useUTC: true,
      }}
      xFormat="time:%m/%d/%y"
      yScale={{
        type: "linear",
        stacked: false,
        min: yMin,
        max: yMax,
      }}
      axisLeft={{
        format: yFormatter,
        tickSize: 5,
        tickValues: yTicks,
        tickPadding: 5,
        legend: yTitle,
        legendOffset: -76,
        legendPosition: "middle",
      }}
      axisBottom={{
        format: "%m/%d/%y",
        tickValues: xTicks,
        tickSize: 5,
        tickPadding: 5,
        legend: xTitle,
        legendOffset: 46,
        legendPosition: "middle",
      }}
      useMesh={!enableSlices}
      enableSlices={enableSlices}
      enablePoints={enablePoints}
      enableGridX={false}
      enableGridY={false}
      innerPadding={24}
      padding={24}
      tooltip={tooltipRendererFactory(yType)}
      colors={"#49aef0"}
      curve={curveType}
    />
  );
}

function succinctFormatterFactory(yType) {
  return SUCCINCT_FORMATTERS_BY_TYPE[yType];
}

export function formatterFactory(yType) {
  return FORMATTERS_BY_TYPE[yType];
}

function succinctMoneyFormatter(value) {
  return "$" + succinctFloatFormatter(value);
}

function succinctFloatFormatter(value, minPrecision = 0, maxPrecision = 0) {
  let suffix = "";
  if (value >= 1000000000) {
    suffix = "B";
    value = value / 1000000000;
  } else if (value >= 1000000) {
    suffix = "M";
    value = value / 1000000;
  } else if (value >= 1000) {
    suffix = "K";
    value = value / 1000;
  }
  const nf = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  });
  return nf.format(value) + suffix;
}

const SUCCINCT_FORMATTERS_BY_TYPE = {
  money: succinctMoneyFormatter,
  integer: (value) => succinctFloatFormatter(value, 0, 2),
  float: succinctFloatFormatter,
};

const FORMATTERS_BY_TYPE = {
  money: (value) => moneyVF({ value }),
  integer: (value) => integerVF({ value }),
  float: (value) => floatVF({ value }),
};

function tooltipRendererFactory(yType) {
  const yFormatter = formatterFactory(yType);
  return ({
    point: {
      data: { x, y },
    },
  }) => (
    <Tag
      large
      className={`${Classes.ELEVATION_1}`}
      css={`
        color: #fff !important;
        background-color: rgba(0, 25, 43, 0.8) !important;
      `}
    >
      {`${yFormatter(y)} on ${moment(x).format("M/D/YY")}`}
    </Tag>
  );
}
