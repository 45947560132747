import _ from "lodash";
import {
  DEFAULT_SELECTED_ENTITY_IDS,
  useSelectedEntityId,
} from "../../store/model-entity-selection";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { Unit } from "./unit";
import React from "react";
import AgTable from "../AgTable";
import { useEndpoint } from "../../helpers/use-endpoint";
import { AgColDef } from "../AgTable/types";
import { Spinner } from "@blueprintjs/core";
import { timestampComparator } from "../AgTable/gridOptions";
import { TableRow } from "../../store/table-model-factory";
import { InitialCategoryFilters } from "../AgTable/ag_table";
import { getEnrollmentBuzzUrl } from "../../entities/ecp/ecp-enrollment-entity";
import {
  getStudentBuzzUrl,
  getStudentGeniusUrl,
} from "../../entities/ecp/ecp-student-entity";

export function HistoryUnit({
  unitId,
  departmentNamesInitialCategoryFilter,
}: {
  unitId: string;
  departmentNamesInitialCategoryFilter: string[];
}): JSX.Element {
  const loading = _.max([
    DEFAULT_SELECTED_ENTITY_IDS.student === useSelectedEntityId("student"),
    useEnsureData("ecp_student"),
  ]);

  return (
    <Unit unitId={unitId} loading={loading}>
      <HistoryContent
        unitId={unitId}
        departmentNamesInitialCategoryFilter={
          departmentNamesInitialCategoryFilter
        }
      />
    </Unit>
  );
}

function HistoryContent({
  unitId,
  departmentNamesInitialCategoryFilter,
}: {
  unitId: string;
  departmentNamesInitialCategoryFilter: string[];
}): JSX.Element {
  // customerId <==> StudentIndex
  const customerId = +useSelectedEntityId("student");

  let usedEndpoint = useEndpoint(`/ecp/historical_enrollments/${customerId}`);

  const loading =
    usedEndpoint.loading ||
    !usedEndpoint.data ||
    !_.size(usedEndpoint.data as any[]) ||
    usedEndpoint.data[0].StudentIndex !== customerId;

  return loading ? (
    <Spinner />
  ) : (
    <HistoryContentInner
      rowData={usedEndpoint.data as TableRow[]}
      unitId={unitId}
      departmentNamesInitialCategoryFilter={
        departmentNamesInitialCategoryFilter
      }
    />
  );
}
function HistoryContentInner({
  rowData,
  unitId,
  departmentNamesInitialCategoryFilter,
}: {
  rowData: TableRow[];
  unitId: string;
  departmentNamesInitialCategoryFilter: string[];
}): JSX.Element {
  rowData = rowData
    .map((histEnr) => ({
      ...histEnr,
      id: histEnr.EnrollmentIndex,
    }))
    .sort(compareHistoricalEnrollments);
  const colDefs = useColDefs();
  const initialCategoryFilters = useInitialCategoryFilters(
    departmentNamesInitialCategoryFilter
  );
  const height = _.min([500, 64 + 48 * rowData.length]);
  return (
    <AgTable
      initialCategoryFilters={initialCategoryFilters}
      tableName={`historical-enrollments--student-${rowData[0].StudentIndex}`}
      tableNameSuffix={unitId}
      rowData={rowData}
      columnDefs={colDefs}
      ribbonTitle={"Historical Enrollments"}
      height={`${height}px`}
    />
  );
}

function useInitialCategoryFilters(
  departmentNamesInitialCategoryFilter: string[]
): InitialCategoryFilters {
  return _.size(departmentNamesInitialCategoryFilter)
    ? { DepartmentName: departmentNamesInitialCategoryFilter }
    : {};
}

function useColDefs(): AgColDef[] {
  return [
    {
      headerName: "Department",
      field: "DepartmentName",
      type: "categoryColumn",
      pinned: "left",
    },
    {
      headerName: "Course-Program Melded Display Name",
      field: "CourseProgramofStudyMeldedDisplayName",
      type: "categoryColumn",
      width: 193,
      pinned: "left",
    },
    {
      headerName: "Status",
      field: "Status",
      type: "categoryColumn",
      pinned: "left",
    },
    {
      headerName: "Start Date",
      field: "StartDate",
      type: "dateColumn",
    },
    {
      headerName: "End Date",
      field: "EndDate",
      type: "dateColumn",
    },
    {
      headerName: "Buzz Enr",
      field: "LMSID",
      type: "linkedTextColumn",
      __linkValueGetter: getEnrollmentBuzzUrl,
      __linkIsNotAnEmail: true,
    },
    {
      headerName: "Course Name",
      field: "CourseName",
      type: "textColumn",
      width: 228,
      suppressSizeToFit: true,
      tooltipField: "CourseName",
    },
    {
      headerName: "Course Display Name",
      field: "CourseDisplayName",
      type: "textColumn",
      width: 220,
    },
    {
      headerName: "Course-Program Display Name",
      field: "CourseProgramofStudyDisplayName",
      type: "categoryColumn",
      width: 220,
    },
    {
      headerName: "Current Grade",
      field: "CurrentGrade",
      type: "integer",
    },
    {
      headerName: "Final Grade",
      field: "FinalGrade",
      type: "textColumn",
    },
    {
      headerName: "Grade Lvl",
      field: "grade_level",
      type: "integerColumn",
      filter: "categoryFilterer",
    },
    {
      headerName: "Price",
      field: "Price",
      type: "moneyColumn",
    },
    {
      headerName: "Section",
      field: "SectionName",
      type: "textColumn",
      width: 241,
    },
    {
      headerName: "Genius Enrollment ID",
      field: "EnrollmentIndex",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "Genius Section ID",
      field: "SectionIndex",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "Genius Course ID",
      field: "CourseIndex",
      type: "integerIdentifierColumn",
    },
    { headerName: "Entry", field: "EntryDate", type: "dateColumn" },
    {
      headerName: "Genius Stu",
      field: "StudentIndex",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentGeniusUrl,
      __linkIsNotAnEmail: true,
      width: 97,
    },
    {
      headerName: "Buzz Stu",
      field: "sLMSID",
      type: "linkedTextColumn",
      __linkValueGetter: ({ sLMSID }) => getStudentBuzzUrl({ LMSID: sLMSID }),
      __linkIsNotAnEmail: true,
    },
  ];
}

function compareHistoricalEnrollments(a: TableRow, b: TableRow): number {
  const aDept = a.DepartmentName;
  const bDept = b.DepartmentName;
  let ret;
  if (aDept > bDept) {
    ret = -1;
  } else if (bDept > aDept) {
    ret = 1;
  } else {
    ret = timestampComparator(a.StartDate, b.StartDate);
  }
  return -1 * ret;
}
