import React from "react";
import { Spinner } from "@blueprintjs/core";
import AgTable from "../../components/AgTable";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { useStoreState } from "../../hooks/ep";
import {
  DEFAULT_STUDENT_ID,
  isDefaultStudentId,
} from "../../pages/loyalty/loyalty-students-splitview/splitview-helpers";
import { useColDefs } from "./loyalty-communications-coldefs";
import { useEndpoint } from "../../helpers/use-endpoint";

export function LoyaltyCommunicationsTable({
  fullScreenEnabled = true,
  studentId = DEFAULT_STUDENT_ID,
  onlyLoyaltyComms = false,
  ...restProps
}) {
  console.log(
    `In LoyaltyCommunicationsTable w/ studentId=${studentId}, ` +
      `onlyLoyaltyComms=${onlyLoyaltyComms}`
  );
  if (isDefaultStudentId(studentId) && onlyLoyaltyComms) {
    return (
      <OnlyLoyaltyCommsTableForAllStudents
        fullScreenEnabled={fullScreenEnabled}
        {...restProps}
      />
    );
  } else {
    return (
      <AllCommsTableForSingleStudent
        fullScreenEnabled={fullScreenEnabled}
        studentId={studentId}
        {...restProps}
      />
    );
  }
}

function OnlyLoyaltyCommsTableForAllStudents({
  fullScreenEnabled,
  ...restProps
}) {
  const loading = useEnsureData("loyaltyCommunications");
  return loading ? (
    <Spinner />
  ) : (
    <OnlyLoyaltyCommsTableForAllStudentsInner
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

function AllCommsTableForSingleStudent({
  fullScreenEnabled,
  studentId,
  ...restProps
}) {
  const { loading, data: rowData } = useEndpoint(
    `ecp/loyalty_stu_comms/student/${studentId}`
  );
  return loading ? (
    <Spinner />
  ) : (
    <AllCommsTableForSingleStudentInner
      fullScreenEnabled={fullScreenEnabled}
      rowData={rowData}
      studentId={studentId}
      {...restProps}
    />
  );
}

function OnlyLoyaltyCommsTableForAllStudentsInner({
  tableName = null,
  tableNameSuffix = null,
  ...restProps
}) {
  const columnDefs = useColDefs(DEFAULT_STUDENT_ID);
  const rowData = useStoreState((s) => s.loyaltyCommunications.initialData);
  return (
    <AgTable
      rowData={rowData}
      columnDefs={columnDefs}
      tableName={tableName || "only-loyalty-comms-table-for-all-students"}
      tableNameSuffix={tableNameSuffix}
      {...restProps}
    />
  );
}

function AllCommsTableForSingleStudentInner({
  studentId,
  rowData,
  tableName = null,
  tableNameSuffix = null,
  ...restProps
}) {
  const columnDefs = useColDefs(studentId);
  return (
    <AgTable
      rowData={rowData}
      columnDefs={columnDefs}
      tableName={tableName || "all-comms-table-for-single-student"}
      tableNameSuffix={tableNameSuffix}
      {...restProps}
    />
  );
}
