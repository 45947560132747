import { Recommendation } from "../types";
import React from "react";
import { History } from "./body-history";
import { Future } from "./body-future";

export function Body({
  nextCourseRecos,
}: {
  nextCourseRecos: Recommendation[];
}): JSX.Element {
  return (
    <div className={"single-dept-recos--body"}>
      <History nextCourseRecos={nextCourseRecos} />
      <Future nextCourseRecos={nextCourseRecos} />
    </div>
  );
}
