import { useStoreState } from "../../hooks/ep";
import { Select } from "@blueprintjs/select";
import { Button, Classes, Intent, MenuItem } from "@blueprintjs/core";
import React, { useCallback, useMemo } from "react";
import "styled-components/macro";
import _ from "lodash";
import { TableRow } from "../../store/table-model-factory";
import { useSyncSelectedWithQueryParams } from "./use-sync-qp-with-selected";
import { StringParam, useQueryParam } from "use-query-params";
import { useEntitySelection } from "../../store/model-entity-selection";

type CustomerId = string;
export type Predicate = { (row: TableRow): boolean };

export function useAllCustomerIds({
  filter,
}: {
  filter: null | Predicate;
}): CustomerId[] {
  let customerRows = useStoreState((s) => s.ecp_student.rowData) as TableRow[];

  return useMemo(() => {
    let viableCustRows = customerRows;
    if (!_.isNil(filter)) {
      viableCustRows = viableCustRows.filter(filter);
    }
    let ret = viableCustRows.map((el) => el.customer_id);
    ret = _.sortBy(ret);
    ret = ret.map((v) => v.toString());
    return ret;
  }, [customerRows, filter]);
}

function useCustomerId2Name(): { [id: number]: string } {
  let customerRows = useStoreState((s) => s.ecp_student.rowData) as TableRow[];
  return Object.fromEntries(
    customerRows.map((row) => [row.customer_id, row.customer_name])
  );
}

export function StudentSelector({
  filter,
}: {
  filter: null | Predicate;
}): JSX.Element {
  const [selectedCustomerId, selectCustomerId] = useEntitySelection("student");
  const allCustomerIds = useAllCustomerIds({ filter });
  const id2name = useCustomerId2Name();
  const [qpSelectedCustomerId, qpSelectCustomerId] = useQueryParam(
    "student",
    StringParam
  );

  useSyncSelectedWithQueryParams(
    allCustomerIds,
    selectedCustomerId,
    selectCustomerId,
    qpSelectedCustomerId,
    qpSelectCustomerId
  );

  const onItemSelect = useCallback(
    (v) => {
      qpSelectCustomerId(v, "pushIn");
    },
    [qpSelectCustomerId]
  );

  return (
    <div
      css={`
        display: flex;
        align-items: center;
        & .customer-name-select-btn {
          box-shadow: none !important;
        }
      `}
    >
      <Select
        large
        intent="primary"
        itemPredicate={itemPredicateFactory(id2name)}
        itemRenderer={itemRendererFactory(id2name)}
        items={allCustomerIds}
        onItemSelect={onItemSelect}
        popoverProps={POPOVER_PROPS}
      >
        <Button
          intent={Intent.NONE}
          className="customer-name-select-btn"
          large
          text={id2name[selectedCustomerId]}
          rightIcon="caret-down"
        />
      </Select>
    </div>
  );
}

function itemRendererFactory(id2name) {
  return function itemRenderer(v, { handleClick, modifiers }) {
    return (
      <MenuItem
        active={false}
        key={v}
        label={id2name[v]}
        onClick={handleClick}
        text={v}
      />
    );
  };
}

function itemPredicateFactory(id2name) {
  return function itemPredicate(
    query: string,
    item: string,
    index: number,
    exactMatch: boolean
  ) {
    const customerName = id2name[item];
    const queryPatt = new RegExp(`^.*\\b${_.escapeRegExp(query)}.*$`, "gi");
    const isCustomerNameMatch = customerName && queryPatt.test(customerName);
    const isItemMatch = queryPatt.test(item);

    if (exactMatch) {
      return item === query || customerName === query;
    } else if (!query.length) {
      return true;
    } else {
      return isItemMatch || isCustomerNameMatch;
    }
  };
}

const POPOVER_PROPS = { targetClassName: Classes.ELEVATION_1 };
