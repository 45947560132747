import { useColDefsForCampaignEmailsTable } from "../../entities/ecp/ecp-campaignEmail-entity";
import AgTable from "../../components/AgTable";
import React from "react";
import { DEFAULT_SHOULD_FETCH, useEndpoint } from "../../helpers/use-endpoint";
import { Spinner } from "@blueprintjs/core";

export function CampaignemailTable() {
  // let rowData = useStoreState((s) =>
  //   s.ecp_campaignEmail.rowData.filter((row) => isCgnEmail(row.email_type_code))
  // );
  const { loading, data: rowData } = useEndpoint(
    "ecp/ff_campaignEmails/new_customer_sales",
    DEFAULT_SHOULD_FETCH,
    { responseFormat: "arrow" }
  );
  return loading || !rowData ? (
    <Spinner />
  ) : (
    <CampaignemailTableInner rowData={rowData} />
  );
}

export function CampaignemailTableInner({ rowData }) {
  const colDefs = useColDefsForCampaignEmailsTable();
  return (
    <AgTable
      rowData={rowData.toArray()}
      columnDefs={colDefs}
      tableName={"ecp_campaignEmail--new-customer-sales"}
      ribbonTitle={"Campaign Emails"}
      height={"calc(100vh - 64px - 40px - 40px - 46px - 48px)"}
    />
  );
}

//
// export const CGN4A_EMAIL_TYPE_CODE_PATT =
//   /^customer__hot_lead_outreach(\d+)(_\w+)?$/;
// export const CGN5A_EMAIL_TYPE_CODE_PATT =
//   /^customer__cold_lead_outreach(\d+)(_\w+)?$/;
//
// function isCgnEmail(code: string): boolean {
//   if (CGN4A_EMAIL_TYPE_CODE_PATT.test(code)) {
//     return true;
//   } else if (CGN5A_EMAIL_TYPE_CODE_PATT.test(code)) {
//     return true;
//   } else {
//     return false;
//   }
// }
