import React, { useCallback } from "react";
import { Alignment, Switch, Tooltip } from "@blueprintjs/core";
import styled from "styled-components/macro";
import { RowH4 } from "../../components/units/selection-unit";
import { useEntitySelection } from "../../store/model-entity-selection";

export function ActionableStudentCourseScheduling({
  noActionableStudents,
}: {
  noActionableStudents: boolean;
}): JSX.Element {
  const [
    selectedShouldFilterActionableStudentsCourseScheduling,
    selectShouldFilterActionableStudentsCourseScheduling,
  ] = useEntitySelection("should_filter_actionable_students_course_scheduling");

  const onChange = useCallback(
    (event) => {
      selectShouldFilterActionableStudentsCourseScheduling(
        event.target.checked
      );
    },
    [selectShouldFilterActionableStudentsCourseScheduling]
  );

  return (
    <Tooltip
      content={
        "This switch filters down to students who have orders that are in an acceptable status and also have a valid start date for course schedule propagation"
      }
    >
      <RowH4>
        <span style={{ marginLeft: "20px" }}>Actionable:</span>
        <StyledSwitch
          large
          alignIndicator={Alignment.RIGHT}
          checked={
            selectedShouldFilterActionableStudentsCourseScheduling &&
            !noActionableStudents
          }
          onChange={onChange}
          disabled={noActionableStudents}
        />
      </RowH4>
    </Tooltip>
  );
}

const StyledSwitch = styled(Switch)`
  && {
    margin-bottom: 0;
  }

  input:not(:checked) ~ .bp3-control-indicator {
    background: #f5f8fa !important;
    opacity: 0.5 !important;
  }

  &:hover input:not(:checked) ~ .bp3-control-indicator {
    background: #f5f8fa !important;
    opacity: 0.7 !important;
  }
`;
