import { tint } from "polished";
import { percentageVF } from "../../../../components/AgTable/gridOptions";
import React from "react";

export const RECO_STRENGTH__ELEM_WIDTH = "3.8em";
export const RECO_STRENGTH__BASE_COLOR = "#a2f66d";

export function Strength({ proba }: { proba: number }): JSX.Element {
  const inverseProba = 1.0 - proba / 100;
  return (
    <span
      className={"recommendation-probability"}
      css={`
        width: ${RECO_STRENGTH__ELEM_WIDTH};
        color: ${tint(inverseProba, RECO_STRENGTH__BASE_COLOR)};
      `}
    >
      {formatProba(proba)}
    </span>
  );
}

function formatProba(proba: number): string {
  return `(${percentageVF(proba)})`;
}
