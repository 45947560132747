import React from "react";
import _ from "lodash";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { Colors } from "@blueprintjs/colors";
import {
  Classes,
  Popover,
  PopoverInteractionKind,
  Position,
} from "@blueprintjs/core";
import { SPECIAL_AC_NAMES } from "../../../entities/admissionsCoordinator-entity";
import { AdmissionsCoordinatorsKPIPopoverTarget } from "./popover-target";
import { AdmissionsCoordinatorsKPIPopoverContent } from "./popover-content";

export const KPI_INTENT_COLORS = {
  none: "inherit",
  primary: Colors.BLUE3,
  danger: Colors.RED3,
  success: Colors.GREEN3,
  warning: Colors.ORANGE3,
};

export function AdmissionsCoordinatorsKPICellRenderer({
  value: cellValue,
  data,
  colDef,
  crp,
}) {
  crp = crp || {
    dailyRelatedField: colDef.field,
    getIntent: () => Intent.NONE,
  };
  const invalidCellValue = _.isNil(cellValue) || _.isNaN(cellValue);
  const isSpecialRow = Object.values(SPECIAL_AC_NAMES).includes(
    data.AC_FullName
  );
  const intent =
    invalidCellValue ||
    (isSpecialRow ? Intent.NONE : crp.getIntent(data[crp.dailyRelatedField]));
  return invalidCellValue ? (
    <AdmissionsCoordinatorsKPIPopoverTarget cellValue={"N/A"} intent={intent} />
  ) : (
    <AdmissionsCoordinatorsKPIPopover
      cellValue={cellValue}
      colDef={colDef}
      data={data}
      crp={crp}
      intent={intent}
    />
  );
}

export function AdmissionsCoordinatorsKPIPopover({
  cellValue,
  colDef,
  data,
  crp,
  position,
  width,
  intent,
}) {
  return (
    <Popover
      content={
        <AdmissionsCoordinatorsKPIPopoverContent
          cellValue={cellValue}
          kpiName={colDef.headerName}
          crp={crp}
          data={data}
          intent={intent}
        />
      }
      position={position ?? Position.LEFT_TOP}
      interactionKind={PopoverInteractionKind.HOVER}
      popoverClassName={`${Classes.ELEVATION_4}`}
      hoverOpenDelay={300}
      fill={true}
      boundary={"viewport"}
    >
      <AdmissionsCoordinatorsKPIPopoverTarget
        cellValue={cellValue}
        intent={intent}
        width={width}
      />
    </Popover>
  );
}
