import { submitUserActionPostRequest } from "./user_actions";

export async function createNewUser(
  action_payload: ActionPayload_CreateNewUser
) {
  return await submitUserActionPostRequest("create_new_user", action_payload);
}

export interface NewUser {
  role: string;
  email: string;
  timezone: string;
  first_name: string;
  last_name: string;
}

export interface ActionPayload_CreateNewUser {
  email: string;
  formData: NewUser;
}
