import {
  DataSnapshot,
  submitUserActionPostRequest,
  UserAction,
} from "./user_actions";
import { CallTopic } from "../components/reporting-actions/call-topics-input";
import { CustomerIssue } from "../components/reporting-actions/customer-issues-input";
import { assert } from "../helpers/assertions";
import {
  ALL_CUSTOMER_SENTIMENT_VALUES,
  CustomerSentimentValue,
} from "../components/reporting-actions/customer-sentiment-input";
import {
  ALL_CHURN_LIKELIHOOD_VALUES,
  ChurnLikelihoodValue,
} from "../components/reporting-actions/churn-likelihood-input";

export async function submitUserAction__ree_customer_interaction(
  actionPayload: ActionPayload_ReeCustomerInteraction
) {
  verifyActionPayload(actionPayload);
  return await submitUserActionPostRequest(
    "ree_customer_interaction",
    actionPayload
  );
}

function verifyActionPayload(
  actionPayload: ActionPayload_ReeCustomerInteraction
): void {
  assert(typeof actionPayload.customer_id === typeof 12345);
  assert(
    actionPayload.customer_id === actionPayload.customer_snapshot.customer_id
  );
  assert(
    ALL_CUSTOMER_SENTIMENT_VALUES.includes(
      actionPayload.form_payload.customerSentiment as CustomerSentimentValue
    )
  );
  assert(
    ALL_CHURN_LIKELIHOOD_VALUES.includes(
      actionPayload.form_payload.churnLikelihood as ChurnLikelihoodValue
    )
  );
}

export interface ActionPayload_ReeCustomerInteraction {
  customer_id: number;
  form_payload: {
    callTopics: CallTopic[];
    customerIssues: CustomerIssue[];
    notes: string;
    attendees: string;
    customerSentiment: string | CustomerSentimentValue;
    churnLikelihood: string | ChurnLikelihoodValue;
  };
  customer_snapshot: DataSnapshot;
}

export type UserActionCode_ReeCustomerInteraction = "ree_customer_interaction";
export interface UserAction_ReeCustomerInteraction
  extends UserAction<
    UserActionCode_ReeCustomerInteraction,
    ActionPayload_ReeCustomerInteraction
  > {}
