import { useLoyaltyStudentsTitle } from "./titles";
import { Tab } from "@blueprintjs/core";
import React from "react";
import { TableRow } from "../../store/table-model-factory";
import _ from "lodash";
import moment from "moment";
import "styled-components/macro";
import { LOYALTY_TAB_IDS } from "./loyalty-table-tabs-unit";
import { LoyaltyStudentsAndCommunicationsSplitview } from "./loyalty-students-splitview/loyalty-students-and-communications-splitview";

export function useLoyaltyStudentsTab() {
  const title = useLoyaltyStudentsTitle();
  return (
    <Tab
      id={LOYALTY_TAB_IDS.students}
      title={title}
      panel={
        <LoyaltyStudentsAndCommunicationsSplitview
          finalizeStudentRowData={finalizeRowData}
          viewId={"loyalty-students-and-communications-splitview"}
        />
      }
    />
  );
}

export function isPriEnrStu(row: TableRow): boolean {
  return row.LoyaltyEligible;
}

export function isStudentOfRecord(row: TableRow): boolean {
  /* "Student of Record" <==> "SoR" */
  return !!row.SchoolRecord;
}

function finalizeRowData(rowData: TableRow[]): TableRow[] {
  // Switched out the following line on 2021-09-28 to include all
  // SoR students on the loyalty dashboard
  // rowData = rowData.filter(isPriEnrStu);
  rowData = rowData.filter(isStudentOfRecord);

  rowData = _.sortBy(rowData, (row) => moment(row.latest_geniusComm_sent_at));
  rowData = _.sortBy(rowData, (row) => row.is_loyalty_reenrolled);
  rowData = _.sortBy(rowData, (row) => row.student_flag__no_action_required);
  return rowData;
}
