import React from "react";
import _ from "lodash";
import { Spinner } from "@blueprintjs/core";
import "styled-components/macro";
import { useEndpoint } from "../../helpers/use-endpoint";

import {
  Cgn4a_CampaignStats,
  Cgn5a_CampaignStats,
  Cgn9a_CampaignStats,
  Cgn10a_CampaignStats,
  Cgn18a_CampaignStats,
} from "./common";
import { NewCustomerSuccessCampaignPageInner } from "./page-inner";

export function NewCustomerSuccessCampaignPage() {
  const { loading: loading_cgn4aStats, data: cgn4aData } = useEndpoint<
    Cgn4a_CampaignStats[]
  >("ecp/campaign_stats/cgn4a");

  const { loading: loading_cgn5aStats, data: cgn5aData } = useEndpoint<
    Cgn5a_CampaignStats[]
  >("ecp/campaign_stats/cgn5a");

  const { loading: loading_cgn9aStats, data: cgn9aData } = useEndpoint<
    Cgn9a_CampaignStats[]
  >("ecp/campaign_stats/cgn9a");

  const { loading: loading_cgn10aStats, data: cgn10aData } = useEndpoint<
    Cgn10a_CampaignStats[]
  >("ecp/campaign_stats/cgn10a");

  const { loading: loading_cgn18aStats, data: cgn18aData } = useEndpoint<
    Cgn18a_CampaignStats[]
  >("ecp/campaign_stats/cgn18a");

  const loading = _.max([
    loading_cgn4aStats,
    loading_cgn5aStats,
    loading_cgn9aStats,
    loading_cgn10aStats,
    loading_cgn18aStats,
    // useEnsureData("ecp_campaignEmail"),
  ]);

  return loading ? (
    <Spinner />
  ) : (
    <NewCustomerSuccessCampaignPageInner
      cgn4aData={cgn4aData}
      cgn5aData={cgn5aData}
      cgn9aData={cgn9aData}
      cgn10aData={cgn10aData}
      cgn18aData={cgn18aData}
    />
  );
}
