import { Computed, computed, thunk } from "easy-peasy";
import { StoreModel } from "./model";
import { TableModel, tableModelFactory, TableRow } from "./table-model-factory";
import {
  makeAuthorizedGetRequestToBackend3,
  makeUrl,
} from "../helpers/backendApi";
import { timeit } from "../helpers/timing";
import _ from "lodash";

export type LeadsQueueModel = TableModel & {
  rowData: Computed<LeadsQueueModel, TableRow[], StoreModel>;
  rowDataWithCustomerInteractionFlag: Computed<
    LeadsQueueModel,
    TableRow[],
    StoreModel
  >;
};

export function getLeadsQueueModel(): LeadsQueueModel {
  return {
    ...tableModelFactory(
      "leadsQueue",
      "leads_queue",
      (row) => row.ns_customer_id,
      [
        {
          name: "interactionReport",
          arity: "OneToOne",
          foreignName: "lead_customerInteraction",
          foreignField: ["action_payload", "customer_id"],
          field: "ns_customer_id",
        },
      ]
    ),
    rowData: computed([(state) => state.initialData]),
    rowDataWithCustomerInteractionFlag: computed(
      [
        (state) => state.initialData,
        (state, storeState) => storeState.lead_customerInteraction.byCustomerId,
      ],
      timeit((rowData, byCustomerId) => {
        return rowData.map((row) => {
          const customer_id = row.ns_customer_id;
          const interactionPayload = byCustomerId[customer_id];
          return {
            ...row,
            has_customer_interaction: !!(
              interactionPayload && _.size(interactionPayload) >= 1
            ),
          };
        });
      }, "store.leadsQueue.rowDataWithCustomerInteractionFlag")
    ),
    handleFetchInitialData: thunk(async (actions, payload, { getState }) => {
      const { INITIAL_DATA_ENDPOINT, fetchQueryParams } = getState();
      const data = await makeAuthorizedGetRequestToBackend3({
        url: makeUrl(INITIAL_DATA_ENDPOINT, {
          responseFormat: "json",
          ...fetchQueryParams,
        }),
      });
      actions.receiveInitialData(data);
      actions.markInitialDataReceived();
    }),
  };
}
