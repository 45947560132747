import React, { useState } from "react";
import { Tabs } from "@blueprintjs/core";
import { useLoyaltyStudentsTab } from "./loyalty-students-tab";
import { useLoyaltyCommunicationsTab } from "./loyalty-communications-tab";
import "styled-components/macro";

export const LOYALTY_TAB_IDS = {
  students: "loyalty-students-tab",
  communications: "loyalty-communications-tab",
};

export function LoyaltyTableTabsUnit({
  fullScreenEnabled = true,
  ...restProps
}) {
  const defaultTabId = LOYALTY_TAB_IDS.students;
  const [selectedTabId, setSelectedTabId] = useState(defaultTabId);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };

  const studentsTab = useLoyaltyStudentsTab();
  const communicationsTab = useLoyaltyCommunicationsTab();
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
      `}
    >
      <Tabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        fill
        renderActiveTabPanelOnly={true}
        css={`
          margin-top: -15px;
        `}
      >
        {studentsTab}
        {communicationsTab}
      </Tabs>
    </div>
  );
}
