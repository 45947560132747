export type EmailTypeCode = string;

export enum CampaignType {
  CUSTOMER_SUCCESS = "customer_success",
  NEW_CUSTOMER_SALES = "new_customer_sales",
}

export enum CampaignStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum CampaignOperationCriteria {
  DIRECT_OUTREACH = "direct_outreach",
  STATIC_SCHEDULED = "static_scheduled",
  DYNAMIC_SCHEDULED = "dynamic_scheduled",
  CUSTOMER_STATE_BASED = "customer_state_based",
}

export interface CampaignConfiguration {
  campaign_codename: string;
  allowed_email_type_codes: EmailTypeCode[];
  display_name: string;
  campaign_type: CampaignType;
  campaign_operation_criteria: CampaignOperationCriteria;
  email_body_templates_by_email_type_code: {
    [email_type_code: string]: string;
  };
  email_subject_templates_by_email_type_code: {
    [email_type_code: string]: string;
  };
  wave_numbers_by_email_type_code: {
    [email_type_code: string]: number;
  };
  sending_schedule: {
    [email_type_code: string]: string;
  };
  campaign_status: CampaignStatus;
}

export interface CampaignSelectorOption {
  campaign_codename: string;
  display_name: string;
  campaign_status?: CampaignStatus;
}

export interface AttributeItem {
  headerName: string;
  formattedValue: any;
}

export interface AttributeUnitConfig {
  headerName: string;
  field: string;
  valueFormatter?: (value: any) => any;
}
