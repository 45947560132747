import { NonIdealState } from "@blueprintjs/core";
import React from "react";
import "styled-components/macro";

export function NoData() {
  return (
    <NonIdealState
      icon="inbox"
      title="No Data"
      description={
        "If this behavior is unexpected, please press the button below to kick off an email chain with the developers."
      }
      action={<EmailTeamForHelpButton />}
    />
  );
}

function EmailTeamForHelpButton() {
  const et = {
    subject: "Customax Data Issue",
    body: `
Hi SaxeCap Team,

Please help me debug a data issue I encountered on Customax.

While I expected to see _____ when I visited the _____ page, I instead saw _____.

Please [[do/do not]] notify me via email once you solve this issue.

Thank you.
`.trim(),
  };
  const subject = encodeURIComponent(et.subject);
  const body = encodeURIComponent(et.body);
  return (
    <a
      className="bp3-button bp3-intent-primary"
      href={`mailto:minerva-admin@saxecap.com?subject=${subject}&body=${body}`}
      target={"_blank"}
      rel={"noopener noreferrer"}
    >
      Request Assistance
    </a>
  );
}
