import { useStoreActions, useStoreState } from "../../hooks/ep";
import { Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";
import React, { useMemo } from "react";
import "styled-components/macro";
import _ from "lodash";
import { ActionCreator } from "easy-peasy";
import { TableRow } from "../../store/table-model-factory";
import { useSyncSelectedWithQueryParams } from "./use-sync-qp-with-selected";
import { StringParam, useQueryParam } from "use-query-params";
import { AdmissionsCoordinatorName } from "../../store/model-ac-selection";
import { useEndpoint } from "../../helpers/use-endpoint";
import { isAdminRoleForACDailyQueue } from "../../pages/ac-daily-queue-page/ac-daily-queue-page";
import { SPECIAL_AC_NAMES } from "../../entities/admissionsCoordinator-entity";
import { useMeData } from "../../hooks/useMe";
import styled from "styled-components/macro";

export function useAllAdmissionsCoordinators(): {
  loading: boolean;
  data: AdmissionsCoordinatorName[];
} {
  const { loading, data: acRows } = useEndpoint<TableRow[]>(
    "admissions_coordinators",
    true
  );

  return {
    loading,
    data: useMemo(() => {
      let ret = acRows
        ? [
            ...Array.from(new Set(acRows.map((el) => el.AC_FullName))).filter(
              (el) => !Object.values(SPECIAL_AC_NAMES).includes(el)
            ),
          ]
        : [];
      ret.sort();
      return ret;
    }, [acRows]),
  };
}

export function useAdmissionsCoordinatorSelection(): [
  AdmissionsCoordinatorName,
  ActionCreator<AdmissionsCoordinatorName>
] {
  let selectedAdmissionsCoordinatorName = useStoreState(
    (s) => s.admissionsCoordinatorSelection.selectedAdmissionsCoordinatorName
  );
  const me = useMeData();
  const isAdminRole = isAdminRoleForACDailyQueue(me.role);
  if (!isAdminRole) {
    selectedAdmissionsCoordinatorName = `${me.first_name?.trim()} ${me.last_name?.trim()}`;
  }
  const selectAdmissionsCoordinatorName = useStoreActions(
    (a) => a.admissionsCoordinatorSelection.selectAdmissionsCoordinatorName
  );

  return [selectedAdmissionsCoordinatorName, selectAdmissionsCoordinatorName];
}

export function AdmissionsCoordinatorSelector({
  allAdmissionsCoordinatorNames,
  selectedAdmissionsCoordinatorName,
  selectAdmissionsCoordinatorName,
  hide = false,
}): JSX.Element {
  const [
    qpSelectedAdmissionsCoordinatorName,
    qpSelectAdmissionsCoordinatorName,
  ] = useQueryParam("ac", StringParam);
  useSyncSelectedWithQueryParams(
    allAdmissionsCoordinatorNames,
    selectedAdmissionsCoordinatorName,
    selectAdmissionsCoordinatorName,
    qpSelectedAdmissionsCoordinatorName,
    qpSelectAdmissionsCoordinatorName
  );

  return hide ? null : (
    <ACSelectorContainer>
      <label className="bp3-label">
        Admissions Coordinator
        <Select
          large
          intent="primary"
          itemPredicate={itemPredicate}
          itemRenderer={(v, { handleClick, modifiers }) => {
            return (
              <MenuItem
                active={false}
                key={v}
                label={""}
                onClick={handleClick}
                text={v}
              />
            );
          }}
          items={_.sortBy(allAdmissionsCoordinatorNames)}
          onItemSelect={(v) => {
            qpSelectAdmissionsCoordinatorName(v, "pushIn");
          }}
        >
          <Button
            large
            text={selectedAdmissionsCoordinatorName}
            rightIcon="caret-down"
          />
        </Select>
      </label>
    </ACSelectorContainer>
  );
}

function itemPredicate(
  query: string,
  item: string,
  index: number,
  exactMatch: boolean
) {
  item = item.toLowerCase();
  query = query.toLowerCase();
  if (exactMatch) {
    return item === query;
  } else if (!query.length) {
    return true;
  } else {
    return _.startsWith(item, query);
  }
}

const ACSelectorContainer = styled.div`
  width: 200px;
  & .bp3-button {
    width: 100%;
    justify-content: space-between;
  }
`;
