import {
  GetRowId,
  TableModel,
  tableModelFactory,
  TableRow,
  TableRowId,
} from "./table-model-factory";
import { Actions, Computed, computed, Thunk, thunk } from "easy-peasy";
import {
  ActionPayload_LeadCustomerInteraction,
  submitUserAction__lead_customer_interaction,
} from "../user-actions/submit-user-action__lead_customer_interaction";
import _ from "lodash";
import { toaster } from "../toaster";
import { UserAction } from "../user-actions/user_actions";
import { StoreModel } from "./model";
import { Injections } from "./store-injections";

export type LeadCustomerInteractionModel = Omit<
  TableModel,
  "rowIds" | "rowExists"
> & {
  handleReportCustomerInteraction: Thunk<
    LeadCustomerInteractionModel,
    ActionPayload_LeadCustomerInteraction,
    Injections,
    StoreModel
  >;
  byCustomerId: Computed<
    LeadCustomerInteractionModel,
    { [key: number]: UserAction[] },
    StoreModel
  >;
  customerId_to_latest: Computed<
    LeadCustomerInteractionModel,
    { [key: number]: UserAction },
    StoreModel
  >;
  rowIds: Computed<LeadCustomerInteractionModel, Set<TableRowId>, StoreModel>;
  rowExists: Computed<
    LeadCustomerInteractionModel,
    { (row: TableRow): boolean },
    StoreModel
  >;
};

export function getLeadCustomerInteractionModel(): LeadCustomerInteractionModel {
  return {
    ...tableModelFactory(
      "lead_customerInteraction",
      "ecp/lead_customer_interactions",
      (row) => row.id
    ),
    handleReportCustomerInteraction: thunk(
      async (
        actions: Actions<LeadCustomerInteractionModel>,
        userActionPayload: ActionPayload_LeadCustomerInteraction,
        { getState }
      ) => {
        const state = getState();
        // @ts-ignore
        const maxRowId = _.max([...state.rowIds]) ?? 0;
        const rowId = state.GET_ROW_ID(userActionPayload);
        const oldRow = state.getRow(rowId);
        const nextRowId = maxRowId + 1;
        actions.upsertRow({
          id: nextRowId,
          action_payload: userActionPayload,
        });
        try {
          const { data } = await submitUserAction__lead_customer_interaction(
            userActionPayload
          );
          toaster.success("Successfully reported customer interaction", 2);
          actions.deleteRow(nextRowId);
          actions.upsertRow(data);
        } catch (e) {
          if (oldRow) {
            actions.upsertRow(oldRow);
          } else {
            actions.deleteRow(rowId);
          }
          toaster.warning(
            "Failed to report customer interaction -- please try again"
          );
          throw Error();
        }
      }
    ),
    byCustomerId: computed(
      [(s) => s.initialData],
      (initialData: UserAction[]) =>
        _.groupBy(initialData, "action_payload.customer_id")
    ),
    customerId_to_latest: computed(
      [(s) => s.byCustomerId],
      (byCustomerId: { [key: string]: UserAction[] }) =>
        Object.fromEntries(
          Object.entries(byCustomerId).map(([customerId, itxns]) => [
            customerId,
            _.maxBy(itxns, "submitted_at"),
          ])
        )
    ),
    rowIds: computed(
      [(s) => s.initialData, (s) => s.GET_ROW_ID],
      (initialData: TableRow[], GET_ROW_ID: GetRowId) =>
        new Set(initialData.map((row: UserAction) => GET_ROW_ID(row)))
    ),
    rowExists: computed(
      [(s) => s.rowIds, (s) => s.GET_ROW_ID],
      (rowIds: Set<TableRowId>, GET_ROW_ID: GetRowId) => (row: UserAction) =>
        rowIds.has(GET_ROW_ID(row))
    ),
  };
}
