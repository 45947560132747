import moment from "moment-timezone";
import { useMe, useMeData } from "../../hooks/useMe";
import { useStoreActions, useStoreState } from "../../hooks/ep";
import { useEndpoint } from "../../helpers/use-endpoint";
import { useEffect } from "react";

export const STATLINE_NAMES = {
  netsuiteDataRefreshedAt: "Netsuite Data Refreshed At",
  buzzAndGeniusDataRefreshedAt: "Buzz & Genius Data Refreshed At",
};

export function useBuzzAndGeniusDataRefreshedAt(): string {
  const dts = useStoreState((s) => s.misc.buzzAndGeniusDataRefreshedAt);
  const setDts = useStoreActions((a) => a.misc.setBuzzAndGeniusDataRefreshedAt);
  const { loading, data } = useEndpoint<string>(
    "buzz_and_genius_data_refreshed_at",
    !dts
  );

  useEffect(() => {
    // @ts-ignore
    if (!loading && !dts && !!data && !!data.data_refreshed_at) {
      // @ts-ignore
      setDts(data.data_refreshed_at);
    }
  }, [loading, data]);

  return useFormattedTimestamp(dts);
}

export function useNetsuiteDataRefreshedAt(): string {
  const dts = useStoreState((s) => s.misc.netsuiteDataRefreshedAt);
  const setDts = useStoreActions((a) => a.misc.setNetsuiteDataRefreshedAt);
  const { loading, data } = useEndpoint<string>(
    "netsuite_data_refreshed_at",
    !dts
  );

  useEffect(() => {
    // @ts-ignore
    if (!loading && !dts && !!data && !!data.data_refreshed_at) {
      // @ts-ignore
      setDts(data.data_refreshed_at);
    }
  }, [loading, data]);

  return useFormattedTimestamp(dts);
}

export function useStatlineContent(dts: string, name: string): string {
  const timezone = useUserTimezone();
  if (dts && timezone) {
    return `${name}: ${formatTimestamp(dts, timezone)}`;
  } else {
    return "";
  }
}

export function useUserTimezone(): null | string {
  const { loading } = useMe();
  const meData = useMeData();
  if (loading || !meData || !meData.timezone) {
    return null;
  } else {
    return meData.timezone;
  }
}

function formatTimestamp(dts: string, timezone: string): string {
  return moment(dts).tz(timezone).format("MM/DD/YY [at] hh:mm A (z)");
}

export function useFormattedTimestamp(dts: string): string {
  const timezone = useUserTimezone();
  if (dts && timezone) {
    return formatTimestamp(dts, timezone);
  } else {
    return "";
  }
}
