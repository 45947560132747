import { Action, action, ActionOn, actionOn } from "easy-peasy";
import { StoreModel } from "./model";
import queryString from "query-string";

export type EnrollmentCoordinatorIndex = number;

export type EnrollmentCoordinator = {
  EC_FullName: string;
  EC_Email: string;
  EC_Index: number;
  n_recent_completion_students: number;
  n_upcoming_renr_potential_students: number;
  n_active_stus_within_past_1_to_365_days: number;
  n_recent_completion_students__not_reenrolled: number;
  n_upcoming_renr_potential_students__not_reenrolled: number;
  pct_recent_completion_students__not_reenrolled: number;
  pct_upcoming_renr_potential_students__not_reenrolled: number;
  n_active_stus_within_past_366_to_730_days: number;
  n_renr_stus_within_past_1_to_365_days: number;
  retention_churn_rate_current_year: number;
};

const qpEnrollmentCoordinatorIndex = queryString.parse(
  window.location.search
).ecIndex;
export const ENROLLMENT_COORDINATOR_INDEX__ALL = -1;
const DEFAULT_SELECTED_EC_INDEX = qpEnrollmentCoordinatorIndex
  ? parseInt(qpEnrollmentCoordinatorIndex)
  : ENROLLMENT_COORDINATOR_INDEX__ALL;

export interface EnrollmentCoordinatorSelectionModel {
  NAME: string;
  selectedEnrollmentCoordinatorIndex: EnrollmentCoordinatorIndex;
  selectEnrollmentCoordinatorIndex: Action<
    EnrollmentCoordinatorSelectionModel,
    EnrollmentCoordinatorIndex
  >;
  onLogout: ActionOn<EnrollmentCoordinatorSelectionModel, StoreModel>;
}

export const modelEnrollmentCoordinatorSelection: EnrollmentCoordinatorSelectionModel =
  {
    NAME: "enrollmentCoordinatorSelection",
    selectedEnrollmentCoordinatorIndex: DEFAULT_SELECTED_EC_INDEX,
    selectEnrollmentCoordinatorIndex: action(
      (state, newEnrollmentCoordinator) => {
        state.selectedEnrollmentCoordinatorIndex = newEnrollmentCoordinator;
      }
    ),
    onLogout: actionOn(
      (__, { me }) => me.resetData,
      (state, target) => {
        state.selectedEnrollmentCoordinatorIndex = DEFAULT_SELECTED_EC_INDEX;
      }
    ),
  };
