import { useEffect } from "react";
import smartlookClient from "smartlook-client";
import { useBoolean } from "../helpers/useBoolean";

const PROJECT_KEY = "aac01f6a251f2c9e15496ff67dd87398b4259640";

export function useSmartlook(meData) {
  useInit();
  useIdentify(meData);
}

function maybeInitSmartlook() {
  if (process.env.NODE_ENV === "production") {
    try {
      smartlookClient.init(PROJECT_KEY);
    } catch (e) {
      console.log("Failed smartlook init");
    }
  }
}

function useInit() {
  useEffect(() => {
    maybeInitSmartlook();
  }, []);
}

function maybeIdentifyMyselfToSmartlook(myEmail, meData, identified) {
  if (myEmail && !identified.value) {
    try {
      smartlookClient.identify(myEmail, meData);
      identified.setTrue();
    } catch (e) {
      console.log("Failed smartlook identify");
    }
  }
}

function useIdentify(meData) {
  const identified = useBoolean(false);
  const myEmail = meData?.email ?? null;

  useEffect(() => {
    maybeIdentifyMyselfToSmartlook(myEmail, meData, identified);
  }, [myEmail]);
}
