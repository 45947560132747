import { Recommendation } from "../types";
import { Card, Elevation } from "@blueprintjs/core";
import React from "react";
import { Body } from "./body";
import { Title } from "./title";
import styled from "styled-components/macro";

export function SingleDepartmentRecos({
  departmentName,
  nextCourseRecos,
}: {
  departmentName: string;
  nextCourseRecos: Recommendation[];
}): JSX.Element {
  return (
    <ContainerCard
      className={"single-dept-recos--container"}
      elevation={Elevation.TWO}
    >
      <Title departmentName={departmentName} />
      <Body nextCourseRecos={nextCourseRecos} />
    </ContainerCard>
  );
}

const ContainerCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > .single-dept-recos--title {
    margin: 0 !important;
    padding: 0 !important;
  }

  & > .single-dept-recos--body {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;
