import React, { useEffect, useState } from "react";
import { Intent, Spinner } from "@blueprintjs/core";
import _ from "lodash";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { useStoreState } from "../../hooks/ep";
import { UserAction } from "../../user-actions/user_actions";
import AgTable from "../../components/AgTable";
import { RbnBtn } from "../../components/AgTable/ribbon";
import { Icon as BP3Icon } from "@blueprintjs/core/lib/esm/components/icon/icon";
import { DEFAULT_RISK_REASONS } from "../../components/reporting-actions/ecp-risk-reasons-input";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import { useMeData } from "../../hooks/useMe";
import { useBoolean } from "../../helpers/useBoolean";

import { CrownFilled } from "@ant-design/icons";
import { TagIconProps } from "./tags";

export function EcpCustomerInteractionsTable({
  fullScreenEnabled = true,
  ...restProps
}) {
  const loading = useEnsureData("ecp_customerInteraction");
  return loading ? (
    <Spinner />
  ) : (
    <EcpCustomerInteractionsTableInner
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

function EcpCustomerInteractionsTableInner({
  finalizeRowData = (v) => v,
  finalizeColDefs = (v) => v,
  ...restProps
}) {
  let rowData = useStoreState(
    (s) => s.ecp_customerInteraction.initialData
  ) as UserAction[];

  const filterUserInterations = useBoolean(false);
  const [categoryFilters, setCategoryFilters] = useState<any>({});
  const { email } = useMeData();
  const allEmails = _.uniq(_.map(rowData, "submitted_by"));

  rowData = rowData.map((row) => ({
    ...row,
    riskReasons_codeNames:
      row.action_payload.form_payload?.riskReasons
        ?.map((v) => v.codeName)
        ?.join(",") ?? "",
  }));

  rowData = _.sortBy(rowData, "submitted_at").reverse();

  const colDefs = useColDefs();

  useEffect(() => {
    if (
      _.isEqual(categoryFilters?.submitted_by?.value?.sort(), allEmails.sort())
    ) {
      filterUserInterations.setFalse();
    }
  }, [categoryFilters]);

  useEffect(() => {
    if (filterUserInterations.value) {
      setCategoryFilters({
        ...categoryFilters,
        submitted_by: {
          value: [email],
        },
      });
    } else {
      setCategoryFilters({
        ...categoryFilters,
        submitted_by: {
          value: allEmails,
        },
      });
    }
  }, [filterUserInterations.value]);

  const customRibbonLeftElements = [
    <RbnBtn
      title={
        filterUserInterations.value
          ? "Show all interactions"
          : "Filter to my interactions only"
      }
      icon={<CrownFilled />}
      onClick={() => filterUserInterations.toggle()}
    />,
  ];

  return (
    <AgTable
      tableName="ecp_interactions"
      rowData={finalizeRowData(rowData)}
      columnDefs={finalizeColDefs(colDefs)}
      customRibbonLeftElements={customRibbonLeftElements}
      categoryFilters={categoryFilters}
      setCategoryFilters={setCategoryFilters}
      {...restProps}
    />
  );
}

export const RISK_REASONS__TAGS_INFO = {
  tagIcons: {
    financial_issues: ({ intent = Intent.NONE }: TagIconProps) => (
      <BP3Icon
        icon={"dollar"}
        intent={intent}
        iconSize={BP3Icon.SIZE_STANDARD}
      />
    ),
    family_issues: ({ intent = Intent.NONE }: TagIconProps) => (
      <BP3Icon icon={"home"} intent={intent} iconSize={BP3Icon.SIZE_STANDARD} />
    ),
    struggling_in_subject: ({ intent = Intent.NONE }: TagIconProps) => (
      <BP3Icon
        icon={"manual"}
        intent={intent}
        iconSize={BP3Icon.SIZE_STANDARD}
      />
    ),
    busy_with_outside_activities: ({ intent = Intent.NONE }: TagIconProps) => (
      <BP3Icon
        icon={"cycle"}
        intent={intent}
        iconSize={BP3Icon.SIZE_STANDARD}
      />
    ),
    time_management_issues: ({ intent = Intent.NONE }: TagIconProps) => (
      <BP3Icon
        icon={"stopwatch"}
        intent={intent}
        iconSize={BP3Icon.SIZE_STANDARD}
      />
    ),
  },
  tagSortOrder: DEFAULT_RISK_REASONS.map((v) => v.codeName),
  tagDisplayNames: Object.fromEntries(
    DEFAULT_RISK_REASONS.map((v) => [v.codeName, v.longName])
  ),
};

function useColDefs(): AgColDefs {
  const { role: myRole, timezone: myTz } = useMeData();
  const ret = [
    {
      headerName: "Submitted By",
      field: "submitted_by",
      type: "categoryColumn",
    },
    {
      headerName: "Submitted At",
      field: "submitted_at",
      type: "timestampColumn",
      __timezone: myTz,
    },
    // { headerName: "Action", field: "action_code", type: "categoryColumn" },
    {
      headerName: "Student Name",
      field: "action_payload.customer_snapshot.customer_name",
      type: "textColumn",
    },
    {
      headerName: "Interaction Type",
      field: "action_payload.form_payload.interactionType",
      type: "categoryColumn",
      width: 125,
      suppressSizeToFit: true,
      tooltipField: "action_payload.form_payload.interactionType",
    },
    {
      headerName: "Notes",
      field: "action_payload.form_payload.notes",
      type: "textColumn",
      width: 400,
      suppressSizeToFit: true,
      autoHeight: true,
      // wrapText: true,
      cellStyle: { minHeight: "48px" },
    },
    {
      headerName: "Email Address",
      field: "action_payload.form_payload.emailAddress",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "action_payload.form_payload.emailAddress",
    },
    {
      headerName: "Phone Number",
      field: "action_payload.form_payload.phoneNumber",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "action_payload.form_payload.phoneNumber",
    },
    {
      headerName: "Risk Reasons",
      field: "riskReasons_codeNames",
      type: "tagsColumn",
      width: 193,
      filterParams: {
        fp: RISK_REASONS__TAGS_INFO,
      },
      cellRendererParams: {
        crp: RISK_REASONS__TAGS_INFO,
      },
      tooltipField: null,
      pinned: "right",
    },
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
  });
}
