import versionedLocalStorageKey from "../../../helpers/versionedLocalStorageKey";

type StudentId = number;
export const DEFAULT_STUDENT_ID = -1;
export const TABLE_HEIGHT =
  "calc((100vh - 64px - 40px - 40px - 46px) / 2 - 20px - 48px)";

export function get_selected_student_lsk(viewId) {
  return versionedLocalStorageKey(
    `${viewId}--loyalty-students-and-communications-splitview--selectedStudentId`
  );
}

export function isDefaultStudentId(selectedStudentId: number): boolean {
  return selectedStudentId === DEFAULT_STUDENT_ID;
}

export function isNotDefaultStudentId(selectedStudentId: number): boolean {
  return !isDefaultStudentId(selectedStudentId);
}

export function getFullScreenEnabled(
  selectedStudentId: number,
  studentSelectionDisabled: boolean
): boolean {
  return studentSelectionDisabled || isDefaultStudentId(selectedStudentId);
}

export function getTableHeight(
  selectedStudentId: number,
  studentSelectionDisabled: boolean
): string | null {
  return studentSelectionDisabled || isDefaultStudentId(selectedStudentId)
    ? null
    : TABLE_HEIGHT;
}
