// This function takes a string representation of a date-time in the UTC timezone
// and converts it into a "naive" Date object that is unaware of timezones.
// The created Date object represents the same day, month, and year as the input,
// but with the time set to midnight in the local timezone of the user's system.
export function getNaiveDate(date: string): Date | null {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

  if (!regex.test(date)) {
    return null;
  }

  const dateParts = date.split("T")[0].split("-");
  return new Date(
    Number(dateParts[0]),
    Number(dateParts[1]) - 1,
    Number(dateParts[2])
  );
}
