import React, { useMemo } from "react";
import { useStoreState } from "../../hooks/ep";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import AgTable from "../../components/AgTable";
import { getCountColDef } from "../helpers/relation-agg-cr";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import {
  CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
  useMyRole,
} from "../helpers/authorization";
import { useMe } from "../../hooks/useMe";
import { UserRole } from "../../store/model-me";
import { useTeacherAttr_ContractType_ColDef } from "./teacher-coldefs/teacher-attr-contrat-type";

export function EcpTeacherTable({ fullScreenEnabled = true, ...restProps }) {
  const loading = _.max([
    useEnsureData("ecp_teacher"),
    useEnsureData("ecp_teacher_teacherCourse"),
    useEnsureData("ecp_teacher_enrollment"),
    useEnsureData("teacher_attr"),
    useEnsureData("teacherRetentionKpis"),
  ]);
  return loading ? (
    <Spinner />
  ) : (
    <EcpTeacherTableInner
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

const INITIAL_CATEGORY_FILTERS = {
  n_enrollments: {
    filter: 10,
    filterType: "number",
    type: "greaterThanOrEqual",
  },
};

const COMMA_PATT = /,\s?/;

function extractValuesString(value) {
  if (_.isString(value)) {
    return value.split(COMMA_PATT);
  }
  return value;
}

function EcpTeacherTableInner(props) {
  const rowData = useStoreState((s) => s.ecp_teacher.rowData);

  // Default sort
  const sortedRowData = useMemo(
    () =>
      _.orderBy(
        rowData,
        ["n_asgns_graded_this_school_year", "ManagerFullName"],
        ["desc", "asc"]
      ),
    [rowData]
  );

  const { initialData } = useMe();
  const userFullName = `${initialData.first_name} ${initialData.last_name}`;

  // If the user is an academic department chair, sort the table so that teachers that report to them are at the top
  const sortedRowDataWithManagersFirst = useMemo(() => {
    if (initialData.role === UserRole.ACADEMIC_DEPARTMENT_CHAIR) {
      const managerRows = sortedRowData.filter(
        (row) =>
          row.ManagerFullName && row.ManagerFullName.includes(userFullName)
      );
      const nonManagerRows = sortedRowData.filter(
        (row) => !row.ManagerFullName?.includes(userFullName)
      );
      return [...managerRows, ...nonManagerRows];
    }
    return sortedRowData;
  }, [sortedRowData, userFullName, initialData.role]);

  // If the user is an academic department chair, the categories in the "Supervisor Names" should display the supervisor name first
  const managersSortOrder = useMemo(() => {
    const managerNames: string[] = _.uniq(
      _.map(sortedRowData, "ManagerFullName").map(extractValuesString).flat()
    );
    managerNames.sort();

    if (
      initialData.role === UserRole.ACADEMIC_DEPARTMENT_CHAIR &&
      managerNames.includes(userFullName)
    ) {
      return [userFullName, ..._.without(managerNames, userFullName)];
    }
    return managerNames;
  }, [sortedRowData, userFullName, initialData.role]);

  // If the user is an academic department chair, set an initial category filter to only show teachers that report to them
  let initialCategoryFilters = INITIAL_CATEGORY_FILTERS;
  if (
    initialData.role === UserRole.ACADEMIC_DEPARTMENT_CHAIR &&
    managersSortOrder.includes(userFullName)
  ) {
    initialCategoryFilters = {
      ...initialCategoryFilters,
      ManagerFullName: [userFullName],
    };
  }

  const colDefs = useColumnDefs(userFullName, managersSortOrder);

  return (
    <AgTable
      rowData={sortedRowDataWithManagersFirst}
      columnDefs={colDefs}
      tableName={"ecp_teacher"}
      initialCategoryFilters={initialCategoryFilters}
      {...props}
    />
  );
}

export function useColumnDefs(
  userFullName: string,
  managersSortOrder: string[]
): AgColDefs {
  const myRole = useMyRole();
  let ret = [
    {
      headerName: "Teacher Name",
      field: "TeacherName",
      type: "categoryColumn",
      width: 225,
      suppressSizeToFit: true,
      tooltipField: "TeacherName",
      pinned: "left",
    },
    {
      headerName: "Retention Rate",
      field: "teacherRetentionRateCurrent",
      type: "percentageColumn",
      headerTooltip:
        "Percentage of students who had classes with this teacher 366-730 days ago (excluding 12th grade) and re-enrolled with any teacher in the last 1-365 days",
    },
    {
      headerName: "# Asgns Graded this School Year",
      field: "n_asgns_graded_this_school_year",
      type: "integerColumn",
      cellClass: "ag-numeric-cell",
      headerTooltip: "Number of assignments graded this school year",
      width: 140,
    },
    {
      headerName: "# Asgns Graded this Month",
      field: "n_asgns_graded_this_month",
      type: "integerColumn",
      cellClass: "ag-numeric-cell",
      headerTooltip: "Number of assignments graded this past month",
      width: 140,
    },
    {
      headerName: "# Asgns Graded this Week",
      field: "n_asgns_graded_this_week",
      type: "integerColumn",
      cellClass: "ag-numeric-cell",
      headerTooltip: "Number of assignments graded this past week",
      width: 130,
    },
    {
      headerName: "Avg Time to Grade [hours]",
      field: "avg_time_to_grade_asgns__hours",
      type: "floatColumn",
      __nDecimals: 1,
      headerTooltip:
        "Average time to grade assignments in this school year [hours]",
      width: 130,
    },
    {
      headerName: "Grading Backlog",
      field: "n_asgns_pending_grade",
      type: "integerColumn",
      cellClass: "ag-numeric-cell",
      headerTooltip:
        "Number of assignments that have been submitted and are not graded",
      width: 100,
    },
    {
      headerName: "# Asgns that took more than 48hrs to grade",
      field: "n_asgns_time_to_grade_gt_48hrs",
      type: "integerColumn",
      cellClass: "ag-numeric-cell",
      headerTooltip:
        "Number of assignments that it took more than 48 hours to grade over this school year",
      width: 170,
    },
    {
      headerName: "% Asgns that took more than 48hrs to grade",
      field: "pct_asgns_time_to_grade_gt_48hrs",
      type: "percentageColumn",
      headerTooltip:
        "Percentage of assignments that it took more than 48 hours to grade over this school year",
      width: 170,
    },
    {
      headerName: "Teacher Email",
      field: "TeacherEmail",
      type: "textColumn",
    },
    useTeacherAttr_ContractType_ColDef(),
    getCountColDef({
      baseEntityName: "ecp_teacher",
      headerName: "# Enrollments",
      field: "enrollmentIds",
      entityName: "ecp_teacher_enrollment",
      headerTooltip: "Number of active enrollments",
      cellClass: "react-rendered-cell ag-numeric-cell",
      actualField: "n_enrollments",
      type: "integerColumn",
    }),
    getCountColDef({
      baseEntityName: "ecp_teacher",
      headerName: "# Courses",
      field: "teacherCourseIds",
      entityName: "ecp_teacher_teacherCourse",
      headerTooltip: "Number of active courses",
      cellClass: "react-rendered-cell ag-numeric-cell",
      actualField: "n_courses",
      type: "integerColumn",
    }),
    {
      headerName: "Departments",
      field: "department_names",
      tooltipField: "department_names",
      type: "multiCategoryColumn",
      width: 300,
      suppressSizeToFit: true,
      cellClass: ["ellipses-tooltip-cell"],
    },
    {
      headerName: "Supervisor Names",
      field: "ManagerFullName",
      type: "multiCategoryColumn",
      width: 300,
      suppressSizeToFit: true,
      cellClass: ["ellipses-tooltip-cell"],
      valueFormatter: ({ value }) =>
        placeUserNameAtBeginning(value, userFullName),
      filterParams: {
        fp: {
          categorySortOrder: managersSortOrder,
        },
      },
    },
    {
      headerName: "Supervisor Emails",
      field: "ManagerEmail",
      type: "multiCategoryColumn",
      width: 300,
      suppressSizeToFit: true,
      cellClass: ["ellipses-tooltip-cell"],
    },
    ...useTeacherActivityColDefs(),
    ...useTeacherCommunicationStatsColDefs(),
    {
      headerName: "Risk",
      field: "churn_risk_score",
      type: "floatColumn",
      cellRenderer: "churnScoreCellRenderer",
      cellClass: "react-rendered-cell",
      headerTooltip: "Avg failure/extension risk across enrollments",
      width: 200,
    },
    {
      headerName: "At-Risk Enr",
      field: "n_enrs_at_risk",
      type: "integerColumn",
      cellClass: "ag-numeric-cell",
      headerTooltip: "# of active enrollments with >70% failure/extension risk",
      width: 100,
    },
    {
      headerName: "% At-Risk Enr",
      field: "pct_enrs_at_risk",
      type: "percentageColumn",
      headerTooltip: "% of active enrollments with >70% failure/extension risk",
    },
    getCountColDef({
      baseEntityName: "ecp_teacher",
      headerName: "# MIA Enr",
      field: "miaEnrollmentIds",
      entityName: "ecp_teacher_enrollment",
      headerTooltip: "Number of MIA enrollments",
      cellClass: "react-rendered-cell ag-numeric-cell",
      actualField: "n_enrs_missing_in_action",
      type: "integerColumn",
    }),
    {
      headerName: "% MIA Enr",
      field: "pct_enrs_missing_in_action",
      type: "percentageColumn",
      headerTooltip: "Percentage of MIA enrollments",
    },
    {
      headerName: "Current Avg Grade",
      field: "curr_avg_grade",
      width: 100,
      type: "percentageColumn",
    },
    {
      headerName: "Current Grade StdDev",
      field: "curr_avg_grade_std",
      width: 100,
      type: "percentageColumn",
    },
    {
      headerName: "Subject Areas",
      field: "subject_areas",
      tooltipField: "subject_areas",
      type: "textColumn",
      width: 300,
      suppressSizeToFit: true,
      cellClass: ["ellipses-tooltip-cell"],
    },
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
  });
}

function useTeacherActivityColDefs(): AgColDefs {
  let ret = [
    {
      headerName: "Last Buzz Activity",
      field: "last_login_date",
      type: "dateColumn",
      headerTooltip: "Last time the teacher used Buzz",
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "# Buzz Usages (1 week)",
      field: "total_n_sessions_within_past_7_days",
      type: "integerColumn",
      headerTooltip:
        "Number of times the teacher used Buzz within the past 7 days",
      width: 143,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "# Buzz Usages (1 month)",
      field: "total_n_sessions_within_past_30_days",
      type: "integerColumn",
      headerTooltip:
        "Number of times the teacher used Buzz within the past 30 days",
      width: 143,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "# Buzz Usages (6 months)",
      field: "total_n_sessions_within_past_180_days",
      type: "integerColumn",
      headerTooltip:
        "Number of times the teacher used Buzz within the past 180 days",
      width: 143,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Total Time on Buzz (1 week)",
      field: "total_session_duration_hours_within_past_7_days",
      type: "floatColumn",
      valueFormatter: ({ value }) => floatToTime(value),
      headerTooltip:
        "Total amount of time the teacher spent using Buzz over the past 7 days",
      width: 154,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Total Time on Buzz (1 month)",
      field: "total_session_duration_hours_within_past_30_days",
      type: "floatColumn",
      valueFormatter: ({ value }) => floatToTime(value),
      headerTooltip:
        "Total amount of time the teacher spent using Buzz over the past 30 days",
      width: 154,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Total Time on Buzz (6 months)",
      field: "total_session_duration_hours_within_past_180_days",
      type: "floatColumn",
      valueFormatter: ({ value }) => floatToTime(value),
      headerTooltip:
        "Total amount of time the teacher spent using Buzz over the past 180 days",
      width: 154,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Avg Time on Buzz Per Day (1 week)",
      field: "avg_session_duration_hours_per_day_within_past_7_days",
      type: "floatColumn",
      valueFormatter: ({ value }) => floatToTime(value),
      headerTooltip:
        "Average amount of time the teacher spent using Buzz per day over the past 7 days",
      width: 131,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Avg Time on Buzz Per Day (1 month)",
      field: "avg_session_duration_hours_per_day_within_past_30_days",
      type: "floatColumn",
      valueFormatter: ({ value }) => floatToTime(value),
      headerTooltip:
        "Average amount of time the teacher spent using Buzz per day over the past 30 days",
      width: 131,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Avg Time on Buzz Per Day (6 months)",
      field: "avg_session_duration_hours_per_day_within_past_180_days",
      type: "floatColumn",
      valueFormatter: ({ value }) => floatToTime(value),
      headerTooltip:
        "Average amount of time the teacher spent using Buzz per day over the past 180 days",
      width: 131,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Avg # Buzz Usages Per Day (1 week)",
      field: "avg_n_sessions_per_day_within_past_7_days",
      type: "floatColumn",
      __nDecimals: 2,
      headerTooltip:
        "Average number of times the teacher used Buzz per day over the past 7 days",
      width: 174,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Avg # Buzz Usages Per Day (1 month)",
      field: "avg_n_sessions_per_day_within_past_30_days",
      type: "floatColumn",
      __nDecimals: 2,
      headerTooltip:
        "Average number of times the teacher used Buzz per day over the past 30 days",
      width: 174,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Avg # Buzz Usages Per Day (6 months)",
      field: "avg_n_sessions_per_day_within_past_180_days",
      type: "floatColumn",
      __nDecimals: 2,
      headerTooltip:
        "Average number of times the teacher used Buzz per day over the past 180 days",
      width: 174,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "# Active Days (1 week)",
      field: "n_active_days_within_past_7_days",
      type: "integerColumn",
      cellClass: "ag-numeric-cell",
      headerTooltip:
        "Number of days the teacher used Buzz within the past 7 days",
      width: 135,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "# Active Days (1 month)",
      field: "n_active_days_within_past_30_days",
      type: "integerColumn",
      cellClass: "ag-numeric-cell",
      headerTooltip:
        "Number of days the teacher used Buzz within the past 30 days",
      width: 135,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "# Active Days (6 months)",
      field: "n_active_days_within_past_180_days",
      type: "integerColumn",
      cellClass: "ag-numeric-cell",
      headerTooltip:
        "Number of days the teacher used Buzz within the past 180 days",
      width: 135,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Avg Time on Buzz Per Active Day (1 week)",
      field: "avg_session_duration_hours_per_active_day_within_past_7_days",
      type: "floatColumn",
      valueFormatter: ({ value }) => floatToTime(value),
      headerTooltip:
        'Average amount of time the teacher spent using Buzz per "active day" over the past 7 days ("active days" are days the teacher used Buzz at least once)',
      width: 150,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Avg Time on Buzz Per Active Day (1 month)",
      field: "avg_session_duration_hours_per_active_day_within_past_30_days",
      type: "floatColumn",
      valueFormatter: ({ value }) => floatToTime(value),
      headerTooltip:
        'Average amount of time the teacher spent using Buzz per "active day" over the past 30 days ("active days" are days the teacher used Buzz at least once)',
      width: 150,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Avg Time on Buzz Per Active Day (6 months)",
      field: "avg_session_duration_hours_per_active_day_within_past_180_days",
      type: "floatColumn",
      valueFormatter: ({ value }) => floatToTime(value),
      headerTooltip:
        'Average amount of time the teacher spent using Buzz per "active day" over the past 180 days ("active days" are days the teacher used Buzz at least once)',
      width: 150,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Avg # Buzz Usages Per Active Day (1 week)",
      field: "avg_n_sessions_per_active_day_within_past_7_days",
      type: "floatColumn",
      __nDecimals: 2,
      headerTooltip:
        'Average number of times the teacher used Buzz per "active day" over the past 7 days ("active days" are days the teacher used Buzz at least once)',
      width: 196,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Avg # Buzz Usages Per Active Day (1 month)",
      field: "avg_n_sessions_per_active_day_within_past_30_days",
      type: "floatColumn",
      __nDecimals: 2,
      headerTooltip:
        'Average number of times the teacher used Buzz per "active day" over the past 30 days ("active days" are days the teacher used Buzz at least once)',
      width: 196,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "Avg # Buzz Usages Per Active Day (6 months)",
      field: "avg_n_sessions_per_active_day_within_past_180_days",
      type: "floatColumn",
      __nDecimals: 2,
      headerTooltip:
        'Average number of times the teacher used Buzz per "active day" over the past 180 days ("active days" are days the teacher used Buzz at least once)',
      width: 196,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return cd.__authorized ?? true;
  });
}

function useTeacherCommunicationStatsColDefs(): AgColDefs {
  const ret = [
    {
      headerName: "Last Communication",
      field: "last_communication_date",
      type: "dateColumn",
      headerTooltip:
        "Date of the last time the teacher communicated with a student",
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "# Communications (1 week)",
      field: "n_communications_within_past_7_days",
      type: "integerColumn",
      headerTooltip:
        "Number of communications with a student within the past 7 days",
      width: 180,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
    {
      headerName: "# Communications (1 month)",
      field: "n_communications_within_past_30_days",
      type: "integerColumn",
      headerTooltip:
        "Number of communications with a student within the past 30 days",
      width: 180,
      __authorized_roles: CAN_VIEW_TEACHER_ACTIVITY__USER_ROLES,
    },
  ] as AgColDef[];

  return ret;
}

function placeUserNameAtBeginning(
  managerNames: string,
  userFullName: string
): string {
  // If the managerNames string is empty, return the original string
  if (!managerNames) {
    return "N/A";
  }

  let sortedManagerNames = managerNames
    .split(COMMA_PATT)
    .map((v) => v.trim())
    .sort();

  // If the user is not in the managerNames string, return the managers names string sorted
  if (sortedManagerNames.includes(userFullName)) {
    sortedManagerNames = [
      userFullName,
      ..._.without(sortedManagerNames, userFullName),
    ];
  }

  return sortedManagerNames.join(", ");
}

function floatToTime(nHours: number): string {
  // Ensure that the nHours passed is actually a number
  if (typeof nHours !== "number") {
    return "N/A";
  }

  // Ensure nHours is positive or 0
  if (nHours < 0) {
    return "N/A";
  }

  // Generate hours and minutes
  const hours = Math.floor(nHours);
  const minutes = Math.round((nHours - hours) * 60);

  // Convert hours and minutes to strings
  const hoursStr = hours.toString();
  const minutesStr = minutes < 10 ? "0" + minutes : minutes.toString();

  // Form and return the time string "HH hrs MM mins"
  return hoursStr + "h " + minutesStr + "m";
}
