import { Action, action, Computed, computed } from "easy-peasy";
import { ActionPayload_CreateNewUser } from "../../../user-actions/create-new-user";
import { VALID_NEW_USER_ROLES } from "../../../entities/helpers/writes-auth";
import { VALID_TIMEZONES } from "../../../entities/ecp/ecp-user-entity";
import { UserRole } from "../../../store/model-me";

export interface CreateNewUser_Model {
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  timezone: string;
  setField: Action<
    CreateNewUser_Model,
    {
      type: "email" | "first_name" | "last_name" | "role" | "timezone";
      value: string;
    }
  >;
  formData: Computed<
    CreateNewUser_Model,
    {
      email: string;
      first_name: string;
      last_name: string;
      role: string;
      timezone: string;
    }
  >;
  actionPayload: Computed<CreateNewUser_Model, ActionPayload_CreateNewUser>;
  dataIsSubmittable: Computed<CreateNewUser_Model, boolean>;
  errorMessage: Computed<CreateNewUser_Model, null | string>;
}

export function createNewUser_formStoreModelFactory(): CreateNewUser_Model {
  return {
    email: "",
    first_name: "",
    last_name: "",
    role: "",
    timezone: "",
    //
    setField: action((state, { type, value }) => {
      state[type] = (value || "").trim();
    }),
    //
    formData: computed(
      [
        (s) => s.email,
        (s) => s.first_name,
        (s) => s.last_name,
        (s) => s.role,
        (s) => s.timezone,
      ],
      (email, first_name, last_name, role, timezone) => ({
        email,
        first_name,
        last_name,
        role,
        timezone,
      })
    ),
    actionPayload: computed([(s) => s.formData], (formData) => {
      return {
        email: formData.email,
        formData: {
          ...formData,
          nickname: "",
        },
      };
    }),
    dataIsSubmittable: computed(
      [(s) => s.errorMessage],
      (errorMessage) => !errorMessage
    ),
    errorMessage: computed([(s) => s.formData], (formData) => {
      const { email, first_name, last_name, role, timezone } = formData;

      if (!email.includes("@")) {
        return "Please provide a valid email address";
      } else if (!first_name) {
        return "Please provide a first name";
      } else if (!last_name) {
        return "Please provide a last name";
      } else if (!VALID_NEW_USER_ROLES.includes(role as UserRole)) {
        return "Please provide a valid role";
      } else if (!VALID_TIMEZONES.includes(timezone)) {
        return "Please provide a valid timezone";
      }
    }),
  };
}
