import { thunkOn, ThunkOn } from "easy-peasy";
import { EntityName, isAuthorizedForEntity } from "../../entities/helpers/core";
import { StoreModel } from "../model";
import { Injections } from "../store-injections";
import { TableModel, tableModelFactory } from "../table-model-factory";
import { EcpUserModel } from "./model-ecp-user";

export type EcpUserMetricModel = TableModel & {
  onSourceDataChange: ThunkOn<EcpUserModel, Injections, StoreModel>;
};

export function buildEcpUserMetricModel(): EcpUserMetricModel {
  return {
    ...tableModelFactory("ecp_userMetric", "ecp/user_metrics", (row) => row.id),
    onSourceDataChange: thunkOn(
      (actions, storeActions) => [storeActions.ecp_user.handlePatchUser],
      async (actions, target, { getState, getStoreState }) => {
        const sliceName = getState().NAME as EntityName;
        const myRole = getStoreState().me.initialData?.role;
        if (isAuthorizedForEntity(sliceName, myRole, true)) {
          await actions.handleFetchInitialData();
        }
      }
    ),
  };
}
