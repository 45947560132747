import { Spinner } from "@blueprintjs/core";
import React from "react";
import { useStoreState } from "../../hooks/ep";
import "styled-components/macro";
// eslint-disable-next-line
import { useSelectedLoyaltyYear } from "./loyalty-year-helpers";
import {
  DescriptionItemConfig,
  FlatDescriptions,
} from "../../components/flat-descriptions";
import { buildDescriptionItem } from "../../components/overview--description-item";
import { useEnsureData } from "../../helpers/use-ensure-data";

export function LoyaltyStatisticsUnitDescriptionHeader(): JSX.Element {
  const selectedLoyaltyYear = useSelectedLoyaltyYear();
  const rowData =
    useStoreState(
      (s) => s.loyaltyStatistics.byLoyaltyYear[selectedLoyaltyYear]
    ) || [];

  const loading = useEnsureData("loyaltyStatistics");
  const itemConfigs = useLoyaltyStatisticsItemConfigs();

  return loading ? (
    <Spinner />
  ) : (
    <FlatDescriptions>
      {itemConfigs.map((itemConfig, key) =>
        buildDescriptionItem({
          key,
          data: rowData.find((item) => item.email_type_name === "Overall"),
          itemConfig,
        })
      )}
    </FlatDescriptions>
  );
}

function useLoyaltyStatisticsItemConfigs(): DescriptionItemConfig[] {
  return [
    {
      field: "nuniq_students",
      label: "# Students Targeted",
      type: "integer",
      tooltip:
        "The number of students targeted by at least one Loyalty22 campaign email",
    },
    {
      field: "n_re_enrollments",
      label: "# Re-Enrollments",
      type: "integer",
      tooltip: "The number of students who have re-enrolled",
    },
    {
      field: "n_academy_upsells",
      label: "# Academy Upsells",
      type: "integer",
      tooltip:
        'The number of students who (1) Were targeted by an "academy_potential" Loyalty22 campaign email and (2) Have an LSS order created on/after 2022-02-01 of type "Academy Re Enrollment" or "Academy  New Enrollment"',
    },
  ];
}
