import React from "react";
import "styled-components/macro";
import { SimpleRadioGroup } from "../forms-stuff/simple-radio";

export type InteractionTypeValue = "Email" | "Phone";
export const ALL_INTERACTION_TYPE_VALUES: InteractionTypeValue[] = [
  "Email",
  "Phone",
];
const FIELD_LABEL = "Interaction Type";
const FIELD_ID = "interactionType";
const HELPER_TEXT = "Did this interaction occur over email or on the phone?";

export function InteractionTypeInput({
  value,
  onValueChange,
  activeField,
  setActiveField,
}): JSX.Element {
  return (
    <SimpleRadioGroup
      selectedValue={value}
      setSelectedValue={onValueChange}
      allValues={ALL_INTERACTION_TYPE_VALUES}
      fieldLabel={FIELD_LABEL}
      fieldHelperText={HELPER_TEXT}
      fieldId={FIELD_ID}
      activeFieldId={activeField}
      setActiveFieldId={setActiveField}
    />
  );
}
