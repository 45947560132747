import React, { useMemo } from "react";
import styled from "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import { Layout, Menu, Tooltip } from "antd";
import { useAuthorizedRoutes } from "../../helpers/useAuthorization";
import { useHistory, useLocation } from "react-router";
import {
  DRAWER_WIDTH_PX,
  LAYOUT_CONTENT_HORIZONTAL_PADDING,
  LAYOUT_CONTENT_VERTICAL_PADDING,
  LAYOUT_HEADER_HEIGHT_PX,
  LAYOUT_LEFTNAV_ITEM_HEIGHT,
  LAYOUT_LEFTNAV_WIDTH_PX,
} from "../../styles/constants";
import { LeftNavMenuItem } from "../../helpers/leftNavMenuItems";
import { Colors, Icon as BP3Icon, Button, Intent } from "@blueprintjs/core";
import { useFullScreenModeEnabled } from "../../store/model-misc";
import { leftNavMenuItems } from "../../pages-routes";
import { MyHeader } from "./header";
import { OpenMailtoDialog, MailtoDrawer } from "../opening-mailto-links";
import { useBoolean } from "../../helpers/useBoolean";
import { DrawerFormWrapper } from "../drawer-form/drawer";
import { SentEmailContentDialog } from "../sent-email-content-dialog";

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

const StyledSider = styled(Sider)`
  && {
    margin: 0;
    padding: 0;
    width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;
    min-width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;
    max-width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;
    background: white;
  }
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;

  && .ant-menu-item.ant-menu-item.ant-menu-item,
  && .ant-menu-submenu-title.ant-menu-submenu-title.ant-menu-submenu-title {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${LAYOUT_LEFTNAV_ITEM_HEIGHT} !important;
    line-height: ${LAYOUT_LEFTNAV_ITEM_HEIGHT} !important;
  }
  && .bp3-icon,
  && .anticon {
    height: 16px;
    line-height: 16px;
    vertical-align: middle;
    color: rgb(255, 255, 255, 0.65);
  }
  && .ant-menu-item:hover,
  && .ant-menu-submenu-selected,
  && .ant-menu-item-selected {
    .bp3-icon,
    .anticon {
      color: rgb(255, 255, 255, 1);
    }
  }
`;

function MySubMenu({ children, ...restProps }) {
  return <SubMenu {...restProps}>{children}</SubMenu>;
}

function LeftNavSiderNested() {
  const location = useLocation();
  const history = useHistory();
  const authorizedRoutes = useAuthorizedRoutes();
  const authorizedLeftNavMenuItems = useMemo(
    () =>
      leftNavMenuItems.filter(({ urlPath }) => authorizedRoutes.has(urlPath)),
    [authorizedRoutes]
  );

  const ecp_leftNavMenuItemsComponents = authorizedLeftNavMenuItems
    .filter((el) => el.urlPath.startsWith("/ecp/"))
    .map((el) => ({ ...el, history }))
    .map(LeftNavMenuItem);
  const ecpIcon = <BP3Icon icon="predictive-analysis" />;

  const ree_leftNavMenuItemsComponents = authorizedLeftNavMenuItems
    .filter((el) => el.urlPath.startsWith("/ree/"))
    .map((el) => ({ ...el, history }))
    .map(LeftNavMenuItem);

  const reeIcon = <BP3Icon icon="repeat" />;

  return (
    <StyledSider collapsed={true}>
      <StyledMenu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={[location.pathname]}
        inlineCollapsed={true}
      >
        <MySubMenu
          key="ecp"
          icon={ecpIcon}
          popupClassName={"leftnav-popup"}
          // title="ecp"
          // title="Enrollment Churn Prediction"
        >
          {ecp_leftNavMenuItemsComponents}
        </MySubMenu>
        <MySubMenu
          key="ree"
          icon={reeIcon}
          popupClassName={"leftnav-popup"}
          // title="ree"
          // title="Re-Enrollment"
        >
          {ree_leftNavMenuItemsComponents}
        </MySubMenu>
      </StyledMenu>
    </StyledSider>
  );
}

function LeftNavSider() {
  const location = useLocation();
  const history = useHistory();
  const authorizedRoutes = useAuthorizedRoutes();
  const authorizedLeftNavMenuItems = useMemo(
    () =>
      leftNavMenuItems.filter(({ urlPath }) => authorizedRoutes.has(urlPath)),
    [authorizedRoutes]
  );
  const leftNavMenuItemsComponents = authorizedLeftNavMenuItems
    .map((el) => ({ ...el, history }))
    .map(LeftNavMenuItem);

  return (
    <StyledSider collapsed={true}>
      <StyledMenu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={[location.pathname]}
      >
        {leftNavMenuItemsComponents}
      </StyledMenu>
    </StyledSider>
  );
}

const getStyledContentWidth = ({ hideLeftNav }) =>
  hideLeftNav ? "auto" : `100%`;

function StyledContent({ hideLeftNav, children, ...restProps }) {
  const fullScreen = useFullScreenModeEnabled();
  return (
    <Content
      {...restProps}
      css={`
        background-color: ${Colors.DARK_GRAY3};
        padding: ${fullScreen
          ? "0"
          : `${LAYOUT_CONTENT_VERTICAL_PADDING} ${LAYOUT_CONTENT_HORIZONTAL_PADDING}`};
        margin: 0;
        min-height: 280px;
        height: max(
          calc(100vh - ${fullScreen ? "0" : LAYOUT_HEADER_HEIGHT_PX}),
          100%
        );
        width: ${getStyledContentWidth};
        display: flex;
        justify-content: center;
      `}
    >
      {children}
    </Content>
  );
}

function RootLayout({ children }) {
  return (
    <Layout
      className={"ws-full"}
      css={`
        min-height: 100vh;
        &.ws-full {
          width: 100vw;
          transition: width 0s linear;
        }
        &.ws-sm {
          width: calc(100vw - ${DRAWER_WIDTH_PX});
          transition: width 0.3s linear;
        }
      `}
    >
      {children}
    </Layout>
  );
}

// function renderCurrentBreadcrumb({ text, ...restProps }) {
//   // customize rendering of last breadcrumb
//   return <Breadcrumb {...restProps}>{text}</Breadcrumb>;
// }

const AppWrapper = ({ hideLeftNav, children }) => {
  const fullScreen = useFullScreenModeEnabled();

  const leftNavManuallyHidden = useBoolean(false);

  const isLeftNavHidden = hideLeftNav || leftNavManuallyHidden.value;

  return (
    <RootLayout>
      <MyHeader />
      <Layout>
        {isLeftNavHidden || fullScreen ? null : <LeftNavSider />}
        <Layout>
          <StyledContent hideLeftNav={isLeftNavHidden}>
            {children}
          </StyledContent>
        </Layout>
      </Layout>
      <OpenMailtoDialog />
      <SentEmailContentDialog />
      <MailtoDrawer />
      <DrawerFormWrapper />
      {!hideLeftNav && (
        <LeftNavHider
          isHidden={isLeftNavHidden}
          toggleHidden={leftNavManuallyHidden.toggle}
        />
      )}
    </RootLayout>
  );
};
function LeftNavHider({ isHidden, toggleHidden }) {
  return (
    <div
      css={`
        position: fixed;
        bottom: 12px;
        left: 12px;
      `}
    >
      <Tooltip
        title={isHidden ? "Show Left-Nav Bar" : "Hide Left-Nav Bar"}
        placement="right"
      >
        <Button
          large
          outlined
          intent={Intent.PRIMARY}
          icon={isHidden ? "menu-open" : "menu-closed"}
          onClick={toggleHidden}
        />
      </Tooltip>
    </div>
  );
}
// {fullScreen ? null : <MyBreadcrumbs />}

export default AppWrapper;
