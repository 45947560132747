import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/performance";
import firebaseConfig from "./fbAppConfig";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAnalytics = firebase.analytics();

// Look in the <head> tag in `public/index.html` for
// the "first-input-delay" polyfill <script> tag
const firebasePerformance = firebase.performance();

const fbReducer = combineReducers({
  firebase: firebaseReducer,
});

export { fbReducer, firebase, firebaseAnalytics, firebasePerformance };
