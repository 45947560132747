import React, { useCallback } from "react";
import { Spinner, Tag, Tooltip } from "@blueprintjs/core";
import "styled-components/macro";
import { useBoolean } from "../../helpers/useBoolean";
import { useStoreActions } from "../../hooks/ep";

export function DailyQueueEmailActionButtonCellRenderer({
  data,
  crp,
}: {
  data: any;
  value: string;
  crp: { tooltipText?: string };
}) {
  const isLoading = useBoolean(false);
  const handleFetchMailtoLink = useStoreActions(
    (a) => a.misc.handleFetchMailtoLink
  );

  const customerId = data["ns_customer_id"];
  const customerEmail = data["ns_customer_email"];

  const onClickFactory = useCallback(
    (emailTemplateId) => async () => {
      if (isLoading.value) {
        return;
      }
      isLoading.setTrue();
      await handleFetchMailtoLink({
        entityType: "leadsQueue",
        entityId: customerId,
        emailTemplateId,
      });
      isLoading.setFalse();
    },
    // eslint-disable-next-line
    [customerId, handleFetchMailtoLink]
  );

  const onClick = onClickFactory("lead__outreach");
  const isDisabled = !customerEmail || isLoading.value;
  return (
    <span>
      <Tooltip
        content={"Compose email from template"}
        boundary="viewport"
        hoverOpenDelay={1500}
        hoverCloseDelay={0}
        isOpen={isLoading.value ? false : null}
      >
        <Tag
          css={`
            font-size: 12px;
            height: 26px;
            & .bp3-icon,
            & .bp3-spinner {
              margin-right: 6px;
            }
          `}
          fill={true}
          minimal={true}
          intent={isDisabled ? "none" : "primary"}
          large={false}
          onClick={onClick}
          icon={
            isLoading.value ? <Spinner size={Spinner.SIZE_SMALL} /> : "envelope"
          }
          interactive={!isDisabled}
          disabled={isDisabled}
        />
      </Tooltip>
    </span>
  );
}
