import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { Select } from "@blueprintjs/select";
import { Button, Classes, Intent, MenuItem } from "@blueprintjs/core";

import {
  ALL_SOR,
  useEntitySelection,
} from "../../store/model-entity-selection";
import { Predicate } from "./student-selector";
import { useStoreState } from "../../hooks/ep";
import { TableRow } from "../../store/table-model-factory";

type SoR = -1 | 0 | 1;

export function useAllSoROptions({
  filter,
}: {
  filter: null | Predicate;
}): SoR[] {
  const customerRows = useStoreState(
    (s) => s.ecp_student.rowData
  ) as TableRow[];

  return useMemo(() => {
    const filteredCustomerRows = filter
      ? customerRows.filter(filter)
      : customerRows;
    const schoolRecordAsIntFlags = filteredCustomerRows.map((el) =>
      el["SchoolRecord"] ? 1 : 0
    ) as SoR[];
    const schoolRecordAsUniqueIntFlags = Array.from(
      new Set(schoolRecordAsIntFlags)
    );
    return _.sortBy([...schoolRecordAsUniqueIntFlags, ALL_SOR]);
  }, [customerRows, filter]);
}

export function SoRSelector({
  filter,
}: {
  filter: null | Predicate;
}): JSX.Element {
  const allSoROptions = useAllSoROptions({ filter });
  const [selectedSoR, selectSoR] = useEntitySelection("sor");

  const onItemSelect = useCallback((v: SoR) => selectSoR(v), [selectedSoR]);

  useEffect(() => {
    if (!allSoROptions.includes(selectedSoR as SoR)) {
      selectSoR(ALL_SOR);
    }
  }, [allSoROptions, selectedSoR, selectSoR]);

  return (
    <div
      css={`
        display: flex;
        align-items: center;
        & .sor-select-btn {
          box-shadow: none !important;
        }
      `}
    >
      <Select
        large
        intent="primary"
        itemPredicate={itemPredicate}
        itemRenderer={itemRendererFactory(sorRenderer)}
        items={allSoROptions}
        onItemSelect={onItemSelect}
        popoverProps={POPOVER_PROPS}
      >
        <Button
          intent={Intent.NONE}
          className="sor-select-btn"
          large
          text={sorRenderer(selectedSoR as number)}
          rightIcon="caret-down"
        />
      </Select>
    </div>
  );
}

const POPOVER_PROPS = { targetClassName: Classes.ELEVATION_1 };

const sorRenderer = (sor: number) => {
  switch (sor) {
    case ALL_SOR:
      return "All";
    case 0:
      return "Non-SOR";
    default:
      return "SOR";
  }
};

function itemRendererFactory(sorRenderer) {
  return function itemRenderer(v, { handleClick, modifiers }) {
    return (
      <MenuItem
        active={false}
        key={v}
        onClick={handleClick}
        text={sorRenderer(v)}
      />
    );
  };
}

function itemPredicate(
  query: string,
  item: number,
  index: number,
  exactMatch: boolean
) {
  const renderedItem = sorRenderer(item);
  const queryPatt = new RegExp(`^.*\\b${_.escapeRegExp(query)}.*$`, "gi");
  if (exactMatch) {
    return renderedItem === query;
  } else if (!query.length) {
    return true;
  } else {
    return queryPatt.test(renderedItem);
  }
}
