import {
  TableModel,
  tableModelFactory,
  TableRow,
} from "../table-model-factory";
import { Computed, computed } from "easy-peasy";
import _ from "lodash";
import { StoreModel } from "../model";

export type ReeOrderModel = TableModel & {
  byCustomerId: Computed<
    ReeOrderModel,
    { [key: number]: TableRow[] },
    StoreModel
  >;
};

export function getReeOrderModel(): ReeOrderModel {
  return {
    ...tableModelFactory(
      "ree_order",
      "ree/churnrisk_student_orders",
      (row) => row.OrderIndex
    ),
    byCustomerId: computed([(s) => s.initialData], (initialData: TableRow[]) =>
      _.groupBy(initialData, "customer_id")
    ),
  };
}
