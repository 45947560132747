import React from "react";
import { useStoreState } from "../../hooks/ep";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { ReportActionModalWrapper } from "../../components/reporting-actions/modal-or-drawer";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import AgTable from "../../components/AgTable";
import { getCountColDef } from "../helpers/relation-agg-cr";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import { useMyRole } from "../helpers/authorization";

export function ReeStudentTable({ fullScreenEnabled = true, ...restProps }) {
  const loading = _.max([
    useEnsureData("ree_student"),
    useEnsureData("ree_order"),
    useEnsureData("ree_enrollment"),
    useEnsureData("stars"),
    useEnsureData("ree_customerInteraction"),
  ]);
  return loading ? (
    <Spinner />
  ) : (
    <ReeStudentTableInner
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

function ReeStudentTableInner(props) {
  let rowData = useStoreState((s) => s.ree_student.rowData);
  const colDefs = useColumnDefs();

  return (
    <>
      <AgTable
        rowData={rowData}
        columnDefs={colDefs}
        tableName={"ree_student"}
        {...props}
      />
      <ReportActionModalWrapper />
    </>
  );
}

function useColumnDefs(): AgColDefs {
  const myRole = useMyRole();
  let ret = [
    {
      field: "starred",
      headerName: " ",
      type: "categoryColumn",
      cellClass: "react-rendered-cell",
      cellRenderer: "starCellRenderer",
      width: 60,
      pinned: "left",
    },
    {
      field: "hasReportedCustomerInteraction",
      headerName: "",
      type: "booleanColumn",
    },
    {
      headerName: "Genius ID",
      field: "StudentIndex",
      type: "linkedTextColumn",
      __linkField: "student_genius_url",
      pinned: "left",
    },
    {
      headerName: "Full Name",
      field: "full_name",
      type: "textColumn",
      width: 150,
      suppressSizeToFit: true,
      tooltipField: "full_name",
    },
    {
      headerName: "Priority",
      field: "churnrisk_priority",
      type: "integerColumn",
      headerTooltip:
        "(1 - top priority, 2 - medium priority) - 1 is for students who don’t have open re-enrollment orders, 2 is for students who have not re-enrolled but have an open enrollment order",
    },
    {
      headerName: "Value at Risk",
      field: "value_at_risk",
      type: "moneyColumn",
      headerTooltip: "(# SY to Grad) * (LTV per SY)",
    },
    {
      headerName: "Grade Lvl",
      field: "GradeLevel",
      type: "integerColumn",
      filter: "categoryFilterer",
    },
    getCountColDef({
      baseEntityName: "ree_student",
      headerName: "Enrls",
      field: "enrollmentIds",
      entityName: "ree_enrollment",
    }),
    getCountColDef({
      baseEntityName: "ree_student",
      headerName: "Orders",
      field: "orderIds",
      entityName: "ree_order",
    }),
    {
      headerName: "Retention Notes",
      field: "RetentionNotes",
      type: "textColumn",
      width: 300,
      suppressSizeToFit: true,
      tooltipField: "RetentionNotes",
    },
    {
      headerName: "Historical Order Value",
      field: "life_time_value",
      type: "moneyColumn",
    },
    {
      headerName: "# SY Enrld",
      field: "n_schoolyears_enrolled",
      type: "floatColumn",
    },
    {
      headerName: "Historical Order Value per SY",
      field: "ltv_per_sy_enrolled",
      type: "moneyColumn",
    },
    {
      headerName: "# SY to Grad",
      field: "n_schoolyears_remaining_until_graduation",
      type: "integerColumn",
    },
    {
      headerName: "CReO",
      field: "n_complete_reenrollment_orders",
      type: "integerColumn",
      headerTooltip: "Number of completed re-enrollment orders w/ LSS",
    },
    {
      headerName: "Max Enrl Start",
      field: "max_enrollment_start_date",
      type: "dateColumn",
    },
    {
      headerName: "Max Enrl End",
      field: "max_enrollment_end_date",
      type: "dateColumn",
    },
    {
      headerName: "Admissions Coordinator",
      field: "AdmissionsCoordinator",
      type: "textColumn",
    },
    {
      headerName: "Counselor / Coach",
      field: "CounselorCoach",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "CounselorCoach",
    },
    {
      headerName: "Student Services",
      field: "StudentServices",
      type: "textColumn",
    },
    {
      headerName: "Partner",
      field: "Partner",
      type: "categoryColumn",
    },
    {
      headerName: "Birth",
      field: "CountryOfBirth",
      type: "categoryColumn",
    },
    {
      headerName: "Citizen",
      field: "CountryOfCitizenship",
      type: "categoryColumn",
    },
    {
      headerName: "Max Trusted Start",
      field: "max_trustworthy_start_date",
      type: "dateColumn",
    },
    {
      headerName: "Max Trusted Non-Summer Start",
      field: "max_trustworthy_nonsummer_start_date",
      type: "dateColumn",
    },
    {
      headerName: "Latest RE Order Status",
      field: "latest_reenrollment_order_Status",
      type: "categoryColumn",
    },
    {
      headerName: "Latest RE Order Start",
      field: "latest_reenrollment_order_StartDate",
      type: "dateColumn",
    },
    {
      headerName: "Latest Non-Summer Activity",
      field: "max_nonsummer_activity_date",
      type: "dateColumn",
    },
    {
      headerName: "Max Order Start",
      field: "max_order_start_date",
      type: "dateColumn",
    },
    {
      headerName: "Max Paid-Order Start",
      field: "max_transacted_order_start_date",
      type: "dateColumn",
    },
    {
      headerName: "Max Non-Summer Enrl Start",
      field: "max_nonsummer_enrollment_start_date",
      type: "dateColumn",
    },
    {
      headerName: "Max Non-Summer Enrl End",
      field: "max_nonsummer_enrollment_end_date",
      type: "dateColumn",
    },
    {
      headerName: "Loyalty",
      field: "LoyaltyEligible",
      type: "booleanColumn",
    },
    {
      headerName: "Academy",
      field: "AcademyStudent",
      type: "booleanColumn",
    },
    {
      headerName: "Gender",
      field: "Gender",
      type: "categoryColumn",
    },
    {
      headerName: "G1 Name",
      field: "guardian1_full_name",
      type: "textColumn",
    },
    {
      headerName: "G1 Phone",
      field: "Guardian1Phone",
      type: "textColumn",
    },
    {
      headerName: "G1 Email",
      field: "Guardian1Email",
      type: "textColumn",
    },
    {
      headerName: "G2 Name",
      field: "guardian2_full_name",
      type: "textColumn",
    },
    {
      headerName: "G2 Phone",
      field: "Guardian2Phone",
      type: "textColumn",
    },
    {
      headerName: "G2 Email",
      field: "Guardian2Email",
      type: "textColumn",
    },
    {
      headerName: "Academy Student",
      field: "AcademyStudent",
      type: "booleanColumn",
    },
    {
      headerName: "NCAA",
      field: "IsNCAA",
      type: "booleanColumn",
    },
    {
      headerName: "Student Email",
      field: "Email",
      type: "textColumn",
    },
    {
      headerName: "Date of Birth",
      field: "DOB",
      type: "dateColumn",
    },
    {
      headerName: "Start Date",
      field: "StartDate",
      type: "dateColumn",
    },
    {
      headerName: "End Date",
      field: "EndDate",
      type: "dateColumn",
    },
    {
      headerName: "Entry Date",
      field: "RowInsertedDate",
      type: "dateColumn",
    },
    {
      headerName: "NetSuite ID",
      field: "NetsuiteId",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "LMSID",
      field: "LMSID",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "Status",
      field: "Status",
      type: "categoryColumn",
    },
    {
      headerName: "SoR",
      field: "SchoolRecord",
      type: "booleanColumn",
    },
    {
      headerName: "",
      field: "Guardian1Phone",
      cellClass: "react-rendered-cell",
      cellRenderer: "phoneActionButtonCellRenderer",
      type: "textColumn",
      width: 52, // 176
      pinned: "right",
      cellRendererParams: {
        crp: {
          phoneField: "Guardian1Phone",
        },
      },
    },
    {
      headerName: "",
      field: "action",
      cellClass: "react-rendered-cell",
      cellRenderer: "actionButtonCellRenderer",
      width: 48,
      pinned: "right",
      cellRendererParams: {
        crp: {
          includedMenuItems: ["reportReeCustomerInteractionMenuItem"],
        },
      },
    },
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
  });
}
