import React, { useState } from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import { SingleStudentCourseSchedulingTab } from "./single-student-course-scheduling-tab";
import { AllStudentsNextYearCourseScheduleRecommendationsTab } from "./all-students-next-year-course-schedule-recommendations-tab";

export function CourseSchedulingTabsUnit() {
  const defaultTabId = TAB_IDS.singleStudentView;
  const [selectedTabId, setSelectedTabId] = useState(defaultTabId);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
      `}
    >
      <Tabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        fill
        renderActiveTabPanelOnly={true}
        css={`
          margin-top: -15px;
        `}
      >
        <Tab
          id={TAB_IDS.singleStudentView}
          title="Single Student View"
          panel={<SingleStudentCourseSchedulingTab />}
        />
        <Tab
          id={TAB_IDS.allStudentsView}
          title="All Students View"
          panel={<AllStudentsNextYearCourseScheduleRecommendationsTab />}
        />
      </Tabs>
    </div>
  );
}

const TAB_IDS = {
  singleStudentView: "single-student-view",
  allStudentsView: "all-students-view",
};
