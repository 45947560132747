import { AgColDefs } from "../../components/AgTable/types";

export const ECP_CGN_STATS_TABLE_COLDEFS: AgColDefs = [
  {
    field: "seq_name",
    headerName: "Sequence",
    type: "categoryColumn",
    headerTooltip: "The campaign sequence(s) informing the row's statistics",
    pin: "left",
  },
  {
    field: "customer_group_name",
    headerName: "Customer Group",
    type: "categoryColumn",
    headerTooltip:
      "Target: Customers who received campaign emails. Control: Customers who purposefully had their campaign emails held back as an experimental control.",
    pin: "left",
  },
  {
    field: "first_email_sent_at",
    headerName: "Start",
    type: "dateColumn",
    headerTooltip: "The date the first wave of emails was sent",
    __renderNansAsEmpty: true,
  },
  {
    field: "last_email_sent_at",
    headerName: "Latest",
    type: "dateColumn",
    headerTooltip: "The date the latest wave of emails was sent",
    __renderNansAsEmpty: true,
  },
  {
    field: "n_custs",
    headerName: "# Customers",
    type: "integerColumn",
    headerTooltip: "The number of distinct customers in the group",
  },
  {
    field: "pct_opened_by_TO",
    headerName: "% Opened",
    type: "percentageColumn",
    headerTooltip:
      "The percentage of campaign emails that have been opened by their intended recipient (parent)",
    __renderNansAsEmpty: true,
  },
  {
    field: "n_new_entities",
    headerName: "Incremental Orders",
    // headerName: "Incremental Enrollments",
    type: "integerColumn",
    headerTooltip:
      "The number of incremental orders in the group after the campaign started",
  },
  {
    field: "total_new_revenue",
    headerName: "Incremental Revenue",
    type: "moneyColumn",
    headerTooltip: "The total revenue from the incremental orders in the group",
  },
  {
    field: "avg_new_revenue",
    headerName: "Avg. Inc. Rev. per Customer",
    type: "moneyColumn",
    headerTooltip:
      "The average amount of incremental revenue per customer in the group",
    __renderNansAsEmpty: true,
  },
];
