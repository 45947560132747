import { TableModel, tableModelFactory, TableRow } from "./table-model-factory";
import { Actions, Computed, computed, Thunk, thunk } from "easy-peasy";
import {
  ActionPayload_UpdateTeacherAttr,
  ALL_TEACHER_ATTR_CODENAMES,
  submitUserAction_update_teacher_attr,
} from "../user-actions/submit-user-action__update_teacher_attr";
import { toaster } from "../toaster";
import { StoreModel } from "./model";
import { Injections } from "./store-injections";
import _ from "lodash";

export type TeacherAttrModel = TableModel & {
  handleUpdateTeacherAttr: Thunk<
    TeacherAttrModel,
    ActionPayload_UpdateTeacherAttr,
    Injections,
    StoreModel
  >;
  valueByAttrByTeacher: Computed<
    TeacherAttrModel,
    { [attr_codename: string]: { [teacher_id: number]: string } },
    StoreModel
  >;
  valuesMapByTeacher: Computed<
    TeacherAttrModel,
    { [teacher_id: number]: { [attr_codename: string]: string } },
    StoreModel
  >;
};

export function getTeacherAttrModel(): TeacherAttrModel {
  return {
    ...tableModelFactory(
      "teacher_attr",
      "teacher_attrs",
      (row) => `${row.teacher_id}|${row.attr_codename}`
    ),
    handleUpdateTeacherAttr: thunk(
      async (
        actions: Actions<TeacherAttrModel>,
        userActionPayload: ActionPayload_UpdateTeacherAttr,
        { getState }
      ) => {
        const { teacher_id, attr_codename, attr_newvalue, teacher_snapshot } =
          userActionPayload;
        const state = getState();
        // @ts-ignore
        const rowId = state.GET_ROW_ID(userActionPayload);
        const oldRow = state.getRow(rowId);
        actions.upsertRow({
          id: rowId,
          teacher_id,
          attr_codename,
          attr_value: attr_newvalue,
        });
        let meat;
        if (_.isNil(attr_newvalue)) {
          meat = `removed attribute "${attr_codename}" for teacher "${teacher_snapshot.TeacherName}"`;
        } else {
          meat = `set attribute "${attr_codename}" to "${attr_newvalue}" for teacher "${teacher_snapshot.TeacherName}"`;
        }
        try {
          await submitUserAction_update_teacher_attr(userActionPayload);
          toaster.success(`Successfully ${meat}`, 4);
        } catch (e) {
          if (oldRow) {
            actions.upsertRow(oldRow);
          } else {
            actions.deleteRow(rowId);
          }
          toaster.warning(`Failed to ${meat} -- please try again`);
          throw Error();
        }
      }
    ),
    valueByAttrByTeacher: computed(
      [(s) => s.initialData],
      (initialData: TableRow[]) =>
        Object.fromEntries(
          ALL_TEACHER_ATTR_CODENAMES.map((attr_codename) => {
            const valueByTeacher = Object.fromEntries(
              initialData
                .filter((row) => row.attr_codename === attr_codename)
                .map((row) => {
                  return [row.teacher_id, row.attr_value];
                })
            );
            return [attr_codename, valueByTeacher];
          })
        )
    ),
    valuesMapByTeacher: computed(
      [(s) => s.initialData],
      (initialData: TableRow[]) => {
        const ret = {};
        initialData.forEach(({ teacher_id, attr_codename, attr_value }) => {
          ret[teacher_id] = {
            ...(ret[teacher_id] || {}),
            [`teacher_attr__${attr_codename}`]: attr_value,
          };
        });
        return ret;
      }
    ),
  };
}
