import React from "react";
import { useStoreState } from "../../hooks/ep";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import { AgColDefs } from "../../components/AgTable/types";
import { useMeData } from "../../hooks/useMe";
import AgTable from "../../components/AgTable";

export function ReeOrderTable({ fullScreenEnabled = true, ...restProps }) {
  const loading = _.max([
    useEnsureData("ree_order"),
    useEnsureData("ree_enrollment"),
  ]);
  return loading ? (
    <Spinner />
  ) : (
    <ReeOrderTableInner fullScreenEnabled={fullScreenEnabled} {...restProps} />
  );
}

function ReeOrderTableInner(props) {
  const rowData = useStoreState((s) => s.ree_order.initialData);
  const colDefs = useColumnDefs();

  return (
    <AgTable
      rowData={rowData}
      columnDefs={colDefs}
      tableName={"ree_order"}
      {...props}
    />
  );
}

function useColumnDefs(): AgColDefs {
  const myRole = useMeData().role;
  const ret: AgColDefs = [
    { headerName: "Name", field: "Name", type: "textColumn" },
    { headerName: "Create", field: "CreatedOn", type: "timestampColumn" },
    // { headerName: "Changed", field: "ChangedOn", type: "dateColumn" },
    { headerName: "Expire", field: "ExpirationDate", type: "dateColumn" },
    { headerName: "Start", field: "StartDate", type: "dateColumn" },
    { headerName: "Type", field: "order_type", type: "categoryColumn" },
    { headerName: "Status", field: "Status", type: "categoryColumn" },
    { headerName: "Txn ID", field: "tranId", type: "textColumn" },
    { headerName: "Estimate", field: "Estimate", type: "moneyColumn" },
    {
      headerName: "Paid",
      field: "has_netsuite_transaction",
      type: "booleanColumn",
    },
    { headerName: "Re-Enrl", field: "is_re_enrollment", type: "booleanColumn" },
    {
      headerName: "New Enrl",
      field: "is_new_enrollment",
      type: "booleanColumn",
    },
    {
      headerName: "Summer Enrl",
      field: "is_summer_enrollment",
      type: "booleanColumn",
    },
    {
      headerName: "Enrl Change",
      field: "is_enrollment_change",
      type: "booleanColumn",
    },
    {
      headerName: "Pre-Enrl Assess",
      field: "is_pre_enrollment_assessment",
      type: "booleanColumn",
    },
    {
      headerName: "OrderIndex",
      field: "OrderIndex",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "StudentIndex",
      field: "StudentIndex",
      type: "linkedTextColumn",
      __linkField: "student_genius_url",
    },
  ];
  return ret.filter(
    // @ts-ignore
    (cd) => !cd.__authorized_roles || cd.__authorized_roles.includes(myRole)
  );
}
