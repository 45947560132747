import { DataSnapshot, submitUserActionPostRequest } from "./user_actions";
import { assert } from "../helpers/assertions";
import {
  ALL_INTERACTION_TYPE_VALUES,
  InteractionTypeValue,
} from "../components/reporting-actions/interaction-type-input";
import { RiskReason } from "../components/reporting-actions/ecp-risk-reasons-input";
import { UserAction_EcpCustomerInteraction } from "../store/ecp/model-ecp-customer-interactions";
import { AxiosResponse } from "axios";

export async function submitUserAction__ecp_customer_interaction(
  actionPayload: ActionPayload_EcpCustomerInteraction
): Promise<AxiosResponse<UserAction_EcpCustomerInteraction>> {
  verifyActionPayload(actionPayload);
  return await submitUserActionPostRequest(
    "ecp_customer_interaction",
    actionPayload
  );
}

function verifyActionPayload(
  actionPayload: ActionPayload_EcpCustomerInteraction
): void {
  assert(typeof actionPayload.customer_id === typeof 12345);
  assert(
    actionPayload.customer_id === actionPayload.customer_snapshot.customer_id
  );
  assert(
    ALL_INTERACTION_TYPE_VALUES.includes(
      actionPayload.form_payload.interactionType as InteractionTypeValue
    )
  );
}

export interface ActionPayload_EcpCustomerInteraction {
  customer_id: number;
  form_payload: {
    interactionType: string | InteractionTypeValue;
    riskReasons: RiskReason[];
    emailAddress: string;
    phoneNumber: string;
    notes: string;
  };
  customer_snapshot: DataSnapshot;
}
