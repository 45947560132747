import {
  TableModel,
  tableModelFactory,
  TableRow,
} from "../table-model-factory";
import { Computed, computed } from "easy-peasy";
import _ from "lodash";
import { StoreModel } from "../model";

export type CourseAssessmentMetricsModel = TableModel & {
  rowData: Computed<CourseAssessmentMetricsModel, TableRow[], StoreModel>;
};

export function getCourseAssessmentMetricsModel(): CourseAssessmentMetricsModel {
  return {
    ...tableModelFactory(
      "courseAssessmentMetrics",
      "/ecp/course_assessment_metrics",
      (row) => row.CourseIndex,
      [
        {
          name: "ecp_enrollment",
          arity: "OneToMany",
          foreignName: "ecp_enrollment",
          foreignField: "CourseIndex",
          field: "CourseIndex",
        },
      ]
    ),
    rowData: computed(
      [(state) => state.initialData, (state) => state.relatedRowsMap],
      (rowData, relatedRowsMap) =>
        rowData.map((row) => {
          const enrollments = relatedRowsMap[row.id]?.ecp_enrollment ?? [];
          const enrollmentIds = enrollments.map((v) => v.id);

          return {
            ...row,
            enrollments,
            enrollmentIds,
            num_enrollments: _.size(enrollments),
          };
        })
    ),
  };
}
