import React from "react";
import { CampaignConfiguration, EmailTypeCode } from "./types";
import {
  Button,
  Callout,
  Card,
  Elevation,
  H4,
  H5,
  Icon,
  Intent,
  Tooltip,
} from "@blueprintjs/core";
import { Letter } from "react-letter";
import styled from "styled-components/macro";
import { numberToOrdinal, colorizeHTMLTemplate } from "./value-formatting";

export function buildEmailTemplateUnit(
  campaignConfiguration: CampaignConfiguration,
  emailTypeCode: EmailTypeCode,
  isEmailTemplateBodyVisible: boolean,
  toggleEmailTemplateBodyVisibility: () => void
): JSX.Element {
  const emailTemplate: string =
    campaignConfiguration.email_body_templates_by_email_type_code[
      emailTypeCode
    ];
  const waveNumber: number =
    campaignConfiguration.wave_numbers_by_email_type_code[emailTypeCode];
  const subject: string =
    campaignConfiguration.email_subject_templates_by_email_type_code[
      emailTypeCode
    ];

  return (
    <EmailTemplateUnit
      emailTemplate={emailTemplate}
      waveNumber={waveNumber}
      subject={subject}
      key={`${waveNumber}-${emailTypeCode}`}
      isEmailTemplateBodyVisible={isEmailTemplateBodyVisible}
      toggleEmailTemplateBodyVisibility={toggleEmailTemplateBodyVisibility}
    />
  );
}

function EmailTemplateUnit({
  emailTemplate,
  waveNumber,
  subject,
  isEmailTemplateBodyVisible: emailTemplateCardState,
  toggleEmailTemplateBodyVisibility: toggleEmailTemplateCardState,
}: {
  emailTemplate: string;
  waveNumber: number;
  subject: string;
  isEmailTemplateBodyVisible: boolean;
  toggleEmailTemplateBodyVisibility: () => void;
}): JSX.Element {
  return (
    <EmailTemplateUnitContainer>
      <StyledCard elevation={Elevation.TWO}>
        <CardHeaderContainer>
          <H4>{`Email Template for ${numberToOrdinal(waveNumber)} Wave`}</H4>
          <ButtonWrapper>
            <ShowHideButton
              emailTemplateCardState={emailTemplateCardState}
              toggleEmailTemplateCardState={toggleEmailTemplateCardState}
            />
          </ButtonWrapper>
        </CardHeaderContainer>
        <StyledH5>{"Subject"}</StyledH5>
        <Callout key={"subject-callout"} intent={Intent.NONE}>
          <StyledLetter html={colorizeHTMLTemplate(subject)} />
        </Callout>
        <EmailBodyTemplateContainer
          emailTemplateCardState={emailTemplateCardState}
        >
          <Separator />
          <StyledH5>{"Body"}</StyledH5>
          <Callout key={"body-callout"} intent={Intent.NONE}>
            <StyledLetter html={colorizeHTMLTemplate(emailTemplate)} />
          </Callout>
        </EmailBodyTemplateContainer>
      </StyledCard>
    </EmailTemplateUnitContainer>
  );
}

function ShowHideButton({
  emailTemplateCardState,
  toggleEmailTemplateCardState,
}: {
  emailTemplateCardState: boolean;
  toggleEmailTemplateCardState: () => void;
}) {
  return (
    <Tooltip content={`${emailTemplateCardState ? "Hide" : "Show"} email body`}>
      <Button
        icon={
          <Icon
            icon={emailTemplateCardState ? "chevron-up" : "chevron-down"}
            iconSize={Icon.SIZE_LARGE}
          />
        }
        large
        minimal
        intent={Intent.NONE}
        onClick={toggleEmailTemplateCardState}
      />
    </Tooltip>
  );
}

const StyledCard = styled(Card)`
  width: 100%;
  height: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const Separator = styled.div`
  height: 10px;
`;
const EmailTemplateUnitContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const CardHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const StyledH5 = styled(H5)`
  margin-right: 5px;
`;

const ButtonWrapper = styled.div``;

const StyledLetter = styled(Letter)`
  margin-right: 5px;
`;

const EmailBodyTemplateContainer = styled.div<{
  emailTemplateCardState: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  height: ${({ emailTemplateCardState }) =>
    emailTemplateCardState ? "fit-content" : "0px"};
  visibility: ${({ emailTemplateCardState }) =>
    emailTemplateCardState ? "visible" : "hidden"};
`;
