import React, { useCallback } from "react";
import _ from "lodash";
import { MyCheckbox, MyCheckboxGroup } from "./styled-checkbox-group";
import "styled-components/macro";
import { useBoolean } from "../../helpers/useBoolean";
import { Card, Classes } from "@blueprintjs/core"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import { AllAnyRadios } from "./AllAnyRadios";

function getAllRowNodes(gridApi) {
  const rowNodes = [];
  gridApi.forEachNode((node) => rowNodes.push(node));
  return rowNodes;
}

const COMMA_PATT = /,\s?/;

function extractValuesString(value) {
  if (_.isString(value)) {
    return value.split(COMMA_PATT);
  }
  return value;
}

export default class MultiCategoryColumnFilter extends React.Component {
  constructor(props) {
    super(props);

    this.valueGetter = props.valueGetter;
    this.fp = props.fp || {};
    this.categorySortOrder = this.fp.categorySortOrder || null;

    const allItemCounts = _.countBy(
      getAllRowNodes(props.api)
        .map(this.valueGetter)
        .map(extractValuesString)
        .flat(),
      (v) => v
    );
    const allItemValues = _.sortBy(
      Object.keys(allItemCounts),
      this.categorySortOrder
        ? (v) => (v === "N/A" ? Infinity : this.categorySortOrder.indexOf(v))
        : (v) => (v === "N/A" ? Infinity : -allItemCounts[v])
    );

    this.state = {
      selectedItemValues: [],
      allItemValues,
      allItemCounts,
      filtrationMode: "all",
    };

    this.onChange = this.onChange.bind(this);
    this.setFiltrationMode = this.setFiltrationMode.bind(this);
  }

  setFiltrationMode(filtrationMode) {
    if (!_.isEqual(this.state.filtrationMode, filtrationMode)) {
      this.setState(
        (state) => ({ ...state, filtrationMode }),
        () => {
          this.props.filterChangedCallback();
        }
      );
    }
  }

  isFilterActive() {
    return _.size(this.state.selectedItemValues) !== 0;
  }

  doesFilterPass(params) {
    const categories = extractValuesString(this.valueGetter(params.node));
    if (this.isFilterActive()) {
      let numCategoryMatches = 0;
      const numSelectedCategories = _.size(this.state.selectedItemValues);
      const numNodeCategories = _.size(categories);
      if (_.isNull(categories)) {
        return false;
      }
      categories.forEach((category) => {
        if (this.state.selectedItemValues.includes(category)) {
          numCategoryMatches += 1;
        }
      });
      if (this.state.filtrationMode === "all") {
        return numCategoryMatches === numSelectedCategories;
      } else if (this.state.filtrationMode === "any") {
        return numCategoryMatches > 0;
      } else if (this.state.filtrationMode === "only") {
        return numCategoryMatches === numNodeCategories;
      } else {
        // "exactly"
        return (
          numCategoryMatches === numNodeCategories &&
          numCategoryMatches === numSelectedCategories
        );
      }
    } else {
      return true;
    }
  }

  getModel() {
    return { value: this.state.selectedItemValues };
  }

  setModel(model) {
    console.log("In setModel: ", model);

    const selectedItemValues = model && model.value;
    this.onChange(selectedItemValues, true);
  }

  componentMethod(message) {
    alert(`Alert from MultiCategoryColumnFilter ${message}`);
  }

  onChange(newSelectedItemValues, isFromSetModel = false) {
    console.log("In onChange: ", newSelectedItemValues);

    if (newSelectedItemValues === null) {
      newSelectedItemValues = isFromSetModel ? [] : this.state.allItemValues;
    }
    newSelectedItemValues = newSelectedItemValues.map((v) => `${v}`);
    if (!_.isEqual(this.state.selectedItemValues, newSelectedItemValues)) {
      this.setState(
        (state) => ({ ...state, selectedItemValues: newSelectedItemValues }),
        () => {
          this.props.filterChangedCallback();
        }
      );
    }
  }

  render() {
    return (
      <MultiCategoryFilterView
        selectedItemValues={this.state.selectedItemValues}
        allItemValues={this.state.allItemValues}
        allItemCounts={this.state.allItemCounts}
        filtrationMode={this.state.filtrationMode}
        setFiltrationMode={this.setFiltrationMode}
        onChange={this.onChange}
      />
    );
  }
}

function getCheckboxTitle(v, nItems, onClickCount) {
  return (
    <span>
      <span>{v}</span>
      <span className="nItems" onClick={onClickCount}>
        {nItems}
      </span>
    </span>
  );
}

function MultiCategoryFilterView({
  selectedItemValues,
  allItemValues,
  allItemCounts,
  onChange,
  filtrationMode,
  setFiltrationMode,
}) {
  const alwaysDisplayCounts = useBoolean(false);
  const onClickCount = useCallback(
    (ev) => {
      ev.preventDefault();
      alwaysDisplayCounts.toggle();
    },
    // eslint-disable-next-line
    [alwaysDisplayCounts.toggle]
  );

  return (
    <Card
      css={`
        border-radius: 0 !important;
        border: transparent !important;
        box-shadow: none !important;

        & .ant-checkbox-group {
          background: unset !important;
          color: unset !important;
        }
        & .ant-checkbox-wrapper {
          color: unset !important;
        }
      `}
    >
      <h5 className={Classes.HEADING}>Select Filter Values</h5>
      <AllAnyRadios
        selectedValue={filtrationMode}
        setSelectedValue={setFiltrationMode}
      />
      <MyCheckboxGroup value={selectedItemValues} onChange={onChange}>
        {allItemValues.map((v) => {
          const nItems = allItemCounts[v];
          const title = getCheckboxTitle(v, nItems, onClickCount);
          return (
            <span
              key={v}
              css={`
                & .nItems {
                  font-size: 0.6em;
                  padding: 0.3em !important;
                  text-align: center;
                  vertical-align: middle;
                  background-color: transparent !important;
                  border-radius: 1em;
                  margin-left: 1em;
                  color: transparent !important;
                  border: 1px solid transparent;
                }
                && .nItems.nItems:hover {
                  background-color: rgba(255, 255, 255, 0.2) !important;
                  color: white !important;
                  border: none !important;
                }
                ${alwaysDisplayCounts.value ? "& .nItems" : "&:hover .nItems"} {
                  color: white !important;
                  border: 1px solid rgba(255, 255, 255, 0.2);
                }
              `}
            >
              <MyCheckbox key={v} value={v} title={title} />
            </span>
          );
        })}
      </MyCheckboxGroup>
    </Card>
  );
}
