import { Computed, computed, State } from "easy-peasy";
import { StoreModel } from "../model";
import {
  TableModel,
  tableModelFactory,
  TableRow,
} from "../table-model-factory";
import _ from "lodash";
import { addTagsField } from "../../entities/ecp/tags";

export type EcpTeachercourseTableRow = TableRow & {
  teacherCourse_id: number;
  TeacherIndex: number;
  CourseIndex: number;
  DepartmentIndex: number;
  department_codename: string;
  DepartmentName: string;
  TeacherName: string;
  CourseName: string;
  TeacherEmail: string;
};

export interface RowDataById {
  [key: string]: EcpTeachercourseTableRow;
  [key: number]: EcpTeachercourseTableRow;
}

export type EcpTeachercourseModel = TableModel<EcpTeachercourseTableRow> & {
  rowData: Computed<
    EcpTeachercourseModel,
    EcpTeachercourseTableRow[],
    StoreModel
  >;
  rowsById: Computed<
    EcpTeachercourseModel,
    {
      [rowIdString: string]: EcpTeachercourseTableRow;
    },
    StoreModel
  >;
};

export function getEcpTeachercourseModel(): EcpTeachercourseModel {
  return {
    ...tableModelFactory(
      "ecp_teacherCourse",
      "ecp/ff_teacherCourses",
      (row) => row.teacherCourse_id,
      [
        {
          name: "ecp_enrollment",
          arity: "OneToMany",
          foreignName: "ecp_enrollment",
          foreignField: "teacherCourse_id",
          field: "teacherCourse_id",
        },
      ],
      (initData) => addTagsField(initData, null)
    ),
    rowData: computed(
      [(state) => state.initialData, (state) => state.relatedRowsMap],
      (rowData, relatedRowsMap) =>
        rowData.map((row) => {
          const enrollments = relatedRowsMap[row.id]?.ecp_enrollment ?? [];
          const enrollmentIds = enrollments.map((v) => v.id);
          return {
            ...row,
            enrollments,
            enrollmentIds,
            num_enrollments: _.size(enrollments),
          };
        })
    ),
    rowsById: computed<
      EcpTeachercourseModel,
      { [rowIdString: string]: EcpTeachercourseTableRow },
      StoreModel,
      [{ (k: State<EcpTeachercourseModel>): EcpTeachercourseTableRow[] }]
    >([(s): EcpTeachercourseTableRow[] => s.rowData], (rowData) =>
      Object.fromEntries(rowData.map((row) => [row.id, row]))
    ),
  };
}
