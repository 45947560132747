import { TableModel, tableModelFactory } from "../table-model-factory";

export interface EcpSourceDataIssueModel extends TableModel {}

export function getEcpSourceDataIssueModel(): EcpSourceDataIssueModel {
  return {
    ...tableModelFactory(
      "ecp_sourceDataIssue",
      "ecp/source_data_issues",
      (row) =>
        [
          row.issue_codename,
          row.EnrollmentIndex ?? row.enrollment_index ?? "",
          row.StudentIndex ?? row.student_index ?? "",
          row.TeacherIndex ?? "",
        ].join("|")
    ),
  };
}
