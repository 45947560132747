import { useNextCourseRecommendationsByDepartmentName } from "../hooks/use-recos-by-dept";
import { SingleDepartmentRecos } from "../single-dept/single-department-recos";
import React from "react";
import { Recommendation } from "../types";

export function Body(): JSX.Element {
  const recosByDept = useNextCourseRecommendationsByDepartmentName();
  const elems = Object.entries(recosByDept)
    .sort(compareDepartments)
    .map(createBodyElement);
  return <div className={"recommendations-unit-content--body"}>{elems}</div>;
}

function createBodyElement([departmentName, nextCourseRecos]: [
  string,
  Recommendation[]
]): JSX.Element {
  return (
    <SingleDepartmentRecos
      key={departmentName}
      departmentName={departmentName}
      nextCourseRecos={nextCourseRecos}
    />
  );
}

function compareDepartments(
  a: [string, Recommendation[]],
  b: [string, Recommendation[]]
): number {
  const [aDept] = a;
  const [bDept] = b;
  if (aDept > bDept) {
    return 1;
  } else if (bDept > aDept) {
    return -1;
  } else {
    return 0;
  }
}
