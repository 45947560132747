import _ from "lodash";
import {
  DEFAULT_SELECTED_ENTITY_IDS,
  useSelectedEntityId,
} from "../../store/model-entity-selection";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { Unit } from "../../components/units/unit";
import React, { useState } from "react";
import {
  Button,
  Callout,
  Card,
  H5,
  H6,
  Intent,
  Position,
  Tooltip,
} from "@blueprintjs/core";
import { useSelectedCustomer } from "../../components/units/selection-unit";
import { OrderSelector } from "../../components/selection/order-selector";
import { CoursePropagationDialog } from "./course-propagation-dialog";
import { getNaiveDate } from "../../helpers/getNaiveDate";
import styled from "styled-components/macro";
import { isCurrentOrFutureWeekday } from "../../helpers/isCurrentOrFutureWeekday";

const ORDER_STATUSES_CAN_ADD_COURSES = [
  "IN_PROGRESS",
  "RE_ENROLLMENT_SCHEDULE",
  "COUNSELOR_APPROVAL",
  "CHANGE_REQUEST",
];

// The start date of the order is stored without timezone in the minerva-ds dataset and is interpreted by
// the frontend as UTC timezone, which is not correct. Therefore, we are using naive dates (dates that are not
// aware of timezones) because we want the start date of the order to align with the user's current timezone.
// This way, we avoid validation errors and propagation of courses with incorrect dates. Note: We are assuming
// that the start date of the order was set in the same timezone that the user is currently in when adding
// courses to the order.
export function SingleStudentPropagateCourseScheduleIntoGeniusUnit(): JSX.Element {
  const loading = _.max([
    DEFAULT_SELECTED_ENTITY_IDS.student === useSelectedEntityId("student"),
    useEnsureData("ecp_student"),
    useEnsureData("ecp_order"),
    useEnsureData("nextYearCourseScheduleRecommendations"),
  ]);

  return (
    <Unit unitId={"propagate-course-schedule-into-genius"} loading={loading}>
      <SingleStudentPropagateCourseScheduleIntoGeniusContent />
    </Unit>
  );
}

function SingleStudentPropagateCourseScheduleIntoGeniusContent(): JSX.Element {
  const customer = useSelectedCustomer();
  const customerOrders = customer.orders;
  const validOrdersForCourseSchedulePropagation = customerOrders.filter(
    filterValidOrdersForCourseSchedulePropagation
  );

  const nextYearCourseScheduleRecommendations =
    customer.next_year_course_schedule_recommendations;

  const orderIndexToAddCoursesTo = +useSelectedEntityId("order_index");
  const orderToAddCoursesTo = customerOrders.find(
    (order) => order.OrderIndex === orderIndexToAddCoursesTo
  );
  const nextSchoolYearStartDateFormatted = getNextSchoolYearStartDateFormatted(
    orderToAddCoursesTo?.StartDate
  );
  const nextCourseNamesByIndex = getNextCourseNamesByIndex(
    nextYearCourseScheduleRecommendations
  );

  const [coursePropagationDialogIsOpen, setCoursePropagationDialogIsOpen] =
    useState(false);
  const openCoursePropagationDialog = () =>
    setCoursePropagationDialogIsOpen(true);
  const closeCoursePropagationDialog = () =>
    setCoursePropagationDialogIsOpen(false);

  return (
    <PropagateCourseScheduleCard>
      <PropagateCourseScheduleCardTitle>
        {"Propagate Course Schedule Into Genius"}
      </PropagateCourseScheduleCardTitle>
      <VerticalLayoutContainer>
        <PropagationInfoRow>
          <Tooltip
            content={
              "Please select an order that does not have courses added to it"
            }
            position={Position.BOTTOM}
          >
            <span>Order to Add Courses To:</span>
          </Tooltip>
          <OrderSelector
            filter={filterValidOrdersForCourseSchedulePropagation}
          />
        </PropagationInfoRow>
        <div style={{ marginTop: "10px" }}>
          <PropagationInfoRow>
            <Tooltip
              content={
                "The start date of the next courses will be the same as the start date of the selected order"
              }
              position={Position.BOTTOM}
            >
              <span>Next Year Start Date:</span>
            </Tooltip>
            <span>{nextSchoolYearStartDateFormatted}</span>
          </PropagationInfoRow>
        </div>
      </VerticalLayoutContainer>
      {!_.size(nextCourseNamesByIndex) && (
        <ValidationError>
          There are no next recommended courses to be propagated into Genius.
        </ValidationError>
      )}
      {!_.size(validOrdersForCourseSchedulePropagation) && (
        <ValidationError>
          The student does not have any orders in an acceptable status (
          {ORDER_STATUSES_CAN_ADD_COURSES.join(", ")}) for course propagation,
          with a start date that falls on a current or future weekday.
        </ValidationError>
      )}
      <Button
        type="button"
        intent="primary"
        disabled={isPropagateCourseScheduleButtonDisabled(
          orderToAddCoursesTo,
          nextCourseNamesByIndex
        )}
        style={{ marginTop: "10px", height: "40px" }}
        onClick={openCoursePropagationDialog}
      >
        Propagate Course Schedule
      </Button>
      <CoursePropagationDialog
        isOpen={coursePropagationDialogIsOpen}
        onClose={closeCoursePropagationDialog}
        nextSchoolYearStartDateFormatted={nextSchoolYearStartDateFormatted}
        orderIndexToAddCoursesTo={orderIndexToAddCoursesTo}
        orderNameToAddCoursesTo={orderToAddCoursesTo?.Name}
        nextCourseNamesByIndex={nextCourseNamesByIndex}
        studentName={customer.customer_name}
      />
    </PropagateCourseScheduleCard>
  );
}

const PropagationInfoRow = styled(H6)`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > span:first-child {
    margin-right: 8px;
  }
`;

const PropagateCourseScheduleCard = styled(Card)`
  height: 100%;
  padding: 20px !important;
`;

const PropagateCourseScheduleCardTitle = styled(H5)`
  margin: 0 0 40px 0 !important;
  padding: 0 !important;
`;

const VerticalLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
`;

function ValidationError({ children }): JSX.Element {
  return (
    <div style={{ maxWidth: "fit-content", marginTop: "10px" }}>
      <Callout intent={Intent.DANGER}>{children}</Callout>
    </div>
  );
}

function getNextSchoolYearStartDateFormatted(
  orderToAddCoursesToStartDate?: string
): string {
  if (orderToAddCoursesToStartDate) {
    const date = getNaiveDate(orderToAddCoursesToStartDate);

    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  return "";
}

export const filterValidOrdersForCourseSchedulePropagation = (order) => {
  return (
    ORDER_STATUSES_CAN_ADD_COURSES.includes(order.Status) &&
    isCurrentOrFutureWeekday(order.StartDate)
  );
};

function getNextCourseNamesByIndex(
  nextYearCourseScheduleRecommendations
): Map<number, string> {
  let nextCourseNamesByIndex = new Map();

  for (const recommendation of nextYearCourseScheduleRecommendations) {
    if (recommendation.first_next_recommended_course_index) {
      nextCourseNamesByIndex.set(
        recommendation.first_next_recommended_course_index,
        recommendation.first_next_recommended_course_name
      );
    }
    if (recommendation.second_next_recommended_course_index) {
      nextCourseNamesByIndex.set(
        recommendation.second_next_recommended_course_index,
        recommendation.second_next_recommended_course_name
      );
    }
  }

  return nextCourseNamesByIndex;
}

function isPropagateCourseScheduleButtonDisabled(
  orderToAddCoursesTo,
  nextCourseNamesByIndex: Map<number, string>
): boolean {
  return !orderToAddCoursesTo || !_.size(nextCourseNamesByIndex);
}
