import React from "react";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import "styled-components/macro";
import AgTable from "../components/AgTable";
import { AgColDef, AgColDefs } from "../components/AgTable/types";
import { useMyRole } from "./helpers/authorization";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { useEndpoint } from "../helpers/use-endpoint";
import { TableRow } from "../store/table-model-factory";
import { RbnDateRange } from "../components/AgTable/ribbon";
import { InitialCategoryFilters } from "../components/AgTable/ag_table";

export const SPECIAL_AC_NAMES = {
  Unassigned: "- Unassigned -",
  Total: "Total",
};
const EXCLUDED_AC_NAMES = [
  "Ariel Feigenbaum",
  "Bridgitte Rutkowski",
  "Kevin Mitchel",
  "Stacy Geyer",
];
const getInitialCategoryFilters = (
  rowData: TableRow[]
): InitialCategoryFilters => ({
  AC_FullName: rowData
    .map((row) => row.AC_FullName)
    .filter((name) => !Object.values(EXCLUDED_AC_NAMES).includes(name)),
});
const finalizeACsKPIsRowData = (rowData: TableRow[]): TableRow[] => {
  let finalizedRowData = rowData.map((row) => {
    let defaultSortValue = 0;
    if (row.AC_FullName === SPECIAL_AC_NAMES.Total) {
      defaultSortValue = 999999;
    }
    if (row.AC_FullName === SPECIAL_AC_NAMES.Unassigned) {
      defaultSortValue = 999998;
    }
    return {
      id: row.AC_FullName,
      ...row,
      defaultSortValue,
    };
  });
  finalizedRowData = _.sortBy(finalizedRowData, [
    "Enrollments_daily",
  ]).reverse();
  finalizedRowData = _.sortBy(finalizedRowData, ["defaultSortValue"]);
  return finalizedRowData;
};

export function AdmissionsCoordinatorTable({
  fullScreenEnabled = true,
  selectedDateRange,
  onDateRangeSelected,
  ...restProps
}) {
  const queryParams = {
    startDate: selectedDateRange[0]
      ? selectedDateRange[0].toISOString().split("T")[0]
      : null,
    endDate: selectedDateRange[1]
      ? selectedDateRange[1].toISOString().split("T")[0]
      : null,
  };
  let { loading, data: rowData } = useEndpoint<TableRow[]>(
    "admissions_coordinators_kpis",
    true,
    queryParams
  );
  return loading || !rowData ? (
    <Spinner />
  ) : (
    <AdmissionsCoordinatorTableInner
      tableName={"admissionsCoordinators"}
      rowData={finalizeACsKPIsRowData(rowData)}
      ribbonTitle={"Admissions Coordinators"}
      fullScreenEnabled={fullScreenEnabled}
      customRibbonLeftElements={[
        <RbnDateRange
          key={"admissions-coordinator-table-date-range-picker"}
          dateRange={selectedDateRange}
          onChangeHandler={onDateRangeSelected}
        />,
      ]}
      {...restProps}
    />
  );
}

function AdmissionsCoordinatorTableInner({
  tableName,
  ribbonTitle,
  rowData,
  customRibbonLeftElements,
  ...restProps
}) {
  const colDefs = useColumnDefs();
  return (
    <AgTable
      rowData={rowData}
      columnDefs={colDefs}
      tableName={tableName}
      ribbonTitle={ribbonTitle}
      pinLastRowToBottom={true}
      customRibbonLeftElements={customRibbonLeftElements}
      initialCategoryFilters={getInitialCategoryFilters(rowData)}
      {...restProps}
    />
  );
}

export function useColumnDefs(): AgColDefs {
  const myRole = useMyRole();
  let ret = [
    {
      headerName: "Name",
      field: "AC_FullName",
      type: "categoryColumn",
      width: 180,
      suppressSizeToFit: true,
      tooltipField: "AC_FullName",
      pinned: "left",
    },
    {
      headerName: "ID",
      field: "AC_Id",
      type: "integerIdentifierColumn",
      suppressSizeToFit: true,
      width: 100,
      hide: true,
    },
    {
      headerName: "Email",
      field: "AC_Email",
      type: "textColumn",
      suppressSizeToFit: true,
    },
    {
      headerName: "Leads",
      field: "Leads",
      type: "integerColumn",
      headerTooltip:
        "Total number of leads created during specified date range",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      headerName: "Current Inquiries",
      field: "CurrentInquiries",
      type: "integerColumn",
      headerTooltip:
        "Number of leads created during specified date range currently in the 'Inquiry' stage",
      suppressSizeToFit: true,
      cellRenderer: "admissionsCoordinatorsKPICellRenderer",
      cellRendererParams: {
        crp: {
          dailyRelatedField: "Inquiries_daily",
          getIntent: () => Intent.NONE,
        },
      },
      width: 100,
    },
    {
      headerName: "Current Applications",
      field: "CurrentApplications",
      type: "integerColumn",
      headerTooltip:
        "Number of leads created during specified date range that currently have reached the 'Application' stage",
      suppressSizeToFit: true,
      width: 140,
    },
    {
      headerName: "Current Commitments",
      field: "CurrentCommitments",
      type: "integerColumn",
      headerTooltip:
        "Number of leads created during specified date range currently with a 'Application - Commitment' status",
      suppressSizeToFit: true,
      cellRenderer: "admissionsCoordinatorsKPICellRenderer",
      cellRendererParams: {
        crp: {
          dailyRelatedField: "Commitments_daily",
          getIntent: () => Intent.NONE,
        },
      },
      width: 150,
    },
    {
      headerName: "Enrollments",
      field: "Enrollments",
      type: "integerColumn",
      headerTooltip:
        "Number of leads created during specified date range that reached the 'Customer' stage",
      suppressSizeToFit: true,
      cellRenderer: "admissionsCoordinatorsKPICellRenderer",
      cellRendererParams: {
        crp: {
          dailyRelatedField: "Enrollments_daily",
          getIntent: (value) => {
            if (value < 0.5) return Intent.DANGER;
            if (value >= 1) return Intent.SUCCESS;
            return Intent.WARNING;
          },
        },
      },
      width: 140,
    },
    {
      headerName: "# Enrollments / Day",
      field: "Enrollments_daily",
      type: "floatColumn",
      headerTooltip:
        "Average number of leads converted to Enrollments per business day in specified date range",
      suppressSizeToFit: true,
      width: 145,
      hide: false,
    },
    {
      headerName: "Lead to Enrollment Conversion",
      field: "Enrollments_rate",
      type: "percentageColumn",
      headerTooltip:
        "Ratio between Enrollments and total number of leads created in specified date range",
      suppressSizeToFit: true,
      width: 180,
    },
    {
      headerName: "Total Revenue",
      field: "Revenue_total",
      type: "moneyColumn",
      headerTooltip:
        "Total revenue obtained from leads that became enrollments",
      suppressSizeToFit: true,
      width: 140,
    },
    {
      headerName: "Avg Revenue / Lead",
      field: "Revenue_avg",
      type: "moneyColumn",
      headerTooltip:
        "Average revenue obtained from each lead that became an enrollment",
      suppressSizeToFit: true,
      width: 140,
    },
    {
      headerName: "Reported\nInteractions",
      field: "LeadInteractions",
      type: "integerColumn",
      headerTooltip:
        "Number of lead interactions reported in specified date range",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      headerName: "# Inquiries / Day",
      field: "Inquiries_daily",
      type: "floatColumn",
      headerTooltip:
        "Average number of Inquiries considering business days in specified date range",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "# Commitments / Day",
      field: "Commitments_daily",
      type: "floatColumn",
      headerTooltip:
        "Average number of leads being converted to Commitments considering business days in specified date range",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Number of Business Days",
      field: "business_days_count",
      type: "integerColumn",
      headerTooltip: "Number of business days within the specified date range",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "SoR %",
      field: "SOR_pct",
      type: "percentageColumn",
      headerTooltip:
        "Calculated as the number of SoR enrollments divided by the total number of SoR and Non-SoR enrollments",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      headerName: "# SoR",
      field: "SOR",
      type: "integerColumn",
      headerTooltip: "Number of enrollments with a SoR status",
      suppressSizeToFit: true,
      width: 100,
      hide: true,
    },
    {
      headerName: "# Non-SoR",
      field: "NonSOR",
      type: "integerColumn",
      headerTooltip: "Number of enrollments without a SoR status",
      suppressSizeToFit: true,
      width: 120,
      hide: true,
    },
    {
      headerName: "Academy %",
      field: "Academy_pct",
      type: "percentageColumn",
      headerTooltip:
        "Calculated as number of Academy enrollments divided by the total number of Academy and Non-Academy enrollments",
      suppressSizeToFit: true,
      width: 120,
    },
    {
      headerName: "# Academy",
      field: "Academy",
      type: "integerColumn",
      headerTooltip: "Number of Academy enrollments",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Inquiry - New",
      field: "Inquiry-New_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 100,
      hide: true,
    },
    {
      headerName: "Inquiry - Uncontacted",
      field: "Inquiry-Uncontacted_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Inquiry - Contacted Hot",
      field: "Inquiry-Contacted Hot_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Inquiry - Contacted Cold",
      field: "Inquiry-Contacted Cold_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 150,
      hide: true,
    },
    {
      headerName: "Inquiry - Spam",
      field: "Inquiry-Spam_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 100,
      hide: true,
    },
    {
      headerName: "Inquiry - Duplicate",
      field: "Inquiry-Duplicate_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Inquiry - Wrong Information",
      field: "Inquiry-Wrong Information_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 150,
      hide: true,
    },
    {
      headerName: "Inquiry - Not Interested",
      field: "Inquiry-Not Interested_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Inquiry - DNC",
      field: "Inquiry-DNC_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 100,
      hide: true,
    },
    {
      headerName: "Inquiry - Partners",
      field: "Inquiry-Partners_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 100,
      hide: true,
    },
    {
      headerName: "Inquiry - Partners - MKT",
      field: "Inquiry-Partners-MKT_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 150,
      hide: true,
    },
    {
      headerName: "Inquiry - Potential Partner",
      field: "Inquiry-Potential Partner_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 155,
      hide: true,
    },
    {
      headerName: "Application - In Progress",
      field: "Application-In Progress_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Application - Contacted Hot",
      field: "Application-Contacted Hot_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Application - Contacted Cold",
      field: "Application-Contacted Cold_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 150,
      hide: true,
    },
    {
      headerName: "Application - Uncontacted",
      field: "Application-Uncontacted_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Application - Completed",
      field: "Application-Completed_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Application - Proposal",
      field: "Application-Proposal_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Application - Wrong Information",
      field: "Application-Wrong Information_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 170,
      hide: true,
    },
    {
      headerName: "Application - Not Interested",
      field: "Application-Not Interested_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 160,
      hide: true,
    },
    {
      headerName: "Application - Duplicate",
      field: "Application-Duplicate_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Application - E-Commerce",
      field: "Application-E-Commerce_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Application - Lost Opportunity",
      field: "Application-Lost Opportunity_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 155,
      hide: true,
    },
    {
      headerName: "Customer - Closed Won",
      field: "Customer-Closed Won_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer - Enrolled - Active",
      field: "Customer-Enrolled-Active_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer - Enrolled - Extension",
      field: "Customer-Enrolled-Extension_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer - Enrolled -Future Start",
      field: "Customer-Enrolled-Future Start_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer - Enrolled - LOA",
      field: "Customer-Enrolled-LOA_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer - Enrolled - Suspended",
      field: "Customer-Enrolled-Suspended_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer - Pending Information",
      field: "Customer-Pending Information_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer - Withdrawn - Administrative",
      field: "Customer-Withdrawn-Administrative_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer - Withdrawn - Complete",
      field: "Customer-Withdrawn-Complete_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer - Withdrawn - Graduated",
      field: "Customer-Withdrawn-Graduated_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
    {
      headerName: "Customer - Withdrawn - Incomplete",
      field: "Customer-Withdrawn-Incomplete_count",
      type: "integerColumn",
      suppressSizeToFit: true,
      width: 140,
      hide: true,
    },
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
  });
}
