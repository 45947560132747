import React from "react";
import { useStoreState } from "../../hooks/ep";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import { AgColDefs } from "../../components/AgTable/types";
import { useMeData } from "../../hooks/useMe";
import AgTable from "../../components/AgTable";

export function ReeEnrollmentTable({ fullScreenEnabled = true, ...restProps }) {
  const loading = _.max([useEnsureData("ree_enrollment")]);
  return loading ? (
    <Spinner />
  ) : (
    <ReeEnrollmentTableInner
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

function ReeEnrollmentTableInner(props) {
  const rowData = useStoreState((s) => s.ree_enrollment.initialData);
  const colDefs = useColumnDefs();

  return (
    <AgTable
      rowData={rowData}
      columnDefs={colDefs}
      tableName={"ree_enrollment"}
      {...props}
    />
  );
}

function useColumnDefs(): AgColDefs {
  const myRole = useMeData().role;
  const ret: AgColDefs = [
    {
      headerName: "Grade Lvl",
      field: "grade_level",
      type: "integerColumn",
      filter: "categoryFilterer",
    },
    { headerName: "Status", field: "Status", type: "categoryColumn" },
    { headerName: "Course", field: "CourseName", type: "textColumn" },
    { headerName: "Start", field: "StartDate", type: "dateColumn" },
    { headerName: "End", field: "EndDate", type: "dateColumn" },
    { headerName: "Exit", field: "ExitDate", type: "dateColumn" },
    {
      headerName: "Pre-Extension End",
      field: "EndDatePriorToExtension",
      type: "dateColumn",
    },
    {
      headerName: "School Year",
      field: "SchoolYear",
      type: "integerColumn",
      filter: "categoryFilterer",
    },
    { headerName: "Semester", field: "Semester", type: "categoryColumn" },
    {
      headerName: "Last Activity",
      field: "LastActivityDate",
      type: "dateColumn",
    },
    {
      headerName: "Last Teacher Communication",
      field: "LastTeacherCommunicationDate",
      type: "dateColumn",
    },
    {
      headerName: "Completion",
      field: "PercentCompleteAdjusted",
      type: "percentageColumn",
    },
    {
      headerName: "Credits",
      field: "CreditsEarned",
      type: "floatColumn",
    },
    {
      headerName: "Final Grade",
      field: "FinalGrade",
      type: "textColumn",
    },
    {
      headerName: "Weeks Remaining",
      field: "WeeksRemaining",
      type: "integerColumn",
    },
    {
      headerName: "Summer",
      field: "is_summer_enrollment",
      type: "booleanColumn",
    },
    {
      headerName: "Orientation",
      field: "is_orientation_course",
      type: "booleanColumn",
    },
    { headerName: "Price", field: "Price", type: "moneyColumn" },
    {
      headerName: "Order Total Price",
      field: "OrderTotalPrice",
      type: "moneyColumn",
      headerTooltip: "Sum of 'Price' from all enrollments tied to this order",
    },
    {
      headerName: "Order Total Estimate",
      field: "Estimate",
      type: "moneyColumn",
    },
    { headerName: "Order Name", field: "OrderName", type: "textColumn" },
    { headerName: "Order Type", field: "order_type", type: "categoryColumn" },
    {
      headerName: "Order Status",
      field: "order_Status",
      type: "categoryColumn",
    },
    { headerName: "Order Txn ID", field: "order_tranId", type: "textColumn" },
    {
      headerName: "EnrollmentIndex",
      field: "EnrollmentIndex",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "OrderIndex",
      field: "OrderIndex",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "StudentIndex",
      field: "StudentIndex",
      type: "linkedTextColumn",
      __linkField: "student_genius_url",
    },
  ];
  return ret.filter(
    // @ts-ignore
    (cd) => !cd.__authorized_roles || cd.__authorized_roles.includes(myRole)
  );
}
