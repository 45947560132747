import React, { useState } from "react";
import "styled-components/macro";
import { useEndpoint } from "../helpers/use-endpoint";
import _ from "lodash";
import { useEnsureData } from "../helpers/use-ensure-data";
import {
  Card,
  Classes,
  H4,
  H5,
  H6,
  Spinner,
  Intent,
  Elevation,
  Tooltip,
  Tag,
  Icon,
  Button,
} from "@blueprintjs/core";
import { EcpCampaignemailTable } from "../entities/ecp/ecp-campaignEmail-entity";
import {
  DescriptionItemConfig,
  FlatDescriptions,
} from "../components/flat-descriptions";
import { buildDescriptionItem } from "../components/overview--description-item";
import AgTable from "../components/AgTable";
import { AgColDefs } from "../components/AgTable/types";
import {
  floatVF,
  integerVF,
  isna,
  moneyVF,
  percentageVF,
  riskPercentageChangeVF,
} from "../components/AgTable/gridOptions";
import { useFullScreenModeEnabled } from "../store/model-misc";
import { useBoolean } from "../helpers/useBoolean";

export function EcpAutomationTrackingPage() {
  const { loading: loading_campaignStats, data } = useEndpoint<
    EnrMIA_CampaignStats[]
  >("ecp/enr_mia__campaign_stats");
  const loading = _.max([
    loading_campaignStats,
    // useEnsureData("ecp_campaignEmail"),
  ]);

  return loading ? <Spinner /> : <EcpAutomationTrackingPageInner data={data} />;
}

function StatsBarSection({ data }: { data: EnrMIA_CampaignStats }) {
  return (
    <div
      css={`
        width: 100%;
        display: flex;
        align-items: center;
        flex-grow: 0;
      `}
    >
      <H5
        css={`
          margin-right: 20px;
          flex-basis: auto;
          flex-grow: 2;
          white-space: nowrap;
        `}
      >
        Student MIA Automation Stats:{" "}
      </H5>
      <StatsBar data={data} />
    </div>
  );
}

function EcpAutomationTrackingPageInner({
  data,
}: {
  data: EnrMIA_CampaignStats[];
}) {
  // console.log("execsumm", data);
  const fullscreenOff = !useFullScreenModeEnabled();
  const cgnStatsHidden = useBoolean(false);
  const showCgnStats = fullscreenOff && !cgnStatsHidden.value;
  const ecpCampaignemailTableProps = showCgnStats
    ? {
        height:
          "calc(max(400px, 100vh - 40px - 40px - 40px - 76px - 64px - 48px - 262px))",
      }
    : {};
  const [selectedCampaignType, selectCampaignType] = useState<string>();

  return (
    <div
      css={`
        width: 100%;
        height: 100%;
      `}
    >
      {showCgnStats && (
        <EcpEnrMiaCgnStatsTable
          rowData={data}
          cgnStatsHidden={cgnStatsHidden}
          selectCampaignType={selectCampaignType}
        />
      )}
      {showCgnStats && (
        <div
          css={`
            height: 40px;
          `}
        >
          {" "}
        </div>
      )}
      <EcpCampaignemailTable
        selectedCampaignType={selectedCampaignType}
        selectCampaignType={selectCampaignType}
        {...ecpCampaignemailTableProps}
      />
    </div>
  );
}

function StatsBar({ data }: { data: EnrMIA_CampaignStats }): JSX.Element {
  return !data ? <Spinner /> : <StatsBarInner data={data} />;
}
function StatsBarInner({ data }: { data: EnrMIA_CampaignStats }): JSX.Element {
  const descriptionItems = useDescriptionItems(data);
  return <FlatDescriptions fill>{descriptionItems}</FlatDescriptions>;
}
function useDescriptionItems(data: EnrMIA_CampaignStats): JSX.Element[] {
  return [
    useDescriptionItem__n_emails_sent(data),
    useDescriptionItem__nuniq_students(data),
    useDescriptionItem__pct_overall_shed_mia_tag(data),
    useDescriptionItem__pct_shed_mia_tag_within_7days(data),
    useDescriptionItem__avg_days_until_shed_mia_tag(data),
    useDescriptionItem__pct_opened_by_TO(data),
  ];
}

function useDescriptionItem__n_emails_sent(
  data: EnrMIA_CampaignStats
): JSX.Element {
  const key = "n_emails_sent";
  const itemConfig: DescriptionItemConfig = {
    label: "# Emails Sent",
    field: "n_emails_sent",
    type: "integer",
    tooltip:
      "The number of emails sent to students about newly-MIA enrollments",
  };
  return buildDescriptionItem({
    key,
    data,
    itemConfig,
  });
}

function useDescriptionItem__nuniq_students(
  data: EnrMIA_CampaignStats
): JSX.Element {
  const key = "nuniq_students";
  const itemConfig: DescriptionItemConfig = {
    label: "# Students Targeted",
    field: "nuniq_students",
    type: "integer",
    tooltip: "The number of distinct students targeted by MIA emails",
  };
  return buildDescriptionItem({
    key,
    data,
    itemConfig,
  });
}

function useDescriptionItem__pct_opened_by_TO(
  data: EnrMIA_CampaignStats
): JSX.Element {
  const key = "pct_opened_by_TO";
  const itemConfig: DescriptionItemConfig = {
    label: "% Opened",
    field: "pct_opened_by_TO",
    type: "percentage",
    tooltip:
      "The percentage of MIA emails that have been opened by their intended recipient (student/parent)",
  };
  return buildDescriptionItem({
    key,
    data,
    itemConfig,
  });
}

function useDescriptionItem__pct_overall_shed_mia_tag(
  data: EnrMIA_CampaignStats
): JSX.Element {
  const key = "pct_overall_shed_mia_tag";
  const itemConfig: DescriptionItemConfig = {
    label: "% Shed MIA Tag",
    field: "pct_overall_shed_mia_tag",
    type: "percentage",
    tooltip:
      "The percentage of MIA enrollments that shed their MIA tag (by submitting an assignment in Buzz)",
  };
  return buildDescriptionItem({
    key,
    data,
    itemConfig,
  });
}

function useDescriptionItem__pct_shed_mia_tag_within_7days(
  data: EnrMIA_CampaignStats
): JSX.Element {
  const key = "pct_shed_mia_tag_within_7days";
  const itemConfig: DescriptionItemConfig = {
    label: "% Promptly Shed MIA Tag",
    field: "pct_shed_mia_tag_within_7days",
    type: "percentage",
    tooltip:
      "The percentage of newly-MIA enrollments that shed their MIA tag (by submitting an assignment in Buzz) within 7 days of the MIA email being sent",
  };
  return buildDescriptionItem({
    key,
    data,
    itemConfig,
  });
}

function useDescriptionItem__avg_days_until_shed_mia_tag(
  data: EnrMIA_CampaignStats
): JSX.Element {
  const key = "avg_days_until_shed_mia_tag";
  const itemConfig: DescriptionItemConfig = {
    label: "Avg # Days to Shed MIA Tag",
    field: "avg_days_until_shed_mia_tag",
    type: "float",
    tooltip:
      "Among enrollments whose MIA tags were shed, the average number of days that elapsed between the MIA email being sent and the student submitting another assignment in Buzz for the enrollment in question",
  };
  return buildDescriptionItem({
    key,
    data,
    itemConfig,
  });
}

export interface EnrMIA_CampaignStats {
  email_type_code: MIAEmailTypeCodeT;
  n_emails_sent: number;
  nuniq_students: number;
  nuniq_enrollments: number;
  n_opened_by_TO: number;
  pct_opened_by_TO: number;
  pct_overall_shed_mia_tag: number;
  pct_shed_mia_tag_within_7days: number;
  avg_days_until_shed_mia_tag: number;
}

type MIAEmailTypeCodeT =
  | "enrollment__newly_mia"
  | "enrollment__followup_mia1"
  | "enrollment__followup_mia2"
  | "enrollment__followup_mia3"
  | "enrollment__recurring_mia"
  | "enrollment__summer__newly_mia"
  | "enrollment__summer__followup_mia1"
  | "enrollment__summer__followup_mia2"
  | "enrollment__summer__followup_mia3"
  | "enrollment__summer__recurring_mia"
  | "teacher__newly_mia";

function getTagIntent(value: string) {
  if (value.startsWith("+")) {
    return Intent.DANGER;
  } else if (value.startsWith("-")) {
    return Intent.SUCCESS;
  }
  return Intent.PRIMARY;
}

function PipeSeparatedPercentageTags({ values }: { values: string[] }) {
  return (
    <div
      css={`
        display: flex;
        justify-content: ${values[0] && values[1]
          ? "space-between"
          : values[1]
          ? "flex-end"
          : "flex-start"};
        & .bp3-tag > .bp3-fill {
          width: 55px;
          text-align: center;
        }
        & .separator {
          margin: 0 5px;
          opacity: 0.5;
        }
      `}
    >
      {values[0] && (
        <Tag
          fill={false}
          minimal={true}
          intent={getTagIntent(values[0])}
          large={false}
          interactive={false}
        >
          {values[0]}
        </Tag>
      )}
      {values[0] && values[1] && <div className={"separator"}>|</div>}
      {values[1] && (
        <Tag
          fill={false}
          minimal={true}
          intent={getTagIntent(values[1])}
          large={false}
          interactive={false}
        >
          {values[1]}
        </Tag>
      )}
    </div>
  );
}

const CARD_TITLE = "MIA Campaign Stats";

function EcpEnrMiaCgnStatsTable({
  rowData,
  cgnStatsHidden,
  selectCampaignType,
}) {
  const colDefs = useEcpEnrMiaCgnStatsTableColDefs(selectCampaignType);

  rowData = rowData.map((row) => ({ ...row, id: row.email_type_code }));
  return (
    <div
      css={`
        //position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
      `}
    >
      <Card
        elevation={Elevation.TWO}
        css={`
          position: relative;
        `}
      >
        <H4>{CARD_TITLE}</H4>
        <table
          className={[
            Classes.HTML_TABLE,
            // Classes.HTML_TABLE_STRIPED,
          ].join(" ")}
          css={`
            & > tbody > tr > td {
              &.coltype-integerColumn,
              &.coltype-floatColumn,
              &.coltype-percentageColumn {
                text-align: right;
              }
            }
          `}
        >
          <thead>
            <tr>
              {colDefs
                .filter((cd) => !cd.hide)
                .map((cd) => (
                  <th key={cd.field}>
                    <Tooltip
                      content={
                        cd.field === "email_type_name" ? null : (
                          <div
                            css={`
                              max-width: 40vw;
                            `}
                          >
                            {cd.headerTooltip.split("\n").map((line) => (
                              <span>
                                {line}
                                <br />
                              </span>
                            ))}
                          </div>
                        )
                      }
                      position={"top"}
                    >
                      {cd.field === "email_type_name" ? (
                        ""
                      ) : (
                        <div>
                          {cd.headerName.split("\n").map((line) => (
                            <span>
                              {line}
                              <br />
                            </span>
                          ))}
                        </div>
                      )}
                    </Tooltip>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {rowData.map((row) => (
              <tr key={row.id}>
                {colDefs
                  .filter((cd) => !cd.hide)
                  .map((cd) => {
                    if (cd.type === "composedPercentageColumn") {
                      const fields = cd.field.split("|");
                      const values = [row[fields[0]], row[fields[1]]];
                      const formattedValues = values.map((value) =>
                        riskPercentageChangeVF(value)
                      );
                      return (
                        <td
                          key={fields.join("-")}
                          className={`coltype-${cd.type}`}
                        >
                          <PipeSeparatedPercentageTags
                            values={formattedValues}
                          />
                        </td>
                      );
                    } else if (
                      cd.type === "linkButtonTextColumn" &&
                      !_.isNil(cd["__onClick"])
                    ) {
                      const value = row[cd.field];
                      const onClick = cd["__onClick"];
                      return (
                        <td key={cd.field} className={`coltype-${cd.type}`}>
                          <Button
                            minimal
                            small
                            intent={Intent.PRIMARY}
                            onClick={() => onClick(value)}
                          >
                            {formatValue(row[cd.field], cd.type as string)}
                          </Button>
                        </td>
                      );
                    } else {
                      return (
                        <td key={cd.field} className={`coltype-${cd.type}`}>
                          {formatValue(row[cd.field], cd.type as string)}
                        </td>
                      );
                    }
                  })}
              </tr>
            ))}
          </tbody>
        </table>
        <div
          css={`
            position: absolute;
            right: 0;
            top: 0;
          `}
        >
          <Tooltip content={`Hide ${CARD_TITLE}`}>
            <Button
              icon={<Icon icon="cross" iconSize={Icon.SIZE_LARGE} />}
              minimal
              interactive
              large
              intent={Intent.DANGER}
              onClick={cgnStatsHidden.setTrue}
            />
          </Tooltip>
        </div>
      </Card>
    </div>
  );
}

function formatValue(value: any, columnType: string): string {
  const vf = FORMATTERS_BY_TYPE[columnType];
  return vf(value);
}

const FORMATTERS_BY_TYPE = {
  moneyColumn: (value) => moneyVF({ value }),
  integerColumn: (value) => integerVF({ value }),
  floatColumn: (value) => (isna(value) ? "" : floatVF({ value })),
  percentageColumn: (value) => percentageVF({ value }),
  riskPercentageChangeColumn: (value) => riskPercentageChangeVF({ value }),
  textColumn: (value) => value,
  categoryColumn: (value) => value,
  linkButtonTextColumn: (value) => value,
};
//
// function EcpEnrMiaCgnStatsTable({ rowData }) {
//   const colDefs = ECP_ENR_MIA_CGN_STATS_TABLE_COLDEFS;
//   return (
//     <AgTable
//       rowData={rowData}
//       columnDefs={colDefs}
//       tableName={"ecp_enr_mia_campaign_stats"}
//       height={"258px"}
//       ribbonTitle={"MIA Campaign Stats"}
//     />
//   );
// }

function useEcpEnrMiaCgnStatsTableColDefs(
  selectCampaignType: (string) => void
): AgColDefs {
  return [
    {
      field: "email_type_code",
      headerName: "Email Type Code",
      type: "categoryColumn",
      hide: true,
    },
    {
      field: "email_type_name",
      headerName: "Email Type",
      type: "linkButtonTextColumn",
      pin: "left",
      __onClick: (value) => selectCampaignType(value),
    },
    {
      field: "n_emails_sent",
      headerName: "# Emails Sent",
      type: "integerColumn",
      headerTooltip: "The number of MIA emails sent to students",
    },
    {
      field: "nuniq_students",
      headerName: "# Students Targeted",
      type: "integerColumn",
      headerTooltip: "The number of distinct students targeted by MIA emails",
    },
    {
      field: "pct_overall_shed_mia_tag",
      headerName: "% Shed MIA",
      type: "percentageColumn",
      headerTooltip:
        "The percentage of MIA enrollments that shed their MIA tag (by submitting an assignment in Buzz)",
    },
    {
      field: "pct_shed_mia_tag_within_7days",
      headerName: "% Promptly Shed MIA",
      type: "percentageColumn",
      headerTooltip:
        "The percentage of enrollments that shed their MIA tag (by submitting an assignment in Buzz) within 7 days of the MIA email being sent",
    },
    // {
    //   field: "pct_permanently_shed_mia",
    //   headerName: "% Permanently Shed MIA",
    //   type: "percentageColumn",
    //   headerTooltip:
    //     "The percentage of enrollments that shed their MIA tag after the MIA email was sent and never went MIA again",
    // },
    {
      field: "avg_days_until_shed_mia_tag",
      headerName: "Avg # Days to\nShed MIA",
      type: "floatColumn",
      headerTooltip:
        "Among enrollments whose MIA tags were shed, the average number of days that elapsed between the MIA email being sent and the student submitting another assignment in Buzz for the enrollment in question",
    },
    {
      field: "pct_opened_by_TO",
      headerName: "% Opened",
      type: "percentageColumn",
      headerTooltip:
        "The percentage of MIA emails that have been opened by their intended recipient (student/parent)",
    },
    {
      headerName: "Risk Score Change\nOverall | Promptly Shed",
      headerTooltip:
        "The median percentage change of the students risk score from when the MIA email is sent and 30 days after.\n(Overall score change | Score change of students that promptly shed the MIA tag)",
      field:
        "mdn_relative_change_in_risk_score_30d_after_mia_email|mdn_relative_change_in_risk_score_30d_after_mia_email___where_promptly_shed",
      // "pct_mdn_risk_score_change_30_days_after_email_sent|promptly_shed_pct_mdn_risk_score_change_30_days_after_email_sent",
      type: "composedPercentageColumn",
    },
  ];
}
