/* Campaign Stats Table */
import {
  Button,
  Card,
  Classes,
  Elevation,
  H4,
  Icon,
  Intent,
  Tooltip,
} from "@blueprintjs/core";
import React from "react";
import { actuallyFormatValue } from "./value-formatting";
import { ECP_CGN_STATS_TABLE_COLDEFS } from "./stats-unit-table-coldefs";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import { get_sequence_name_from_id } from "./sequences";
import _ from "lodash";
import { TableRow } from "../../store/table-model-factory";
// eslint-disable-next-line no-restricted-imports
import { css } from "styled-components";
import { transparentize } from "polished";

export function StatsUnit({ rowData, cgnStatsHidden, cardTitle }) {
  const colDefs = ECP_CGN_STATS_TABLE_COLDEFS;

  const allSequenceIds: number[] = _.sortBy(
    _.uniq(rowData.map((row) => row.seq_id).filter((v) => v > 0))
  );

  rowData = _.uniqBy(
    rowData
      .map((row) => ({
        ...row,
        id: row.customer_group_name,
        seq_name: get_sequence_name_from_id(row.seq_id, allSequenceIds),
        // customer_group_name:
        //   row.customer_group_name === "Target" ? "Target Group" : "Control Group",
      }))
      .sort(),
    // @ts-ignore
    (row) => `${row.customer_group_name}|${row.seq_name}`
  );
  return (
    <div
      css={`
        //position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
      `}
    >
      <Card
        elevation={Elevation.TWO}
        css={`
          position: relative;
        `}
      >
        <H4>{cardTitle}</H4>
        <table
          className={[Classes.HTML_TABLE].join(" ")}
          css={`
            & > tbody > tr > td {
              &.coltype-integerColumn,
              &.coltype-floatColumn,
              &.coltype-percentageColumn {
                text-align: right;
              },                  
            }
          `}
        >
          <thead>
            <tr>
              {colDefs
                .filter((cd) => !cd.hide)
                .map((cd) => (
                  <th key={cd.field}>
                    <TableHeaderTooltip cd={cd} />
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {rowData.map((row) => (
              <TableBodyRow key={row.id} row={row} colDefs={colDefs} />
            ))}
          </tbody>
        </table>
        <div
          css={`
            position: absolute;
            right: 0;
            top: 0;
          `}
        >
          <Tooltip content={`Hide ${cardTitle}`}>
            <Button
              icon={<Icon icon="cross" iconSize={Icon.SIZE_LARGE} />}
              minimal
              interactive
              large
              intent={Intent.DANGER}
              onClick={cgnStatsHidden.setTrue}
            />
          </Tooltip>
        </div>
      </Card>
    </div>
  );
}

function TableBodyRow({
  row,
  colDefs,
}: {
  row: TableRow;
  colDefs: AgColDefs;
}): JSX.Element {
  return (
    <tr
      key={row.id}
      css={`
        ${row.customer_group_name === "Control"
          ? css`
              background-color: ${transparentize(0.3, "#293742")};
            `
          : css``}
      `}
    >
      {colDefs
        .filter((cd) => !cd.hide)
        .map((cd) => (
          <TableBodyCell key={cd.field} row={row} cd={cd} />
        ))}
    </tr>
  );
}

function TableBodyCell({
  row,
  cd,
}: {
  row: TableRow;
  cd: AgColDef;
}): JSX.Element {
  return (
    <td key={cd.field} className={`coltype-${cd.type}`}>
      {actuallyFormatValue(row, cd)}
    </td>
  );
}

function TableHeaderTooltip({ cd }: { cd: AgColDef }): JSX.Element {
  const content = (
    // cd.field === "customer_group_name" ? null :
    <div
      css={`
        max-width: 40vw;
      `}
    >
      {cd.headerTooltip.split("\n").map((line) => (
        <span>
          {line}
          <br />
        </span>
      ))}
    </div>
  );

  const children = (
    // cd.field === "customer_group_name" ? ("") :
    <div>
      {cd.headerName.split("\n").map((line) => (
        <span>
          {line}
          <br />
        </span>
      ))}
    </div>
  );

  return (
    <Tooltip content={content} position={"top"} boundary={"viewport"}>
      {children}
    </Tooltip>
  );
}
