import { TableModel, tableModelFactory, TableRow } from "./table-model-factory";
import { Actions, Computed, computed, Thunk, thunk } from "easy-peasy";
import {
  ActionPayload_UpdateStudentFlag,
  ALL_STUDENT_FLAG_CODENAMES,
  submitUserAction__update_student_flag,
} from "../user-actions/submit-user-action__update_student_flag";
import { toaster } from "../toaster";
import { StoreModel } from "./model";
import { Injections } from "./store-injections";

export type StudentFlagModel = TableModel & {
  handleUpdateStudentFlag: Thunk<
    StudentFlagModel,
    ActionPayload_UpdateStudentFlag,
    Injections,
    StoreModel
  >;
  valueByFlagByStudent: Computed<
    StudentFlagModel,
    { [flag_codename: string]: { [student_id: number]: boolean } },
    StoreModel
  >;
  valuesMapByStudent: Computed<
    StudentFlagModel,
    { [student_id: number]: { [flag_codename: string]: boolean } },
    StoreModel
  >;
};

export function getStudentFlagModel(): StudentFlagModel {
  return {
    ...tableModelFactory(
      "student_flag",
      "student_flags",
      (row) => `${row.student_id}|${row.flag_codename}`
    ),
    handleUpdateStudentFlag: thunk(
      async (
        actions: Actions<StudentFlagModel>,
        userActionPayload: ActionPayload_UpdateStudentFlag,
        { getState }
      ) => {
        const {
          student_id,
          flag_codename,
          flag_oldvalue,
          flag_newvalue,
          student_snapshot,
        } = userActionPayload;
        const state = getState();
        // @ts-ignore
        const rowId = state.GET_ROW_ID(userActionPayload);
        const oldRow = state.getRow(rowId);
        actions.upsertRow({
          id: rowId,
          student_id,
          flag_codename,
          flag_value: flag_newvalue,
        });
        const meat = `flag "${flag_codename}" from ${flag_oldvalue} --> ${flag_newvalue} for student "${student_snapshot.customer_name}"`;
        try {
          await submitUserAction__update_student_flag(userActionPayload);
          toaster.success(`Successfully updated ${meat}`, 2);
        } catch (e) {
          if (oldRow) {
            actions.upsertRow(oldRow);
          } else {
            actions.deleteRow(rowId);
          }
          toaster.warning(`Failed to update ${meat} -- please try again`);
          throw Error();
        }
      }
    ),
    valueByFlagByStudent: computed(
      [(s) => s.initialData],
      (initialData: TableRow[]) =>
        Object.fromEntries(
          ALL_STUDENT_FLAG_CODENAMES.map((flag_codename) => {
            const valueByStudent = Object.fromEntries(
              initialData
                .filter((row) => row.flag_codename === flag_codename)
                .map((row) => {
                  return [row.student_id, row.flag_value];
                })
            );
            return [flag_codename, valueByStudent];
          })
        )
    ),
    valuesMapByStudent: computed(
      [(s) => s.initialData],
      (initialData: TableRow[]) => {
        const ret = {};
        initialData.forEach(({ student_id, flag_codename, flag_value }) => {
          ret[student_id] = {
            ...(ret[student_id] || {}),
            [`student_flag__${flag_codename}`]: flag_value,
          };
        });
        return ret;
      }
    ),
  };
}
