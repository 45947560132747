import { Button, MenuItem, Position } from "@blueprintjs/core";
import React, { useLayoutEffect, useRef } from "react";
import { Select } from "@blueprintjs/select";
import "styled-components/macro";

const ENTER_RETURN_KEY_CODE = 13;

export type InteractionResultCodeName =
  | "talked"
  | "emailed"
  | "did_not_speak"
  | "left_voicemail";

export interface InteractionResult {
  codeName: InteractionResultCodeName;
  longName: string;
}

export const InteractionResultS = Select.ofType<InteractionResult>();

export const DEFAULT_INTERACTION_RESULTS: InteractionResult[] = [
  {
    codeName: "talked",
    longName: "Talked on the phone",
    type: "phoneInteraction",
  },
  {
    codeName: "emailed",
    longName: "Emailed",
    type: "emailInteraction",
  },
  {
    codeName: "did_not_speak",
    longName: "Did not speak",
    type: "phoneInteraction",
  },
  {
    codeName: "left_voicemail",
    longName: "Left a voicemail",
    type: "phoneInteraction",
  },
];

export function InteractionResultInput({
  selectedItem,
  activeField,
  onSelect,
  setActiveField,
  interactionType = null,
  defaultOptions = DEFAULT_INTERACTION_RESULTS,
}) {
  const interactionResultInputRef = useRef(null);

  const handleFocus = () => {
    interactionResultInputRef.current &&
      interactionResultInputRef.current.focus();
    setActiveField("interactionResult");
  };
  const isItemSelected = (r) => selectedItem === r;
  const onItemSelect = (interactionResult) => {
    if (selectedItem !== interactionResult) {
      onSelect(interactionResult);
    }
  };
  const getFilteredDefaultOptions = (defaultOptions, interactionType) => {
    if (interactionType === "Phone") {
      return defaultOptions.filter((opt) => opt.type === "phoneInteraction");
    } else if (interactionType === "Email") {
      return defaultOptions.filter((opt) => opt.type === "emailInteraction");
    }
    return defaultOptions;
  };
  useLayoutEffect(() => {
    // noinspection JSUnresolvedFunction
    handleFocus();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      css={`
        .bp3-multi-select-popover .bp3-menu {
          //max-width: none !important;
          max-height: 220px !important;
          overflow-y: auto;
        }
      `}
    >
      <InteractionResultS
        fill
        filterable={false}
        inputValueRenderer={(v) => v}
        createNewItemFromQuery={undefined}
        onKeyDown={(ev) => {
          if (ev.keyCode === ENTER_RETURN_KEY_CODE) {
            ev.preventDefault();
          }
        }}
        resetOnSelect={false}
        createNewItemRenderer={null}
        openOnKeyDown={false}
        items={getFilteredDefaultOptions(defaultOptions, interactionType)}
        itemDisabled={isItemSelected}
        itemRenderer={interactionResultItemRenderer(isItemSelected)}
        selectedItem={selectedItem}
        onItemSelect={onItemSelect}
        placeholder=""
        // onFocus={handleFocus}
        popoverProps={{
          usePortal: false,
          minimal: true,
          position: Position.BOTTOM_LEFT,
        }}
        inputProps={{
          onFocus: handleFocus,
          name: "interactionResult",
          autoFocus: true,
          inputRef: (r) => {
            interactionResultInputRef.current = r;
          },
          large: true,
        }}
      >
        <Button text={selectedItem.longName} rightIcon="caret-down" />
      </InteractionResultS>
    </div>
  );
}
const interactionResultItemRenderer =
  (isItemSelected) =>
  (interactionResult: InteractionResult, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const isSelected = isItemSelected(interactionResult);
    return (
      <MenuItem
        active={modifiers.active}
        icon={isSelected ? "tick" : "blank"}
        disabled={false}
        key={interactionResult.codeName}
        text={interactionResult.longName}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    );
  };
