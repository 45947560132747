import { ColTableConfig } from "../../components/table-descriptions";

export const ECP_CUSTOMER_SUCCESS_CGN_STATS_COLTABLE_CONFIGS: ColTableConfig[] =
  [
    {
      field: "seq_name",
      label: "Sequence",
      type: "text",
      toltip: "The campaign sequence(s) informing the row's statistics",
      fixed: "left",
      width: 95,
    },
    {
      field: "student_group_name",
      label: "Student Group",
      type: "text",
      tooltip:
        "Target: Students who received campaign emails. Control: Students who purposefully had their campaign emails held back as an experimental control.",
      fixed: "left",
      width: 95,
    },
    {
      field: "first_email_sent_at",
      label: "Start",
      type: "date",
      width: 95,
      tooltip: "The date the first wave of emails was sent",
      __renderNansAsEmpty: true,
    },
    {
      field: "last_email_sent_at",
      label: "Latest",
      type: "date",
      width: 95,
      tooltip: "The date the latest wave of emails was sent",
      __renderNansAsEmpty: true,
    },
    {
      field: "n_stus",
      label: "# Students",
      type: "integer",
      width: 95,
      tooltip: "The number of distinct students in the group",
    },
    {
      field: "pct_opened_by_TO",
      label: "% Opened",
      type: "percentage",
      width: 95,
      tooltip:
        "The percentage of campaign emails that have been opened by their intended recipient (parent)",
      __renderNansAsEmpty: true,
    },
    {
      field: "n_new_entities",
      label: "Incremental Orders",
      type: "integer",
      width: 95,
      tooltip:
        "The number of incremental orders in the group after the campaign started",
    },
    {
      field: "total_new_revenue",
      label: "Incremental Revenue",
      type: "money",
      width: 110,
      tooltip: "The total revenue from the incremental orders in the group",
    },
    {
      field: "avg_new_revenue",
      label: "Avg. Inc. Rev. per Student",
      type: "money",
      width: 110,
      tooltip:
        "The average amount of incremental revenue per student in the group",
      __renderNansAsEmpty: true,
    },
  ];

export const ECP_CUSTOMER_SUCCESS_CGN_STATS_EXPANDABLE_COLTABLE_CONFIGS: ColTableConfig[] =
  [
    {
      label: "Wave",
      field: "wave_number",
      type: "integer",
      fixed: "left",
      width: 95,
    },
    {
      field: "student_group_name",
      label: "Student Group",
      type: "text",
      fixed: "left",
      width: 95,
    },
    {
      label: "Start",
      field: "first_email_sent_at",
      type: "date",
      width: 95,
      __renderNansAsEmpty: true,
    },
    {
      label: "Latest",
      field: "last_email_sent_at",
      type: "date",
      width: 95,
      __renderNansAsEmpty: true,
    },
    {
      label: "# Students",
      field: "n_stus",
      type: "integer",
      width: 95,
    },
    {
      label: "% Opened",
      field: "pct_opened_by_TO",
      type: "percentage",
      width: 95,
      __renderNansAsEmpty: true,
    },
  ];
