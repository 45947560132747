import React from "react";
import { Card, Elevation, H5 } from "@blueprintjs/core";
import {
  AttributeUnitConfig,
  AttributeItem,
  CampaignConfiguration,
  CampaignStatus,
  CampaignType,
  CampaignSelectorOption,
} from "./types";
import { Unit } from "../../components/units/unit";
import { CampaignSendingSchedule } from "./campaign-sending-schedule";
import {
  formatCampaignStatus,
  formatCampaignType,
  getFormattedAttributeValue,
} from "./value-formatting";
import styled from "styled-components/macro";

export function CampaignAttributes({
  data,
  loading,
  selectedCampaign,
}: {
  data: CampaignConfiguration[];
  loading: boolean;
  selectedCampaign: CampaignSelectorOption;
}): JSX.Element {
  return (
    <Unit unitId={selectedCampaign} loading={loading}>
      <CampaignAttributesContent
        data={data}
        selectedCampaign={selectedCampaign}
      />
    </Unit>
  );
}

function CampaignAttributesContent({
  data,
  selectedCampaign,
  displayCampaignSchedule = false,
}: {
  data: CampaignConfiguration[];
  selectedCampaign: CampaignSelectorOption;
  displayCampaignSchedule?: boolean;
}): JSX.Element {
  const campaignConfiguration: CampaignConfiguration = data.find(
    (configuration) =>
      configuration.campaign_codename === selectedCampaign.campaign_codename
  );

  const attributeItems = campaignConfiguration
    ? campaignAttributesItems(campaignConfiguration)
    : [];

  const hasSendingSchedule = !!campaignConfiguration?.sending_schedule ?? false;

  return (
    <StyledCard elevation={Elevation.TWO}>
      <StyledH5>{"Attributes"}</StyledH5>
      <CampaignAttributesContainer>
        {attributeItems.map((item) => (
          <BasicUnitItemContainer
            key={item.headerName}
            className={"basic-unit-item"}
          >
            <ItemHeaderName className={"bp3-text-muted"}>
              {item.headerName}:
            </ItemHeaderName>
            <span>{item.formattedValue}</span>
          </BasicUnitItemContainer>
        ))}
      </CampaignAttributesContainer>
      {hasSendingSchedule && displayCampaignSchedule ? (
        <CampaignSendingSchedule
          campaign_configuration={campaignConfiguration}
        />
      ) : (
        <></>
      )}
    </StyledCard>
  );
}

function campaignAttributesItems(
  campaignConfiguration: CampaignConfiguration
): AttributeItem[] {
  const attributeUnitConfigs: AttributeUnitConfig[] = [
    {
      headerName: "Campaign Name",
      field: "display_name",
    },
    {
      headerName: "Campaign Codename",
      field: "campaign_codename",
    },
    {
      headerName: "Campaign Type",
      field: "campaign_type",
      valueFormatter: (value: CampaignType) => formatCampaignType(value),
    },
    {
      headerName: "Campaign Status",
      field: "campaign_status",
      valueFormatter: (value: CampaignStatus) => formatCampaignStatus(value),
    },
    {
      headerName: "Number of email waves in campaign",
      field: "wave_numbers_by_email_type_code",
      valueFormatter: (value: {}) => Object.keys(value).length.toString(),
    },
  ];

  return attributeUnitConfigs.map(
    (attributeUnitConfig: AttributeUnitConfig) => ({
      headerName: attributeUnitConfig.headerName,
      formattedValue: getFormattedAttributeValue(
        campaignConfiguration,
        attributeUnitConfig
      ),
    })
  );
}

const StyledH5 = styled(H5)`
  margin: 0 0 40px 0 !important;
  padding: 0 !important;
`;

const CampaignAttributesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 20px;

  & .basic-unit-item {
    width: 450px;
  }
`;

const ItemHeaderName = styled.div`
  display: inline-block !important;
  margin-right: 4px;
  margin-top: 0;
  margin-bottom: 0 !important;
`;

const BasicUnitItemContainer = styled.div`
  display: flex;
`;

const StyledCard = styled(Card)`
  height: 100%;
  padding: 20px !important;
`;
