import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import React from "react";
import { MyCheckbox, MyCheckboxGroup } from "./styled-checkbox-group";
import _ from "lodash";
import { Card, Classes, Elevation, Icon as BP3Icon } from "@blueprintjs/core";
import * as COLORS from "../../styles/colors";

const TakeAction = () => (
  <BP3Icon
    css={`
      font-size: 1em;
      color: #49aeef;
    `}
    icon="take-action"
    theme="filled"
  />
);

const Star = () => (
  <BP3Icon
    css={`
      font-size: 1em;
      color: ${COLORS.mustard};
    `}
    icon="star"
    theme="filled"
  />
);

const PhoneCall = () => (
  <BP3Icon
    css={`
      font-size: 1em;
      color: #49aeef;
    `}
    icon="phone"
    theme="filled"
  />
);

const Email = () => (
  <BP3Icon
    css={`
      font-size: 1em;
      color: #49aeef;
    `}
    icon="envelope"
    theme="filled"
  />
);

const Chat = () => (
  <BP3Icon
    css={`
      font-size: 1em;
      color: #49aeef;
    `}
    icon="chat"
    theme="filled"
  />
);

function accountForBlankStarColumnHeaderName(name, field) {
  name = _.trim(name);
  if (name !== "") {
    return <span className={Classes.UI_TEXT}>{name}</span>;
  } else {
    if (field === "starred") {
      return Star();
    } else if (field === "action") {
      return TakeAction();
    } else if (field === "hasReportedCustomerInteraction") {
      return Chat;
    } else if (
      [
        "formatted_contact_phone_number",
        "Guardian1Phone",
        "ns_customer_phone",
      ].includes(field)
    ) {
      return PhoneCall();
    } else if (
      [
        "formatted_contact_email_address",
        "Guardian1Email",
        "email_action",
      ].includes(field)
    ) {
      return Email();
    } else {
      return <span className={Classes.UI_TEXT}>{field}</span>;
    }
  }
}

function VisibilityModal({ columns, showColumn, hideColumn }) {
  const shownColIds = columns
    .filter((col) => col.visible)
    .map((col) => col.colId);
  const onChange = (checkedList) => {
    columns.forEach((col) => {
      const toBeChecked =
        checkedList === null || checkedList.includes(col.colId);
      if (col.visible && !toBeChecked) {
        hideColumn(col.colId);
      } else if (!col.visible && toBeChecked) {
        showColumn(col.colId);
      }
    });
  };

  const checkboxChildren = columns.map((col) => (
    <MyCheckbox
      key={col.colId}
      value={col.colId}
      title={accountForBlankStarColumnHeaderName(
        col.userProvidedColDef.headerName,
        col.userProvidedColDef.field
      )}
    />
  ));

  return (
    <VisibilityModalInner
      shownColIds={shownColIds}
      checkboxChildren={checkboxChildren}
      onChange={onChange}
    />
  );
}

function VisibilityModalInner({ shownColIds, checkboxChildren, onChange }) {
  return (
    <Card
      elevation={Elevation.FOUR}
      css={`
        border-radius: 4px !important;
        max-height: 100%;
        overflow: auto;
        & .ant-checkbox-group {
          background: unset !important;
          color: white !important;
        }
        & .ant-checkbox-wrapper {
          color: white !important;
        }
      `}
    >
      <h5 className={Classes.HEADING}>Show/Hide Columns</h5>
      <MyCheckboxGroup
        value={shownColIds}
        onChange={onChange}
        maxheight={"none"}
      >
        {checkboxChildren}
      </MyCheckboxGroup>
    </Card>
  );
}

export { VisibilityModal };
