import {
  makeAuthorizedPostRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import { AxiosResponse } from "axios";

export type ActionCode =
  | "create_new_user"
  | "ree_customer_interaction"
  | "ecp_customer_interaction"
  | "lead_customer_interaction"
  | "update_student_flag"
  | "update_student_attr"
  | "update_teacher_attr";

export interface ActionPayload {
  [key: string]: any;
}

export interface DataSnapshot {
  [key: string]: any;
}

export interface UserAction<CodeT = ActionCode, PayloadT = ActionPayload> {
  id: number;
  flask_request_id: number;
  submitted_by: string;
  submitted_at: string;
  action_code: CodeT;
  action_payload: PayloadT;
}

const SubmitUserActionPromise = Promise;

export type SubmitUserActionPromise<
  CodeT = ActionCode,
  PayloadT = ActionPayload
> = Promise<AxiosResponse<UserAction<CodeT, PayloadT>>>;

export async function submitUserActionPostRequest<
  CodeT = ActionCode,
  PayloadT = ActionPayload
>(
  action_code: CodeT,
  action_payload: PayloadT
): SubmitUserActionPromise<CodeT, PayloadT> {
  return await makeAuthorizedPostRequestToBackend({
    url: makeUrl("user_actions"),
    data: { action_code, action_payload },
    axiosConfig: undefined,
  });
}
