import { getNaiveDate } from "./getNaiveDate";

const DAY_OF_WEEK_MONDAY = 1;
const DAY_OF_WEEK_FRIDAY = 5;

// This function checks if a given date string in the format "YYYY-MM-DDTHH:mm:ssZ"
// is a current or future weekday.
export function isCurrentOrFutureWeekday(date?: string): boolean {
  if (!date) {
    return false;
  }

  const naiveDate = getNaiveDate(date);
  const currentNaiveDate = new Date();
  currentNaiveDate.setHours(0, 0, 0, 0);

  const isFutureOrToday = naiveDate >= currentNaiveDate;
  const dayOfWeek = naiveDate.getDay();
  const isWeekday =
    dayOfWeek >= DAY_OF_WEEK_MONDAY && dayOfWeek <= DAY_OF_WEEK_FRIDAY;

  return isFutureOrToday && isWeekday;
}
