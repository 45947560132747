import React from "react";
import { useStoreState } from "../../hooks/ep";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { ReportActionModalWrapper } from "../../components/reporting-actions/modal-or-drawer";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import AgTable from "../../components/AgTable";
import { getCountColDef } from "../helpers/relation-agg-cr";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import { useMyRole } from "../helpers/authorization";
import { getStudentTagsInfo, getTagsColumnWidth } from "./tags";
import { useStudentFlag_DoNotEmail_ColDef } from "./student-coldefs/student-flag-do-not-email";
import { useStudentAttr_LoyaltyRenrCampaignType_ColDef } from "./student-coldefs/student-attr-loyalty-renr-campaign-type";
import { useStudentFlag_ExcludeFromNextCourseRecosCgn_ColDef } from "./student-coldefs/student-flag-exclude-from-next-course-recos-cgn";
import { useStudentFlag_NoActionRequired_ColDef } from "./student-coldefs/student-flag-no-action-required";
import { useStudentFlag_DoNotSendMiaEmails_ColDef } from "./student-coldefs/student-flag-do-not-send-mia-emails";
import { CAN_READ_FINANCIALS__ROLES } from "../helpers/authorization";

export function EcpStudentTable({ fullScreenEnabled = true, ...restProps }) {
  const loading = _.max([
    useEnsureData("ecp_student"),
    useEnsureData("ecp_enrollment"),
    useEnsureData("stars"),
    useEnsureData("student_flag"),
    useEnsureData("student_attr"),
    useEnsureData("ecp_customerInteraction"),
  ]);
  return loading ? (
    <Spinner />
  ) : (
    <EcpStudentTableInner
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

// const DEFAULT_SORT = {
//   pct_behind: "desc",
// };

function EcpStudentTableInner({
  prependedColDefs = null,
  whitelistedTagIds = null,
  actionColDefs = null,
  whitelistedColIds = null,
  blacklistedColIds = null,
  defaultSort = null,
  tableName = null,
  tableNameSuffix = null,
  ...restProps
}) {
  let rowData = useStoreState(
    (s) => s.ecp_student.rowDataWithStudentFlagFieldsAndStudentAttrFields
  );
  rowData = rowData.map((row) => {
    const studentFullNameCommaSeparated = `${row["LastName"]}, ${row["FirstName"]}`;
    return { ...row, studentFullNameCommaSeparated };
  });
  // const rowData = useStoreState((s) =>
  //   addTagsField(s.ecp_student.rowDataWithStudentFlagFieldsAndStudentAttrFields, whitelistedTagIds)
  // );

  const colDefs = useColumnDefs(
    prependedColDefs,
    whitelistedTagIds,
    actionColDefs,
    whitelistedColIds,
    defaultSort,
    blacklistedColIds
  );

  return (
    <>
      <AgTable
        rowData={rowData}
        columnDefs={colDefs}
        tableName={tableName || "ecp_student"}
        tableNameSuffix={tableNameSuffix}
        {...restProps}
      />
      <ReportActionModalWrapper />
    </>
  );
}

export function getStudentGeniusUrls(args): string[] {
  let stuIxs =
    args?.data?.student_indexes ?? args?.student_indexes ?? args?.value ?? args;
  stuIxs = [...(stuIxs || [])];
  return stuIxs.map(formatStudentGeniusUrl);
}

export function getStudentGeniusUrl(args): string {
  const stuIx =
    args?.data?.StudentIndex ??
    args?.data?.student_index ??
    args?.StudentIndex ??
    args?.student_index ??
    args?.value ??
    args;
  return formatStudentGeniusUrl(stuIx);
}

export function getOrderGeniusUrl(args): string {
  const stuIx =
    args?.data?.OrderIndex ??
    args?.data?.order_index ??
    args?.OrderIndex ??
    args?.order_index ??
    args?.value ??
    args;
  return formatOrderGeniusUrl(stuIx);
}

export function formatStudentGeniusUrl(stuIx: number): string {
  return `https://laurelsprings.geniussis.com/StudentMain.aspx?id=${stuIx}`;
}

export function formatOrderGeniusUrl(orderIndex: number): string {
  return `https://laurelsprings.geniussis.com/StudentEditOrder.aspx?id=${orderIndex}`;
}

export function getTeacherGeniusUrl(args) {
  const teacherIx =
    args?.data?.TeacherIndex ??
    args?.data?.teacher_index ??
    args?.TeacherIndex ??
    args?.teacher_index ??
    args?.value ??
    args;
  return `https://laurelsprings.geniussis.com/TeacherMain.aspx?id=${teacherIx}`;
}

export function getStudentBuzzUrl(args) {
  const LMSID =
    args?.data?.student_lmsid ??
    args?.student_lmsid ??
    args?.data?.LMSID ??
    args?.LMSID ??
    args?.value ??
    args;
  return `https://lss.agilixbuzz.com/admin/2190401/users/${LMSID}/performance/all`;
}

export function getReportedCustomerInteractionCheckmarkCellClass({ data }) {
  if (data.wasCustomerInteractionToday) {
    return "";
  } else if (data.hasReportedCustomerInteraction) {
    return "bp3-text-muted";
  } else {
    return "";
  }
}
export const GAVEL_ACTION_COL_DEF = {
  headerName: "",
  field: "action",
  cellClass: "react-rendered-cell",
  cellRenderer: "actionButtonCellRenderer",
  width: 48,
  pinned: "right",
  cellRendererParams: {
    crp: {
      includedMenuItems: ["reportEcpCustomerInteractionMenuItem"],
    },
  },
};

export const PHONE_ACTION_COL_DEF = {
  headerName: "",
  field: "Guardian1Phone",
  cellClass: "react-rendered-cell",
  cellRenderer: "phoneActionButtonCellRenderer",
  type: "textColumn",
  width: 52, // 176
  pinned: "right",
  cellRendererParams: {
    crp: {
      phoneField: "Guardian1Phone",
      tooltipText: "Call Guardian1",
    },
  },
};

const DEFAULT_ACTION_COL_DEFS = [];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OLD_ACTION_COL_DEFS = [
  {
    headerName: "",
    field: "Guardian1Email",
    cellClass: "react-rendered-cell",
    cellRenderer: "studentEmailActionButtonCellRenderer",
    type: "textColumn",
    width: 52, // 176
    pinned: "right",
    cellRendererParams: {
      crp: {
        tooltipText: "Compose email from template",
      },
    },
  },
  PHONE_ACTION_COL_DEF,
  GAVEL_ACTION_COL_DEF,
];

export function useColumnDefs(
  prependedColDefs = null,
  whitelistedTagIds = null,
  actionColDefs = null,
  whitelistedColIds = null,
  defaultSort = null,
  blacklistedColIds = null
): AgColDefs {
  const myRole = useMyRole();

  actionColDefs = actionColDefs || DEFAULT_ACTION_COL_DEFS;

  const studentTagsInfo = getStudentTagsInfo(whitelistedTagIds);
  const tagsColDef = {
    headerName: "Tags",
    headerTooltip:
      "At-Risk and MIA tags consider active enrollments, excluding irregularly-paced courses. All other tags take into account all active enrollments",
    field: "tags",
    type: "tagsColumn",
    // cellRenderer: "tagsCellRenderer",
    pinned: "right",
    width: getTagsColumnWidth(studentTagsInfo),
    filterParams: {
      fp: studentTagsInfo,
    },
    cellRendererParams: {
      crp: studentTagsInfo,
    },
  };

  const studentFlag_noActionRequired_colDef =
    useStudentFlag_NoActionRequired_ColDef();
  const studentFlag_excludeFromNextCourseRecosCgn_colDef =
    useStudentFlag_ExcludeFromNextCourseRecosCgn_ColDef();
  const studentAttr_loyaltyRenrCampaignType_colDef =
    useStudentAttr_LoyaltyRenrCampaignType_ColDef();
  const studentFlag_DoNotEmail_ColDef = useStudentFlag_DoNotEmail_ColDef();
  const studentFlag_DoNotSendMiaEmails_ColDef =
    useStudentFlag_DoNotSendMiaEmails_ColDef();

  let ret = [
    {
      field: "starred",
      headerName: " ",
      type: "categoryColumn",
      cellClass: "react-rendered-cell",
      cellRenderer: "starCellRenderer",
      width: 60,
      pinned: "left",
    },
    {
      field: "hasReportedCustomerInteraction",
      headerName: "",
      pinned: "left",
      hide: true,
      type: "booleanColumn",
      tooltipField: "formattedLatestInteractionTimestamp",
      cellClass: getReportedCustomerInteractionCheckmarkCellClass,
    },
    whitelistedColIds?.includes("student_flag__no_action_required") &&
      studentFlag_noActionRequired_colDef,
    whitelistedColIds?.includes(
      "student_flag__exclude_from_next_course_recos_cgn"
    ) && studentFlag_excludeFromNextCourseRecosCgn_colDef,
    whitelistedColIds?.includes("student_attr__loyalty_renr_campaign_type") &&
      studentAttr_loyaltyRenrCampaignType_colDef,
    {
      headerName: "Student Name",
      field: "studentFullNameCommaSeparated",
      type: "textColumn",
      width: 150,
      suppressSizeToFit: true,
      tooltipField: "studentFullNameCommaSeparated",
    },
    {
      headerName: "SoR",
      field: "SchoolRecord",
      type: "booleanColumn",
    },
    {
      headerName: "NCAA",
      field: "IsNCAA",
      type: "booleanColumn",
    },
    {
      headerName: "Academy",
      field: "AcademyStudent",
      type: "booleanColumn",
    },
    whitelistedColIds?.includes("student_flag__do_not_email") &&
      studentFlag_DoNotEmail_ColDef,
    whitelistedColIds?.includes("student_flag__do_not_send_mia_emails") &&
      studentFlag_DoNotSendMiaEmails_ColDef,
    {
      headerName: "Genius Status",
      field: "Status",
      type: "categoryColumn",
      width: 100,
    },
    getCountColDef({
      baseEntityName: "ecp_student",
      headerName: "Enr",
      field: "enrollmentIds",
      entityName: "ecp_enrollment",
      headerTooltip: "Number of active enrollments",
      filter: "categoryFilterer",
      cellClass: "react-rendered-cell ag-numeric-cell",
      actualField: "num_enrollments",
      type: "integerColumn",
    }),
    {
      headerName: "APP?",
      headerTooltip: "Is the student on an Academic Performance Plan (APP)?",
      field: "AcademicPerformancePlan",
      type: "booleanColumn",
      width: 80,
    },
    whitelistedColIds?.includes("latest_geniusComm_sent_at") && {
      field: "latest_geniusComm_sent_at",
      headerName: "Latest Genius Communication Sent At",
      type: "timestampColumn",
      sortingOrder: ["asc", "desc", null],
      // sort: "asc",
      width: 240,
    },
    whitelistedColIds?.includes("AcademyPotential") && {
      field: "AcademyPotential",
      headerName: "Academy Potential",
      type: "booleanColumn",
      width: 110,
    },
    // {
    //   field: "hasReportedCustomerInteraction",
    //   headerName: "",
    //   type: "booleanColumn",
    // },
    {
      headerName: "Genius",
      field: "StudentIndex",
      type: "linkedTextColumn",
      // __linkField: "student_genius_url",
      __linkValueGetter: getStudentGeniusUrl,
      __linkIsNotAnEmail: true,
      pinned: "left",
    },
    {
      headerName: "Buzz",
      field: "LMSID",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentBuzzUrl,
      __linkIsNotAnEmail: true,
      pinned: "left",
      // hide: true,
    },
    {
      headerName: "Risk",
      field: "churn_risk_score",
      type: "floatColumn",
      cellRenderer: "churnScoreCellRenderer",
      cellClass: "react-rendered-cell",
      headerTooltip:
        "Avg failure/extension risk across active enrollments, excluding irregularly-paced courses",
      width: 200,
    },
    {
      headerName: "At-Risk Enr",
      field: "n_tags__at_risk",
      type: "integerColumn",
      filter: "categoryFilterer",
      cellClass: "ag-numeric-cell",
      headerTooltip:
        "# of active enrollments, excluding irregularly-paced courses, with >70% failure/extension risk",
      width: 100,
    },
    tagsColDef,
    {
      headerName: "Grade Level",
      field: "GradeLevel",
      type: "integerColumn",
      filter: "categoryFilterer",
      cellClass: "ag-numeric-cell",
      width: 87,
    },
    {
      headerName: "Latest Activity",
      field: "buzz_last_activity_date",
      type: "dateColumn",
      headerTooltip: "Latest Activity date across active enrollments",
      width: 125,
    },
    {
      headerName: "Latest Submission",
      field: "MAX(latest_buzz_submission_timestamp)",
      type: "dateColumn",
      headerTooltip:
        "Latest Submission date across active enrollments, excluding irregularly-paced courses",
      width: 125,
    },
    {
      headerName: "Max Days MIA",
      field: "max__n_active_days_without_submissions__regularly_paced_courses",
      headerTooltip:
        "Maximum # days since latest Buzz submission across active enrollments, excluding irregularly-paced courses",
      type: "integerColumn",
      width: 82,
      valueGetter: (params): number =>
        Math.floor(
          params.data
            .max__n_active_days_without_submissions__regularly_paced_courses
        ),
    },
    {
      headerName: "First Became MIA",
      field: "date_first_became_mia",
      headerTooltip:
        "Date when the student started to be considered MIA. The date is calculated subtracting the # of days since latest Buzz submission across active enrollments with the 'MIA' tag from the current date for students without submissions, including the 7 (summer enrollments) or 14 days grace period for becoming MIA.",
      type: "naiveDateColumn",
      width: 130,
    },
    {
      headerName: "Avg Grade",
      field: "curr_avg_grade",
      type: "percentageColumn",
      headerTooltip: "Avg Grade across active enrollments",
      width: 97,
    },
    {
      headerName: "Avg % Completion",
      field: "avg_percent_complete_among_active_enrollments",
      type: "percentageColumn",
      headerTooltip:
        "Average percent of completed assignments over the student's active courses.",
      width: 140,
    },
    {
      headerName: "Avg % Time Elapsed",
      field: "avg_percent_of_time_elapsed_among_active_enrollments",
      type: "percentageColumn",
      headerTooltip:
        "Average percent of time elapsed over the student's active courses",
      width: 140,
    },
    {
      headerName: "First Start Date",
      field: "min_enrollment_start_date",
      type: "dateColumn",
      headerTooltip: "The earliest start date among the student's enrollments",
      width: 122,
    },
    {
      headerName: "Last End Date",
      field: "max_enrollment_end_date",
      type: "dateColumn",
      headerTooltip: "The latest end date among the student's enrollments",
      width: 122,
    },
    {
      headerName: "Has Future Enrollment",
      field: "has_future_enrollment",
      type: "booleanColumn",
      headerTooltip:
        "Indicates if the student has any enrollments in the future",
      width: 140,
    },
    {
      headerName: "FC 100% Completion Date",
      field: "forecasted_100p_completion_date",
      type: "dateColumn",
      width: 122,
      headerTooltip:
        "The latest forecasted 100% completion date among the student's active enrollments (based on current assignment completion pace for each enrollment)",
    },
    {
      headerName: "% Behind",
      field: "pct_behind",
      type: "percentageColumn",
      headerTooltip: "Avg % Behind across active enrollments",
      width: 106,
    },
    // {
    //   headerName: "Slow Starts",
    //   field: "n_slow_start",
    //   type: "integerColumn",
    //   width: 87,
    // },
    // {
    //   headerName: "Extension Candidates",
    //   field: "n_extension_candidate",
    //   type: "integerColumn",
    //   width: 125,
    // },
    {
      headerName: "Tenure [years]",
      field: "n_schoolyears_enrolled",
      type: "floatColumn",
      headerTooltip: "# of schoolyears enrolled with LSS",
      width: 100,
    },
    {
      headerName: "Retention Notes",
      field: "RetentionNotes",
      type: "textColumn",
      width: 300,
      suppressSizeToFit: true,
      tooltipField: "RetentionNotes",
      cellClass: ["ellipses-tooltip-cell"],
    },
    {
      headerName: "Admissions Coordinator",
      field: "AdmissionsCoordinator",
      type: "categoryColumn",
    },
    {
      headerName: "Academic Advisor",
      field: "AcademicAdvisor",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "AcademicAdvisor",
    },
    {
      headerName: "Counselor",
      field: "Counselor",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "Counselor",
    },
    {
      headerName: "Student Services",
      field: "StudentServices",
      type: "categoryColumn",
    },
    {
      headerName: "Student Phone",
      field: "Phone",
      type: "textColumn",
      tooltipField: "Phone",
    },
    {
      headerName: "Student Email",
      field: "Email",
      type: "textColumn",
      tooltipField: "Email",
    },
    {
      headerName: "Guardian1 Phone",
      field: "Guardian1Phone",
      type: "textColumn",
      tooltipField: "Guardian1Phone",
    },
    {
      headerName: "Guardian1 Email",
      field: "Guardian1Email",
      type: "textColumn",
      tooltipField: "Guardian1Email",
    },
    ...actionColDefs,

    ...DEFAULT_HIDDEN_COLDEFS,
  ] as AgColDef[];

  ret = [...(prependedColDefs ?? []), ...ret];

  ret = ret
    .filter((cd) => !!cd)
    .filter((cd) => {
      // @ts-ignore
      return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
    });
  if (blacklistedColIds) {
    ret = ret.filter(
      (cd) => !blacklistedColIds.includes(cd.colId ?? cd.field ?? cd.headerName)
    );
  }
  if (defaultSort) {
    ret = ret.map((cd) => ({
      ...cd,
      sort: defaultSort[cd.colId || cd.field || cd.headerName],
    }));
  }
  return ret;
}

const DEFAULT_HIDDEN_COLDEFS = [
  {
    headerName: "Historical Order Value",
    field: "life_time_value",
    type: "moneyColumn",
    hide: true,
    __authorized_roles: CAN_READ_FINANCIALS__ROLES,
  },
  {
    headerName: "Value at Risk",
    field: "value_at_risk",
    type: "moneyColumn",
    headerTooltip: "(# SY to Grad) * (LTV per SY)",
    hide: true,
    __authorized_roles: CAN_READ_FINANCIALS__ROLES,
  },
  {
    headerName: "Historical Order Value per SY",
    field: "ltv_per_sy_enrolled",
    type: "moneyColumn",
    hide: true,
    __authorized_roles: CAN_READ_FINANCIALS__ROLES,
  },
  {
    headerName: "Date of Birth",
    field: "DOB",
    type: "dateColumn",
    hide: true,
  },
  {
    headerName: "Max Score",
    field: "y: MAX(y_score)",
    type: "floatColumn",
    headerTooltip:
      "Max Score across active enrollments, excluding irregularly-paced courses",
    hide: true,
  },
  {
    headerName: "Mean Score",
    field: "y: MEAN(y_score)",
    type: "floatColumn",
    headerTooltip:
      "Mean Score across active enrollments, excluding irregularly-paced courses",
    hide: true,
  },
  {
    headerName: "Median Score",
    field: "y: MEDIAN(y_score)",
    type: "floatColumn",
    headerTooltip:
      "Median Score across active enrollments, excluding irregularly-paced courses",
    hide: true,
  },
  {
    headerName: "StdDev of Scores",
    field: "y: STD(y_score)",
    type: "floatColumn",
    headerTooltip:
      "StdDev of Scores across active enrollments, excluding irregularly-paced courses",
    hide: true,
  },
  // {
  //   headerName: "Avg Behind Pace %",
  //   type: "percentageColumn",
  //   valueGetter: (params) => {
  //     const pacingData = JSON.parse(params.data["sfv_pacing"]);
  //     return pacingData ? -getAvgPctAhead(pacingData) : null;
  //   },
  //   hide: true,
  //   // sort: "desc",
  // },
  // {
  //   headerName: "# Predicted Successes",
  //   field: "COUNT_FALSE(y_pred)",
  //   type: "integerColumn",
  // },
  // {
  //   headerName: "All Cutoffs Are Recent",
  //   field: "all_cutoffs_are_recent",
  //   type: "booleanColumn",
  // },
  // {
  //   headerName: "MAX(% Complete) / MIN(Cutoff %)",
  //   field: "max_pct_complete__div__min_cutoff_pct_int",
  //   type: "floatColumn",
  //   hide: true,
  // },
  // {
  //   headerName: "Buzz ID",
  //   field: "student_lmsid",
  //   type: "integerIdentifierColumn",
  // },
];
