import React, { useEffect, useState } from "react";
import { useStoreState } from "./hooks/ep";
import { TableRow } from "./store/table-model-factory";
import "styled-components/macro";
import AgTable from "./components/AgTable/ag_table";
import { MyBoolean, useBoolean } from "./helpers/useBoolean";
import { Table } from "apache-arrow/table";
import { duckdb_db } from "./duckdb-wasm-instantiation";

async function doOtherStuff(
  table,
  fetched: MyBoolean,
  text: string,
  setText: (value: ((prevState: string) => string) | string) => void
) {
  const db = duckdb_db;
  const c = await db.connect();

  // Data can be inserted from an existing arrow.Table
  await c.insertArrowTable(table, { name: "austos_table" });

  // // ..., from Arrow vectors
  // await c.insertArrowVectors(
  //   {
  //     col1: arrow.Int32Vector.from([1, 2]),
  //     col2: arrow.Utf8Vector.from(["foo", "bar"]),
  //   },
  //   {
  //     name: "arrow_vectors",
  //   }
  // );
  //
  // // ..., from a raw Arrow IPC stream
  // const streamResponse = await fetch(`someapi`);
  // const streamReader = streamResponse.body.getReader();
  // const streamInserts = [];
  // while (true) {
  //   const { value, done } = await streamReader.read();
  //   if (done) break;
  //   streamInserts.push(c.insertArrowFromIPCStream(value, { name: "streamed" }));
  // }
  // await Promise.all(streamInserts);
  //
  // // ..., from CSV files
  // // (interchangeable: registerFile{Text,Buffer,URL,Handle})
  // await db.registerFileText(`data.csv`, "1|foo\n2|bar\n");
  //
  // // ... with typed insert options
  // await db.insertCSVFromPath("data.csv", {
  //   schema: "main",
  //   name: "foo",
  //   detect: false,
  //   header: false,
  //   delimiter: "|",
  //   columns: {
  //     col1: new arrow.Int32(),
  //     col2: new arrow.Utf8(),
  //   },
  // });
  //
  // // ..., from JSON documents in row-major format
  // await db.registerFileText(
  //   "rows.json",
  //   `[
  //     { "col1": 1, "col2": "foo" },
  //     { "col1": 2, "col2": "bar" },
  // ]`
  // );
  //
  // // ... or column-major format
  // await db.registerFileText(
  //   "columns.json",
  //   `{
  //     "col1": [1, 2],
  //     "col2": ["foo", "bar"]
  // }`
  // );
  //
  // // ... with typed insert options
  // await c.insertJSONFromPath("rows.json", { name: "rows" });
  // await c.insertJSONFromPath("columns.json", { name: "columns" });
  //
  // // ..., from Parquet files
  // const pickedFile: File = letUserPickFile();
  // await db.registerFileHandle("local.parquet", pickedFile);
  // await db.registerFileURL("remote.parquet", "https://origin/remote.parquet");
  // const res = await fetch("https://origin/remote.parquet");
  // await db.registerFileBuffer("buffer.parquet", await res.arrayBuffer());
  //
  // // ..., by specifying URLs in the SQL text
  // await c.query(`
  //     CREATE TABLE direct AS
  //         SELECT * FROM "https://origin/remote.parquet"
  // `);
  //
  // // ..., or by executing raw insert statements
  // await c.query(`INSERT INTO existing_table
  //   VALUES (1, "foo"), (2, "bar")`);

  const nRows: Table = await c.query(
    "SELECT COUNT(*) AS cnt FROM austos_table"
  );

  // Close the connection to release memory
  await c.close();

  setTimeout(() => {
    setText(nRows.get(0).toString());
    console.log(`nRows.get(0).cnt=${nRows.get(0).cnt}`);
  }, 5000);
}

export function DevPage() {
  const fetched = useBoolean(false);
  const [text, setText] = useState("Loading...");
  useEffect(() => {
    if (!fetched.value) {
      Table.from(
        fetch(
          "https://gist.githubusercontent.com/TheNeuralBit/64d8cc13050c9b5743281dcf66059de5/raw/c146baf28a8e78cfe982c6ab5015207c4cbd84e3/scrabble.arrow"
        )
      ).then(async (table) => {
        await doOtherStuff(table, fetched, text, setText);
        const newText = `Loaded scrabble.arrow! It has ${
          table.length
        } rows and the following columns: ${table.schema.fields
          .map((f) => f.name)
          .join(",")}`;
        setText(newText);
      });
      fetched.setTrue();
    }
  }, [fetched, text, setText]);
  return <p>{text}</p>;
}

export type CustomerId = number;
export type DepartmentName = string;

function DevPageInner() {
  const [selectedCustomerId, setSelectedCustomerId] = useState<CustomerId>(0);
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      `}
    >
      <CustomerIdSelect
        selectedCustomerId={selectedCustomerId}
        setSelectedCustomerId={setSelectedCustomerId}
      />
      {(selectedCustomerId || "") && (
        <CustomerOrdersTable customerId={selectedCustomerId} />
      )}
    </div>
  );
}

function CustomerOrdersTable({ customerId, ...restProps }) {
  const rowData = useStoreState((s) => s.ree_order.initialData);
  const colDefs = [
    { headerName: "Created", field: "CreatedOn", type: "timestampColumn" },
    { headerName: "Type", field: "order_type", type: "categoryColumn" },
  ];
  return (
    <AgTable
      rowData={rowData}
      columnDefs={colDefs}
      tableName="customer-orders"
      {...restProps}
    />
  );
}

function CustomerIdSelect({
  selectedCustomerId,
  setSelectedCustomerId,
}): JSX.Element {
  const customers: TableRow[] = useStoreState(
    (s) => s.ree_student.rowData
  ).slice(0, 20);

  useEffect(() => {
    setSelectedCustomerId(customers[0].customer_id);
  }, []);

  return (
    <div
      css={`
        width: fit-content;
        &&& select {
          background-color: transparent;
          border: none;
          color: white;
        }
      `}
    >
      <select
        value={selectedCustomerId}
        onChange={(ev) => {
          ev.preventDefault();
          setSelectedCustomerId(ev.target.value);
        }}
      >
        <option key={"null"} value={0}>
          Select...
        </option>
        {customers.map((row) => (
          <option
            key={row.customer_id}
            value={row.customer_id}
            selected={selectedCustomerId === row.customer_id}
          >
            {`${row.customer_id}: "${row.customer_name}"`}
          </option>
        ))}
      </select>
    </div>
  );
}
