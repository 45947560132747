import React from "react";
import AgTable from "../../components/AgTable";
import "styled-components/macro";
import { Spinner } from "@blueprintjs/core";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { useStoreActions, useStoreState } from "../../hooks/ep";
import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import _ from "lodash";
import { CellValueChangedEvent } from "@ag-grid-community/core";
import { toaster } from "../../toaster";
import {
  useCanDoLevelWrites,
  useMyRole,
  useMyTimezone,
} from "../helpers/authorization";
import { UserRole } from "../../store/model-me";
import { VALID_NEW_USER_ROLES } from "../helpers/writes-auth";
import { CreateNewUserButton } from "../../components/drawer-form/triggers/create-new-user";
import { useCanCreateNewUser } from "../../hooks/use-can-create-new-user";

export function EcpUserTable(props): JSX.Element {
  const loading = [useEnsureData("ecp_user"), useEnsureData("ecp_userMetric")];
  return _.max(loading) ? <Spinner /> : <EcpUserTableInner {...props} />;
}

function EcpUserTableInner({ employeeType, ...restProps }): JSX.Element {
  let rowData = useStoreState((s) => s.ecp_user.rowData);
  rowData = rowData.sort((a, b) => {
    if (
      !a.num_active_days_in_past_30_days &&
      !b.num_active_days_in_past_30_days
    )
      return 99;
    return (
      b.num_active_days_in_past_30_days - a.num_active_days_in_past_30_days
    );
  });
  const columnDefs = useColumnDefs();
  const customRibbonElements = useCustomRibbonElements();
  return (
    <AgTable
      rowData={rowData}
      columnDefs={columnDefs}
      tableName="user"
      customRibbonLeftElements={customRibbonElements}
      {...restProps}
    />
  );
}

function useCustomRibbonElements() {
  const canCreateNewUser = useCanCreateNewUser();
  return canCreateNewUser
    ? [<CreateNewUserButton key="button--create-new-user" />]
    : [];
}
function useColumnDefs(): AgColDefs {
  const myRole = useMyRole();
  const myTz = useMyTimezone();
  let ret = [
    {
      headerName: "Email",
      field: "email",
      type: "textColumn",
      pinned: "left",
    },
    {
      headerName: "Last Visited",
      field: "latest_flask_request_timestamp",
      type: "timestampColumn",
      __timezone: myTz,
      __authorized_roles: [
        UserRole.SAXECAP_DEV,
        UserRole.ADMIN_READ_WRITE,
        UserRole.ADMIN_READ_ONLY,
      ],
    },
    {
      headerName: "# Days Visited (Past Month)",
      field: "num_active_days_in_past_30_days",
      type: "integerColumn",
      __authorized_roles: [
        UserRole.SAXECAP_DEV,
        UserRole.ADMIN_READ_WRITE,
        UserRole.ADMIN_READ_ONLY,
      ],
    },
    useIsActiveColDef(),
    useRoleColDef(),
    useFirstNameColDef(),
    useLastNameColDef(),
    useTimezoneColDef(),
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
  });
}

function useIsActiveColDef(): AgColDef {
  const canDoLevel3Writes = useCanDoLevelWrites(3);
  const handlePatchUser = useStoreActions((a) => a.ecp_user.handlePatchUser);
  let colDef = {
    headerName: "Active",
    field: "is_active",
    type: "booleanColumn",
  };
  if (canDoLevel3Writes) {
    colDef = {
      ...colDef,
      editable: (params) => true,
      cellClass: (params) => {
        return [
          "bp3-minimal",
          "bp3-tag",
          "bp3-interactive",
          // "bp3-round",
          "bp3-small",
        ];
      },
      valueParser: (params) => parseBoolean(params.newValue),
      valueSetter: (params) => {
        const isValid = typeof params.newValue === "boolean";
        if (isValid) {
          params.data.is_active = params.newValue;
          return true;
        } else {
          toaster.error("Please submit the word 'true' or the word 'false'");
          return false;
        }
      },
      onCellValueChanged: async (ev: CellValueChangedEvent) => {
        await handlePatchUser([
          ev.data,
          {
            is_active: ev.newValue,
          },
        ]);
      },
    };
  }
  return colDef;
}

function useFirstNameColDef(): AgColDef {
  const canDoLevel3Writes = useCanDoLevelWrites(3);
  const handlePatchUser = useStoreActions((a) => a.ecp_user.handlePatchUser);
  let colDef = {
    headerName: "First",
    field: "first_name",
    type: "textColumn",
  };
  if (canDoLevel3Writes) {
    colDef = {
      ...colDef,
      editable: (params) => true,
      cellClass: (params) => {
        return [
          "bp3-minimal",
          "bp3-tag",
          "bp3-interactive",
          // "bp3-round",
          "bp3-small",
        ];
      },
      valueParser: (params) => _.trim(params.newValue),
      valueSetter: (params) => {
        if (params.newValue) {
          params.data.first_name = params.newValue;
          return true;
        } else {
          toaster.error("Please submit a name");
          return false;
        }
      },
      onCellValueChanged: async (ev: CellValueChangedEvent) => {
        await handlePatchUser([
          ev.data,
          {
            first_name: ev.newValue,
          },
        ]);
      },
    };
  }
  return colDef;
}

function useLastNameColDef(): AgColDef {
  const canDoLevel3Writes = useCanDoLevelWrites(3);
  const handlePatchUser = useStoreActions((a) => a.ecp_user.handlePatchUser);
  let colDef = {
    headerName: "Last",
    field: "last_name",
    type: "textColumn",
  };
  if (canDoLevel3Writes) {
    colDef = {
      ...colDef,
      editable: (params) => true,
      cellClass: (params) => {
        return [
          "bp3-minimal",
          "bp3-tag",
          "bp3-interactive",
          // "bp3-round",
          "bp3-small",
        ];
      },
      valueParser: (params) => _.trim(params.newValue),
      valueSetter: (params) => {
        if (params.newValue) {
          params.data.last_name = params.newValue;
          return true;
        } else {
          toaster.error("Please submit a name");
          return false;
        }
      },
      onCellValueChanged: async (ev: CellValueChangedEvent) => {
        await handlePatchUser([
          ev.data,
          {
            last_name: ev.newValue,
          },
        ]);
      },
    };
  }
  return colDef;
}

export const VALID_TIMEZONES = [
  "America/Denver",
  "America/Chicago",
  "America/New_York",
  "America/Los_Angeles",
];

function useTimezoneColDef(): AgColDef {
  const canDoLevel3Writes = useCanDoLevelWrites(3);
  const handlePatchUser = useStoreActions((a) => a.ecp_user.handlePatchUser);
  let colDef = {
    headerName: "Timezone",
    field: "timezone",
    type: "categoryColumn",
  };
  if (canDoLevel3Writes) {
    colDef = {
      ...colDef,
      editable: (params) => true,
      cellClass: (params) => {
        return [
          "bp3-minimal",
          "bp3-tag",
          "bp3-interactive",
          // "bp3-round",
          "bp3-small",
        ];
      },
      valueParser: (params) => _.trim(params.newValue),
      valueSetter: (params) => {
        const isValid = VALID_TIMEZONES.includes(params.newValue);

        if (isValid) {
          params.data.timezone = params.newValue;
          return true;
        } else {
          toaster.error(
            "Please choose one of the 4 timezones already assigned to other users"
          );
          return false;
        }
      },
      onCellValueChanged: async (ev: CellValueChangedEvent) => {
        await handlePatchUser([
          ev.data,
          {
            timezone: ev.newValue,
          },
        ]);
      },
    };
  }
  return colDef;
}

function useRoleColDef(): AgColDef {
  const canDoLevel3Writes = useCanDoLevelWrites(3);
  const myRole = useMyRole();
  const handlePatchUser = useStoreActions((a) => a.ecp_user.handlePatchUser);
  let colDef = {
    headerName: "Role",
    field: "role",
    type: "categoryColumn",
  };
  if (canDoLevel3Writes) {
    colDef = {
      ...colDef,
      editable: (params) => true,
      cellClass: (params) => {
        return [
          "bp3-minimal",
          "bp3-tag",
          "bp3-interactive",
          // "bp3-round",
          "bp3-small",
        ];
      },
      valueParser: (params) => _.trim(params.newValue),
      valueSetter: (params) => {
        const validUpdatedUserRoles =
          myRole === UserRole.SAXECAP_DEV
            ? [UserRole.SAXECAP_DEV, ...VALID_NEW_USER_ROLES]
            : VALID_NEW_USER_ROLES;
        const isValid = validUpdatedUserRoles.includes(params.newValue);

        if (isValid) {
          params.data.role = params.newValue;
          return true;
        } else {
          toaster.error(
            `Please enter one of the following: ${validUpdatedUserRoles.join(
              ", "
            )}`
          );
          return false;
        }
      },
      onCellValueChanged: async (ev: CellValueChangedEvent) => {
        await handlePatchUser([
          ev.data,
          {
            role: ev.newValue,
          },
        ]);
      },
    };
  }
  return colDef;
}

export function parseBoolean(value: string): boolean | null {
  value = value && _.trim(value);
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else {
    return null;
  }
}
