import _ from "lodash";
import { useEnsureData } from "../../helpers/use-ensure-data";
import React from "react";
import AgTable from "../../components/AgTable";
import { AgColDef } from "../../components/AgTable/types";
import { getStudentGeniusUrl } from "../../entities/ecp/ecp-student-entity";
import { useStoreState } from "../../hooks/ep";
import { Spinner } from "@blueprintjs/core";

export function AllStudentsNextYearCourseScheduleRecommendationsTab(): JSX.Element {
  const loading = _.max([
    useEnsureData("nextYearCourseScheduleRecommendations"),
  ]);

  return loading ? (
    <Spinner />
  ) : (
    <AllStudentsNextYearCourseScheduleRecommendationsContent />
  );
}

function AllStudentsNextYearCourseScheduleRecommendationsContent(): JSX.Element {
  const rowData = useStoreState(
    (s) => s.nextYearCourseScheduleRecommendations.initialData
  );

  const colDefs = useNextYearCourseScheduleRecommendationsColDefs([
    "StudentIndex",
    "StudentName",
    "CourseName",
  ]);

  return (
    <AgTable
      tableName={"all-students-next-year-course-schedule-recommendations"}
      rowData={rowData}
      columnDefs={colDefs}
      ribbonTitle={"Next Year Course Schedule Recommendations"}
    />
  );
}

export function useNextYearCourseScheduleRecommendationsColDefs(
  orderedLeftPinnedFields: string[]
): AgColDef[] {
  let ret: AgColDef[] = [
    {
      headerName: "Current Course Name",
      field: "CourseName",
      type: "textColumn",
      width: 228,
      suppressSizeToFit: true,
      tooltipField: "CourseName",
    },
    {
      headerName: "Recommended Next Part A Course Name",
      field: "first_next_recommended_course_name",
      type: "textColumn",
      width: 228,
      suppressSizeToFit: true,
      tooltipField: "first_next_recommended_course_name",
    },
    {
      headerName: "Recommended Next Part B Course Name",
      field: "second_next_recommended_course_name",
      type: "textColumn",
      width: 228,
      suppressSizeToFit: true,
      tooltipField: "second_next_recommended_course_name",
    },
    {
      headerName: "Current Melded Course Name",
      headerTooltip:
        "Where 'melded' means 'Part A course name melded with Part B course name'",
      field: "CourseProgramofStudyMeldedDisplayName",
      type: "categoryColumn",
      width: 220,
    },
    {
      headerName: "Recommended Next Melded Course Name",
      headerTooltip:
        "Where 'melded' means 'Part A course name melded with Part B course name'",
      field:
        "first_next_recommended_course_program_of_study_melded_display_name",
      type: "categoryColumn",
      width: 263,
    },
    {
      headerName: "Explanation of Next Course Recommendation",
      headerTooltip:
        "Where 'melded' means 'Part A course name melded with Part B course name'",
      field: "explanation_of_next_course_recos",
      type: "textColumn",
      width: 500,
      suppressSizeToFit: true,
      autoHeight: true,
      cellStyle: { minHeight: "50px" },
    },
    {
      headerName: "Recommended Next Year Course Schedule Start Date",
      headerTooltip:
        "The proposed start date for the next Part A course, calculated as the first weekday that is more than 30 days after the current course end date",
      field: "next_year_recommended_start_date",
      type: "dateColumn",
      width: 180,
    },
    {
      headerName: "Current Course End Date",
      field: "EndDate",
      type: "dateColumn",
      width: 145,
    },
    {
      headerName: "Current Course Start Date",
      field: "StartDate",
      type: "dateColumn",
      width: 145,
    },
    {
      headerName: "Department",
      field: "DepartmentName",
      type: "categoryColumn",
    },
    {
      headerName: "Grade Lvl",
      headerTooltip:
        "Student grade level based on the grade anchoring course (Health & PE course)",
      field: "grade_level_based_on_grade_anchoring_course",
      type: "integerColumn",
      filter: "categoryFilterer",
    },
    {
      headerName: "Current Course Grade",
      field: "effective_grade",
      type: "integer",
      width: 95,
    },
    {
      headerName: "Genius Current Course ID",
      field: "CourseIndex",
      type: "integerIdentifierColumn",
      hide: true,
    },
    {
      headerName: "Current Course Display Name",
      field: "CourseDisplayName",
      type: "categoryColumn",
      width: 220,
    },
    {
      headerName: "Recommended Next Part A Course Display Name",
      field: "first_next_recommended_course_display_name",
      type: "categoryColumn",
      width: 225,
    },
    {
      headerName: "Recommended Next Part B Course Display Name",
      field: "second_next_recommended_course_display_name",
      type: "categoryColumn",
      width: 225,
    },
    {
      headerName: "Recommended Next Part A Course Genius ID",
      field: "first_next_recommended_course_index",
      type: "integerIdentifierColumn",
      width: 203,
    },
    {
      headerName: "Recommended Next Part B Course Genius ID",
      field: "second_next_recommended_course_index",
      type: "integerIdentifierColumn",
      width: 203,
    },
    {
      headerName: "Genius Stu",
      field: "StudentIndex",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentGeniusUrl,
      __linkIsNotAnEmail: true,
      width: 97,
    },
    {
      headerName: "Student",
      field: "StudentName",
      type: "textColumn",
    },
  ];
  if ((orderedLeftPinnedFields || []).length > 0) {
    ret = ret.map((colDef) => {
      if (orderedLeftPinnedFields.includes(colDef.field)) {
        return { ...colDef, pinned: "left" };
      } else {
        return colDef;
      }
    });

    ret = _.sortBy(ret, (colDef) => {
      if (orderedLeftPinnedFields.includes(colDef.field)) {
        return orderedLeftPinnedFields.indexOf(colDef.field);
      } else {
        return 1000;
      }
    });
  }
  return ret;
}
