import React, { useEffect, useState } from "react";
import {
  CampaignConfiguration,
  EmailTypeCode,
  CampaignSelectorOption,
} from "./campaign-overview/types";
import { ToggleVisibilityButton } from "../components/toggle-visibility-button";
import { buildEmailTemplateUnit } from "./campaign-overview/campaign-email-template-unit";
import _ from "lodash";
import styled from "styled-components/macro";

export function CampaignEmailTemplates({
  data,
  selectedCampaign,
  loading,
}: {
  data: CampaignConfiguration[];
  selectedCampaign: CampaignSelectorOption;
  loading: boolean;
}): JSX.Element {
  const campaignConfiguration: CampaignConfiguration = data.find(
    (configuration) =>
      configuration.campaign_codename === selectedCampaign.campaign_codename
  );

  const allowedEmailTypeCodes: EmailTypeCode[] =
    campaignConfiguration?.allowed_email_type_codes ?? [];

  const [emailBodyVisibilityStates, setEmailBodyVisibilityStates] = useState<
    boolean[]
  >(allowedEmailTypeCodes.map(() => true));

  const hideAll = () => {
    setEmailBodyVisibilityStates(allowedEmailTypeCodes.map(() => false));
  };

  const showAll = () => {
    setEmailBodyVisibilityStates(allowedEmailTypeCodes.map(() => true));
  };

  const toggleEmailBodyVisibilityState = (index: number) => {
    setEmailBodyVisibilityStates((previousStates) => {
      const newStates = [...previousStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  useEffect(() => {
    if (!loading && campaignConfiguration) {
      showAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignConfiguration, loading]);

  return (
    <CampaignEmailTemplatesContainer>
      <ToggleVisibilityButton
        contentVisibilityStates={emailBodyVisibilityStates}
        hideAll={hideAll}
        showAll={showAll}
        buttonText={"email bodies"}
        width={220}
      />
      {!!_.size(allowedEmailTypeCodes) ? (
        allowedEmailTypeCodes.map((emailTypeCode, index) =>
          buildEmailTemplateUnit(
            campaignConfiguration,
            emailTypeCode,
            emailBodyVisibilityStates[index],
            () => toggleEmailBodyVisibilityState(index)
          )
        )
      ) : (
        <></>
      )}
    </CampaignEmailTemplatesContainer>
  );
}

const CampaignEmailTemplatesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  row-gap: 20px;
`;
