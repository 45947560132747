import { getCountColDef } from "../../entities/helpers/relation-agg-cr";

export function getCommonMetricsColDefs(is_course_metrics: boolean) {
  const enrollmentScope = is_course_metrics ? "course" : "vendor's courses";
  const aggBaseEntityName = is_course_metrics
    ? "courseAssessmentMetrics"
    : "vendorAssessmentMetrics";
  const consideringActiveCurrentEnrollmentsText = `considering active and current enrollments for the ${enrollmentScope}`;
  const ytdEnrollmentsText = `Number of enrollments for the ${enrollmentScope} with a start date between the first day of the current year and today`;

  return [
    {
      headerName: "# Total Enrollments",
      field: "num_total_enrollments",
      width: 130,
      headerTooltip: `Total number of enrollments for the ${enrollmentScope}, excluding those with 'BLOCKED' and 'HOLD' statuses`,
      type: "integerColumn",
    },
    getCountColDef({
      baseEntityName: aggBaseEntityName,
      headerName: "# Active Enrollments",
      field: "enrollmentIds",
      entityName: "ecp_enrollment",
      width: 145,
      headerTooltip: `Number of active and current enrollments for the ${enrollmentScope}`,
      cellClass: "react-rendered-cell ag-numeric-cell",
      actualField: "num_enrollments",
      type: "integerColumn",
      sort: "desc",
    }),
    {
      headerName: "# YTD Enrollments",
      field: "num_year_to_date_enrollments",
      width: 130,
      headerTooltip: `${ytdEnrollmentsText}, excluding those with 'BLOCKED' and 'HOLD' statuses`,
      type: "integerColumn",
    },
    {
      headerName: "# Future Enrollments",
      field: "num_future_enrollments",
      width: 130,
      headerTooltip: `Number of enrollments for the ${enrollmentScope} with a start date after today, and with 'ACTIVE' or 'FUTURE' statuses`,
      type: "integerColumn",
    },
    {
      headerName: "# Total Dropped Enrollments",
      field: "num_total_dropped_enrollments",
      type: "integerColumn",
      width: 130,
      headerTooltip: `Number of enrollments for the ${enrollmentScope} with 'DROPPED' or 'DROPPED_GRACE' statuses`,
    },
    {
      headerName: "Drop Rate (Total)",
      field: "pct_total_enrollments_drop_rate",
      type: "percentageColumn",
      width: 125,
      headerTooltip:
        "Drop Rate (Total) = (# Total Dropped Enrollments) / (# Total Enrollments)",
    },
    {
      headerName: "# YTD Dropped Enrollments",
      field: "num_year_to_date_dropped_enrollments",
      width: 130,
      type: "integerColumn",
      headerTooltip: `${ytdEnrollmentsText}, and with 'DROPPED' or 'DROPPED_GRACE' statuses`,
    },
    {
      headerName: "Drop Rate (YTD)",
      field: "pct_year_to_date_enrollments_drop_rate",
      width: 125,
      type: "percentageColumn",
      headerTooltip:
        "Drop Rate (YTD) = (# YTD Dropped Enrollments) / (# YTD Enrollments)",
    },
    {
      headerName: "# Teachers",
      field: "num_teachers__ff_enrollments",
      width: 120,
      type: "integerColumn",
      headerTooltip: `Number of teachers ${consideringActiveCurrentEnrollmentsText}`,
    },
    {
      headerName: "# At-Risk Teachers",
      field: "num_at_risk_teachers__ff_enrollments",
      width: 120,
      type: "integerColumn",
      headerTooltip: `Number of at-risk teachers ${consideringActiveCurrentEnrollmentsText}`,
    },
    {
      headerName: "% At-Risk Teachers",
      field: "pct_at_risk_teachers__ff_enrollments",
      width: 120,
      type: "percentageColumn",
      headerTooltip:
        "Pct At-Risk Teachers = (# At-Risk Teachers) / (# Teachers)",
    },
    {
      headerName: "# Students",
      field: "num_students__ff_enrollments",
      width: 120,
      type: "integerColumn",
      headerTooltip: `Number of students ${consideringActiveCurrentEnrollmentsText}`,
    },
    {
      headerName: "# At-Risk Students",
      field: "num_at_risk_students__ff_enrollments",
      width: 120,
      type: "integerColumn",
      headerTooltip: `Number of at-risk students ${consideringActiveCurrentEnrollmentsText}`,
    },
    {
      headerName: "% At-Risk Students",
      field: "pct_at_risk_students__ff_enrollments",
      width: 120,
      type: "percentageColumn",
      headerTooltip:
        "Pct At-Risk Students = (# At-Risk Students) / (# Students)",
    },
    {
      headerName: "# MIA Students",
      field: "num_missing_in_action_students__ff_enrollments",
      width: 120,
      type: "integerColumn",
      headerTooltip: `Number of MIA students ${consideringActiveCurrentEnrollmentsText}`,
    },
    {
      headerName: "% MIA Students",
      field: "pct_missing_in_action_students__ff_enrollments",
      width: 120,
      type: "percentageColumn",
      headerTooltip: "Pct MIA Students = (# MIA Students) / (# Students)",
    },
    {
      headerName: "# Slow Start Students",
      field: "num_slow_start_students__ff_enrollments",
      width: 120,
      type: "integerColumn",
      headerTooltip: `Number of slow start students ${consideringActiveCurrentEnrollmentsText}`,
    },
    {
      headerName: "% Slow Start Students",
      field: "pct_slow_start_students__ff_enrollments",
      width: 120,
      type: "percentageColumn",
      headerTooltip:
        "Pct Slow Start Students = (# Slow Start Students) / (# Students)",
    },
    {
      headerName: "# Behind Pace Students",
      field: "num_behind_pace_students__ff_enrollments",
      width: 120,
      type: "integerColumn",
      headerTooltip: `Number of behind pace students ${consideringActiveCurrentEnrollmentsText}`,
    },
    {
      headerName: "% Behind Pace Students",
      field: "pct_behind_pace_students__ff_enrollments",
      width: 120,
      type: "percentageColumn",
      headerTooltip:
        "Pct Behind Pace Students = (# Behind Pace Students) / (# Students)",
    },
    {
      headerName: "# Low Grade Students",
      field: "num_low_grade_students__ff_enrollments",
      width: 120,
      type: "integerColumn",
      headerTooltip: `Number of low grade students ${consideringActiveCurrentEnrollmentsText}`,
    },
    {
      headerName: "% Low Grade Students",
      field: "pct_low_grade_students__ff_enrollments",
      width: 120,
      type: "percentageColumn",
      headerTooltip:
        "Pct Low Grade Students = (# Low Grade Students) / (# Students)",
    },
    {
      headerName: "# Super Pacer Students",
      field: "num_super_pacer_students__ff_enrollments",
      width: 120,
      type: "integerColumn",
      headerTooltip: `Number of super pacer students ${consideringActiveCurrentEnrollmentsText}`,
    },
    {
      headerName: "% Super Pacer Students",
      field: "pct_super_pacer_students__ff_enrollments",
      width: 120,
      type: "percentageColumn",
      headerTooltip:
        "Pct Super Pacer Students = (# Super Pacer Students) / (# Students)",
    },
  ];
}
