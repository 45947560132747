import { Computed, computed } from "easy-peasy";
import { StoreModel } from "./model";
import { TableModel, tableModelFactory, TableRow } from "./table-model-factory";
import { timeit } from "../helpers/timing";
import _ from "lodash";

export type UpcomingRenrPotentialStudentsModel = TableModel & {
  rowData: Computed<UpcomingRenrPotentialStudentsModel, TableRow[], StoreModel>;
};

export function getUpcomingRenrPotentialStudentsModel(): UpcomingRenrPotentialStudentsModel {
  return {
    ...tableModelFactory(
      "upcomingRenrPotentialStudents",
      "upcoming_renr_potential",
      (row) => row.StudentIndex || row.customer_id
    ),
    rowData: computed(
      [
        (state) => state.initialData,
        (state) => state.relatedIdsMap,
        (state) => state.relatedRowsMap,
      ],
      timeit((rowData, relatedIdsMap, relatedRowsMap) => {
        if (!_.size(rowData)) {
          return [];
        }
        return rowData.map((row) => {
          const next_course_recos =
            relatedRowsMap[row.id]?.nextCourseRecos?.next_course_recos ?? [];
          const hasNextCourseRecos = next_course_recos.length > 0;

          return {
            ...row,
            hasNextCourseRecos,
          };
        });
      }, "store.upcomingRenrPotentialStudents.rowData")
    ),
  };
}
