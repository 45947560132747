import {
  Actions,
  Computed,
  computed,
  Thunk,
  thunk,
  thunkOn,
  ThunkOn,
} from "easy-peasy";
import {
  makeAuthorizedPatchRequestToBackend,
  makeUrl,
} from "../../helpers/backendApi";
import { toaster } from "../../toaster";
import { StoreModel } from "../model";
import { Injections } from "../store-injections";
import {
  TableModel,
  tableModelFactory,
  TableRow,
} from "../table-model-factory";
import _ from "lodash";
import {
  ActionPayload_CreateNewUser,
  createNewUser,
} from "../../user-actions/create-new-user";

export type EcpUserModel = TableModel & {
  rowData: Computed<EcpUserModel, TableRow[], StoreModel>;
  rowDataById: Computed<
    EcpUserModel,
    { [key: string]: TableRow; [key: number]: TableRow },
    StoreModel
  >;
  handlePatchUser: Thunk<
    EcpUserModel,
    [TableRow, { [key: string]: any }],
    Injections,
    StoreModel
  >;
  handleCreateNewUser: Thunk<
    EcpUserModel,
    ActionPayload_CreateNewUser,
    Injections,
    StoreModel
  >;
  onSourceDataChange: ThunkOn<EcpUserModel, Injections, StoreModel>;
};

export function getEcpUserModel(): EcpUserModel {
  return {
    ...tableModelFactory("ecp_user", "ecp/users", (row) => row.id, [
      {
        name: "userMetric",
        arity: "OneToOne",
        foreignName: "ecp_userMetric",
        foreignField: "email",
        field: "email",
      },
    ]),
    rowData: computed(
      [(s) => s.initialData, (s) => s.relatedRowsMap],
      (initialData, relatedRowsMap) => {
        let ret = initialData.map((row) => {
          const um = relatedRowsMap[row.id]?.["userMetric"] as
            | TableRow
            | undefined;
          return { ...(um || {}), ...row };
        });
        ret = _.sortBy(ret, (row) => -row.id);
        return ret;
      }
    ),
    rowDataById: computed([(s) => s.rowData], (rowData) =>
      Object.fromEntries(rowData.map((r) => [r.id, r]))
    ),
    handlePatchUser: thunk(
      async (
        actions: Actions<EcpUserModel>,
        [row, patchPayload],
        { getState, dispatch }
      ) => {
        const state = getState();
        const rowId = state.GET_ROW_ID(row);
        const oldRow = state.getRow(rowId);
        actions.upsertRow({ ...oldRow, ...patchPayload });
        try {
          const { data: newRow } = await makeAuthorizedPatchRequestToBackend({
            url: makeUrl(`ecp/users/${rowId}`),
            data: patchPayload,
            axiosConfig: undefined,
          });
          toaster.success("Update succeeded", 2);
          actions.upsertRow(newRow);
          await dispatch.ecp_userMetric.handleFetchInitialData();
        } catch (e) {
          if (oldRow) {
            actions.upsertRow(oldRow);
          } else {
            actions.deleteRow(rowId);
          }
          toaster.error("Update failed -- please try again");
        }
      }
    ),
    handleCreateNewUser: thunk(
      async (
        actions: Actions<EcpUserModel>,
        payload,
        { getState, dispatch }
      ) => {
        const state = getState();
        const existingEmails = state.initialData.map((row) =>
          row.email.toLowerCase()
        );

        if (existingEmails.includes(payload.email.toLowerCase())) {
          toaster.warning(
            `Failed to create new user -- a user with that email address already exists`
          );
          throw Error();
        }

        const maxCurrentUserId = _.max(
          state.initialData.map((row) => row.id)
        ) as number;
        const rowId = maxCurrentUserId + 1;
        const newUserFakeId = {
          ...payload.formData,
          id: rowId,
          is_active: true,
        };
        actions.upsertRow(newUserFakeId);

        try {
          await createNewUser(payload);

          toaster.success(`Successfully created new user`, 2);
        } catch (e) {
          actions.deleteRow(rowId);
          toaster.warning(`Failed to create new user -- please try again`);
          throw Error();
        }
      }
    ),
    onSourceDataChange: thunkOn(
      (actions, storeActions) => [
        actions.handlePatchUser,
        //actions.handleCreateNewUser,
      ],
      async (actions, target, { getStoreState }) => {
        await actions.handleFetchInitialData();
      }
    ),
  };
}
