import { DataSnapshot, submitUserActionPostRequest } from "./user_actions";
import { assert } from "../helpers/assertions";
import {
  ALL_INTERACTION_TYPE_VALUES,
  InteractionTypeValue,
} from "../components/reporting-actions/interaction-type-input";
import { InteractionResult } from "../components/reporting-actions/lead--interaction-result-input";
export async function submitUserAction__lead_customer_interaction(
  actionPayload: ActionPayload_LeadCustomerInteraction
) {
  verifyActionPayload(actionPayload);
  return await submitUserActionPostRequest(
    "lead_customer_interaction",
    actionPayload
  );
}

function verifyActionPayload(
  actionPayload: ActionPayload_LeadCustomerInteraction
): void {
  assert(typeof actionPayload.customer_id === typeof 12345);
  assert(
    actionPayload.customer_id === actionPayload.customer_snapshot.customer_id
  );
  assert(
    ALL_INTERACTION_TYPE_VALUES.includes(
      actionPayload.form_payload.interactionType as InteractionTypeValue
    )
  );
}

export interface ActionPayload_LeadCustomerInteraction {
  customer_id: number;
  form_payload: {
    interactionType: string | InteractionTypeValue;
    interactionResult: InteractionResult;
    notes: string;
  };
  customer_snapshot: DataSnapshot;
}
