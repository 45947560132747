import {
  Button,
  Colors,
  Icon,
  Intent,
  Position,
  Tooltip,
} from "@blueprintjs/core";
import { Table } from "antd";
import { FixedType } from "rc-table/lib/interface";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import styled from "styled-components/macro";
import _ from "lodash";
import React, { ReactNode, useState } from "react";
import {
  getFormatter,
  RelatedEntitiesSumTag,
  RelatedEntitiesTag,
} from "../entities/helpers/relation-agg-cr";
import { TableRow } from "../store/table-model-factory";
import { integerVF, isna } from "./AgTable/gridOptions";
import {
  HierarchicalRelatedEntitiesSumTag,
  HierarchicalRelatedEntitiesTag,
  HierarchicalRelatedsIdsTag,
} from "./hier-rel-ent-counts-tag";
import { DescriptionItemType } from "./flat-descriptions";
import { EntityName } from "../entities/helpers/core";
import { ColumnType } from "antd/lib/table";
export interface ColTableConfig {
  label: string;
  field: string;
  fieldIds?: string;
  baseEntityName?: EntityName;
  type: DescriptionItemType;
  childItemConfigs?: ColTableConfig[];
  tooltip?: string | JSX.Element;
  width?: number;
  fixed?: FixedType;
  getVisible?: { (data: TableRow): boolean };
  __authorized?: boolean;
  __renderNansAsEmpty?: boolean;
  extra?: any;
  renderer?: {
    (
      key: number | string,
      data: TableRow,
      colConfig: ColTableConfig
    ): JSX.Element;
  };
}

export function TableDescriptions({
  columnsFormat,
  dataSource,
  subTableColumnsFormat,
  ...restProps
}: {
  columnsFormat: ColTableConfig[];
  subTableColumnsFormat?: ColTableConfig[];
  dataSource: any[];
  size?: SizeType;
  title?: any | string;
  withoutBoxShadow?: boolean;
}): JSX.Element {
  let childrenMapping = {};
  dataSource = dataSource.map((row, index) => {
    const { children, ...rowWithoutKey } = row;
    childrenMapping[index] = children;
    return { rowKey: index, ...rowWithoutKey };
  });
  const [expandedRows, setExpandedRows] = useState(
    Object.keys(childrenMapping).reduce((acc, keyRow) => {
      acc[keyRow] = false;
      return acc;
    }, {})
  );

  const expandedRowRender = (record) => (
    <RowExpandedDetail
      subTableColumnsFormat={subTableColumnsFormat}
      record={record}
      childrenMapping={childrenMapping}
    />
  );

  return (
    <StyledTable
      dataSource={dataSource}
      rowKey={(record) => record.rowKey}
      expandable={{
        expandedRowRender,
        rowExpandable: (record) => !!childrenMapping[record.rowKey],
        expandIcon: ({ expanded, onExpand, record }) => {
          const setter = setExpandedRows;
          const currentState = expandedRows[record.rowKey];
          return !!childrenMapping[record.rowKey] &&
            _.size(childrenMapping[record.rowKey]) ? (
            <Button
              icon={
                <Icon
                  icon={expanded ? "caret-up" : "caret-down"}
                  iconSize={Icon.SIZE_LARGE}
                />
              }
              minimal
              interactive
              large
              intent={Intent.NONE}
              onClick={(e) => {
                setter({ ...expandedRows, [record.rowKey]: !currentState });
                onExpand(record, e);
              }}
            />
          ) : null;
        },
      }}
      title={restProps.title || ""}
      size={restProps.size || "small"}
      pagination={{ hideOnSinglePage: true }}
      scroll={{ x: "max-content" }}
      columns={columnsFormat.map((colConfig, key) =>
        buildColumn({
          key,
          dataSource,
          colConfig,
        })
      )}
      {...restProps}
    />
  );
}

function RowExpandedDetail({
  subTableColumnsFormat,
  record,
  childrenMapping,
  ...restProps
}) {
  if (!childrenMapping[record.rowKey] || !subTableColumnsFormat) {
    return null;
  }
  return (
    <StyledTable
      columns={subTableColumnsFormat.map((colConfig, key) =>
        buildColumn({
          key,
          dataSource: childrenMapping[record.rowKey],
          colConfig,
        })
      )}
      dataSource={childrenMapping[record.rowKey]}
      pagination={false}
    />
  );
}

function buildColumn({
  key,
  dataSource,
  colConfig,
}: {
  key: number | string;
  dataSource: TableRow[] | undefined;
  colConfig: ColTableConfig;
}): ColumnType<{ [key: string]: any }> {
  const label = getTableColLabel(colConfig.label, colConfig.tooltip);

  return {
    title: label,
    key: key,
    fixed: colConfig.fixed ?? false,
    width: colConfig.width,
    dataIndex: colConfig.field,
    render: (value, _, index) =>
      getCellContent({ key, dataSource, index, value, colConfig }),
  };
}

function getCellContent({
  key,
  dataSource,
  index,
  value,
  colConfig,
}: {
  key: number | string;
  dataSource: TableRow[] | undefined;
  index: number;
  value: number | string | any;
  colConfig: ColTableConfig;
}) {
  const data = dataSource[index];
  const visible = colConfig.getVisible ? colConfig.getVisible(data) : true;

  if (!visible) {
    return null;
  }
  let contents: ReactNode;
  if (value === undefined || value == null || !data) {
    contents = "N/A";
  } else if (colConfig.type === "element") {
    contents = colConfig.renderer(key, data, colConfig);
  } else if (colConfig.type === "relatedsCount") {
    const formattedValue = integerVF({ value: _.size(value.entityIds) });
    if (colConfig.childItemConfigs?.length) {
      contents = (
        <HierarchicalRelatedEntitiesTag itemConfig={colConfig} data={data}>
          {formattedValue}
        </HierarchicalRelatedEntitiesTag>
      );
    } else {
      contents = (
        <RelatedEntitiesTag
          entityName={value.entityName}
          entityIds={value.entityIds}
        >
          {formattedValue}
        </RelatedEntitiesTag>
      );
    }
  } else if (colConfig.type === "relatedIds") {
    const formattedValue = formatNumberAsPct(value);
    if (colConfig.childItemConfigs?.length) {
      contents = (
        <HierarchicalRelatedsIdsTag colConfig={colConfig} data={data}>
          {formattedValue}
        </HierarchicalRelatedsIdsTag>
      );
    }
  } else if (colConfig.type === "relatedsSum") {
    if (colConfig.childItemConfigs?.length) {
      contents = (
        <HierarchicalRelatedEntitiesSumTag itemConfig={colConfig} data={data} />
      );
    } else {
      contents = (
        <RelatedEntitiesSumTag
          entityName={value.entityName}
          entityIds={value.entityIds}
          foreignField={colConfig.extra.foreignField}
          type={colConfig.extra.type}
        />
      );
    }
  } else {
    let formatter;
    if (colConfig.type === "percentage") {
      formatter = formatNumberAsPct;
    } else {
      formatter = getFormatter(colConfig.type);
    }
    const formattedValue = formatter ? formatter(value) : value;
    if (colConfig.childItemConfigs?.length) {
      contents = (
        <HierarchicalRelatedEntitiesTag itemConfig={colConfig} data={data}>
          {formattedValue}
        </HierarchicalRelatedEntitiesTag>
      );
    } else {
      if (isna(value)) {
        contents = colConfig.__renderNansAsEmpty ? "" : "N/A";
      } else {
        contents = formattedValue;
      }
    }
  }

  return contents;
}

function formatNumberAsPct(value: number): string {
  return nfFloat.format(value) + " %";
}

const nfFloat = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export function getTableColLabel(
  label: string,
  tooltip?: string | JSX.Element
): JSX.Element | string {
  if (!tooltip) {
    return label;
  } else {
    return (
      <Tooltip
        content={tooltip}
        boundary={"viewport"}
        position={Position.TOP}
        usePortal={true}
        hoverOpenDelay={500}
      >
        {label}
      </Tooltip>
    );
  }
}

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: white !important;
    font-weight: bold !important;
    background-color: ${Colors.DARK_GRAY4} !important;
    border-bottom: transparent !important;
  }
  .ant-table {
    color: white !important;
    background-color: ${Colors.DARK_GRAY3} !important;
    ${(props) =>
      props.withoutBoxShadow
        ? "box-shadow: none;"
        : `box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4), 0 2px 6px rgba(16, 22, 26, 0.4);`}
  }
  .ant-table-tbody > tr > td {
    border-bottom: transparent !important;
  }
  .ant-table-tbody > tr:nth-child(even) > td {
    background-color: ${Colors.DARK_GRAY3} !important;
  }
  .ant-table-tbody > tr:nth-child(odd) > td {
    background-color: ${Colors.DARK_GRAY4} !important;
  }
  && tbody > tr:hover > td {
    background-color: ${Colors.DARK_GRAY3};
  }
`;
