// This function converts a date string from the American format (MM/DD/YYYY) to the
// ISO format (YYYY-MM-DD).
// If the input string does not match the American format, the function returns null.
export function convertDateStringAmericanFormatToIsoFormat(
  dateAmericanFormat: string
): string {
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1[0-9]|2[0-9]|3[01])\/\d{4}$/;

  if (!regex.test(dateAmericanFormat)) {
    return null;
  }

  const dateParts = dateAmericanFormat.split("/");
  return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
}
