import { Recommendation } from "../types";
import { reco2elem } from "./reco2elem";
import React from "react";
import styled from "styled-components/macro";

export function Future({
  nextCourseRecos,
}: {
  nextCourseRecos: Recommendation[];
}): JSX.Element {
  return (
    <Container>
      <Label />
      <Content nextCourseRecos={nextCourseRecos} />
    </Container>
  );
}

function Label(): JSX.Element {
  return <div className={"single-dept-recos--body-future--label"}>Next:</div>;
}

export function Content({
  nextCourseRecos,
}: {
  nextCourseRecos: Recommendation[];
}): JSX.Element {
  return (
    <div className={"single-dept-recos--body-future--content"}>
      {nextCourseRecos.map(reco2elem)}
    </div>
  );
}

const Container = styled.div`
  margin-left: 24px;
  display: grid;
  gap: 16px;
  grid-template-columns: 44px 1fr;

  & > .single-dept-recos--body-future--label {
    text-align: right;
  }

  & > .single-dept-recos--body-future--content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
