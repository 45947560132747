import { AgColDef } from "../../../components/AgTable/types";
import { useStoreActions } from "../../../hooks/ep";
import { parseBoolean } from "../../../helpers/parse-boolean";
import { toaster } from "../../../toaster";
import { CellValueChangedEvent } from "@ag-grid-community/core/dist/es6/events";

export function useStudentFlag_ExcludeFromNextCourseRecosCgn_ColDef(): AgColDef {
  const handleUpdate = useStoreActions(
    (a) => a.student_flag.handleUpdateStudentFlag
  );
  return {
    field: "student_flag__exclude_from_next_course_recos_cgn",
    headerName: "Excluded From Campaign",
    type: "booleanColumn",
    width: 112,
    editable: true,
    singleClickEdit: true,
    cellClass: (params) => {
      return ["bp3-minimal", "bp3-tag", "bp3-interactive", "bp3-small"];
    },
    valueParser: (params) => parseBoolean(params.newValue),
    valueSetter: (params) => {
      const isValid =
        typeof params.newValue === "boolean" &&
        params.newValue !== params.oldValue;
      if (isValid) {
        params.data.student_flag__exclude_from_next_course_recos_cgn =
          params.newValue;
        return true;
      } else {
        toaster.error("Please submit the word 'true' or the word 'false'");
        return false;
      }
    },
    onCellValueChanged: async (ev: CellValueChangedEvent) => {
      handleUpdate({
        student_id: ev.data.StudentIndex,
        flag_codename: "exclude_from_next_course_recos_cgn",
        flag_oldvalue: ev.oldValue,
        flag_newvalue: ev.newValue,
        student_snapshot: { ...ev.data },
      });
    },
  };
}
