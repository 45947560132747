import React from "react";
import { useStoreState } from "../../hooks/ep";
import AgTable from "../../components/AgTable";
import _ from "lodash";
import {
  ENROLLMENT_COORDINATOR_INDEX__ALL,
  EnrollmentCoordinatorIndex,
} from "../../store/model-ec-selection";
import { useRetentionAnalysisStudentsColDefs } from "./retention-analysis-student-coldefs";
import { AgColDefModifiers } from "../../components/AgTable/types";

export function ChurnRateAnalysisStudentsUnit({
  selectedEnrollmentCoordinatorIndex,
  ...restProps
}: {
  selectedEnrollmentCoordinatorIndex: EnrollmentCoordinatorIndex;
}): JSX.Element {
  const rowData = useStoreState((s) => s.churnRateAnalysisStudents.rowData);
  const filteredRowData =
    selectedEnrollmentCoordinatorIndex !== ENROLLMENT_COORDINATOR_INDEX__ALL
      ? rowData.filter(
          (row) => row.EC_Index === selectedEnrollmentCoordinatorIndex
        )
      : rowData;
  const initialCategoryFilters = {
    within_past_1_to_365_days: {
      value: [true],
    },
  };
  const colDefModifiers: AgColDefModifiers = [
    {
      modifyField: "priority_scaled",
      __ignoreColDef: true,
    },
    {
      modifyField: "n_days_remaining_until_max_enrollment_end_date",
      __ignoreColDef: true,
    },
    {
      modifyField: "has_active_enrs_within_past_1_to_365_days",
      hide: false,
    },
    {
      modifyField: "has_active_enrs_within_past_366_to_730_days",
      hide: false,
    },
  ];
  const colDefs = useRetentionAnalysisStudentsColDefs(colDefModifiers);
  const height = _.min([500, 64 + 64 * _.max([3, filteredRowData.length])]);
  return (
    <AgTable
      rowData={filteredRowData}
      columnDefs={colDefs}
      categoryFilters={initialCategoryFilters}
      tableName={"churnRateAnalysisStudents"}
      ribbonTitle={"Churn Rate Analysis"}
      height={`${height}px`}
      {...restProps}
    />
  );
}
