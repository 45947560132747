import React, { useMemo } from "react";
import moment from "moment-timezone";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import CategoryColumnFilter from "./categoryColumnFilter";
import { ChurnScoreCellRenderer } from "./churn-score-cell/cell-renderer";
import { StarCellRenderer } from "./star-cell-renderer";
import { useGridContext } from "./my-ag-grid-react-context";
import { ActionButtonCellRenderer } from "../table-actions/action-cell-renderer";
import { NapoCellRenderer } from "./napo-cell/cell-renderer";
import { AdmissionsCoordinatorsKPICellRenderer } from "./admissions-coordinators-kpi-cell/cell-renderer";
import _ from "lodash";
import { GridOptions } from "@ag-grid-community/core/dist/es6/entities/gridOptions";
import { ReportReeCustomerInteractionButtonCellRenderer } from "../table-actions/report-ree-customer-interaction-cell-renderer";
import { PhoneActionButtonCellRenderer } from "../table-actions/phone-action-cell-renderer";
import { RelationshipAggregationCellRenderer } from "../../entities/helpers/relation-agg-cr";
import { StudentEmailActionButtonCellRenderer } from "../table-actions/student-email-action-cell-renderer";
import TagsColumnFilter from "./tagsColumnFilter";
import { TagsCellRenderer } from "./tags-cell-renderer";
import { TeachercourseEmailActionButtonCellRenderer } from "../table-actions/teacherCourse-email-action-cell-renderer";
import { SsdashEmailActionButtonCellRenderer } from "../table-actions/ssdash-email-action-cell-renderer";
import { EnrollmentEmailActionButtonCellRenderer } from "../table-actions/enrollment-email-action-cell-renderer";
import { StatsTooltip } from "./stats-tooltip";
import { StudentAttr_LoyaltyRenrCampaignType_Tooltip } from "./student-attr--loyalty-renr-campaign-type--tooltip";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { AnchorButton } from "@blueprintjs/core";
import { useHistory } from "react-router";
import { TableRow } from "../../store/table-model-factory";
import { AgColDef } from "./types";
import { ICellRendererParams } from "@ag-grid-community/core";
import { BaseVector } from "apache-arrow";
import { PriorityScoreCellRenderer } from "./lead-priority-cell/cell-renderer";
import { DailyQueueEmailActionButtonCellRenderer } from "../table-actions/dailyqueue-email-action-cell-renderer";
import { ValueGetterParams } from "@ag-grid-community/core/dist/cjs/entities/colDef";
import { CheckBoxCellRenderer } from "./check-box-cell-renderer";
import { SentEmailViewerButtonCellRenderer } from "../table-actions/sent-email-viewer-cell-renderer";
import MultiCategoryColumnFilter from "./multiCategoryColumnFilter";
import { getNaiveDate } from "../../helpers/getNaiveDate";
import { SelectCellRenderer } from "./select-cell-renderer";

const USE_BIG_HEADERS = false;

export const nfInt = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const FALSEY_VALUES = new Set([
  false,
  "false",
  "False",
  "No",
  "no",
  0,
  0.0,
  -0,
]);
export const TRUTHY_VALUES = new Set([
  true,
  "true",
  "True",
  "Yes",
  "yes",
  1,
  1.0,
]);

function LinkedTextCellRenderer(props) {
  const { value, valueFormatted, colDef, node } = props;
  const text = valueFormatted ?? value;

  // @ts-ignore
  const { __pinnedRowCellRenderer, __linkIsInternal } = colDef;

  if (__pinnedRowCellRenderer && node.isRowPinned()) {
    return __pinnedRowCellRenderer(props);
  }

  let link = getLinkForText(props);

  // noinspection JSConstructorReturnsPrimitive
  if (!link) {
    return <span>{text}</span>;
  } else if (__linkIsInternal) {
    return <InternalLink text={text} link={link} />;
  } else {
    return (
      <a
        href={link}
        // eslint-disable-next-line react/jsx-no-target-blank
        target={"_blank"}
      >
        {text}
      </a>
    );
  }
}

export function InternalLink({ text, link }): JSX.Element {
  const history = useHistory();
  return (
    <AnchorButton
      small={true}
      intent={Intent.PRIMARY}
      minimal
      css={`
        font-size: 14px !important;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & span.bp3-button-text {
          text-align: left;
        }
      `}
      onClick={() => history.push(link)}
    >
      {text}
    </AnchorButton>
  );
}

export function getLinkForText(params: ICellRendererParams) {
  const { data, colDef }: { data: TableRow; colDef: AgColDef } = params;
  const {
    // @ts-ignore
    __linkField,
    // @ts-ignore
    __linkValueGetter,
    // @ts-ignore
    __linkIsNotAnEmail,
    // @ts-ignore
    __linkIsInternal,
  } = colDef;

  let link;
  if (!_.isNil(__linkField)) {
    link = data[__linkField];
  } else if (!_.isNil(__linkValueGetter)) {
    link = __linkValueGetter(params);
  }

  if (!link) {
    return link;
  }

  if (link && !__linkIsInternal && !link.startsWith("http")) {
    link = "//" + link;
  }

  if (!__linkIsNotAnEmail) {
    link = inferMailtoLinks(link);
  }

  return link;
}

function MultiLinkedTextCellRenderer({ value: texts, data, colDef }) {
  if (texts instanceof BaseVector) {
    texts = texts.toArray();
  }

  let links;
  if (!_.isNil(colDef.__linksField)) {
    links = data[colDef.__linksField];
  } else {
    links = colDef.__linksValueGetter(data);
  }

  const hyperlinkText = (text, elementIndex) => {
    const link = links[elementIndex];

    let inner;

    // noinspection JSConstructorReturnsPrimitive
    if (!link) {
      inner = <span key={text}>{text}</span>;
    } else {
      inner = (
        <a
          key={text}
          href={colDef.__linksAreNotEmails ? link : inferMailtoLinks(link)}
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
        >
          {text}
        </a>
      );
    }

    const isNotLast = elementIndex < texts.length - 1;
    return (
      <span key={text}>
        {inner}
        {isNotLast && <span>,&nbsp;</span>}
      </span>
    );
  };

  const elems = texts.map(hyperlinkText);
  return <span>{elems}</span>;
}

const emailPatt =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function validateEmail(email) {
  return emailPatt.test(email);
}

function inferMailtoLinks(url) {
  if (url && validateEmail(url)) {
    return `mailto:${url}`;
  } else {
    return url;
  }
}

function UrlCellRenderer({ value: url }) {
  // noinspection JSConstructorReturnsPrimitive
  return !url ? null : (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={inferMailtoLinks(url)} target="_blank">
      Link
    </a>
  );
}

export function timestampComparator(filterLocalValue, cellValue) {
  // Assume dates are stored as iso
  const cellDate = cellValue && new Date(cellValue);
  const filterLocalDate = filterLocalValue && new Date(filterLocalValue);

  if (!cellDate && !filterLocalDate) {
    return 0;
  } else if (!filterLocalDate) {
    return -1;
  } else if (!cellValue) {
    return 1;
  } else if (filterLocalDate < cellDate) {
    return -1;
  } else if (filterLocalDate > cellDate) {
    return 1;
  } else {
    return 0;
  }
}

export function dateVF(params) {
  const value = _.isString(params) ? params : params.value;
  let ret = null;
  if (value) {
    const dateString = new Date(value).toLocaleDateString([], {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    if (dateString !== "Invalid Date") {
      ret = dateString;
    }
  }
  return ret;
}

export function timestampVF({ value, colDef }) {
  let ret = null;
  let fmt;
  if (value) {
    if (_.isString(value)) {
      ret = moment(value);
    } else if (_.isNumber(value)) {
      ret = moment(value);
    }
  }
  if (ret !== null) {
    if (colDef.__timezone) {
      ret = ret.tz(colDef.__timezone);
      fmt = "ddd ll LT (z)";
    } else {
      ret = ret.utc(false);
      fmt = "ddd ll LT";
    }
    ret = ret.format(fmt);
  }
  return ret;
}

export type Params<T> = T | { value: T };

export function booleanVF(params: Params<boolean>): string {
  const value = typeof params === "object" ? params.value : params;
  // @ts-ignore
  const __renderNansAsEmpty = params?.colDef?.__renderNansAsEmpty || false;
  if (isna(value)) {
    return __renderNansAsEmpty ? "" : "N/A";
  } else if (FALSEY_VALUES.has(value)) {
    return "";
  } else if (TRUTHY_VALUES.has(value)) {
    return "\u2713";
  } else {
    throw Error(`booleanVF cannot format params with value=${value}`);
  }
}

export function percentageVF(params: Params<number>): string {
  let ret = integerVF(params);
  return ret ? `${ret}%` : "";
}
export function riskPercentageChangeVF(params: Params<number>): string {
  const value = params2number(params);
  if (isna(value)) {
    return "N/A";
  } else {
    const prefix = value === 0 ? "" : value < 0 ? "-" : "+";
    return `${prefix}${floatVF(Math.abs(value))}%`;
  }
}
export function floatVF(params: Params<number>): string {
  const value = params2number(params);

  // @ts-ignore
  const formatNanAsEmpty = params.colDef?.__formatNanAsEmpty ?? false;

  if (isna(value) && formatNanAsEmpty) {
    return "";
  }

  // @ts-ignore
  const nDecimals = params.colDef?.__nDecimals ?? 2;

  const fmtr = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: nDecimals,
    maximumFractionDigits: nDecimals,
  });

  return fmtr.format(value);
}

export function moneyVF(params: Params<number>): string {
  const value = params2number(params);
  if (isna(value)) {
    return "";
  } else {
    const prefix = value < 0 ? "-" : "";
    return prefix + "$" + floatVF(Math.abs(value));
  }
}

export function integerVF(params: Params<number>): string {
  const value = params2number(params);
  const valueUnknown = isna(value);

  // @ts-ignore
  const __renderZerosAsEmpty = params?.colDef?.__renderZerosAsEmpty;
  if (valueUnknown || (value === 0 && __renderZerosAsEmpty)) {
    return "";
  } else {
    return nfInt.format(value);
  }
}

export function integerIdentifierVF(params: Params<number>): string {
  const value = params2number(params);
  const valueUnknown = isna(value);
  return valueUnknown ? "" : value.toString();
}

export function sortMultiCategoryAlphabetically(
  params: Params<string>
): string {
  const value = _.isString(params) ? params : params.value;

  if (!value) return "";

  const sortedValues = value
    .split(/,\s?/)
    .map((v) => v.trim())
    .sort();
  return sortedValues.join(", ");
}

export function params2number(params: Params<number>): number {
  const ret = isna(params) || _.isNumber(params) ? params : params.value;
  return ret as number;
}

export function isna(v: any): boolean {
  return _.isNull(v) || _.isUndefined(v) || _.isNaN(v);
}

const NUMERIC_COLUMN_PROPERTIES = {
  filter: "agNumberColumnFilter",
  headerClass: "ag-numeric-header",
  cellClass: "ag-numeric-cell",
};

// @ts-ignore
const window_Cypress = !!window.Cypress;

// noinspection JSUnresolvedFunction
const DEFAULT_GRID_OPTIONS: GridOptions = {
  columnTypes: {
    dateColumn: {
      filter: "agDateColumnFilter",
      // filterParams: {
      //   comparator: timestampComparator,
      // },
      // comparator: timestampComparator,
      // @ts-ignore
      valueGetter: (params: ValueGetterParams): Date | null => {
        const value = params.data[params.colDef.field];
        if (_.isDate(value)) {
          return value;
        } else if (_.isString(value) && value) {
          return new Date(value);
        } else {
          return null;
        }
      },
      valueFormatter: dateVF,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    naiveDateColumn: {
      filter: "agDateColumnFilter",
      // @ts-ignore
      valueGetter: (params: ValueGetterParams): Date | null => {
        const value = params.data[params.colDef.field];
        if (_.isString(value) && value) {
          return getNaiveDate(value);
        } else {
          return null;
        }
      },
      valueFormatter: dateVF,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    timestampColumn: {
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: timestampComparator,
      },
      comparator: timestampComparator,
      valueFormatter: timestampVF,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    booleanColumn: {
      filter: "categoryFilterer",
      valueFormatter: booleanVF,
    },
    percentageColumn: {
      valueFormatter: percentageVF,
      ...NUMERIC_COLUMN_PROPERTIES,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    integerColumn: {
      valueFormatter: integerVF,
      ...NUMERIC_COLUMN_PROPERTIES,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    integerIdentifierColumn: {
      valueFormatter: integerIdentifierVF,
      ...NUMERIC_COLUMN_PROPERTIES,
    },
    floatColumn: {
      valueFormatter: floatVF,
      ...NUMERIC_COLUMN_PROPERTIES,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    moneyColumn: {
      valueFormatter: moneyVF,
      ...NUMERIC_COLUMN_PROPERTIES,
      headerTooltip: "_",
      tooltipComponent: "statsTooltip",
    },
    textColumn: {
      filter: "agTextColumnFilter",
    },
    urlColumn: {
      filter: "agTextColumnFilter",
      cellRenderer: "urlRenderer",
    },
    linkedTextColumn: {
      filter: "agTextColumnFilter",
      cellRenderer: "linkedTextRenderer",
    },
    multiLinkedTextColumn: {
      filter: "agTextColumnFilter",
      cellRenderer: "multiLinkedTextRenderer",
    },
    categoryColumn: {
      filter: "categoryFilterer",
      tooltipComponent: "statsTooltip",
    },
    multiCategoryColumn: {
      filter: "multiCategoryFilterer",
      valueFormatter: sortMultiCategoryAlphabetically,
      // @ts-ignore
      valueGetter: (params: ValueGetterParams): string | null => {
        const value = params.data[params.colDef.field];
        if (_.isString(value)) {
          return value;
        } else if (_.isNull(value) || _.isUndefined(value)) {
          return "N/A";
        } else {
          return null;
        }
      },
    },
    tagsColumn: {
      filter: "tagsFilterer",
      cellRenderer: "tagsCellRenderer",
      cellClass: "react-rendered-cell",
    },
  },
  frameworkComponents: {
    statsTooltip: StatsTooltip,
    studentAttr_loyaltyRenrCampaignType_tooltip:
      StudentAttr_LoyaltyRenrCampaignType_Tooltip,
    urlRenderer: UrlCellRenderer,
    linkedTextRenderer: LinkedTextCellRenderer,
    multiLinkedTextRenderer: MultiLinkedTextCellRenderer,
    categoryFilterer: CategoryColumnFilter,
    multiCategoryFilterer: MultiCategoryColumnFilter,
    tagsFilterer: TagsColumnFilter,
    tagsCellRenderer: TagsCellRenderer,
    churnScoreCellRenderer: ChurnScoreCellRenderer,
    starCellRenderer: StarCellRenderer,
    actionButtonCellRenderer: ActionButtonCellRenderer,
    napoCellRenderer: NapoCellRenderer,
    reportCustomerInteractionButtonCellRenderer:
      ReportReeCustomerInteractionButtonCellRenderer,
    phoneActionButtonCellRenderer: PhoneActionButtonCellRenderer,
    studentEmailActionButtonCellRenderer: StudentEmailActionButtonCellRenderer,
    enrollmentEmailActionButtonCellRenderer:
      EnrollmentEmailActionButtonCellRenderer,
    ssdashEmailActionButtonCellRenderer: SsdashEmailActionButtonCellRenderer,
    teacherCourseEmailActionButtonCellRenderer:
      TeachercourseEmailActionButtonCellRenderer,
    relationshipAggregationCellRenderer: RelationshipAggregationCellRenderer,
    admissionsCoordinatorsKPICellRenderer:
      AdmissionsCoordinatorsKPICellRenderer,
    priorityScoreCellRenderer: PriorityScoreCellRenderer,
    emailViewerButtonCellRenderer: SentEmailViewerButtonCellRenderer,
    dailyqueueEmailActionButtonCellRenderer:
      DailyQueueEmailActionButtonCellRenderer,
    checkBoxCellRenderer: CheckBoxCellRenderer,
    selectCellRenderer: SelectCellRenderer,
  },
  defaultColDef: {
    resizable: true,
    sortable: true,
    sortingOrder: ["desc", "asc", null],
    filter: true,
    suppressAutoSizeColumns: !window_Cypress,
    filterParams: {
      applyButton: true,
      clearButton: true,
      debounceMs: 200,
    },
    maxWidth: USE_BIG_HEADERS ? 250 : undefined,
  },
  immutableData: true,
  getRowNodeId: (data) => data.id,
  // headerHeight: 48,
  rowHeight: 48,
  enableCellTextSelection: true,
  // enableBrowserTooltips: false,

  // all rows assigned CSS class 'my-green-class'
  rowClass: "ag-table--row--odd",

  // all odd rows assigned 'my-shaded-effect'
  getRowClass: (params) => {
    if (params.node.rowPinned) {
      return "ag-table--row--pinned";
    }
    return params.node.rowIndex % 2 === 0 ? "ag-table--row--even" : null;
  },

  skipHeaderOnAutoSize: false,

  /* Label columns */
  headerHeight: 64,
  animateRows: true,
  suppressColumnVirtualisation: true,
  // suppressFieldDotNotation: true
  // enableBrowserTooltips: true,
  // tooltipShowDelay: 1500,
  // tooltipMouseTrack: true,

  singleClickEdit: true,
};

function useGridOptions({
  scrollbarWidth,
  ...onEvents
}: GridOptions): GridOptions {
  const eventListeners = Object.values(onEvents);
  const context = useGridContext();
  const ret = useMemo(
    () => ({
      scrollbarWidth,
      ...onEvents,
      ...DEFAULT_GRID_OPTIONS,
      context,
    }),
    // eslint-disable-next-line
    [context, eventListeners, scrollbarWidth]
  );
  return ret as GridOptions;
}

export { useGridOptions };
