import _ from "lodash";
import { useEnsureData } from "../../helpers/use-ensure-data";
import React from "react";
import AgTable from "../../components/AgTable";
import { AgColDef } from "../../components/AgTable/types";
import { useStoreState } from "../../hooks/ep";
import { Spinner } from "@blueprintjs/core";
import { getCountColDef } from "../../entities/helpers/relation-agg-cr";
import { getCommonMetricsColDefs } from "./common";

export function VendorAssessmentMetricsTable(): JSX.Element {
  const loading = _.max([
    useEnsureData("vendorAssessmentMetrics"),
    useEnsureData("courseAssessmentMetrics"),
    useEnsureData("ecp_enrollment"),
  ]);

  return loading ? <Spinner /> : <VendorAssessmentMetricsContent />;
}

function VendorAssessmentMetricsContent(): JSX.Element {
  const rowData = useStoreState((s) => s.vendorAssessmentMetrics.rowData);

  const colDefs = useVendorAssessmentMetricsColDefs();

  return (
    <AgTable
      tableName={"vendor-assessment-metrics"}
      rowData={rowData}
      columnDefs={colDefs}
      ribbonTitle={"Vendor Assessment Metrics"}
    />
  );
}

function useVendorAssessmentMetricsColDefs(): AgColDef[] {
  return [
    {
      headerName: "Vendor",
      field: "canonical_course_vendor_name",
      type: "categoryColumn",
      pinned: "left",
    },
    getCountColDef({
      baseEntityName: "vendorAssessmentMetrics",
      headerName: "# Courses",
      field: "courseIds",
      entityName: "courseAssessmentMetrics",
      cellClass: "react-rendered-cell ag-numeric-cell",
      actualField: "num_courses",
      type: "integerColumn",
    }),
    ...getCommonMetricsColDefs(false),
    {
      headerName: "Final Grade (Mean)",
      field: "final_grade_mean",
      type: "floatColumn",
      width: 130,
      __formatNanAsEmpty: true,
      headerTooltip:
        "Overall mean of the final grade means for the vendor's courses",
    },
    {
      headerName: "Final Grade (Median)",
      field: "final_grade_median",
      type: "floatColumn",
      width: 130,
      __formatNanAsEmpty: true,
      headerTooltip:
        "Overall median of the final grade medians for the vendor's courses",
    },
    {
      headerName: "Final Grade (StdDev)",
      field: "final_grade_std",
      type: "floatColumn",
      width: 130,
      __formatNanAsEmpty: true,
      headerTooltip:
        "Overall standard deviation of the final grade means for the vendor's courses",
    },
  ];
}
