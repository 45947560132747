import React from "react";
import { Spinner } from "@blueprintjs/core";
import "styled-components/macro";
import { useEndpoint } from "../../helpers/use-endpoint";
import {
  configCgnXY_CustomerSuccess_Stats,
  CustomerSuccess_CampaignStatsEndpoint,
} from "./common";
import { EcpCustomerSucessCampaignPageInner } from "./page-inner";

export function EcpCustomerSuccessCampaignPage() {
  const customerSuccessCampaignStatsConfig: configCgnXY_CustomerSuccess_Stats[] =
    [
      {
        cgnXY_codename: "cgn15a",
        cgnXY_title: "Re-Enrollment (Dynamic)",
        order: 1,
      },
      {
        cgnXY_codename: "cgn8a",
        cgnXY_title: "Active Non-SOR Upsell (Dynamic)",
        order: 2,
      },
      {
        cgnXY_codename: "cgn12a",
        cgnXY_title: "Inactive SOR Winback (Dynamic)",
        order: 3,
      },
      {
        cgnXY_codename: "cgn11a",
        cgnXY_title: "Inactive Non-SOR Winback (Dynamic)",
        order: 4,
      },
      {
        cgnXY_codename: "cgn1a",
        cgnXY_title: "Inactive Non-SOR Upsell (Initial Test)",
        order: 5,
      },
      {
        cgnXY_codename: "cgn16a",
        cgnXY_title: "Summer School Enrollment",
        order: 6,
      },
      {
        cgnXY_codename: "cgn17a",
        cgnXY_title: "Inactive Students Summer School Enrollment",
        order: 7,
      },
      {
        cgnXY_codename: "cgn3a",
        cgnXY_title: "Inactive SOR Winback (Initial Test)",
        order: 8,
      },
      {
        cgnXY_codename: "cgn2a",
        cgnXY_title: "Inactive Non-SOR Winback (Initial Test)",
        order: 9,
      },
    ];

  const { loading, data: cgnXYstatsData } =
    useEndpoint<CustomerSuccess_CampaignStatsEndpoint>(
      "ecp/customer_success_campaign_stats"
    );

  return loading ? (
    <Spinner />
  ) : (
    <EcpCustomerSucessCampaignPageInner
      cgnXYstatsData={cgnXYstatsData}
      customerSuccessCampaignStatsConfig={customerSuccessCampaignStatsConfig}
    />
  );
}
