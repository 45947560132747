import {
  action,
  Action,
  computed,
  Computed,
  Thunk,
  thunk,
  ThunkOn,
  thunkOn,
} from "easy-peasy";
import { StoreModel } from "./model";
import { Injections } from "./store-injections";
import { useStoreActions, useStoreState } from "../hooks/ep";

type InitialData = { [key: string]: any };
export interface DrawerFormModel {
  NAME: string;
  //
  resetData: Thunk<DrawerFormModel, void, Injections, StoreModel>;
  onLogin: ThunkOn<DrawerFormModel, Injections, StoreModel>;
  onResetMeData: ThunkOn<DrawerFormModel, Injections, StoreModel>;
  //
  initialData: null | InitialData;
  title: null | string;
  ContentComponent: null | any;
  handleSubmit: null | any;
  formStoreModelFactory: null | any;
  isOpen: Computed<DrawerFormModel, boolean, StoreModel>;
  open: Action<
    DrawerFormModel,
    {
      initialData: InitialData;
      title: string;
      ContentComponent: any;
      handleSubmit: any;
      formStoreModelFactory: any;
    }
  >;
  close: Action<DrawerFormModel>;
}

const drawerFormModel: DrawerFormModel = {
  NAME: "drawerForm",
  //
  resetData: thunk((actions) => {
    actions.close();
  }),
  onLogin: thunkOn(
    (__, storeActions) => storeActions.me.receiveInitialData,
    (actions, target, { getStoreState }) => {
      actions.resetData();
    }
  ),
  onResetMeData: thunkOn(
    (__, storeActions) => storeActions.me.resetData,
    (actions) => {
      actions.resetData();
    }
  ),
  //
  initialData: null,
  title: null,
  ContentComponent: null,
  handleSubmit: null,
  formStoreModelFactory: null,
  isOpen: computed([(s) => s.initialData], (initialData) => !!initialData),
  open: action(
    (
      state,
      {
        initialData,
        title,
        ContentComponent,
        handleSubmit,
        formStoreModelFactory,
      }
    ) => {
      state.initialData = initialData;
      state.title = title;
      state.ContentComponent = ContentComponent;
      state.handleSubmit = handleSubmit;
      state.formStoreModelFactory = formStoreModelFactory;
    }
  ),
  close: action((state) => {
    state.initialData = null;
    state.title = null;
    state.ContentComponent = null;
    state.handleSubmit = null;
    state.formStoreModelFactory = null;
  }),
};
export const useDrawerFormPayload = () =>
  useStoreState((s) => s.drawerForm.initialData);

export const useOpenDrawerForm = () =>
  useStoreActions((a) => a.drawerForm.open);

export const useCloseDrawerForm = () =>
  useStoreActions((a) => a.drawerForm.close);

export const useDrawerForm = () => {
  const initialData = useDrawerFormPayload();
  const title = useStoreState((a) => a.drawerForm.title);
  const ContentComponent = useStoreState((s) => s.drawerForm.ContentComponent);
  const handleSubmit = useStoreState((s) => s.drawerForm.handleSubmit);
  const formStoreModelFactory = useStoreState(
    (s) => s.drawerForm.formStoreModelFactory
  );

  const isOpen = useStoreState((s) => s.drawerForm.isOpen);

  const open = useOpenDrawerForm();
  const close = useCloseDrawerForm();

  return {
    initialData,
    title,
    ContentComponent,
    handleSubmit,
    formStoreModelFactory,
    isOpen,
    open,
    close,
  };
};

export { drawerFormModel };
