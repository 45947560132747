import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useStoreState } from "../../hooks/ep";
import { TableRow } from "../../store/table-model-factory";
import { Select } from "@blueprintjs/select";
import { Button, Classes, Intent, MenuItem } from "@blueprintjs/core";

import { Predicate } from "./student-selector";
import {
  ALL_GRADE_LEVELS,
  useEntitySelection,
} from "../../store/model-entity-selection";

type GradeLevel = number;

export function useAllCustomerGradeLevels({
  filter,
}: {
  filter: null | Predicate;
}): GradeLevel[] {
  let customerRows = useStoreState((s) => s.ecp_student.rowData) as TableRow[];

  return useMemo(() => {
    let viableCustRows = customerRows;
    if (!_.isNil(filter)) {
      viableCustRows = viableCustRows.filter(filter);
    }
    let ret = viableCustRows.map((el) => el["GradeLevel"]) as GradeLevel[];
    ret = Array.from(new Set(ret));
    ret = _.sortBy([...ret, ALL_GRADE_LEVELS]);
    return ret;
  }, [customerRows, filter]);
}

export function GradeLevelSelector({
  filter,
}: {
  filter: null | Predicate;
}): JSX.Element {
  const allCustomerGradeLevels = useAllCustomerGradeLevels({ filter });
  const [selectedGradeLevel, selectGradeLevel] =
    useEntitySelection("grade_level");

  const onItemSelect = useCallback(
    (v: GradeLevel) => selectGradeLevel(v),
    [selectGradeLevel]
  );

  useEffect(() => {
    if (!allCustomerGradeLevels.includes(selectedGradeLevel as number)) {
      selectGradeLevel(ALL_GRADE_LEVELS);
    }
  }, [allCustomerGradeLevels, selectedGradeLevel, selectGradeLevel]);

  return (
    <div
      css={`
        display: flex;
        align-items: center;
        & .grade-level-select-btn {
          box-shadow: none !important;
        }
      `}
    >
      <Select
        large
        intent="primary"
        itemPredicate={itemPredicate}
        itemRenderer={itemRendererFactory(gradeLevelRenderer)}
        items={allCustomerGradeLevels}
        onItemSelect={onItemSelect}
        popoverProps={POPOVER_PROPS}
      >
        <Button
          intent={Intent.NONE}
          className="grade-level-select-btn"
          large
          text={gradeLevelRenderer(selectedGradeLevel as number)}
          rightIcon="caret-down"
        />
      </Select>
    </div>
  );
}

const POPOVER_PROPS = { targetClassName: Classes.ELEVATION_1 };

const gradeLevelRenderer = (gradeLevel: number) => {
  switch (gradeLevel) {
    case ALL_GRADE_LEVELS:
      return "All Grades";
    case 0:
      return "KG";
    default:
      return gradeLevel.toString();
  }
};

function itemRendererFactory(gradeLevelRenderer) {
  return function itemRenderer(v, { handleClick, modifiers }) {
    return (
      <MenuItem
        active={false}
        key={v}
        onClick={handleClick}
        text={gradeLevelRenderer(v)}
      />
    );
  };
}

function itemPredicate(
  query: string,
  item: number,
  index: number,
  exactMatch: boolean
) {
  const renderedItem = gradeLevelRenderer(item);
  const queryPatt = new RegExp(`^.*\\b${_.escapeRegExp(query)}.*$`, "gi");
  if (exactMatch) {
    return renderedItem === query;
  } else if (!query.length) {
    return true;
  } else {
    return queryPatt.test(renderedItem);
  }
}
