import { useCallback } from "react";
import _ from "lodash";
import { useSetSearchBoxText } from "./search-box-store";
import moment from "moment-timezone";
import { useMeData } from "../../hooks/useMe";
import {
  CsvExportParams,
  ProcessCellForExportParams,
} from "@ag-grid-community/core/dist/cjs/interfaces/exportParams";
import { valueToString } from "apache-arrow/util/pretty";
import { BaseVector } from "apache-arrow";

function formatDate(value) {
  const dateString = moment(value).format("YYYY-MM-DD");
  if (dateString.toLowerCase() === "invalid date") {
    return value;
  }
  return dateString;
}
function formatTimestamp(value) {
  const dateString = moment(value).format("YYYY-MM-DD HH:mm:ss");
  if (dateString.toLowerCase() === "invalid date") {
    return value;
  }
  return dateString;
}

const ECP_REE_PREFIX__PATT = /^(ecp|ree)[-_]+/;
function getFileName(tableName: string, userTz) {
  if (tableName) {
    const tsString = moment().tz(userTz).format("YYYY-MM-DD");
    const entityName = tableName.replace(ECP_REE_PREFIX__PATT, "");
    return `${tsString}-cmax-${entityName}.csv`;
  }
  return "export.csv";
}

export function makeJsonCompatible(value) {
  // if (typeof value?.toJSON === "function") {
  let ret = value;
  if (value instanceof BaseVector) {
    ret = value.toJSON();
  } else if (typeof value?.[Symbol.toPrimitive] === "function") {
    ret = value[Symbol.toPrimitive]("default");
  }
  // console.log("typeof value[Symbol.toPrimitive]:", typeof value[Symbol.toPrimitive]);
  // console.log("value:", value);
  // console.log("value:", value);
  // console.log("typeof value:", typeof value);
  // console.log("ret:", ret);
  return ret;
}

const processCellCallbackForCsvExport = (
  params: ProcessCellForExportParams
) => {
  const { column, value } = params;
  const colType = column.getColDef().type;
  const jsonCompatibleValue = makeJsonCompatible(value);

  let ret;
  if (colType === "dateColumn") {
    ret = formatDate(jsonCompatibleValue);
  } else if (colType === "timestampColumn") {
    ret = formatTimestamp(jsonCompatibleValue);
  } else {
    ret = jsonCompatibleValue;
  }

  return ret;
  //
  // if (_.isNil(ret) || _.isNaN(ret)) {
  //   return "";
  // } else if (typeof ret === "string") {
  //   return ret;
  // } else {
  //   return JSON.stringify(ret);
  // }
};

export function useExportToCsv(gridRef) {
  const { timezone: userTz } = useMeData();

  return useCallback(() => {
    const params: CsvExportParams = {
      skipHeader: false,
      columnGroups: true,
      skipFooters: false,
      skipGroups: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: false,
      shouldRowBeSkipped: () => false,
      processCellCallback: processCellCallbackForCsvExport,
      processHeaderCallback: null,
      fileName: getFileName(
        gridRef.current.gridOptions.context?.tableName,
        userTz
      ),
    };
    // noinspection JSUnresolvedVariable
    gridRef.current.gridOptions.api.exportDataAsCsv(params);
  }, [gridRef]);
}

export function useAutoSizeAll(gridRef) {
  return useCallback(() => {
    // @ts-ignore
    const isCypressRuntime = !!window.Cypress;
    if (!gridRef.current || isCypressRuntime) {
      return;
    }
    const columnApi = gridRef.current.gridOptions.columnApi;
    const allColumnIds = columnApi
      .getAllColumns()
      .filter((column) => _.isUndefined(column.userProvidedColDef.width)) // If user supplied column width, then leave it be
      .map((column) => column.colId);
    // console.log("autoSizing");
    columnApi.autoSizeColumns(allColumnIds);
  }, [gridRef]);
}

export function useGetAllGridColumns(gridRef) {
  return useCallback(() => {
    if (gridRef.current === undefined) {
      return [];
    } else {
      return gridRef.current.columnApi.getAllGridColumns();
    }
  }, [gridRef]);
}

export function useGetIsColumnVisible(gridRef) {
  return useCallback(
    (colId) => {
      return gridRef.current
        ? gridRef.current.columnApi.getColumn(colId).visible
        : null;
    },
    [gridRef]
  );
}

export function useGetAreAllColumnsVisible(gridRef) {
  const getAllGridColumns = useGetAllGridColumns(gridRef);
  return useCallback(
    () => _.every(getAllGridColumns().map((col) => col.visible)),
    [getAllGridColumns]
  );
}

export function useSetQuickFilterText(gridRef) {
  const setText = useSetSearchBoxText();
  return useCallback(
    (text: string) => {
      setText(text);
      if (!gridRef.current) {
        return;
      }
      gridRef.current.api.setQuickFilter(text);
    },
    [setText, gridRef]
  );
}
