import React from "react";
import styled from "styled-components/macro";
import { useStoreState } from "../../hooks/ep";
import { useMyRole } from "../../entities/helpers/authorization";
import { UserRole } from "../../store/model-me";
import {
  ColTableConfig,
  TableDescriptions,
} from "../../components/table-descriptions";
import { TableRow } from "../../store/table-model-factory";

const REFERENCE_DAYS_FOR_LOOKBACK_CALCULATIONS = [
  0, 90, 180, 365, 730, 1095, 1460, 1825,
];

export function SummaryUnit({
  fullScreenEnabled = true,
  selectedEnrollmentCoordinatorIndex,
  ...restProps
}): JSX.Element {
  const retentionKpisRowData = useStoreState((s) => s.retentionKpis.rowData);
  const selectedEnrollmentCoordinator = retentionKpisRowData.filter(
    (ec) => ec.EC_Index === selectedEnrollmentCoordinatorIndex
  );

  let dataSource: TableRow[] = [];
  const orderStats = ["Overall", "SoR", "Non-SoR"];

  orderStats.forEach((stats) => {
    const row = selectedEnrollmentCoordinator.find((row) => row.SoR === stats);
    if (row) dataSource.push(row);
  });

  return (
    <SummaryUnitOuter {...restProps}>
      <SummaryUnitInner summaryRow={dataSource} {...restProps} />
    </SummaryUnitOuter>
  );
}

function SummaryUnitInner({ summaryRow, ...restProps }): JSX.Element {
  return (
    <TableDescriptions
      columnsFormat={useRetentionKpiSummaryCols()}
      dataSource={summaryRow}
      {...restProps}
    />
  );
}

function getHeaderTitle(startDaysAgo: number, date = new Date()): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "2-digit",
    month: "short",
  };

  const endDateFirstRange = new Date(date.getTime());
  endDateFirstRange.setDate(date.getDate() - startDaysAgo);
  const endDateFirstRangeString = endDateFirstRange
    .toLocaleString(["en-US"], options)
    .replaceAll(" ", "");

  const startDateFirstRange = new Date(date.getTime());
  startDateFirstRange.setDate(date.getDate() - (startDaysAgo + 365));
  const startDateFirstRangeString = startDateFirstRange
    .toLocaleString(["en-US"], options)
    .replaceAll(" ", "");

  const endDateSecondRange = new Date(date.getTime());
  endDateSecondRange.setDate(date.getDate() - (startDaysAgo + 365));
  const endDateSecondRangeString = endDateSecondRange
    .toLocaleString(["en-US"], options)
    .replaceAll(" ", "");

  const startDateSecondRange = new Date(date.getTime());
  startDateSecondRange.setDate(date.getDate() - (startDaysAgo + 730));
  const startDateSecondRangeString = startDateSecondRange
    .toLocaleString(["en-US"], options)
    .replaceAll(" ", "");

  const headerName = `Retention (${startDateFirstRangeString} - ${endDateFirstRangeString} vs ${startDateSecondRangeString}-${endDateSecondRangeString})`;
  return headerName;
}

function getRetentionChurnRateColDef({
  today,
  isAdmissionsOrEnrollmentCoordinator,
  startDaysAgo,
}: {
  today: Date;
  isAdmissionsOrEnrollmentCoordinator: boolean;
  startDaysAgo: number;
}): ColTableConfig {
  const renrRange = [startDaysAgo, startDaysAgo + 365];
  const previousEnrRange = [startDaysAgo + 365, startDaysAgo + 730];
  const renrRangeName = `${renrRange[0] + 1}_to_${renrRange[1]}_days`;
  const previousEnrRangeName = `${previousEnrRange[0] + 1}_to_${
    previousEnrRange[1]
  }_days`;

  const n_renr_stus_between_renr_range_colname = `n_renr_stus_within_past_${renrRangeName}`;
  const renr_stus_ids_between_renr_range_colname = `renr_stu_ids_within_past_${renrRangeName}`;
  const n_active_stus_between_previous_enr_range_colname = `n_active_stus_within_past_${previousEnrRangeName}`;
  const active_stus_ids_between_previous_enr_range_colname = `active_stu_ids_within_past_${previousEnrRangeName}`;

  return {
    label: getHeaderTitle(startDaysAgo, today),
    field: `retention_rate_as_of_${startDaysAgo}_days_ago`,
    type: "relatedIds",
    width: 95,
    tooltip: `Ratio of students that have re-enrolled within the past ${renrRange[0]} to ${renrRange[1]} 
        days from the total of students actively enrolled ${previousEnrRange[0]} to ${previousEnrRange[1]} 
        days ago, excluding students in 12th grade`,
    __authorized: !isAdmissionsOrEnrollmentCoordinator,
    childItemConfigs: [
      {
        label: `# Re-Enrolled Past ${renrRange[0]} to ${renrRange[1]} Days`,
        field: n_renr_stus_between_renr_range_colname,
        fieldIds: renr_stus_ids_between_renr_range_colname,
        baseEntityName: "loyaltyStudents",
        type: "relatedIds",
        tooltip: `Students that have re-enrolled within the past ${renrRange[0]} to ${renrRange[1]} days`,
      },
      {
        label: `# Enrolled Within Past ${previousEnrRange[0]} to ${previousEnrRange[1]} Days`,
        field: n_active_stus_between_previous_enr_range_colname,
        fieldIds: active_stus_ids_between_previous_enr_range_colname,
        baseEntityName: "loyaltyStudents",
        type: "relatedIds",
        tooltip: `Students actively enrolled ${previousEnrRange[0]}-${previousEnrRange[1]}  days ago that were not in 12th grade at the time`,
      },
    ],
  };
}

function useRetentionKpiSummaryCols(): ColTableConfig[] {
  const myRole = useMyRole();
  const isAdmissionsOrEnrollmentCoordinator =
    myRole === UserRole.ADMISSIONS_COORDINATOR ||
    myRole === UserRole.ENROLLMENT_COORDINATOR;

  const today = new Date();

  const ret: ColTableConfig[] = [
    {
      label: "Stats",
      field: "SoR",
      type: "text",
      fixed: "left",
      width: 95,
      tooltip: "Indicates whether the stats is SoR, Non-SoR, or Overall",
    },
    {
      label: "Revenue (YTD)",
      field: "year_to_date_revenue",
      type: "money",
      width: 95,
      tooltip:
        "Revenue that has been collected in the current calendar year for the specified group.",
    },
    {
      label: "# Students Completing Courses in Next 45 Days",
      field: "n_upcoming_renr_potential_students",
      type: "integer",
      width: 100,
      tooltip: "Number of students completing courses in next 45 days",
    },
    {
      label:
        "% Students Completing Courses in Next 45 Days who Have Not Re-Enrolled",
      field: "pct_upcoming_renr_potential_students__not_reenrolled",
      type: "percentage",
      width: 100,
      tooltip:
        "Percentage of students completing courses in next 45 days who have not re-enrolled",
    },
    {
      label: "# Students in Past 365 Days Who Have Not Re-Enrolled",
      field: "n_recent_completion_students",
      type: "integer",
      width: 100,
      tooltip: "Number of students in past 365 days who have not re-enrolled",
    },
    {
      label: "% Students in Past 365 Days Who Have Not Re-Enrolled",
      field: "pct_recent_completion_students__not_reenrolled",
      type: "percentage",
      width: 100,
      tooltip:
        "Percentage of students in past 365 days who have not re-enrolled",
    },
    ...REFERENCE_DAYS_FOR_LOOKBACK_CALCULATIONS.map((value) =>
      getRetentionChurnRateColDef({
        today: today,
        isAdmissionsOrEnrollmentCoordinator:
          isAdmissionsOrEnrollmentCoordinator,
        startDaysAgo: value,
      })
    ),
  ];

  return ret.filter((cd) => cd.__authorized ?? true);
}

const SummaryUnitOuter = styled.div`
  width: 100%;
  height: 100%;
`;
