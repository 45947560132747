import React from "react";
import { Tooltip } from "@blueprintjs/core";
import { AgColDef } from "../../components/AgTable/types";
import "styled-components/macro";

function LoyaltyStatisticsUnitTableHeaderCell(props: { cd: any }): JSX.Element {
  return (
    <th>
      <Tooltip
        content={
          props.cd.field === "email_type_name" ? null : (
            <div
              css={`
                max-width: 40vw;
              `}
            >
              {props.cd.headerTooltip.split("\n").map(renderHeaderCellTextLine)}
            </div>
          )
        }
        position={"top"}
        boundary={"viewport"}
      >
        {props.cd.field === "email_type_name" ? (
          ""
        ) : (
          <div>
            {props.cd.headerName.split("\n").map(renderHeaderCellTextLine)}
          </div>
        )}
      </Tooltip>
    </th>
  );
}

export function renderHeaderCell(cd: AgColDef): JSX.Element {
  return <LoyaltyStatisticsUnitTableHeaderCell key={cd.field} cd={cd} />;
}

function HeaderCellTextLine({ line }: { line: string }): JSX.Element {
  return (
    <span key={line}>
      {line}
      <br />
    </span>
  );
}

function renderHeaderCellTextLine(line: string): JSX.Element {
  return <HeaderCellTextLine key={line} line={line} />;
}
