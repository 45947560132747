import { Action, action, ActionCreator, ActionOn, actionOn } from "easy-peasy";
import { StoreModel } from "./model";
import queryString from "query-string";
import { useStoreActions, useStoreState } from "../hooks/ep";

export type EntityId = string | number;
export type EntityType =
  | "student"
  | "grade_level"
  | "loyalty_year"
  | "sor"
  | "order_index"
  | "should_filter_actionable_students_course_scheduling";
export const ALL_GRADE_LEVELS = -1;
export const ALL_SOR = -1;
export const DEFAULT_SELECTED_ENTITY_IDS = {
  student: "",
  grade_level: ALL_GRADE_LEVELS,
  loyalty_year: "",
  order_index: "",
  sor: ALL_SOR,
  should_filter_actionable_students_course_scheduling: 1,
};

export interface EntitySelectionModel {
  NAME: string;
  selectedEntityIds: { [entityType in EntityType]: EntityId };
  selectEntityId: Action<EntitySelectionModel, [EntityType, EntityId]>;
  onLogin: ActionOn<EntitySelectionModel, StoreModel>;
  onLogout: ActionOn<EntitySelectionModel, StoreModel>;
}

export const entitySelectionModel: EntitySelectionModel = {
  // NOTE: Customer "IDs" are `${studentName} (${studentId})`
  // NOTE: User "IDs" are actually user emails
  NAME: "entitySelection",
  selectedEntityIds: DEFAULT_SELECTED_ENTITY_IDS,
  selectEntityId: action((state, [newEntityType, newEntityId]) => {
    state.selectedEntityIds[newEntityType] = newEntityId;
  }),
  onLogin: actionOn(
    (__, { me }) => me.receiveInitialData,
    (state, target) => {
      state.selectedEntityIds = {
        ...DEFAULT_SELECTED_ENTITY_IDS,
        ...queryString.parse(window.location.search),
      };
    }
  ),
  onLogout: actionOn(
    (__, { me }) => me.resetData,
    (state, target) => {
      state.selectedEntityIds = DEFAULT_SELECTED_ENTITY_IDS;
    }
  ),
};

type SelectEntityId = ActionCreator<[EntityType, EntityId]>;
export type SelectEntityIdOfType = { (entityId: EntityId): void };

export function useSelectedEntityId(entityType: EntityType): EntityId {
  return useStoreState((s) => s.entitySelection.selectedEntityIds[entityType]);
}

export function useSelectEntityId(
  entityType: EntityType
): SelectEntityIdOfType {
  const selectEntityId: SelectEntityId = useStoreActions(
    (s) => s.entitySelection.selectEntityId
  );
  return (entityId: EntityId) => selectEntityId([entityType, entityId]);
}

export function useEntitySelection(
  entityType: EntityType
): [EntityId, SelectEntityIdOfType] {
  return [useSelectedEntityId(entityType), useSelectEntityId(entityType)];
}
