import styled from "styled-components/macro";

export const SingleStudentCourseSchedulingTabContainerDiv = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-gap: 40px;

  grid-template:
    "selection  selection selection" min-content
    "basic      basic     basic    " min-content
    "propagate-course-schedule-into-genius    propagate-course-schedule-into-genius   propagate-course-schedule-into-genius  " min-content
    "next-year-course-schedule-recommendations    next-year-course-schedule-recommendations   next-year-course-schedule-recommendations  " min-content
    "orders-history    orders-history   orders-history  " min-content
    "history    history   history  " min-content / 1fr 1fr 1fr;

  & .cmax-selection-next-year-course-schedule-recommendations-unit {
    grid-area: selection;
  }

  & .cmax-basic-next-year-course-schedule-recommendations-unit {
    grid-area: basic;
  }

  & .cmax-propagate-course-schedule-into-genius-unit {
    grid-area: propagate-course-schedule-into-genius;
  }

  & .cmax-next-year-course-schedule-recommendations-unit {
    grid-area: next-year-course-schedule-recommendations;
  }

  & .cmax-history-next-year-course-schedule-recommendations-unit {
    grid-area: history;
  }

  & .cmax-orders-history-next-year-course-schedule-recommendations-unit {
    grid-area: orders-history;
  }
`;
