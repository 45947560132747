import { Computed, computed } from "easy-peasy";
import { StoreModel } from "../model";
import {
  TableModel,
  tableModelFactory,
  TableRow,
} from "../table-model-factory";
import {
  happenedToday,
  happenedWithinPastTwoWeeks,
} from "./model-ecp-customer-interactions";
import { timestampVF } from "../../components/AgTable/gridOptions";
import _ from "lodash";
import { addTagsField } from "../../entities/ecp/tags";
import { timeit } from "../../helpers/timing";
import { ensureStudentFlagFieldDefaults } from "../../user-actions/submit-user-action__update_student_flag";
import { ensureStudentAttrFieldDefaults } from "../../user-actions/submit-user-action__update_student_attr";

export interface EcpStudentTableRow extends TableRow {
  customer_id: number;
  StudentIndex: number;
  customer_name: string;
  PrimaryContactPhoneNumber: string;
  PrimaryContactEmail: string;
  SecondaryContactPhoneNumber: string;
  SecondaryContactEmail: string;
  starred: number;
}

export interface RowDataById {
  [key: string]: EcpStudentTableRow;
  [key: number]: EcpStudentTableRow;
}

export type EcpStudentModel = TableModel & {
  rowData: Computed<EcpStudentModel, TableRow[], StoreModel>;
  rowDataWithStudentFlagFieldsAndStudentAttrFields: Computed<
    EcpStudentModel,
    TableRow[],
    StoreModel
  >;
  rowDataById: Computed<EcpStudentModel, RowDataById, StoreModel>;
  rowDataWithStudentFlagFieldsAndStudentAttrFieldsById: Computed<
    EcpStudentModel,
    RowDataById,
    StoreModel
  >;
};

export function getEcpStudentModel(): EcpStudentModel {
  // @ts-ignore
  return {
    ...tableModelFactory(
      "ecp_student",
      "ecp/ff_students",
      (row) => row.customer_id,
      [
        {
          name: "ecp_enrollment",
          arity: "OneToMany",
          foreignName: "ecp_enrollment",
          foreignField: "customer_id",
          field: "customer_id",
        },
        {
          name: "ecp_order",
          arity: "OneToMany",
          foreignName: "ecp_order",
          foreignField: "customer_id",
          field: "customer_id",
        },
        {
          name: "nextCourseRecos",
          arity: "OneToOne",
          foreignName: "nextCourseRecos",
          foreignField: "StudentIndex",
          field: "customer_id",
        },
        {
          name: "nextYearCourseScheduleRecommendations",
          arity: "OneToMany",
          foreignName: "nextYearCourseScheduleRecommendations",
          foreignField: "StudentIndex",
          field: "customer_id",
        },
        {
          name: "recommendationActionReport",
          arity: "OneToOne",
          foreignName: "ecp_customerInteraction",
          foreignField: ["action_payload", "customer_id"],
          field: "customer_id",
        },
        {
          name: "star",
          arity: "OneToOne",
          foreignName: "stars",
          foreignField: "customer_id",
          field: "customer_id",
        },
        // {
        //   name: "student_flag__no_action_required",
        //   arity: "OneToOne",
        //   foreignName: "student_flag",
        //   foreignField: "student_id",
        //   field: "customer_id",
        //   foreignFilter: (row) => row.flag_codename === "no_action_required",
        // },
      ],
      (initData) =>
        ensureStudentAttrFieldDefaults(
          ensureStudentFlagFieldDefaults(addTagsField(initData, null))
        )
    ),
    rowData: computed(
      [
        (state) => state.initialData,
        (state) => state.relatedIdsMap,
        (state) => state.relatedRowsMap,
        (state, storeState) =>
          storeState.ecp_customerInteraction.customerId_to_latest,
        (state, storeState) => storeState.me.initialData.timezone,
      ],
      timeit(
        (
          rowData,
          relatedIdsMap,
          relatedRowsMap,
          latestItxnById,
          myTimezone
        ) => {
          if (!_.size(rowData)) {
            return [];
          }
          return rowData.map((row) => {
            const starred = +!!relatedIdsMap[row.id]?.star;

            const latestInteraction = latestItxnById[row.id];
            const hasReportedCustomerInteraction = !!latestInteraction;
            const wasCustomerInteractionToday = happenedToday(
              latestInteraction?.submitted_at
            );
            const wasCustomerInteractionWithinPastTwoWeeks =
              happenedWithinPastTwoWeeks(latestInteraction?.submitted_at);
            const latestInteractionTimestamp = latestInteraction?.submitted_at;
            const formattedLatestInteractionTimestamp = timestampVF({
              value: latestInteractionTimestamp,
              colDef: { __timezone: myTimezone },
            });

            const enrollments = relatedRowsMap[row.id]?.ecp_enrollment ?? [];
            const enrollmentIds = enrollments.map((v) => v.id);

            const orders = relatedRowsMap[row.id]?.ecp_order ?? [];

            const next_course_recos =
              relatedRowsMap[row.id]?.nextCourseRecos?.next_course_recos ?? [];
            // const nextCourseRecosRowIds = nextCourseRecosRows.map((v) => v.id);

            const next_year_course_schedule_recommendations =
              relatedRowsMap[row.id]?.nextYearCourseScheduleRecommendations ??
              [];

            return {
              ...row,
              starred,
              hasReportedCustomerInteraction,
              wasCustomerInteractionToday,
              wasCustomerInteractionWithinPastTwoWeeks,
              latestInteractionTimestamp,
              formattedLatestInteractionTimestamp,
              enrollments,
              orders,
              enrollmentIds,
              num_enrollments: _.size(enrollmentIds),
              next_course_recos,
              next_year_course_schedule_recommendations,
              // nextCourseRecosRows,
              // nextCourseRecosRowIds,
            };
          });
        },
        "store.ecp_student.rowData"
      )
    ),
    rowDataWithStudentFlagFieldsAndStudentAttrFields: computed(
      [
        (state) => state.rowData,
        (state, storeState) => storeState.student_flag.valuesMapByStudent,
        (state, storeState) => storeState.student_attr.valuesMapByStudent,
      ],
      timeit(
        (
          rowData,
          studentFlagValuesMapByStudent,
          studentAttrValuesMapByStudent
        ) => {
          return rowData.map((row) => {
            const student_id = row.StudentIndex;
            const flagValuesMap = studentFlagValuesMapByStudent[student_id];
            const attrValuesMap = studentAttrValuesMapByStudent[student_id];
            if (flagValuesMap) {
              row = { ...row, ...flagValuesMap };
            }
            if (attrValuesMap) {
              row = { ...row, ...attrValuesMap };
            }
            return row;
          });
        },
        "store.ecp_student.rowDataWithStudentFlagFieldsAndStudentAttrFields"
      )
    ),
    rowDataById: computed(
      [(s) => s.rowData],
      (rowData) =>
        Object.fromEntries(rowData.map((r) => [r.id, r])) as RowDataById
    ),
    rowDataWithStudentFlagFieldsAndStudentAttrFieldsById: computed(
      [(s) => s.rowDataWithStudentFlagFieldsAndStudentAttrFields],
      (rowData) =>
        Object.fromEntries(rowData.map((r) => [r.id, r])) as RowDataById
    ),
  };
}
