import {
  action,
  actionOn,
  computed,
  createContextStore,
  thunkOn,
} from "easy-peasy";
import _ from "lodash";
import { pushFactory, setFactory } from "./helpers";
import "styled-components/macro";
import {
  getReeCustomerInteractionFormErrorMessage,
  getEcpCustomerInteractionFormErrorMessage,
  getLeadCustomerInteractionFormErrorMessage,
} from "./form-error-messages";
import { DEFAULT_INTERACTION_RESULTS } from "./lead--interaction-result-input";

function model({ key, actionName, snapshot }) {
  if (actionName === "ree_customer_interaction") {
    return {
      formTarget: "Customer",
      DEFAULT_ACTIVE_FIELD: "callTopics",
      //
      callTopicsQueryText: "",
      setCallTopicsQueryText: setFactory("callTopicsQueryText"),
      //
      callTopics: [],
      pushCallTopic: pushFactory("callTopics"),
      clearCallTopics: action((state) => {
        state.callTopics = [];
      }),
      removeCallTopic: action((state, reason) => {
        const codeName = _.isString(reason) ? reason : reason.codeName;
        const longName = _.isString(reason) ? reason : reason.longName;
        state.callTopics = state.callTopics.filter(
          (r) => r.codeName !== codeName && r.longName !== longName
        );
      }),
      //
      //
      customerIssuesQueryText: "",
      setCustomerIssuesQueryText: setFactory("customerIssuesQueryText"),
      //
      customerIssues: [],
      pushCustomerIssue: pushFactory("customerIssues"),
      clearCustomerIssues: action((state) => {
        state.customerIssues = [];
      }),
      removeCustomerIssue: action((state, reason) => {
        const codeName = _.isString(reason) ? reason : reason.codeName;
        const longName = _.isString(reason) ? reason : reason.longName;
        state.customerIssues = state.customerIssues.filter(
          (r) => r.codeName !== codeName && r.longName !== longName
        );
      }),
      //
      //
      notes: "",
      setNotes: setFactory("notes"),
      phone: "",
      setPhone: setFactory("phone"),
      //
      //
      customerSentiment: null,
      setCustomerSentiment: setFactory("customerSentiment"),
      //
      //
      churnLikelihood: null,
      setChurnLikelihood: setFactory("churnLikelihood"),
      //
      //
      attendees: "",
      setAttendees: setFactory("attendees"),
      //
      //
      //
      formData: computed(
        ({
          callTopics,
          customerIssues,
          notes,
          phone,
          customerSentiment,
          churnLikelihood,
          attendees,
        }) => ({
          callTopics,
          customerIssues,
          notes,
          phone,
          customerSentiment,
          churnLikelihood,
          attendees,
        })
      ),
      errorMessage: computed(getReeCustomerInteractionFormErrorMessage),
    };
  } else if (actionName === "ecp_customer_interaction") {
    return {
      formTarget: "Student",
      DEFAULT_ACTIVE_FIELD: "interactionType",
      //
      interactionType: null,
      setInteractionType: setFactory("interactionType"),
      onSetInteractionType: thunkOn(
        (actions) => actions.setInteractionType,
        (actions, _, { getState }) => {
          const newInteractionType = getState().interactionType;
          if (newInteractionType === "Email") {
            actions.clearPhoneNumber();
          } else {
            actions.clearEmailAddress();
          }
        }
      ),
      //
      emailAddressOptions: _.uniq(
        [snapshot.Email, snapshot.Guardian1Email]
          .filter((v) => !!(v ?? "").trim())
          .map((v) => v.toLowerCase())
      ),
      clearEmailAddress: action((state) => {
        state.emailAddress = "";
      }),
      emailAddress: "",
      setEmailAddress: setFactory("emailAddress"),
      // // CAN RE-ENABLE FOR DEBUGGING
      // onSetEmailAddress: actionOn(
      //   (actions) => actions.setEmailAddress,
      //   (state, payload) => {
      //     console.log("src/components/reporting-actions/form-store.js", {
      //       old_emailAddress: state.emailAddress,
      //       new_emailAddress: payload,
      //     });
      //   }
      // ),
      //
      phoneNumberOptions: _.uniq(
        [snapshot.Phone, snapshot.Guardian1Phone].filter(
          (v) => !!(v ?? "").trim()
        )
      ),
      clearPhoneNumber: action((state) => {
        state.phoneNumber = "";
      }),
      phoneNumber: "",
      setPhoneNumber: setFactory("phoneNumber"),
      //
      //
      riskReasonsQueryText: "",
      setRiskReasonsQueryText: setFactory("riskReasonsQueryText"),
      //
      riskReasons: [],
      pushRiskReason: pushFactory("riskReasons"),
      clearRiskReasons: action((state) => {
        state.riskReasons = [];
      }),
      removeRiskReason: action((state, reason) => {
        const codeName = _.isString(reason) ? reason : reason.codeName;
        const longName = _.isString(reason) ? reason : reason.longName;
        state.riskReasons = state.riskReasons.filter(
          (r) => r.codeName !== codeName && r.longName !== longName
        );
      }),
      //
      notes: "",
      setNotes: setFactory("notes"),
      //
      //
      formData: computed(
        ({
          interactionType,
          emailAddress,
          phoneNumber,
          riskReasons,
          notes,
        }) => ({
          interactionType,
          emailAddress,
          phoneNumber,
          riskReasons,
          notes,
        })
      ),
      errorMessage: computed(getEcpCustomerInteractionFormErrorMessage),
    };
  } else if (actionName === "lead_customer_interaction") {
    return {
      formTarget: "Customer",
      DEFAULT_ACTIVE_FIELD: "interactionType",
      //
      interactionType: null,
      setInteractionType: setFactory("interactionType"),
      onSetInteractionType: thunkOn(
        (actions) => actions.setInteractionType,
        (actions, _, { getState }) => {
          const newInteractionType = getState().interactionType;
          if (newInteractionType === "Email") {
            actions.setInteractionResult(
              DEFAULT_INTERACTION_RESULTS.find(
                (item) => item.codeName === "emailed"
              )
            );
          } else {
            actions.setInteractionResult(
              DEFAULT_INTERACTION_RESULTS.find(
                (item) => item.codeName === "talked"
              )
            );
          }
        }
      ),
      //
      interactionResult: "",
      setInteractionResult: setFactory("interactionResult"),
      //
      notes: "",
      setNotes: setFactory("notes"),
      //
      //
      formData: computed(({ interactionType, interactionResult, notes }) => ({
        interactionType,
        interactionResult,
        notes,
      })),
      errorMessage: computed(getLeadCustomerInteractionFormErrorMessage),
    };
  }
}

export const FormStore = createContextStore(model);
