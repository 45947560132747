import React from "react";
import { CampaignConfiguration } from "./types";
import { numberToOrdinal } from "./value-formatting";
import { H5 } from "@blueprintjs/core";
import styled from "styled-components/macro";

export function CampaignSendingSchedule({
  campaign_configuration,
}: {
  campaign_configuration: CampaignConfiguration;
}): JSX.Element {
  const email_type_codes: string[] =
    campaign_configuration.allowed_email_type_codes;

  const formatSendingSchedule = (sendingSchedule: Date): string => {
    return sendingSchedule.toLocaleDateString([], {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <div>
      <StyledH5>{"Sending Schedule"}</StyledH5>
      {email_type_codes.map((email_type_code) => {
        const sendingSchedule = new Date(
          campaign_configuration.sending_schedule[email_type_code]
        );
        const waveNumber =
          campaign_configuration.wave_numbers_by_email_type_code[
            email_type_code
          ];
        return (
          <StyledUnitItem key={email_type_code} className={"basic-unit-item"}>
            <span>
              {`${numberToOrdinal(waveNumber)} Wave: ${formatSendingSchedule(
                sendingSchedule
              )}`}
            </span>
          </StyledUnitItem>
        );
      })}
    </div>
  );
}

const StyledH5 = styled(H5)`
  margin: 40px 0 10px 0 !important;
  padding: 0 !important;
`;

const StyledUnitItem = styled.div`
  display: flex;
`;
