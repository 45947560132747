import React from "react";
import { LeadsQueueTable } from "../../entities/leadsQueue-entity";
import { ReportActionModalWrapper } from "../../components/reporting-actions/modal-or-drawer";
import _ from "lodash";

export function DailyQueueUnit({
  fullScreenEnabled = true,
  selectedAdmissionsCoordinatorName,
}): JSX.Element {
  const finalizeRowData = (rowData) => {
    return _.sortBy(rowData, "has_customer_interaction");
  };
  return selectedAdmissionsCoordinatorName ? (
    <>
      <LeadsQueueTable
        fullScreenEnabled={fullScreenEnabled}
        finalizeRowData={finalizeRowData}
        selectedAdmissionsCoordinatorName={selectedAdmissionsCoordinatorName}
      />
      <ReportActionModalWrapper />
    </>
  ) : null;
}
