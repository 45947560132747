import _ from "lodash";
import {
  DEFAULT_SELECTED_ENTITY_IDS,
  useSelectedEntityId,
} from "../../store/model-entity-selection";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { Unit } from "../../components/units/unit";
import React from "react";
import AgTable from "../../components/AgTable";
import { AgColDef } from "../../components/AgTable/types";
import { useSelectedCustomer } from "../../components/units/selection-unit";
import { getStudentGeniusUrl } from "../../entities/ecp/ecp-student-entity";
import { useNextYearCourseScheduleRecommendationsColDefs } from "./all-students-next-year-course-schedule-recommendations-tab";

export function SingleStudentNextYearCourseScheduleRecommendationsUnit(): JSX.Element {
  const loading = _.max([
    DEFAULT_SELECTED_ENTITY_IDS.student === useSelectedEntityId("student"),
    useEnsureData("ecp_student"),
    useEnsureData("nextYearCourseScheduleRecommendations"),
  ]);

  return (
    <Unit
      unitId={"next-year-course-schedule-recommendations"}
      loading={loading}
    >
      <SingleStudentNextYearCourseScheduleRecommendationsContent />
    </Unit>
  );
}

function SingleStudentNextYearCourseScheduleRecommendationsContent(): JSX.Element {
  const customer = useSelectedCustomer();
  const rowData = customer.next_year_course_schedule_recommendations;
  const colDefs = useNextYearCourseScheduleRecommendationsColDefs([
    "CourseName",
  ]);
  const height = _.min([500, 64 + 48 * rowData.length]);
  return (
    <AgTable
      tableName={`next-year-course-schedule-recommendations--student-${customer.StudentIndex}`}
      rowData={rowData}
      columnDefs={colDefs}
      ribbonTitle={"Next Year Course Schedule Recommendations"}
      height={`${height}px`}
    />
  );
}
