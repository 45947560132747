import { AgColDefModifiers, AgColDefs } from "../../components/AgTable/types";
import {
  getStudentBuzzUrl,
  getStudentGeniusUrl,
} from "../../entities/ecp/ecp-student-entity";
import _ from "lodash";
import { useMyRole } from "../../entities/helpers/authorization";

export function useRetentionAnalysisStudentsColDefs(
  colDefModifiers: AgColDefModifiers = null
): AgColDefs {
  const myRole = useMyRole();
  let ret = [
    {
      headerName: "Genius",
      field: "StudentIndex",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentGeniusUrl,
      __linkIsNotAnEmail: true,
      pinned: "left",
    },
    {
      headerName: "Buzz",
      field: "LMSID",
      type: "linkedTextColumn",
      __linkValueGetter: getStudentBuzzUrl,
      __linkIsNotAnEmail: true,
      pinned: "left",
    },
    {
      headerName: "Student Name",
      field: "customer_name",
      width: 180,
      suppressSizeToFit: true,
      tooltipField: "customer_name",
      type: "linkedTextColumn",
      filter: "categoryFilterer",
      __linkValueGetter: ({ data }) =>
        data.hasNextCourseRecos
          ? `/ecp/customer360?student=${encodeURIComponent(data.customer_id)}`
          : null,
      __linkIsInternal: true,
      __linkIsNotAnEmail: true,
    },
    {
      headerName: "Priority",
      field: "priority_scaled",
      type: "floatColumn",
      cellRenderer: "priorityScoreCellRenderer",
      cellClass: "react-rendered-cell",
      headerTooltip:
        "Priority calculated from the days to a student's last enrollment completion multiplied by the student's future revenue potential",
      width: 200,
    },
    {
      headerName: "Completion Date",
      field: "max_enrollment_end_date",
      type: "dateColumn",
      headerTooltip:
        "Date of completion of the enrollment with the latest end date",
      width: 122,
    },
    {
      headerName: "Days to Completion",
      field: "n_days_remaining_until_max_enrollment_end_date",
      type: "integerColumn",
      headerTooltip:
        "Number of days to complete the student's enrollment with the latest end date",
      width: 120,
    },
    {
      headerName: "Has Future Enrollment",
      field: "has_future_enrollment",
      type: "booleanColumn",
      headerTooltip:
        "Indicates if the student has any enrollments in the future",
      width: 140,
    },
    {
      headerName: "Grade Level",
      field: "GradeLevel",
      type: "integerColumn",
      filter: "categoryFilterer",
      cellClass: "ag-numeric-cell",
      width: 110,
    },
    {
      field: "n_historical_enrollments",
      headerName: "Number of Enrollments",
      headerTooltip:
        "Number of courses that the student has historically enrolled in",
      type: "integerColumn",
      width: 150,
    },
    {
      field: "life_time_value",
      headerName: "Historical Revenue",
      type: "moneyColumn",
      tooltipField:
        "Total revenue calculated from the sum of all historical orders of the student",
    },
    {
      field: "ltv_per_sy_enrolled",
      headerName: "Revenue Per Year",
      type: "moneyColumn",
      tooltipField:
        "Yearly average revenue calculated from the student's Historical Revenue divided by the number of years enrolled with LSS",
    },
    {
      headerName: "Tenure [years]",
      field: "n_schoolyears_enrolled",
      type: "floatColumn",
      headerTooltip: "# of years enrolled with LSS",
      width: 100,
    },
    {
      field: "value_at_risk",
      headerName: "Future Revenue Potential",
      valueGetter: (params): number =>
        params.data.value_at_risk < 0 ? 0 : params.data.value_at_risk,
      type: "moneyColumn",
      headerTooltip:
        "Potential revenue calculated from the Revenue Per Year and the remaining school years to graduate from 12th grade",
    },
    {
      field: "AcademyPotential",
      headerName: "Academy Potential",
      type: "booleanColumn",
      width: 110,
      hide: true,
    },
    {
      headerName: "Avg Grade",
      field: "curr_avg_grade",
      type: "percentageColumn",
      width: 97,
      hide: true,
    },
    {
      headerName: "FC 100% Completion Date",
      field: "forecasted_100p_completion_date",
      type: "dateColumn",
      width: 122,
      headerTooltip:
        "The latest forecasted 100% completion date among the student's active enrollments (based on current assignment completion pace for each enrollment)",
      hide: true,
    },
    {
      headerName: "SoR",
      field: "SchoolRecord",
      type: "booleanColumn",
    },
    {
      headerName: "NCAA",
      field: "IsNCAA",
      type: "booleanColumn",
    },
    {
      headerName: "Academy",
      field: "AcademyStudent",
      type: "booleanColumn",
    },
    {
      headerName: "May Not Return",
      field: "MaynotReturn",
      type: "booleanColumn",
      hide: true,
    },
    {
      headerName: "Not Returning",
      field: "NotReturning",
      type: "booleanColumn",
      valueGetter: (params): boolean => !!params.data.NotReturning,
    },
    {
      headerName: "Has Partnership",
      field: "has_partnership",
      type: "booleanColumn",
      valueGetter: (params): boolean => !!params.data.Partner,
    },
    {
      headerName: "Partner",
      field: "Partner",
      type: "categoryColumn",
      width: 180,
    },
    {
      headerName: "Enrolling After Loyalty",
      field: "EnrollingafterLoyalty",
      type: "booleanColumn",
      hide: true,
    },
    {
      headerName: "Retention Notes",
      field: "RetentionNotes",
      type: "textColumn",
      width: 300,
      suppressSizeToFit: true,
      tooltipField: "RetentionNotes",
      cellClass: ["ellipses-tooltip-cell"],
    },
    {
      headerName: "Admissions Coordinator",
      field: "AdmissionsCoordinator",
      type: "categoryColumn",
    },
    {
      headerName: "Academic Advisor",
      field: "AcademicAdvisor",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "AcademicAdvisor",
    },
    {
      headerName: "Counselor",
      field: "Counselor",
      type: "textColumn",
      width: 200,
      suppressSizeToFit: true,
      tooltipField: "Counselor",
    },
    {
      headerName: "Student Services",
      field: "StudentServices",
      type: "categoryColumn",
      hide: true,
    },
    {
      headerName: "Student Phone",
      field: "Phone",
      type: "textColumn",
      tooltipField: "Phone",
    },
    {
      headerName: "Student Email",
      field: "Email",
      type: "textColumn",
      tooltipField: "Email",
    },
    {
      headerName: "Guardian1 Phone",
      field: "Guardian1Phone",
      type: "textColumn",
      tooltipField: "Guardian1Phone",
    },
    {
      headerName: "Guardian1 Email",
      field: "Guardian1Email",
      type: "textColumn",
      tooltipField: "Guardian1Email",
    },
    {
      headerName: "Re-Enrolled Within Past 365 Days",
      field: "has_active_enrs_within_past_1_to_365_days",
      type: "booleanColumn",
      tooltipField:
        "Indicates if student had any active enrollments within the past 365 days",
      hide: true,
    },
    {
      headerName: "Actively Enrolled Within Past 366 to 730 Days",
      field: "has_active_enrs_within_past_366_to_730_days",
      type: "booleanColumn",
      tooltipField:
        "Indicates if student had any active enrollments within the past 366 to 730 days",
      hide: true,
    },
    {
      headerName: "Was In 12th Grade (Past 366 to 730 Days)",
      field: "was_in_12th_grade_within_past_366_to_730_days",
      type: "booleanColumn",
      tooltipField:
        "Indicates if student had any 12th grade enrollments during the past 366 to 730 days",
      hide: true,
    },
  ] as AgColDefs;
  if (colDefModifiers && _.size(colDefModifiers) > 0) {
    ret = ret.map((cd) => {
      const cdModifier = colDefModifiers.find(
        (cmd) => cmd.modifyField === cd.field
      );
      if (cdModifier) {
        delete cdModifier.modifyField;
        if (cdModifier.__ignoreColDef) {
          return null;
        }
        return { ...cd, ...cdModifier };
      }
      return cd;
    });
  }
  ret = ret
    .filter((cd) => !!cd)
    .filter((cd) => {
      // @ts-ignore
      return !cd.__authorized_roles || cd.__authorized_roles.includes(myRole);
    });
  return ret;
}
