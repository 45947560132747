import { Descriptions } from "antd";
import { Colors } from "@blueprintjs/core";
import React from "react";
import "styled-components/macro";
import { TableRow } from "../store/table-model-factory";
import { EntityName } from "../entities/helpers/core";
// import { EntityName } from "./entities/helpers/authorization";

export type DescriptionItemType =
  | "text"
  | "integer"
  | "float"
  | "percentage"
  | "money"
  | "variance"
  | "headcount"
  | "relatedsCount"
  | "relatedsSum"
  | "relatedIds"
  | "date"
  | "element";

export interface DescriptionItemConfig {
  label: string;
  field?: null | string;
  fieldIds?: string;
  baseEntityName?: EntityName;
  relationshipName?: string;
  type: DescriptionItemType;
  childItemConfigs?: DescriptionItemConfig[];
  tooltip?: string | JSX.Element;
  getVisible?: { (data: TableRow): boolean };
  __authorized?: boolean;
  extra?: any;
  renderer?: {
    (
      key: number | string,
      data: TableRow,
      itemConfig: DescriptionItemConfig
    ): JSX.Element;
  };
}

export function FlatDescriptions({
  children,
  withoutBoxShadow,
  ...restProps
}: {
  children: JSX.Element[];
  fill?: boolean;
  title?: string;
  withoutBoxShadow?: boolean;
}): JSX.Element {
  return (
    <Descriptions
      css={`
        & .ant-descriptions-item-label,
        & .ant-descriptions-item-content,
        & .ant-descriptions-title,
        .ant-descriptions-row {
          color: inherit !important;
          border: transparent !important;
        }

        & .ant-descriptions-item-label {
          font-weight: bold !important;
          background-color: ${Colors.DARK_GRAY4} !important;
        }

        & .ant-descriptions-view {
          border: transparent !important;
          overflow-x: auto;
          ${withoutBoxShadow
            ? "box-shadow: none;"
            : `box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4), 0 2px 6px rgba(16, 22, 26, 0.4);`};
        }

        ${(props) =>
          props.fill
            ? `
                width: 100%;
              `
            : ""}
      `}
      title={restProps.title || ""}
      size="small"
      column={children.length}
      bordered={true}
      layout="vertical"
      {...restProps}
    >
      {children}
    </Descriptions>
  );
}
