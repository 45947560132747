import React, { useEffect, useState } from "react";
import { Spinner } from "@blueprintjs/core";
import { OnCellClicked } from "../AgTable/types";
import { CellClickedEvent } from "@ag-grid-community/core/dist/es6/events";
import { EcpStudentTable } from "../../entities/ecp/ecp-student-entity";
import { EcpEnrollmentTable } from "../../entities/ecp/ecp-enrollment-entity";
import "styled-components/macro";
import { useEnsureData } from "../../helpers/use-ensure-data";
import _ from "lodash";
import versionedLocalStorageKey from "../../helpers/versionedLocalStorageKey";
import { lighten } from "polished";
import { FinalizeRowData } from "../AgTable/ag_table";
import { EcpTeachercourseTable } from "../../entities/ecp/ecp-teacherCourse-entity";

export const DEFAULT_TEACHERCOURSE_ID = -1;
type StudentId = number;

export function EcpTeachercourseEnrollmentsSplitview(props) {
  const loading = [
    useEnsureData("ecp_teacherCourse"),
    useEnsureData("ecp_enrollment"),
  ];
  return _.max(loading) ? (
    <Spinner />
  ) : (
    <EcpTeachercourseEnrollmentsSplitviewInner {...props} />
  );
}

function get_selected_teacherCourse_lsk(viewId) {
  return versionedLocalStorageKey(
    `${viewId}--ecp-teacherCourse-enrollment-splitview-page--selectedTeachercourseId`
  );
}

function EcpTeachercourseEnrollmentsSplitviewInner({
  finalizeTeachercourseRowData,
  viewId,
}): JSX.Element {
  viewId = viewId || "";
  const selected_teacherCourse_lsk = get_selected_teacherCourse_lsk(viewId);
  const [selectedTeachercourseId, setSelectedTeachercourseId] =
    useState<number>(() => {
      const stored = localStorage.getItem(selected_teacherCourse_lsk);
      return +(stored ?? DEFAULT_TEACHERCOURSE_ID);
    });

  useEffect(() => {
    localStorage.setItem(
      selected_teacherCourse_lsk,
      selectedTeachercourseId.toString()
    );
  }, [selectedTeachercourseId, selected_teacherCourse_lsk]);

  const onCellClicked_UpperSplit = (ev: CellClickedEvent) => {
    const teacherCourseRow = ev.data;
    const colDef = ev.colDef;
    const teacherCourseId = teacherCourseRow.id;
    // console.log({
    //   teacherCourseRow,
    //   colDef,
    //   teacherCourseId,
    //   selectedTeachercourseId,
    //   DEFAULT_TEACHERCOURSE_ID,
    // });
    if (!colDef.cellRenderer) {
      setSelectedTeachercourseId((v) =>
        teacherCourseId === v ? DEFAULT_TEACHERCOURSE_ID : teacherCourseId
      );
    }
  };

  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}
    >
      {/*<StudentIdSelect*/}
      {/*  selectedTeachercourseId={selectedTeachercourseId}*/}
      {/*  setSelectedTeachercourseId={setSelectedTeachercourseId}*/}
      {/*/>*/}
      <UpperSplit
        onCellClicked={onCellClicked_UpperSplit}
        selectedTeachercourseId={selectedTeachercourseId}
        finalizeRowData={finalizeTeachercourseRowData}
      />
      {selectedTeachercourseId === DEFAULT_TEACHERCOURSE_ID ? null : (
        <LowerSplit selectedTeachercourseId={selectedTeachercourseId} />
      )}
    </div>
  );
}

const TABLE_HEIGHT = "calc((100vh - 64px - 40px - 40px) / 2 - 20px - 48px)";

function UpperSplit({
  onCellClicked,
  selectedTeachercourseId,
  finalizeRowData,
}: {
  selectedTeachercourseId: number;
  onCellClicked: OnCellClicked;
  finalizeRowData: FinalizeRowData;
}): JSX.Element {
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        & .ag-row[row-id="${selectedTeachercourseId}"] {
          background-color: rgba(0, 21, 40, 0.7) !important;
          &.ag-row-hover {
            background-color: ${lighten(
              0.1,
              "rgba(0, 21, 40, 0.7)"
            )} !important;
          }
        }
      `}
    >
      <EcpTeachercourseTable
        onCellClicked={onCellClicked}
        fullScreenEnabled={selectedTeachercourseId === DEFAULT_TEACHERCOURSE_ID}
        height={
          selectedTeachercourseId === DEFAULT_TEACHERCOURSE_ID
            ? null
            : TABLE_HEIGHT
        }
        finalizeRowData={finalizeRowData}
      />
    </div>
  );
}

function LowerSplit({ selectedTeachercourseId }): JSX.Element {
  if (selectedTeachercourseId !== DEFAULT_TEACHERCOURSE_ID) {
    return (
      <EcpEnrollmentTable
        teacherCourseId={selectedTeachercourseId}
        fullScreenEnabled={false}
        height={TABLE_HEIGHT}
        tableNameSuffix={`dc-dash__${selectedTeachercourseId}`}
      />
    );
  } else {
    return null;
  }
}
