function factory_sortArrayValues<ElemT>(cmp: { (a: ElemT, b: ElemT): number }) {
  const sortArrayValues: {
    ([k, arr]: [string, ElemT[]]): [string, ElemT[]];
  } = ([k, arr]) => [k, arr.sort(cmp)];
  return sortArrayValues;
}

export function sortArrayObjectValues<ElemT>(
  obj: { [k: string]: ElemT[] },
  cmp: { (a: ElemT, b: ElemT): number }
): { [k: string]: ElemT[] } {
  const sortArrayValues = factory_sortArrayValues(cmp);
  const entries = Object.entries(obj).map(sortArrayValues);
  return Object.fromEntries(entries);
}
