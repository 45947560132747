import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Spinner } from "@blueprintjs/core";
import "styled-components/macro";
import { useEndpoint } from "../../helpers/use-endpoint";
import {
  CampaignSelector,
  useAllSelectableCampaigns,
} from "./campaign-selector";
import styled from "styled-components/macro";
import { CampaignAttributes } from "./campaign-attributes";
import { CampaignConfiguration, CampaignSelectorOption } from "./types";
import { CampaignEmailTemplates } from "../campaign-email-templates";

export function CampaignOverviewPage() {
  const { loading, data } = useEndpoint<CampaignConfiguration[]>(
    "ecp/customax_campaign_configurations"
  );

  const allCampaigns = useAllSelectableCampaigns(data);

  const defaultSelectedCampaign: CampaignSelectorOption = {
    campaign_codename: undefined,
    display_name: undefined,
  };

  const [selectedCampaign, setSelectedCampaign] =
    useState<CampaignSelectorOption>(defaultSelectedCampaign);

  useEffect(() => {
    if (!loading && !selectedCampaign.campaign_codename) {
      setSelectedCampaign(allCampaigns[0]);
    }
  }, [allCampaigns, loading, selectedCampaign]);

  return loading ? (
    <Spinner />
  ) : (
    <CampaignOverviewPageContainer>
      <CampaignSelector
        allSelectableCampaigns={allCampaigns}
        selectedCampaign={selectedCampaign}
        setSelectedCampaign={setSelectedCampaign}
      />
      <CampaignAttributes
        data={data}
        loading={loading}
        selectedCampaign={selectedCampaign}
      />
      <CampaignEmailTemplates
        data={data}
        selectedCampaign={selectedCampaign}
        loading={loading}
      />
    </CampaignOverviewPageContainer>
  );
}

const CampaignOverviewPageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  row-gap: 30px;
`;
