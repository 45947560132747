import React, { useState } from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import { IncrementalOrdersTable } from "./incremental-orders-table";
import { CampaignemailTable } from "./campaignemail-table";
import { StudentTable } from "./student-table";

export function TableTabsUnit({
  fullScreenEnabled = true,
  cgnStatsHidden,
  ...restProps
}) {
  const defaultTabId = TAB_IDS.cgnEmails;
  const [selectedTabId, setSelectedTabId] = useState(defaultTabId);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
      `}
    >
      <Tabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        fill
        renderActiveTabPanelOnly={true}
        css={`
          margin-top: -15px;
        `}
      >
        <Tab
          id={TAB_IDS.cgnStudents}
          title="New Students"
          panel={<StudentTable cgnStatsHidden={cgnStatsHidden} />}
        />
        <Tab
          id={TAB_IDS.cgnEmails}
          title="Sent Campaign Emails"
          panel={<CampaignemailTable />}
        />
        <Tab
          id={TAB_IDS.incrementalOrders}
          title="Incremental Orders"
          panel={<IncrementalOrdersTable />}
        />
      </Tabs>
    </div>
  );
}

const TAB_IDS = {
  cgnEmails: "cgn-emails",
  cgnStudents: "cgnStudents",
  incrementalOrders: "incrementalOrders",
};
